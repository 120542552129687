import NewReleases from "@mui/icons-material/NewReleases";
import Notifications from "@mui/icons-material/Notifications";
import Box from "@mui/joy/Box";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import dayjs from "dayjs";
import { Notification } from "../../models/types";

export default function NotificationsDrawer({
  notifications,
}: {
  notifications: Notification[];
}) {
  // group by date
  const grouped = notifications.reduce(
    (acc, n) => {
      const date = dayjs(n.created_on).format("DD MMM YYYY");
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(n);
      return acc;
    },
    {} as { [key: string]: Notification[] },
  );

  return (
    <Sheet
      sx={{
        borderRadius: "md",
        p: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        height: "100%",
        overflow: "auto",
      }}
    >
      <Stack direction="column" gap={1}>
        <Box>
          <Typography level="title-lg" startDecorator={<Notifications />}>
            Notifications
          </Typography>
          <ModalClose />
        </Box>
        <Box>
          {notifications.length > 0 ? (
            Object.keys(grouped).map((date) => (
              <Stack key={date} direction="column" gap={1}>
                <Typography level="title-md">{date}</Typography>
                <Stack direction="column" gap={1}>
                  {grouped[date].map((n) => (
                    <Sheet
                      key={n.id}
                      color={n.read ? "neutral" : "warning"}
                      variant="soft"
                      sx={{
                        p: 1,
                        borderRadius: "md",
                        border: "1px solid transparent",
                        opacity: n.read ? 0.7 : 1,
                        cursor: "pointer",
                      }}
                    >
                      <Stack direction="column" gap={1}>
                        <Stack direction="row" gap={1}>
                          <Typography
                            level="body-md"
                            startDecorator={
                              !n.read && <NewReleases color="warning" />
                            }
                          >
                            {n.verb}
                          </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="flex-end">
                          <Typography level="body-xs">
                            {
                              // if today, show time, else show date and time
                              dayjs(n.created_on).isSame(dayjs(), "day")
                                ? dayjs(n.created_on).format("HH:mm")
                                : dayjs(n.created_on).format(
                                    "DD MMM YYYY — HH:mm",
                                  )
                            }
                          </Typography>
                        </Stack>
                      </Stack>
                    </Sheet>
                  ))}
                </Stack>
              </Stack>
            ))
          ) : (
            <Typography level="body-md">Aucune notification</Typography>
          )}
        </Box>
      </Stack>
    </Sheet>
  );
}
