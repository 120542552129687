import "@fontsource/open-sans";
import { extendTheme } from "@mui/joy/styles";

export const customTheme = extendTheme({
  fontFamily: {
    display: "Open Sans, sans-serif",
    body: "Open Sans, sans-serif",
  },
  components: {
    // Customize Joy UI's Tabs component globally
    JoyTabs: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          borderTop: `1px solid #e0e0e0`,
        },
      },
    },
    JoySheet: {
      styleOverrides: {
        root: {
          padding: "16px",
          borderRadius: "8px",
        },
      },
    },
    JoyTable: {
      // also make sure that table headers have the same border radius
      styleOverrides: {
        root: {
          borderRadius: "8px",
          "& thead": {
            "& th:first-of-type": {
              borderTopLeftRadius: "8px",
            },
            "& th:last-of-type": {
              borderTopRightRadius: "8px",
            },
          },
        },
      },
    },
  },
});
