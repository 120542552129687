import React, { useCallback, useState } from "react";
import DocumentUploader, {
  isValidMetaData,
  MetaData,
} from "./DocumentUploader";
import ModalComponent from "../Modal/Modal";
import { CompteRendu, Patient } from "../../models/types";
import { useApi } from "../../contexts/ApiContext";

interface ModalUploaderProps {
  open: boolean;
  onClose: () => void;
  patient: Patient;
  onSave: (cr: CompteRendu[]) => void;
}

const ModalUploader: React.FC<ModalUploaderProps> = ({
  open,
  onClose,
  patient,
  onSave,
}) => {
  const api = useApi();
  const [compteRendus, setCompteRendus] = useState<MetaData[]>([]);
  const [canValidate, setCanValidate] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const onDownload = async () => {
    setIsLoading(true);
    const crError = [];
    const crCreated = [];
    for (const cr of compteRendus) {
      try {
        const c = await api.createCompteRendu({ ...cr }, patient.id);
        crCreated.push(c);
      } catch (e) {
        crError.push(cr);
      }
    }
    setCompteRendus(crError);
    setIsLoading(false);
    onSave(crCreated);
    !crError.length && onClose();
  };

  const onChange = useCallback((files: File[], metaData: MetaData[]) => {
    setCompteRendus(metaData.map((m, index) => ({ ...m, file: files[index] })));
    setCanValidate(!!metaData.length && metaData.every(isValidMetaData));
  }, []);

  return (
    <ModalComponent
      open={open}
      onClose={onClose}
      title={`Upload de documents`}
      validateLabel={"Upload"}
      style={{ minWidth: "80%", minHeight: "80%" }}
      onValidate={onDownload}
      canValidate={canValidate}
      patient={patient}
    >
      <DocumentUploader
        onChange={onChange}
        isLoading={isLoading}
        patient={patient}
      />
    </ModalComponent>
  );
};

export default ModalUploader;
