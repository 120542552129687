import React from "react";
import Modal from "@mui/joy/Modal";
import { ModalClose } from "@mui/joy";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Save from "@mui/icons-material/Save";
import { Patient } from "../../models/types";
import PatientCard from "../PatientCard";

interface ModalProps {
  open: boolean;
  title: string | React.ReactNode;
  closeLabel?: string;
  onClose: () => void;
  validateLabel?: string;
  onValidate?: () => void;
  canValidate?: boolean;
  isLoading?: boolean;
  patient?: Patient;
  style?: any;
  withCloseButton?: boolean;
  children?: React.ReactNode;
}

const ModalComponent: React.FC<ModalProps> = ({
  open,
  title,
  patient,
  closeLabel = "Cancel",
  onClose,
  validateLabel,
  onValidate,
  canValidate = true,
  isLoading,
  style = {},
  withCloseButton = true,
  children,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog sx={style}>
        <ModalClose />
        <Typography id="nested-modal-title" level="h2">
          {title}
        </Typography>
        {patient && <PatientCard patient={patient} width={"100%"} />}
        <div
          style={{
            flex: 1,
            display: "flex",
            overflow: "scroll",
            backgroundColor: "inherit",
          }}
        >
          {children}
        </div>
        {(onValidate || withCloseButton) && (
          <Box
            sx={{
              mt: 1,
              display: "flex",
              gap: 1,
              flexDirection: { xs: "column", sm: "row-reverse" },
            }}
          >
            {onValidate && (
              <Button
                variant="soft"
                color="primary"
                onClick={onValidate}
                startDecorator={<Save />}
                disabled={!canValidate}
                loading={isLoading}
              >
                {validateLabel}
              </Button>
            )}
            {withCloseButton && (
              <Button variant="outlined" color="neutral" onClick={onClose}>
                {closeLabel}
              </Button>
            )}
          </Box>
        )}
      </ModalDialog>
    </Modal>
  );
};

export default ModalComponent;
