import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import React from "react";
import { useApi } from "../../contexts/ApiContext";
import { User } from "../../models/types";

/*
NOTE:
This component is purely passive and does not have any side effects.
It is used to render a select input with a list of doctors.
The list of doctors is fetched from the API and is used to populate the select input,
by default the select input will have the current user selected.
MAKE SURE YOUR PARENT COMPONENT HANDLES THE STATE OF THE SELECT INPUT OTHERWISE IT WILL NOT WORK.
*/
export default function DoctorSelect({
  value,
  onSelect,
  multiple,
  disabled,
}: {
  value?: number | number[] | null;
  onSelect: (value: User | User[]) => void;
  multiple?: boolean;
  disabled?: boolean;
}) {
  const api = useApi();

  const [options, setOptions] = React.useState<User[]>([]);

  const getUsers = React.useCallback(async () => {
    const res = await api.getLinkedUsers();
    setOptions(res.results);
    if (value === null) {
      onSelect(res.results[0]);
    }
  }, [api]);

  React.useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <Select
      multiple={multiple}
      value={options.find((u) => u.id === value)}
      onChange={(e, newValue) => {
        if (newValue) {
          onSelect(newValue as User | User[]);
        }
      }}
      defaultValue={options.find((u) => u.id === 2)}
      disabled={disabled}
    >
      {options.map((option) => (
        <Option key={option.id} value={option}>
          {option.first_name} {option.last_name}
        </Option>
      ))}
    </Select>
  );
}
