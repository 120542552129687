import { Patient } from "../../models/types";
import Autocomplete from "@mui/joy/Autocomplete";
import { useApi } from "../../contexts/ApiContext";
import React, { useEffect } from "react";

interface PatientSelectorProps {
  value: string;
  onSelect: (value: Patient | null) => void;
}

export default function PatientSelector({
  value,
  onSelect,
}: PatientSelectorProps) {
  const api = useApi();
  const [query, setQuery] = React.useState<string>("");
  const [debouncedQuery, setDebouncedQuery] = React.useState(query);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [patients, setPatients] = React.useState<Patient[]>([]);

  useEffect(() => {
    api.getPatient(value).then((res) => {
      setPatients([res]);
    });
  }, [api, value]);

  React.useEffect(() => {
    if (value && patients.length === 1) return;

    setLoading(true);
    if (query === "" || query === " ") {
      setLoading(false);
      setPatients([]);
      return;
    }

    // Set a timeout to update the debounced query after 300ms
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, 500);

    // Clear the timeout if query changes (cleanup function)
    return () => {
      clearTimeout(handler);
    };
  }, [patients.length, query, value]); // Only re-run the effect if query changes

  // Effect to perform the search action when debounced query changes
  React.useEffect(() => {
    if (debouncedQuery) {
      const fetchResults = async () => {
        try {
          setLoading(true);
          const response = await api?.searchPatients(debouncedQuery);
          setPatients(response.results || []);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching search results:", error);
          setLoading(false);
        }
      };

      // Call the async function
      fetchResults();
    }
  }, [debouncedQuery, api]);

  return (
    <Autocomplete
      placeholder={"Rechercher un patient"}
      options={patients}
      getOptionLabel={(option: Patient) =>
        option ? `${option.nom} ${option.prenom}` : ""
      }
      getOptionKey={(option) => option.id}
      onChange={(event, newValue) => onSelect(newValue)}
      value={patients.find((c) => c.id === value) || null}
      onInputChange={(e, value) => e && setQuery(value)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      sx={{ flex: 1 }}
      loading={loading}
    />
  );
}
