import AddIcon from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import Info from "@mui/icons-material/Info";
import OpenInNew from "@mui/icons-material/OpenInNew";
import Person from "@mui/icons-material/Person";
import Search from "@mui/icons-material/Search";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import Box from "@mui/joy/Box";
import CircularProgress from "@mui/joy/CircularProgress";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import { Link } from "react-router-dom";
import { useApi } from "../contexts/ApiContext";
import { useCurrentPatient } from "../contexts/CurrentPatientContext";
import { PaginatedPatientList, Patient } from "../models/types";
import { ISODateToAge, isMacintosh, sexeToGender } from "../utils/utils";
import ModalOverflow from "@mui/joy/ModalOverflow";
import ModalDialog from "@mui/joy/ModalDialog";
import DialogTitle from "@mui/joy/DialogTitle";
import { CreatePatient } from "./Forms/CreatePatient";

export default function CurrentPatientManager() {
  const api = useApi();
  const [query, setQuery] = React.useState<string>("");
  const [debouncedQuery, setDebouncedQuery] = React.useState(query);
  const [openCreatePatientModal, setOpenCreatePatientModal] =
    React.useState(false);
  const [results, setResults] = React.useState<PaginatedPatientList | null>(
    null,
  );
  const [focused, setFocused] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [currentPatient, setCurrentPatient] = useCurrentPatient();

  function removeCurrentPatient() {
    setFocused(false);
    setTimeout(() => {
      // timeout to ensure the page is navigated before setting the current patient to null
      // otherwise the page will re-render with the current patient still set
      setCurrentPatient(null);
    }, 50);
  }

  function selectPatient(patient: Patient) {
    return () => {
      setCurrentPatient(patient);
      setFocused(false);
      setQuery("");
      localStorage.setItem("currentPatient", JSON.stringify(patient));
    };
  }

  document.addEventListener("keydown", (event: KeyboardEvent) => {
    if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
      setFocused(true);
    }
  });

  const hintText = isMacintosh() ? (
    <Typography level="body-xs" sx={{ opacity: ".4" }}>
      Cmd +{" "}
      <KeyboardReturnIcon
        sx={{ fontSize: "14px", border: "1px solid #777", borderRadius: "2px" }}
      />
    </Typography>
  ) : (
    <Typography level="body-xs" sx={{ opacity: ".4" }}>
      Ctrl +{" "}
      <KeyboardReturnIcon
        sx={{ fontSize: "14px", border: "1px solid #777", borderRadius: "2px" }}
      />
    </Typography>
  );

  React.useEffect(() => {
    const storedPatient = localStorage.getItem("currentPatient");
    if (storedPatient) {
      setCurrentPatient(JSON.parse(storedPatient));
    }
  }, [setCurrentPatient]);

  React.useEffect(() => {
    setLoading(true);
    if (query === "" || query === " ") {
      setResults(null);
      setLoading(false);
      return;
    }

    // Set a timeout to update the debounced query after 300ms
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, 500);

    // Clear the timeout if query changes (cleanup function)
    return () => {
      clearTimeout(handler);
    };
  }, [query]); // Only re-run the effect if query changes

  // Effect to perform the search action when debounced query changes
  React.useEffect(() => {
    if (debouncedQuery) {
      setLoading(true);
      const fetchResults = async () => {
        try {
          const response = await api?.searchPatients(debouncedQuery);
          setResults(response!);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching search results:", error);
          setLoading(false);
        }
      };

      // Call the async function
      fetchResults();
    }
  }, [debouncedQuery, api]);

  return (
    <Sheet
      variant="outlined"
      sx={{ p: 1, borderRadius: "sm", my: 2, width: "220px" }}
    >
      {currentPatient ? (
        <Stack direction="column" gap={0.5}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              level="body-sm"
              startDecorator={<Person fontSize="small" />}
            >
              Patient actif
            </Typography>
            <IconButton size="sm" onClick={removeCurrentPatient}>
              <Close />
            </IconButton>
          </Stack>
          <Link to={"/patient/" + currentPatient.id}>
            <Typography
              level="title-md"
              endDecorator={<OpenInNew fontSize="small" />}
            >
              {currentPatient.nom.toUpperCase() +
                " " +
                currentPatient.prenom.slice(0, 1).toUpperCase() +
                currentPatient.prenom.slice(1).toLowerCase()}
            </Typography>
          </Link>
        </Stack>
      ) : (
        <Stack direction="column" gap={0.5}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              level="body-sm"
              startDecorator={<Person fontSize="small" />}
            >
              Patient actif
            </Typography>
            <IconButton onClick={() => setOpenCreatePatientModal(true)}>
              <AddIcon />
            </IconButton>
          </Stack>

          <Modal
            open={openCreatePatientModal}
            onClose={() => setOpenCreatePatientModal(false)}
          >
            <ModalOverflow>
              <ModalDialog sx={{ width: "400px" }}>
                <ModalClose />
                <DialogTitle>Ajouter un nouveau patient</DialogTitle>
                <CreatePatient />
              </ModalDialog>
            </ModalOverflow>
          </Modal>

          <Input
            sx={{
              visibility: focused ? "hidden" : "visible",
            }}
            size="sm"
            startDecorator={<Search />}
            endDecorator={hintText}
            placeholder="Rechercher"
            onClick={() => setFocused(true)}
          />
          <Modal
            sx={{ zIndex: 909999999 }}
            open={focused}
            onClose={() => setFocused(false)}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                mt: 4,
              }}
            >
              <ModalClose />
              <Input
                sx={{
                  width: "50%",
                  minWidth: "400px",
                }}
                size="lg"
                startDecorator={<Search />}
                placeholder="Rechercher un patient..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                autoFocus
                variant="soft"
              />

              {loading ? (
                <Sheet
                  variant="outlined"
                  sx={{
                    width: "50%",
                    minWidth: "400px",
                    minHeight: "400px",
                    borderRadius: "lg",
                    mt: 2,
                  }}
                >
                  <Stack
                    sx={{ height: "400px" }}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CircularProgress />
                  </Stack>
                </Sheet>
              ) : (
                <Sheet
                  variant="outlined"
                  sx={{
                    width: "50%",
                    minWidth: "400px",
                    minHeight: "400px",
                    borderRadius: "lg",
                    mt: 2,
                  }}
                >
                  {query === "" && !results && (
                    <Stack
                      sx={{ height: "400px" }}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography
                        level="body-md"
                        sx={{ p: 2 }}
                        startDecorator={<Info />}
                      >
                        Tapez le nom d'un patient pour commencer la recherche
                      </Typography>
                    </Stack>
                  )}
                  {results?.results.length === 0 && query !== "" ? (
                    <Stack
                      sx={{ height: "400px" }}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography level="body-md" sx={{ p: 2 }}>
                        Aucun résultat pour <i>"{query}"</i>
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack direction="column" justifyItems="center">
                      {results?.results.map((patient) => (
                        <Stack
                          direction="row"
                          gap={1}
                          key={"search-" + patient.id}
                          sx={{
                            m: 0,
                            px: 2,
                            py: 1,
                            borderBottom: (theme) =>
                              "1px solid " + theme.palette.divider,
                            transition: "all 0.15s ease-out",
                            "&:hover": {
                              backgroundColor: (theme) =>
                                theme.palette.background.level2,
                              cursor: "pointer",
                            },
                          }}
                          onClick={selectPatient(patient)}
                        >
                          <Typography level="title-md">
                            {patient.nom.toUpperCase() +
                              " " +
                              patient.prenom.slice(0, 1).toUpperCase() +
                              patient.prenom.slice(1).toLowerCase()}
                          </Typography>
                          <Typography level="body-sm">
                            {sexeToGender(patient.sexe)} —
                          </Typography>
                          <Typography level="body-sm">
                            {ISODateToAge(patient.dob) + " ans"}
                          </Typography>
                        </Stack>
                      ))}
                    </Stack>
                  )}
                </Sheet>
              )}
            </Box>
          </Modal>
        </Stack>
      )}
    </Sheet>
  );
}
