import ArrowBack from "@mui/icons-material/ArrowBack";
import Draw from "@mui/icons-material/Draw";
import Save from "@mui/icons-material/Save";
import Warning from "@mui/icons-material/Warning";
import Alert from "@mui/joy/Alert";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Stack from "@mui/joy/Stack";
import Textarea from "@mui/joy/Textarea";
import Typography from "@mui/joy/Typography";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import ConsultingSitePlaceSelector from "../../../components/Forms/ConsultingSitePlaceSelector";
import ConsultingSiteSelector from "../../../components/Forms/ConsultingSiteSelector";
import { useApi } from "../../../contexts/ApiContext";
import { useCurrentPatient } from "../../../contexts/CurrentPatientContext";
import {
  PatchedConsultation,
  UserSites,
  UserSitesLieux,
} from "../../../models/types";
import { router } from "../../../router";
import PatientHeader from "../PatientHeader";

export enum Mode {
  PROGRAMME = "programme",
  URGENT = "urgence",
}

export default function CreateConsultation() {
  const [currentPatient] = useCurrentPatient();

  const [mode, setMode] = React.useState<Mode>(Mode.PROGRAMME);
  const [date, setDate] = React.useState<Dayjs | null>(null);
  const [startTime, setStartTime] = React.useState<Dayjs | null>(null);
  const [endTime, setEndTime] = React.useState<Dayjs | null>(null);
  const [reason, setReason] = React.useState<string>("");
  const [siteId, setSiteId] = React.useState<UserSites["id"] | null>(null);
  const [placeId, setPlaceId] = React.useState<UserSitesLieux["id"] | null>(
    null,
  );

  const api = useApi();
  const createConsultation = async () => {
    // check if all required fields are filled
    if (
      !date ||
      !startTime ||
      !endTime ||
      !reason ||
      !siteId ||
      !currentPatient
    ) {
      console.error("Missing fields: ", {
        date,
        startTime,
        endTime,
        reason,
        siteId,
        currentPatient,
      });
      return;
    }
    const payload = {
      consultation_date: date?.format("YYYY-MM-DD") || "",
      consultation_fin_prevu: endTime?.format("hh:mm:ss") || "",
      consultation_debut_reel: startTime?.format("hh:mm:ss") || "",
      motif_consultation: reason,
      zkf_patient: currentPatient?.id!,
      zkf_site: siteId || undefined,
      zkf_lieu: placeId || undefined,
      mode_consultation: mode === Mode.PROGRAMME ? "programme" : "urgent",
    } as PatchedConsultation;
    const res = await api?.createConsultation(payload);
    router.navigate(`/consultation/${res?.id}`);
  };
  if (!currentPatient) {
    return null;
  }

  return (
    <>
      <PatientHeader patient={currentPatient} />
      <Divider sx={{ my: 4, mx: -2 }} />
      <Stack direction="column" gap={1}>
        <span>
          <Typography
            level="body-sm"
            sx={{
              display: "inline-block",
              "&:hover": { textDecoration: "underline", cursor: "pointer" },
            }}
            onClick={() => {
              window.history.back();
            }}
          >
            <ArrowBack sx={{ fontSize: "inherit", color: "inherit" }} /> Revenir
            à la fiche patient
          </Typography>
        </span>
        <Typography level="title-lg">Création d'une consultation</Typography>
      </Stack>
      <Stack direction="row" gap={4}>
        <Box sx={{ flex: 1 }}>
          <Stack sx={{ mt: 4 }} direction="column" gap={3}>
            <Box width="220px">
              <RadioGroup
                size="sm"
                orientation="horizontal"
                name="Mode de consultation"
                value={mode}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setMode(event.target.value as Mode)
                }
                sx={{
                  minHeight: 48,
                  padding: "4px",
                  borderRadius: "12px",
                  bgcolor: "neutral.softBg",
                  "--RadioGroup-gap": "4px",
                  "--Radio-actionRadius": "8px",
                }}
              >
                {["Programmée", "Urgence"].map((item) => (
                  <Radio
                    key={item}
                    color="neutral"
                    value={item === "Programmée" ? Mode.PROGRAMME : Mode.URGENT}
                    disableIcon
                    label={item}
                    variant="plain"
                    sx={{
                      px: 2,
                      alignItems: "center",
                    }}
                    slotProps={{
                      action: ({ checked }) => ({
                        sx: {
                          ...(checked && {
                            bgcolor: "background.surface",
                            boxShadow: "sm",
                            "&:hover": {
                              bgcolor: "background.surface",
                            },
                          }),
                        },
                      }),
                    }}
                  />
                ))}
              </RadioGroup>
            </Box>
            <Stack direction="column" gap={1}>
              <Typography level="title-md">Date de consultation</Typography>
              <Stack direction="row" gap={4} alignItems="flex-end">
                <Stack sx={{ flex: 1 }} gap={1}>
                  <Stack direction="row" gap={1} alignItems="flex-end">
                    <DatePicker
                      sx={{ width: "100%" }}
                      value={date}
                      onChange={(date) => setDate(date)}
                    />
                    <Button
                      variant="outlined"
                      onClick={() => setDate(dayjs())}
                      size="sm"
                    >
                      Aujourd'hui
                    </Button>
                  </Stack>
                  {/* if date is not today and is before today */}
                  {date && date.isBefore(dayjs(), "day") && (
                    <Alert
                      startDecorator={<Warning />}
                      color="warning"
                      size="sm"
                    >
                      La date saisie est antérieure à aujourd'hui, êtes-vous sûr
                      de continuer ?
                    </Alert>
                  )}
                </Stack>
              </Stack>
            </Stack>
            <Stack gap={1}>
              <Stack direction="row" gap={4}>
                <Stack sx={{ flex: 1 }} gap={1}>
                  <Typography level="title-md">Horaire prévu début</Typography>
                  <Typography level="body-md">
                    <TimePicker
                      sx={{ width: "100%" }}
                      value={startTime}
                      onChange={(date) => setStartTime(date)}
                      maxTime={endTime?.startOf("minute")}
                      ampm={false}
                      format="HH:mm"
                    />
                  </Typography>
                </Stack>
                <Stack sx={{ flex: 1 }} gap={1}>
                  <Typography level="title-md">Horaire prévu fin</Typography>
                  <Typography level="body-md">
                    <TimePicker
                      sx={{ width: "100%" }}
                      value={endTime}
                      onChange={(date) => setEndTime(date)}
                      format="HH:mm"
                      minTime={startTime?.startOf("minute")}
                      ampm={false}
                    />
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="row" gap={4}>
              <Stack sx={{ flex: 1 }} gap={1}>
                <Typography level="title-md">Site de consultation</Typography>
                <ConsultingSiteSelector
                  onSelect={(site_id) => {
                    setSiteId(site_id);
                  }}
                />
              </Stack>
              <Stack sx={{ flex: 1 }} gap={1}>
                <Typography level="title-md">Lieu</Typography>
                <ConsultingSitePlaceSelector
                  siteId={siteId}
                  onSelect={(place_id) => {
                    setPlaceId(place_id);
                  }}
                />
              </Stack>
            </Stack>
            <Stack direction="row" gap={4} alignItems="flex-start">
              {/* <Typography
                level="body-md"
                startDecorator={
                  <Checkbox
                    checked={otherConsultingDoctor}
                    onChange={(e) => setOtherConsultingDoctor(e.target.checked)}
                  />
                }
              >
                Autre médecin consultant
              </Typography>
              {otherConsultingDoctor && (
                <Stack sx={{ flex: 1 }} gap={1}>
                  <Typography level="title-md">Médecin consultant</Typography>
                  {/* <DoctorSelect value={""} onSelect={(doctorId) => setDoctor(doctorId)} />
                </Stack>
              )} 
                 */}
            </Stack>
          </Stack>
        </Box>
        <Stack direction="column" gap={2} sx={{ flex: 1 }}>
          <Typography level="title-md" startDecorator={<Draw />}>
            Motif de la consultation
          </Typography>
          <Textarea
            placeholder="Taper ici le motif de consultation."
            minRows={10}
            autoFocus
            onChange={(e) => setReason(e.target.value)}
          />
        </Stack>
      </Stack>
      <Divider sx={{ my: 4, mx: -2 }} />
      <Stack direction="row" justifyContent="flex-end">
        <Button
          startDecorator={<Save />}
          variant="soft"
          color="primary"
          onClick={() => createConsultation()}
        >
          Enregistrer
        </Button>
      </Stack>
    </>
  );
}
