import React from "react";
import { useLocation } from "react-router-dom";
import { Patient } from "../models/types";

export function ISODateToAge(date: string): number {
  // Calculate the age of a person given their date of birth format YYYY-MM-DD
  const today = new Date();
  const birthDate = new Date(date);
  return today.getFullYear() - birthDate.getFullYear();
}

export function formatDateTime(date?: string | null): string {
  // Format an ISO date to a more readable format
  if (!date) return "";
  const d = new Date(date);
  return d.toLocaleDateString("fr-FR", {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function formatDate(date?: string | null): string {
  // Format an ISO date to a more readable format
  if (!date) return "";
  const d = new Date(date);
  return d.toLocaleDateString("fr-FR", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
}

export function formatHour(hour?: string | null): string {
  // hour is formtted as "HH:MM:SS" convert to "HH:MM"
  if (!hour) return "";
  return hour.slice(0, 5);
}

export function isToday(dateStr: string): boolean {
  const today = new Date();
  const date = new Date(dateStr);

  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );
}

export function formatNameAndSurname(
  name?: string | null,
  surname?: string | null,
): string {
  // Format the name and surname of a person
  return `${name ? name.toUpperCase() : ""} ${surname ? surname.slice(0, 1).toUpperCase() + surname.slice(1).toLowerCase() : ""}`;
}

export function sexeToGender(sexe: string): string {
  // convert MALE to "Homme" and FEMALE to "Femme"
  if (sexe.toLowerCase() === "male") {
    return "Homme";
  } else if (sexe.toLowerCase() === "female") {
    return "Femme";
  } else {
    return "Sexe inconnu";
  }
}

export function CurrentTime() {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");

  return `${hours}:${minutes}`;
}

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function isMacintosh() {
  return navigator.platform.indexOf("Mac") > -1;
}

export function isWindows() {
  return navigator.platform.indexOf("Win") > -1;
}

export function niceFormattingOfName(patient: Patient): string {
  return `${patient.nom.toUpperCase()} ${patient.prenom.slice(0, 1).toUpperCase() + patient.prenom.slice(1).toLowerCase()}`;
}

export function truncateText(text: string, maxLength: number): string {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + "[...]";
}

export function roundTo2Decimals(num: number): number {
  return Math.round(num * 100) / 100;
}

export function formatNumbersToFrench(num: number): string {
  return num.toLocaleString("fr-FR", {
    minimumFractionDigits: 2, // Ensures at least two decimal places
    maximumFractionDigits: 2, // Limits to two decimal places
  });
}

export const capitalizeFirstLetter = (text: string): string =>
  text ? text[0].toUpperCase() + text.slice(1) : text;
