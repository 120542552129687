import Delete from "@mui/icons-material/Delete";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Textarea from "@mui/joy/Textarea";
import Box from "@mui/joy/Box";
import Checkbox from "@mui/joy/Checkbox";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Stack from "@mui/joy/Stack";
import Tab from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import Tabs from "@mui/joy/Tabs";
import Tooltip from "@mui/joy/Tooltip";
import Typography from "@mui/joy/Typography";
import React from "react";
import { useApi } from "../../contexts/ApiContext";
import {
  Adresse,
  Contact,
  Correspondant,
  CorrespondantTitreEnum,
  Email,
  Patient,
  SexeEnum,
  Specialite,
  Telephone,
} from "../../models/types";
import {
  emptyAdresse,
  emptyEmail,
  emptyTelephone,
  panelRender,
} from "../ContactPoint/ContactPoint";
import ModalComponent from "../Modal/Modal";

interface CorrespondantModalFormProps {
  open: boolean;
  onClose: () => void;
  onSave?: (contact: Contact) => void;
  contact?: Contact;
  patient: Patient;
}

const CorrespondantModalForm: React.FC<CorrespondantModalFormProps> = ({
  open,
  onClose,
  contact,
  patient,
  onSave,
}) => {
  const api = useApi();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [specialites, setSpecialites] = React.useState<Specialite[]>([]);
  const [nom, setNom] = React.useState<string>(contact?.nom || "");
  const [prenom, setPrenom] = React.useState<string>(contact?.prenom || "");
  const [sexe, setSexe] = React.useState<SexeEnum>(
    (contact?.sexe as SexeEnum) || "",
  );
  const [structure, setStructure] = React.useState<string>(
    contact?.structure || "",
  );
  const [comment, setComment] = React.useState<string>(contact?.comment || "");
  const [titre, setTitre] = React.useState<CorrespondantTitreEnum>(
    contact?.correspondant?.titre || "Docteur",
  );
  const [selectedSpecialites, setSelectedSpecialites] = React.useState<
    Specialite[]
  >(
    specialites.filter((spe) =>
      contact?.correspondant?.specialite?.includes(spe.id),
    ) || [],
  );
  const [emails, setEmails] = React.useState<Email[]>([{ ...emptyEmail }]);
  const [telephones, setTelephones] = React.useState<Telephone[]>([
    { ...emptyTelephone },
  ]);
  const [adresses, setAdresses] = React.useState<Adresse[]>([
    { ...emptyAdresse },
  ]);
  const [isActive, setIsActive] = React.useState<boolean>(
    contact?.correspondant ? contact.correspondant.en_activite || false : true,
  );

  const width = "100px";

  React.useEffect(() => {
    api.listUserSpecialites().then((res) => {
      setSpecialites(res);
      if (contact?.correspondant) {
        setSelectedSpecialites(
          res.filter((spe) =>
            contact?.correspondant?.specialite?.includes(spe.id),
          ),
        );
      }
    });
    if (contact?.id) {
      api
        .listEmailsByContact(contact.id)
        .then((res) => setEmails(res.length > 0 ? res : [{ ...emptyEmail }]));
      api
        .listPhonesByContact(contact.id)
        .then((res) =>
          setTelephones(res.length > 0 ? res : [{ ...emptyTelephone }]),
        );
      api
        .listAddressesByContact(contact.id)
        .then((res) =>
          setAdresses(res.length > 0 ? res : [{ ...emptyAdresse }]),
        );
    }
  }, [api, contact]);

  const addEmail = () => setEmails([...emails, { ...emptyEmail }]);
  const addTelephone = () =>
    setTelephones([...telephones, { ...emptyTelephone }]);
  const addAdresse = () => setAdresses([...adresses, { ...emptyAdresse }]);

  const specialitesValue = selectedSpecialites
    ?.map((selectSpe) => selectSpe.spe)
    .join(", ");

  const updateEmails = (
    index: number,
    field: string,
    value: string | boolean,
  ) =>
    setEmails((prevData) =>
      prevData.map((data, i) =>
        index !== i ? data : { ...data, [field]: value },
      ),
    );

  const updateTelephones = (
    index: number,
    field: string,
    value: string | boolean,
  ) =>
    setTelephones((prevData) =>
      prevData.map((data, i) =>
        index !== i ? data : { ...data, [field]: value },
      ),
    );

  const updateAdresses = async (
    index: number,
    field: string,
    value: string | boolean,
  ) => {
    const newData = { [field]: value };
    if (field === "code_postal" && (value as string).length === 5) {
      const villes = await api.searchVilleByZipCode(value as string);
      if (villes.length === 1) {
        newData.ville = villes[0].nom_ville;
      }
    }
    setAdresses((prevData) =>
      prevData.map((data, i) => (index !== i ? data : { ...data, ...newData })),
    );
  };

  const container = (
    title: string,
    canDelete: boolean,
    handleDelete: () => void,
    isFavori: boolean,
    handleFavori: () => void,
    content: React.ReactNode,
  ) => (
    <Box
      key={title}
      padding={2}
      style={{
        position: "relative",
        border: "1px solid #ccc",
        borderRadius: "8px",
      }}
    >
      <Typography
        sx={{
          position: "absolute",
          top: "-10px",
          left: "10px",
          backgroundColor: "white",
          padding: "0 8px",
          fontSize: "12px",
        }}
        endDecorator={
          canDelete && (
            <Delete
              style={{ fill: "red", cursor: "pointer" }}
              onClick={handleDelete}
            />
          )
        }
      >
        {title}
      </Typography>
      <Typography
        sx={{
          position: "absolute",
          top: "-10px",
          right: "10px",
          backgroundColor: "white",
          padding: "0 8px",
          fontSize: "12px",
        }}
        endDecorator={<Checkbox checked={isFavori} onClick={handleFavori} />}
      >
        Favori
      </Typography>
      {content}
    </Box>
  );

  const emailRender = (email: Email, index: number) =>
    container(
      `Email ${index + 1}`,
      emails.length > 1,
      () => setEmails(emails.filter((e, i) => i !== index)),
      email.preferred || false,
      () => updateEmails(index, "preferred", !email.preferred),
      <Stack spacing={2}>
        <Stack direction={"row"} alignItems={"center"}>
          <Typography sx={{ width }}>Categorie</Typography>
          <Input
            value={email.categorie || ""}
            onChange={(e) => updateEmails(index, "categorie", e.target.value)}
            style={{ flex: 1 }}
          />
        </Stack>
        <Stack direction={"row"} alignItems={"center"}>
          <Typography sx={{ width }}>Email</Typography>
          <Input
            value={email.email || ""}
            onChange={(e) => updateEmails(index, "email", e.target.value)}
            style={{ flex: 1 }}
          />
        </Stack>
      </Stack>,
    );

  const telephoneRender = (telephone: Telephone, index: number) =>
    container(
      `Telephone ${index + 1}`,
      telephones.length > 1,
      () => setTelephones(telephones.filter((e, i) => i !== index)),
      telephone.preferred || false,
      () => updateTelephones(index, "preferred", !telephone.preferred),
      <Stack spacing={2}>
        <Stack direction={"row"} alignItems={"center"}>
          <Typography sx={{ width }}>Categorie</Typography>
          <Select
            value={telephone.categorie || ""}
            onChange={(e, newValue) =>
              updateTelephones(index, "categorie", newValue || "")
            }
            style={{ width: "200px" }}
          >
            <Option value={"PORTABLE"}>Portable</Option>
            <Option value={"PROFESSIONNEL"}>Professionnel</Option>
            <Option value={"DOMICILE"}>Domicile</Option>
            <Option value={"FAX"}>Fax</Option>
            <Option value={"AUTRE"}>Autre</Option>
          </Select>
        </Stack>
        <Stack direction={"row"} alignItems={"center"}>
          <Typography sx={{ width }}>Numero</Typography>
          <Input
            value={telephone.numero || ""}
            onChange={(e) => {
              if (/^\d{0,10}$/.test(e.target.value)) {
                updateTelephones(index, "numero", e.target.value);
              }
            }}
            style={{ flex: 1 }}
          />
        </Stack>
      </Stack>,
    );

  const adresseRender = (adresse: Adresse, index: number) =>
    container(
      `Adresse ${index + 1}`,
      adresses.length > 1,
      () => setAdresses(adresses.filter((e, i) => i !== index)),
      adresse.preferred || false,
      () => updateAdresses(index, "preferred", !adresse.preferred),
      <Stack spacing={2}>
        <Stack direction={"row"} spacing={2}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography width={"100px"}>No de rue</Typography>
            <Input
              value={adresse.numero_rue || ""}
              onChange={(e) => {
                if (/^\d{0,5}$/.test(e.target.value)) {
                  updateAdresses(index, "numero_rue", e.target.value);
                }
              }}
              style={{ flex: 1, width: "75px" }}
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={1} flex={1}>
            <Typography>Rue</Typography>
            <Input
              value={adresse.rue || ""}
              onChange={(e) => updateAdresses(index, "rue", e.target.value)}
              style={{ flex: 1 }}
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Typography width={"100px"}>Complement</Typography>
          <Input
            placeholder={"Complement d'adresse"}
            value={adresse.complement_adresse || ""}
            onChange={(e) =>
              updateAdresses(index, "complement_adresse", e.target.value)
            }
            style={{ flex: 1 }}
          />
        </Stack>
        <Stack direction={"row"} spacing={2}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography width={"100px"}>Code Postal</Typography>
            <Input
              value={adresse.code_postal || ""}
              onChange={(e) => {
                if (/^\d{0,5}$/.test(e.target.value)) {
                  updateAdresses(index, "code_postal", e.target.value);
                }
              }}
              style={{ flex: 1, width: "75px" }}
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography>Ville</Typography>
            <Input
              value={adresse.ville || ""}
              onChange={(e) => updateAdresses(index, "ville", e.target.value)}
              style={{ flex: 1 }}
            />
          </Stack>
        </Stack>
      </Stack>,
    );

  const handleSaveCorrespondant = async () => {
    setIsLoading(true);
    let result: Contact;
    try {
      if (contact?.id) {
        result = await api.updateContact({
          id: contact.id,
          nom,
          prenom,
          sexe,
          comment,
          structure,
          correspondant: {
            id: contact.correspondant?.id,
            titre,
            en_activite: isActive,
            specialite: selectedSpecialites.map((spe) => spe.id),
          } as Correspondant,
          email: emails.filter((email) => email.email),
          telephone: telephones.filter((tel) => tel.numero),
          adresse: adresses.filter((adresse) => adresse.numero_rue),
        });
      } else {
        result = await api.createContact({
          nom,
          prenom,
          sexe,
          comment,
          structure,
          correspondant: {
            titre,
            en_activite: isActive,
            specialite: selectedSpecialites.map((spe) => spe.id),
          } as Correspondant,
          email: emails.filter((email) => email.email),
          telephone: telephones.filter((tel) => tel.numero),
          adresse: adresses.filter((adresse) => adresse.numero_rue),
        });
        await api.linkContactToPatient(patient.id, [
          ...(patient.correspondants || []),
          result.id,
        ]);
      }
      onSave && onSave(result);
      onClose();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalComponent
      open={open}
      onClose={onClose}
      title={`${contact?.id ? "Modifier" : "Créer"} un ${!contact || contact.correspondant ? "correspondant" : "contact"}`}
      style={{ minWidth: "80%", minHeight: "60%" }}
      validateLabel={contact ? "Modifier" : "Ajouter"}
      onValidate={handleSaveCorrespondant}
      canValidate={(!!nom && !!prenom) || !!structure}
      isLoading={isLoading}
      patient={patient}
    >
      <Stack direction={"row"} width={"100%"} spacing={3} marginTop={2}>
        <Stack spacing={2} width={"45%"}>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography sx={{ width }}>Nom</Typography>
            <Input
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              error={!nom && !structure}
              style={{ flex: 1 }}
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography sx={{ width }}>Prenom</Typography>
            <Input
              value={prenom}
              onChange={(e) => setPrenom(e.target.value)}
              error={!prenom && !structure}
              style={{ flex: 1 }}
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography sx={{ width }}>Sexe</Typography>
            <RadioGroup
              orientation={"horizontal"}
              value={sexe}
              onChange={(e) => setSexe(e.target.value as SexeEnum)}
            >
              <Radio value={"MALE"} label={"Male"} />
              <Radio value={"FEMALE"} label={"Female"} />
            </RadioGroup>
          </Stack>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography sx={{ width }}>Structure</Typography>
            <Input
              value={structure}
              onChange={(e) => setStructure(e.target.value)}
              error={!structure && !nom && !prenom}
              style={{ flex: 1 }}
            />
          </Stack>
          <Stack direction="row" alignItems={"center"}>
            <Typography sx={{ width }}>Titre</Typography>
            <Select
              value={titre}
              onChange={(e, newValue) => setTitre(newValue || "Docteur")}
              style={{ width: "200px" }}
            >
              <Option value={"Docteur"}>Docteur</Option>
              <Option value={"Professeur"}>Professeur</Option>
            </Select>
          </Stack>
          <Stack direction="row" alignItems={"center"}>
            <Typography sx={{ width }}>Specialites</Typography>
            <Tooltip title={specialitesValue}>
              <Select
                multiple
                renderValue={() => (
                  <Typography noWrap>{specialitesValue}</Typography>
                )}
                value={selectedSpecialites}
                onChange={(e, newValue) =>
                  setSelectedSpecialites(newValue || [])
                }
                style={{ width: "200px" }}
              >
                {specialites.map((spe) => (
                  <Option key={spe.id} value={spe}>
                    {spe.spe}
                  </Option>
                ))}
              </Select>
            </Tooltip>
          </Stack>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography sx={{ width }}>Comment</Typography>
            <Textarea
              minRows={5}
              maxRows={5}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              style={{ flex: 1 }}
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography sx={{ width }}>En activite</Typography>
            <Checkbox
              checked={isActive}
              onChange={() => setIsActive(!isActive)}
            />
          </Stack>
        </Stack>
        <Tabs defaultValue={0} style={{ flex: 1, backgroundColor: "inherit" }}>
          <TabList tabFlex={1}>
            <Tab>Emails</Tab>
            <Tab>Téléphones</Tab>
            <Tab>Adresses</Tab>
          </TabList>
          {panelRender(
            0,
            addEmail,
            emails.map((email, index) => emailRender(email, index)),
          )}
          {panelRender(
            1,
            addTelephone,
            telephones.map((tel, index) => telephoneRender(tel, index)),
          )}
          {panelRender(
            2,
            addAdresse,
            adresses.map((adresse, index) => adresseRender(adresse, index)),
          )}
        </Tabs>
      </Stack>
    </ModalComponent>
  );
};

export default CorrespondantModalForm;
