// TODO: Fix missing dependencies + Refacto

import React from "react";
import { Patient } from "../models/types"; // Assuming this is where the Patient type is defined
import { useApi } from "./ApiContext";

// Context for Current Patient
const CurrentPatientContext = React.createContext<
  [Patient | null, React.Dispatch<React.SetStateAction<Patient | null>>]
>([null, () => {}]);

interface CurrentPatientProviderProps {
  children: React.ReactNode;
}

// Provider for the Current Patient Context
export function CurrentPatientContextProvider({
  children,
}: CurrentPatientProviderProps) {
  const api = useApi();
  const [patient, setPatient] = React.useState<Patient | null>(null);

  // Function to manually extract patientId from the URL
  const getPatientIdFromUrl = () => {
    const pathParts = window.location.pathname.split("/"); // Split URL by "/"
    const patientIndex = pathParts.indexOf("patient");
    if (patientIndex !== -1 && pathParts[patientIndex + 1]) {
      return pathParts[patientIndex + 1]; // Return the part after '/patient/'
    }
    return null; // Return null if not found
  };

  // Function to fetch the patient by ID
  async function fetchPatientById(id: string): Promise<Patient | null> {
    try {
      const patient = await api.getPatient(id);
      return patient;
    } catch (error) {
      console.error("Error fetching patient:", error);
      return null; // Return null in case of error
    }
  }

  // Function to fetch the patient by ID when the URL changes
  const updatePatientFromUrl = () => {
    const patientId = getPatientIdFromUrl();

    if (patientId) {
      fetchPatientById(patientId)
        .then((patientData) => {
          if (patientData) {
            setPatient(patientData);
          } else {
            setPatient(null); // Reset patient if not found
          }
        })
        .catch(() => setPatient(null)); // Handle error case
    } else {
      setPatient(null); // Reset patient if no patientId in URL
    }
  };

  // Effect to update patient on URL change or page load
  React.useEffect(() => {
    // Update patient on initial load
    updatePatientFromUrl();

    // Listen for `popstate` event to handle browser navigation (back/forward buttons)
    const handlePopState = () => {
      updatePatientFromUrl();
    };

    // Intercept pushState and replaceState to detect client-side navigation
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = function (...args) {
      originalPushState.apply(window.history, args);
      updatePatientFromUrl();
    };

    window.history.replaceState = function (...args) {
      originalReplaceState.apply(window.history, args);
      updatePatientFromUrl();
    };

    window.addEventListener("popstate", handlePopState);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopState);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, []);

  return (
    <CurrentPatientContext.Provider value={[patient, setPatient]}>
      {children}
    </CurrentPatientContext.Provider>
  );
}

// Hook to use the Current Patient Context
export const useCurrentPatient = () => React.useContext(CurrentPatientContext);
