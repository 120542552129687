import CalendarMonth from "@mui/icons-material/CalendarMonth";
import Group from "@mui/icons-material/Group";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import LocalHospital from "@mui/icons-material/LocalHospital";
import Person from "@mui/icons-material/Person";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import MeiflowLogo from "../assets/img/meiflow-logo.png";
import { useApi } from "../contexts/ApiContext";
import { useAuthenticatedUser } from "../contexts/UserContext";
import { router } from "../router";
import CurrentPatientManager from "./CurrentPatientManager";
import Notifications from "./Notifications/Notifications";
import PrintManager from "./PrintManager/PrintManager";
import { useOidc } from "@axa-fr/react-oidc";

export default function Sidebar() {
  const api = useApi();
  const { user } = useAuthenticatedUser();
  const { logout } = useOidc();

  return (
    <Stack
      sx={{ height: "calc(100% - 32px)", position: "fixed" }}
      direction="column"
      justifyContent="space-between"
    >
      <div>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={MeiflowLogo} alt="Meiflow" width="100" />
        </Box>
        <CurrentPatientManager />
        <List
          size="sm"
          sx={{
            gap: 1,
            "--List-nestedInsetStart": "30px",
            "--ListItem-radius": (theme) => theme.vars.radius.sm,
          }}
        >
          <ListItem>
            <ListItemButton role="menuitem" component="a" href="/">
              <HomeRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">Accueil</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton role="menuitem" component="a" href="/service">
              <LocalHospital />
              <ListItemContent>
                <Typography level="title-sm">Service</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton role="menuitem" component="a" href="/patient">
              <Group />
              <ListItemContent>
                <Typography level="title-sm">Patients</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton role="menuitem" component="a" href="/calendar">
              <CalendarMonth />
              <ListItemContent>
                <Typography level="title-sm">Agenda</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>
      </div>
      <Stack direction="column" gap={1}>
        <Box>
          <Notifications />
        </Box>
        <Box>
          <PrintManager />
        </Box>

        <Typography
          component={"div"}
          startDecorator={
            <Avatar color="primary" variant="solid" size="sm">
              <Person />
            </Avatar>
          }
        >
          <Stack>
            <Typography level="title-md">
              {user && user.first_name + " " + user.last_name}
            </Typography>
            <Typography
              sx={{ cursor: "pointer" }}
              level="body-sm"
              color="neutral"
              onClick={() => {
                router.navigate("/preferences");
              }}
            >
              Préférences
            </Typography>
            <Typography
              sx={{ cursor: "pointer" }}
              level="body-sm"
              color="neutral"
              onClick={() => {
                logout();
              }}
            >
              Se déconnecter
            </Typography>
          </Stack>
        </Typography>
      </Stack>
    </Stack>
  );
}
