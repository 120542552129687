import { Input, ModalClose, Stack, Textarea, Typography } from "@mui/joy";
import dayjs from "dayjs";
import React from "react";
import UserPicker from "../../../components/Forms/UserPicker";
import { Evenement } from "../../../models/types";

export default function CreateEventForm({ event }: { event: Evenement }) {
  const [e, setE] = React.useState<Evenement>(event);

  const updateE = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setE({
      id: event.id,
      created_on: event.created_on,
      modified_on: event.modified_on,
      debut_evenement: event.debut_evenement,
      fin_evenement: event.fin_evenement,
      description_evenement: e.target.value,
    });
  };

  const updateInvolvedUsers = (users: number[]) => {
    setE({
      ...e,
      users: users,
    });
  };

  return (
    <Stack direction="column" gap={2}>
      <Typography level="h1">Ajouter un événement</Typography>
      <ModalClose />
      <Stack direction="column" gap={0.5}>
        <Typography level="title-md">Description</Typography>
        <Textarea
          value={e.description_evenement}
          onChange={updateE}
          placeholder="Congrès international à Zurich"
          minRows={3}
          maxRows={3}
        />
      </Stack>
      <Stack direction="row" gap={2}>
        <Stack direction="column" gap={0.5}>
          <Typography level="title-md">Début</Typography>
          <Input
            type="datetime-local"
            value={dayjs(e.debut_evenement).format()}
          />
        </Stack>
        <Stack direction="column" gap={0.5}>
          <Typography level="title-md">Fin</Typography>
          <Input
            type="datetime-local"
            value={dayjs(e.fin_evenement).format()}
          />
        </Stack>
      </Stack>
      <Stack direction="column" gap={0.5}>
        <Typography level="title-md">Personnes</Typography>
        <UserPicker value={e.users} onChange={updateInvolvedUsers} />
      </Stack>
    </Stack>
  );
}
