import Stack from "@mui/joy/Stack";
import Tab from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import TabPanel from "@mui/joy/TabPanel";
import Tabs from "@mui/joy/Tabs";
import Typography from "@mui/joy/Typography";
import DocumentTypesTab from "./panels/DocumentsTypes/DocumentTypesTab";
import ProfileEditTab from "./panels/ProfileEditTab";
import Vacations from "./panels/Vacations";
import Absences from "./panels/Absences";

export default function PreferencePage() {
  return (
    <Stack direction="column" gap={2}>
      <Typography level="h4">Préférences utilisateur</Typography>
      <Tabs sx={{ mx: -2 }}>
        <TabList>
          <Tab>Profil</Tab>
          <Tab>Documents types</Tab>
          <Tab>Vacations</Tab>
          <Tab>Absences</Tab>
        </TabList>
        <TabPanel value={0}>
          <ProfileEditTab />
        </TabPanel>
        <TabPanel value={1}>
          <DocumentTypesTab />
        </TabPanel>
        <TabPanel value={2}>
          <Vacations />
        </TabPanel>
        <TabPanel value={3}>
          <Absences />
        </TabPanel>
      </Tabs>
    </Stack>
  );
}
