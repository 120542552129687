import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { printPlugin } from "@react-pdf-viewer/print";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/print/lib/styles/index.css";
import { Button, Stack } from "@mui/joy";
import { Print } from "@mui/icons-material";

// https://react-pdf-viewer.dev/docs/

interface PDFPreviewAndPrintProps {
  fileUrl: string;
  theme?: string;
  height?: string;
  withPrint?: boolean;
  onPrint?: () => void;
}

const PDFPreviewAndPrint: React.FC<PDFPreviewAndPrintProps> = ({
  fileUrl,
  theme = "dark",
  height = "calc(100vh - 102px)",
  withPrint = true,
  onPrint,
}) => {
  const printPluginInstance = printPlugin();

  const print = () => {
    printPluginInstance.print();
    onPrint && onPrint();
  };

  return (
    <Stack direction="column" height={"100%"}>
      <Worker
        workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
      >
        <div
          style={{
            overflowY: "auto",
            background: "black",
            flex: 1,
            maxHeight: height,
          }}
        >
          <Viewer
            fileUrl={fileUrl}
            plugins={[printPluginInstance]}
            theme={theme}
          />
        </div>
      </Worker>
      {withPrint && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: (theme) => "1px solid " + theme.palette.divider,
            p: 2,
          }}
        >
          <Button onClick={print} startDecorator={<Print />} variant="soft">
            Imprimer les documents
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default PDFPreviewAndPrint;
