import HelpOutline from "@mui/icons-material/HelpOutline";
import Autocomplete from "@mui/joy/Autocomplete";
import Chip from "@mui/joy/Chip";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import { useApi } from "../../contexts/ApiContext";
import { useSnackbar } from "../../contexts/SnackbarContext";
import { FullTag } from "../../models/types";

interface TagAutocompleteProps {
  value: string[];
  onChange: (value: string[]) => void;
  helperText?: boolean;
}

export default function TagAutocomplete({
  value,
  onChange,
  helperText,
}: TagAutocompleteProps) {
  const api = useApi();
  const snackbar = useSnackbar();

  // split tags from value
  const [allTags, setAllTags] = React.useState<FullTag[]>([]);

  // retrieve tags from backend
  React.useEffect(() => {
    const fetchTags = async () => {
      try {
        const tags = await api.getTags();
        setAllTags(tags);
      } catch (error) {
        console.error(error);
        snackbar.show("Erreur lors de la récupération des tags", "danger");
      }
    };
    fetchTags();
  }, []);

  return (
    <Stack gap={0.5}>
      <Autocomplete
        freeSolo
        multiple
        value={value}
        onChange={(_, newValue: string[]) => {
          onChange(newValue);
        }}
        options={allTags.map((tag) => tag.name)}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip variant="outlined" {...getTagProps({ index })} key={option}>
              {option}
            </Chip>
          ))
        }
      />
      {helperText && (
        <Typography
          level="body-xs"
          startDecorator={<HelpOutline fontSize="small" />}
        >
          Pour ajouter un nouveau tag, tapez le texte et appuyez sur entrée
        </Typography>
      )}
    </Stack>
  );
}
