import Delete from "@mui/icons-material/Delete";
import FileOpenSharp from "@mui/icons-material/FileOpenSharp";
import Help from "@mui/icons-material/Help";
import Person from "@mui/icons-material/Person";
import Print from "@mui/icons-material/Print";
import Alert from "@mui/joy/Alert";
import Box from "@mui/joy/Box";
import Checkbox from "@mui/joy/Checkbox";
import DialogContent from "@mui/joy/DialogContent";
import DialogTitle from "@mui/joy/DialogTitle";
import IconButton from "@mui/joy/IconButton";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import { useApi } from "../../contexts/ApiContext";
import { usePrintManager } from "../../contexts/PrintManagerContext";
import { PrintManagerFile } from "../../models/custom";
import PDFPreviewAndPrint from "../PDF/PDFPreview";

export default function PrintManagerDrawer() {
  const { files, removeFromPrintManager } = usePrintManager();

  const [fileBlobURL, setFileBlobURL] = React.useState<string | null>(null);
  const [selected, setSelected] = React.useState<PrintManagerFile[]>([]);
  const [groupedByPatient, setGroupedByPatient] = React.useState<
    Record<string, PrintManagerFile[]>
  >({});

  const handleSelectAll = () => {
    if (selected.length === files.length) {
      setSelected([]);
    } else {
      setSelected(files);
    }
  };

  const handleSelectAllForPatient = (patient_name: string) => () => {
    // if all files for the patient are selected, deselect all
    // otherwise, select all files for the patient
    if (isAllCheckedForPatient(patient_name)) {
      setSelected(selected.filter((file) => file.zkf_patient !== patient_name));
    } else {
      setSelected([
        ...selected,
        ...groupedByPatient[patient_name].filter(
          (file) => !selected.includes(file),
        ),
      ]);
    }
  };

  const isAllCheckedForPatient = (patient_name: string) => {
    return groupedByPatient[patient_name].every((file) =>
      selected.includes(file),
    );
  };

  const handleRemoveSelected = () => {
    // call the api to remove the file from the print manager, then refresh the queue
    // if all files are selected, call without uuid to remove all
    if (selected.length === files.length) {
      removeFromPrintManager();
    } else {
      selected.forEach((file) => {
        removeFromPrintManager(file.id);
      });
    }
  };

  const handleSelect = (file: PrintManagerFile) => {
    if (selected.includes(file)) {
      setSelected(selected.filter((f) => f !== file));
    } else {
      setSelected([...selected, file]);
    }
  };

  React.useEffect(() => {
    const grouped = files.reduce(
      (acc, file) => {
        if (!acc[file.zkf_patient]) {
          // zkf_patient is the patient name
          acc[file.zkf_patient] = [];
        }
        acc[file.zkf_patient].push(file);
        return acc;
      },
      {} as Record<string, PrintManagerFile[]>,
    );
    setGroupedByPatient(grouped);
  }, [files]);

  const api = useApi();

  // debounce on selected files change and call updatePreview
  React.useEffect(() => {
    if (selected.length > 0) {
      const updatePreview = async () => {
        setFileBlobURL(null);
        const blob = await api?.printManagerPrint(
          selected.map((file) => file.id),
        );
        // open the print dialog with the blob
        if (blob) {
          // download the blob
          const url = window.URL.createObjectURL(blob);
          setFileBlobURL(url);
        }
      };
      updatePreview();
    } else {
      setFileBlobURL(null);
    }
  }, [selected, api]);

  return (
    <Stack direction="row" gap={4} sx={{ width: "100vw", p: 2 }}>
      <Box sx={{ width: "450px" }}>
        <Sheet sx={{ height: "calc(100vh - 32px)", borderRadius: "lg" }}>
          <DialogTitle sx={{ p: 2 }}>
            <Typography level="title-lg" startDecorator={<Print />}>
              Gestionnaire d'impression
            </Typography>
            <ModalClose />
          </DialogTitle>
          <DialogContent
            sx={{ height: "calc(100vh - 158px)", px: 2, overflowY: "scroll" }}
          >
            <Stack flex={1} direction="column" gap={1}>
              {groupedByPatient && files.length > 0 ? (
                <Stack
                  flex={1}
                  direction="column"
                  gap={2}
                  justifyContent="space-between"
                >
                  <Box flex={1}>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography
                        level="body-sm"
                        startDecorator={
                          <Checkbox onChange={() => handleSelectAll()} />
                        }
                        sx={{ pl: 1 }}
                      >
                        Tout selectionner
                      </Typography>
                      {selected.length > 0 && (
                        <Typography
                          level="body-sm"
                          fontWeight="bold"
                          sx={{
                            pl: 1,
                            opacity: 0.8,
                            "&:hover": {
                              cursor: "pointer",
                              opacity: 1,
                              textDecoration: "underline",
                            },
                          }}
                          color="danger"
                          onClick={handleRemoveSelected}
                          startDecorator={
                            <Delete sx={{ fontSize: "16px", mr: "-4px" }} />
                          }
                        >
                          Supprimer
                        </Typography>
                      )}
                    </Stack>
                    {Object.keys(groupedByPatient).map((patient_name) => (
                      <Sheet
                        variant="outlined"
                        sx={{ p: 1, mt: 1, borderRadius: "sm" }}
                        key={patient_name}
                      >
                        <Stack direction="row" gap={1}>
                          <Box>
                            {/* <Checkbox checked={selected.includes(file)} /> onChange={handleSelect} */}
                            <Checkbox
                              checked={isAllCheckedForPatient(patient_name)}
                              indeterminate={
                                // if some files are selected for the patient, but not all
                                selected.some(
                                  (file) => file.zkf_patient === patient_name,
                                ) && !isAllCheckedForPatient(patient_name)
                              }
                              onChange={handleSelectAllForPatient(patient_name)}
                            />
                          </Box>
                          <Box flex={1}>
                            <Typography
                              level="body-md"
                              startDecorator={<Person />}
                            >
                              {patient_name}
                            </Typography>
                            <Stack
                              sx={{ mt: 1 }}
                              direction="column"
                              gap={1}
                              alignItems="flex-start"
                            >
                              {
                                // loop through groupedByPatient[patientId] and render each file
                                groupedByPatient[patient_name].map((file) => (
                                  <Stack
                                    direction="row"
                                    gap={0.5}
                                    key={file.id}
                                  >
                                    <Stack direction="row" gap={0.5}>
                                      <Checkbox
                                        size="sm"
                                        checked={selected.includes(file)}
                                        onChange={() => handleSelect(file)}
                                      />
                                      <FileOpenSharp fontSize="small" />{" "}
                                    </Stack>
                                    <Typography
                                      level="body-sm"
                                      lineHeight="100%"
                                    >
                                      {file.titre_document}
                                    </Typography>
                                    <IconButton
                                      size="sm"
                                      color="neutral"
                                      variant="soft"
                                      onClick={() =>
                                        removeFromPrintManager(file.id)
                                      }
                                    >
                                      <Delete fontSize="small" />
                                    </IconButton>
                                  </Stack>
                                ))
                              }
                            </Stack>
                          </Box>
                        </Stack>
                      </Sheet>
                    ))}
                  </Box>
                </Stack>
              ) : (
                <Stack
                  direction="column"
                  gap={1}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  flex={1}
                >
                  <Typography level="title-md">
                    Pas de fichier à imprimer
                  </Typography>
                  <Alert
                    startDecorator={<Help />}
                    sx={{ fontWeight: 400, textAlign: "justify" }}
                  >
                    Vous pouvez des fichiers à imprimer en cliquant sur le
                    bouton "ajout au gestionnaire d'impression" sur les fichiers
                    que vous souhaitez imprimer.
                  </Alert>
                </Stack>
              )}
            </Stack>
          </DialogContent>
        </Sheet>
      </Box>

      <Box sx={{ flex: 1, height: "calc(100vh - 32px)" }}>
        <Sheet
          sx={{
            borderRadius: "lg",
            height: "calc(100vh - 32px)",
            overflow: "hidden",
          }}
        >
          {selected.length > 0 ? (
            fileBlobURL ? (
              <PDFPreviewAndPrint fileUrl={fileBlobURL} />
            ) : (
              <Typography level="title-lg">Chargement...</Typography>
            )
          ) : (
            <Stack
              direction="column"
              flex={1}
              sx={{ height: "100%", textAlign: "center", opacity: 0.8 }}
              alignItems="center"
              justifyContent="center"
            >
              <div>
                <Typography level="title-lg">
                  Pas d'aperçu disponible
                </Typography>
                <Alert
                  startDecorator={<Help />}
                  sx={{ mt: 2, fontWeight: 400, textAlign: "justify" }}
                >
                  Sélectionnez un fichier à imprimer pour voir un aperçu ici.
                </Alert>
              </div>
            </Stack>
          )}
        </Sheet>
      </Box>
    </Stack>
  );
}
