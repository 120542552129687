import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import React from "react";
import { useApi } from "../../contexts/ApiContext";
import { Specialite } from "../../models/types";
import { useAuthenticatedUser } from "../../contexts/UserContext";

interface SpecialiteSelectProps {
  value: Specialite["id"] | null;
  onSelect: (value: Specialite | null) => void;
  width?: string;
}

export default function SpecialiteSelect({
  value,
  onSelect,
  width = "auto",
}: SpecialiteSelectProps) {
  const api = useApi();
  const { profile } = useAuthenticatedUser();

  const [specialites, setSpecialites] = React.useState<Specialite[]>([]);

  React.useEffect(() => {
    const fetch = async () => {
      const res = await api.listUserSpecialites();
      setSpecialites(res);
    };
    fetch();
  }, [api]);

  React.useEffect(() => {
    // if value is null, get the user profile specialite
    if (!value && profile?.specialite_active) {
      const specialite = specialites.find(
        (s) => s.id === profile.specialite_active,
      );
      if (specialite) {
        onSelect(specialite);
      }
    }
  }, [specialites, value, profile?.specialite_active, onSelect]);

  return (
    <Select
      value={value}
      onChange={(e, newSpecialite) => {
        onSelect(specialites.find((s) => s.id === newSpecialite) as Specialite);
      }}
      style={{ width }}
    >
      <Option value={""}>Non renseigné</Option>
      {specialites.map((specialite) => (
        <Option key={specialite.id} value={specialite.id}>
          {specialite.spe}
        </Option>
      ))}
    </Select>
  );
}
