import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Absence as CustomAbsence } from "../../../models/custom";
import { Absence } from "../../../models/types";
import { useApi } from "../../../contexts/ApiContext";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import Divider from "@mui/joy/Divider";
import Button from "@mui/joy/Button";
import Add from "@mui/icons-material/Add";
import ModalComponent from "../../../components/Modal/Modal";
import Input from "@mui/joy/Input";
import Box from "@mui/joy/Box";
import { useUser } from "../../../contexts/UserContext";
import { useSnackbar } from "../../../contexts/SnackbarContext";
import { HistoryToggleOff } from "@mui/icons-material";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import { formatDateTime } from "../../../utils/utils";

export default function Absences() {
  const api = useApi();
  const user = useUser();
  const snackbar = useSnackbar();

  const emptyAbsence: Absence = useMemo(
    () => ({
      id: "",
      motif: "",
      date_heure_start: "",
      date_heure_end: "",
      zkf_user: user.user?.id || 0,
      created_on: "",
      modified_on: "",
    }),
    [user.user?.id],
  );

  const [absences, setAbsences] = useState<CustomAbsence[]>([]);
  const [editAbsence, setEditAbsence] =
    useState<Partial<Absence>>(emptyAbsence);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openHistoryModal, setOpenHistoryModal] = useState<boolean>(false);

  const refreshAbsences = useCallback(
    () => api.getAbsences().then((res) => setAbsences(res)),
    [api],
  );

  useEffect(() => {
    refreshAbsences();
  }, [api, refreshAbsences]);

  const today = useMemo(() => new Date(), []);

  const absencesFuture = useMemo(
    () =>
      absences.filter(
        (abs) => new Date(abs.date_heure_start).getTime() > today.getTime(),
      ),
    [today, absences],
  );

  const absencesEnCours = useMemo(
    () =>
      absences.filter(
        (abs) =>
          new Date(abs.date_heure_start).getTime() <= today.getTime() &&
          new Date(abs.date_heure_end).getTime() > today.getTime(),
      ),
    [absences, today],
  );

  const handleEdit = useCallback(
    (absence?: Absence) => {
      setEditAbsence(absence || emptyAbsence);
      setOpenModal(true);
    },
    [emptyAbsence],
  );

  const handleDelete = useCallback(
    (id: string) => {
      api
        .deleteAbsence(id)
        .then(() => setAbsences(absences.filter((v) => v.id !== id)))
        .catch(() =>
          snackbar.show("Impossible de supprimer l'absence", "danger"),
        );
    },
    [api, snackbar, absences],
  );

  const handleSave = useCallback(() => {
    if (editAbsence.id) {
      api
        .updateAbsence(editAbsence)
        .then(() => {
          refreshAbsences();
          setOpenModal(false);
        })
        .catch(() =>
          snackbar.show("Impossible de modifier l'absence", "danger"),
        );
    } else {
      api
        .createAbsence(editAbsence)
        .then(() => {
          refreshAbsences();
          setOpenModal(false);
        })
        .catch(() => snackbar.show("Impossible de créer la absence", "danger"));
    }
  }, [editAbsence, api, refreshAbsences, snackbar]);

  const formatDateToDatetimeLocal = (dateStr: string) => {
    if (!dateStr) return "";

    const date = new Date(dateStr);
    const pad = (number: number) => String(number).padStart(2, "0");

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const editModal = useMemo(
    () => (
      <ModalComponent
        open={openModal}
        title={`${editAbsence ? "Editer" : "Créer"} une absence`}
        onClose={() => setOpenModal(false)}
        validateLabel={"Enregistrer"}
        onValidate={handleSave}
        canValidate={
          !!editAbsence.date_heure_start &&
          !!editAbsence.date_heure_end &&
          !!editAbsence.motif
        }
      >
        <Stack spacing={2} flex={1}>
          <Stack spacing={1} direction={"row"} alignItems={"center"}>
            <Typography width={"50px"}>Motif</Typography>
            <Input
              value={editAbsence.motif}
              onChange={(e) =>
                setEditAbsence({ ...editAbsence, motif: e.target.value })
              }
              style={{ flex: 1 }}
            />
          </Stack>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Typography width={"50px"}>Du</Typography>
            <Input
              type={"datetime-local"}
              value={formatDateToDatetimeLocal(
                editAbsence.date_heure_start || "",
              )}
              slotProps={{
                input: {
                  max: editAbsence.date_heure_end,
                },
              }}
              style={{ flex: 1 }}
              onChange={(e) =>
                setEditAbsence({
                  ...editAbsence,
                  date_heure_start: e.target.value,
                })
              }
            />
          </Stack>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Typography width={"50px"}>Au</Typography>
            <Input
              type={"datetime-local"}
              value={formatDateToDatetimeLocal(
                editAbsence.date_heure_end || "",
              )}
              slotProps={{
                input: {
                  min: editAbsence.date_heure_start,
                },
              }}
              style={{ flex: 1 }}
              onChange={(e) =>
                setEditAbsence({
                  ...editAbsence,
                  date_heure_end: e.target.value,
                })
              }
            />
          </Stack>
        </Stack>
      </ModalComponent>
    ),
    [openModal, editAbsence, handleSave],
  );

  const absenceCard = useCallback(
    (absence: CustomAbsence) => (
      <Sheet key={absence.id} sx={{ width: "300px", padding: "10px" }}>
        <Stack
          justifyContent={"space-between"}
          direction={"row"}
          alignItems={"center"}
        >
          <Typography
            level={"title-sm"}
          >{`${absence.zkf_user.last_name} ${absence.zkf_user.first_name}`}</Typography>
          {absence.zkf_user.id === user.user?.id && (
            <Stack direction={"row"} gap={0.5}>
              <Edit
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  handleEdit({
                    ...absence,
                    zkf_user: absence.zkf_user.id,
                  } as Absence)
                }
              />
              <Delete
                sx={{ fill: "red", cursor: "pointer" }}
                onClick={() => handleDelete(absence.id)}
              />
            </Stack>
          )}
        </Stack>
        <Typography level={"body-xs"}>Motif: {absence.motif}</Typography>
        <Typography level={"body-xs"}>
          Du: {formatDateTime(absence.date_heure_start)}
        </Typography>
        <Typography level={"body-xs"}>
          Au: {formatDateTime(absence.date_heure_end)}
        </Typography>
      </Sheet>
    ),
    [handleDelete, handleEdit, user.user?.id],
  );

  const historyModal = useMemo(
    () => (
      <ModalComponent
        open={openHistoryModal}
        title={`Historique des absences`}
        onClose={() => setOpenHistoryModal(false)}
        closeLabel={"Fermer"}
        style={{ minWidth: "80%" }}
      >
        <Stack
          direction={"row"}
          gap={1}
          flexWrap={"wrap"}
          height={"500px"}
          overflow={"scroll"}
        >
          {absences.map(absenceCard)}
        </Stack>
      </ModalComponent>
    ),
    [openHistoryModal, absenceCard, absences],
  );

  return (
    <Stack spacing={2}>
      <Box display={"flex"} justifyContent={"end"}>
        <Stack spacing={1} direction={"row"}>
          <Button startDecorator={<Add />} onClick={() => handleEdit()}>
            Ajouter Absence
          </Button>
          <Button
            startDecorator={<HistoryToggleOff />}
            onClick={() => setOpenHistoryModal(true)}
          >
            Historique
          </Button>
        </Stack>
      </Box>
      <Divider />
      <Stack direction={"row"} spacing={2}>
        <Stack spacing={2} width={"50%"} alignItems={"center"}>
          <Typography level={"h4"}>Absences en Cours</Typography>
          {absencesEnCours.map(absenceCard)}
        </Stack>
        <Stack spacing={2} alignItems={"center"}>
          <Typography level={"h4"}>Absences Future</Typography>
          {absencesFuture.map(absenceCard)}
        </Stack>
      </Stack>
      {editModal}
      {historyModal}
    </Stack>
  );
}
