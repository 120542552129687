import React from "react";
import Add from "@mui/icons-material/Add";
import People from "@mui/icons-material/People";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import ConsultationCard from "../../components/ConsultationCard";
import { Consultation } from "../../models/types";
import CreateConsultationModal from "../Calendar/CreateConsultationModal";
import Modal from "@mui/joy/Modal";

interface ListOfConsultationsProps {
  consultations: Consultation[];
  refresh: () => void;
  hideHeader?: boolean;
  hideDate?: boolean;
}

export default function ListOfConsultations({
  consultations,
  hideHeader,
  hideDate,
  refresh,
}: ListOfConsultationsProps) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOnSave = () => {
    handleCloseModal();
    refresh();
  };

  consultations.sort(
    (a, b) =>
      new Date(b.consultation_date!).getTime() -
      new Date(a.consultation_date!).getTime(),
  );

  return (
    <Box>
      {!hideHeader && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            level="title-lg"
            startDecorator={<People />}
            color="primary"
          >
            Consultations
          </Typography>
          <Button
            variant="soft"
            startDecorator={<Add />}
            color="primary"
            size="sm"
            onClick={handleOpenModal}
          >
            Créer
          </Button>
        </Stack>
      )}
      <Stack sx={{ mt: 2 }} direction="column" gap={1}>
        {consultations && consultations.length > 0 ? (
          consultations.map((consult: Consultation) => (
            <ConsultationCard
              key={consult.id}
              consult={consult}
              hideDate={hideDate}
            />
          ))
        ) : (
          <Stack
            sx={{ height: "105px" }}
            alignItems="center"
            justifyContent="center"
          >
            <Typography level="body-md" color="neutral">
              Aucune consultation
            </Typography>
          </Stack>
        )}
      </Stack>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateConsultationModal
          onSave={() => {
            handleOnSave();
          }}
          onClose={handleCloseModal}
        />
      </Modal>
    </Box>
  );
}
