import React from "react";
import { PrintManagerFile } from "../models/custom";
import { useApi } from "./ApiContext";
import { useSnackbar } from "./SnackbarContext";

// TYPE OF FILES
// "crh": 0,
// "cro": 1,
// "devis": 2,
// "ordonnance": 3,
// "doc_libre": 4,
// "convocation_consultation": 5,
// "courrier_consultation": 6,
// "convocation_operation": 7,
// "facture": 8,
// "avoir": 9,

const PrintManagerContext = React.createContext<{
  files: PrintManagerFile[];
  addToPrintManager: (doc_type: string, uuid: string) => void;
  removeFromPrintManager: (uuid?: string) => void;
  refreshPrintManager: () => void;
}>({
  files: [],
  addToPrintManager: () => {},
  removeFromPrintManager: () => {},
  refreshPrintManager: () => {},
});

export function PrintManagerContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const api = useApi();
  const snackbar = useSnackbar();

  // queue is only handled by the serverside, so we need to check with the server
  const [files, setFiles] = React.useState<PrintManagerFile[]>([]);
  const [refresh, setRefresh] = React.useState(new Date().getTime());

  React.useEffect(() => {
    // everytime the files change, check with the server if the files are still valid
    // queue needs to come from the backend, because the files might have been deleted or modified
    // or session might have expired etc
    api?.getPrintManagerQueue().then((files) => {
      if (files) {
        setFiles(files);
      } else {
        setFiles([]);
      }
    });
  }, [refresh, api]);

  const refreshQueue = () => {
    setRefresh(new Date().getTime());
  };

  const addToPrintManager = (doc_type: string, doc_id: string) => {
    // call the api to add the file to the print manager, then refresh the queue
    api
      ?.addToPrintManager(doc_type, doc_id)
      .then(() => {
        snackbar.show(
          "L'ajout au gestionnaire d'impression a réussi",
          "success",
        );
        refreshQueue();
      })
      .catch(() =>
        snackbar.show(
          "L'ajout au gestionnaire d'impression a echoué",
          "danger",
        ),
      );
  };

  const removeFromPrintManager = (uuid?: string) => {
    // call the api to remove the file from the print manager, then refresh the queue
    api?.deletePrintManagerDoc(uuid).then(() => {
      refreshQueue();
    });
  };

  return (
    <PrintManagerContext.Provider
      value={{
        files,
        addToPrintManager,
        removeFromPrintManager,
        refreshPrintManager: refreshQueue,
      }}
    >
      {children}
    </PrintManagerContext.Provider>
  );
}

export const usePrintManager = () => React.useContext(PrintManagerContext);
