import Autocomplete from "@mui/joy/Autocomplete";
import { useApi } from "../../contexts/ApiContext";
import React from "react";
import { UserSitesLieux } from "../../models/types";
import { useSnackbar } from "../../contexts/SnackbarContext";

export default function ConsultingSitePlaceSelector({
  siteId,
  onSelect,
}: {
  siteId: string | null;
  onSelect: (site: UserSitesLieux["id"]) => void;
}) {
  const api = useApi();
  const snackbar = useSnackbar();

  const [places, setPlaces] = React.useState<UserSitesLieux[]>([]);
  const [selectedPlace, setSelectedPlace] =
    React.useState<UserSitesLieux | null>(null);
  const [noPlaces, setNoPlaces] = React.useState<boolean>(false);

  React.useEffect(() => {
    const fetch = async () => {
      if (!siteId) {
        return;
      }
      try {
        const lieux = await api.getSitePlaces(siteId);
        setPlaces(lieux);
        if (lieux.length > 0) {
          setSelectedPlace(lieux[0]);
          onSelect(lieux[0].id);
        } else {
          setNoPlaces(true);
        }
      } catch (e) {
        snackbar.show("Impossible de récupérer les lieux", "danger");
      }
    };
    fetch();
  }, [api, siteId]);

  return (
    <Autocomplete
      sx={{ flex: 1 }}
      options={places}
      disabled={!siteId || noPlaces || places.length === 1}
      getOptionLabel={(place) => place.nom_lieu}
      placeholder={
        noPlaces ? "Pas de lieu défini pour ce site." : "Sélectionner un lieu"
      }
      value={selectedPlace!}
      onChange={(_, newValue) => {
        setSelectedPlace(newValue);
        if (newValue) {
          onSelect(newValue.id);
        }
      }}
      disableClearable
    />
  );
}
