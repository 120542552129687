import CircularProgress from "@mui/joy/CircularProgress";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";

export default function LoadingPage() {
  return (
    <Stack
      sx={{ height: "100vh", width: "100vw" }}
      alignItems="center"
      justifyContent="center"
    >
      <Stack gap={2} alignItems="center">
        <CircularProgress variant="plain" size="md" color="primary" />
        <Typography level="body-sm" color="neutral">
          Chargement de la page...
        </Typography>
      </Stack>
    </Stack>
  );
}
