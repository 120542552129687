import React, { useState } from "react";
import mammoth from "mammoth";

interface DocViewerProps {
  file: File;
  height?: string;
}

const WordPreview: React.FC<DocViewerProps> = ({ file, height = "auto" }) => {
  const [docContent, setDocContent] = useState("");

  React.useEffect(() => {
    if (
      file &&
      (file.type === "application/msword" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
    ) {
      file.arrayBuffer().then((arrayBuffer) => {
        mammoth
          .convertToHtml({ arrayBuffer })
          .then((result) => {
            setDocContent(result.value); // Set the converted HTML content
          })
          .catch((error) => console.error("Error reading file:", error));
      });
    } else {
      alert("Please select a DOC or DOCX file");
    }
  }, [file]);

  return (
    <div
      style={{
        overflowY: "auto",
        flex: 1,
        maxHeight: height,
      }}
    >
      <div
        style={{ marginTop: "20px", border: "1px solid #ddd", padding: "10px" }}
        dangerouslySetInnerHTML={{ __html: docContent }}
      />
    </div>
  );
};

export default WordPreview;
