import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionGroup from "@mui/joy/AccordionGroup";
import AccordionSummary from "@mui/joy/AccordionSummary";
import Box from "@mui/joy/Box";
import React from "react";
import PatientCard from "../../components/PatientCard";
import { isPatient } from "../../models/type_guards";
import { Hospitalisation } from "../../models/types";
import { HospitalisationByService } from "./ServicePage";

interface ListOfServicesProps {
  hospitalisations: HospitalisationByService;
  onLeave: (
    leaveDate: string | undefined,
    key: string,
    serviceId: string,
  ) => void;
}

export default function ListOfServices({
  hospitalisations,
  onLeave,
}: ListOfServicesProps) {
  const [expanded, setExpanded] = React.useState<boolean[]>([]);

  React.useEffect(() => {
    const isExpanded = [];
    for (let i = 0; i < Object.keys(hospitalisations).length; i++) {
      isExpanded.push(true);
    }
    setExpanded(isExpanded);
  }, [hospitalisations]);

  const handleChange = (index: number, isExpanded: boolean) => {
    const newExpanded = [...expanded];
    newExpanded[index] = isExpanded;
    setExpanded(newExpanded);
  };

  if (!expanded.length) {
    return null;
  }

  return (
    <Box width={"50%"}>
      {Object.keys(hospitalisations).map((key: string, index: number) => (
        <AccordionGroup key={key}>
          <Accordion
            expanded={expanded[index]}
            onChange={(_, isExpanded) => handleChange(index, isExpanded)}
          >
            <AccordionSummary>{key}</AccordionSummary>
            {hospitalisations[key].map((service: Hospitalisation) => (
              <AccordionDetails key={service.id}>
                {isPatient(service.zkf_patient) && (
                  <PatientCard
                    patient={service.zkf_patient}
                    width="100%"
                    hospitalisation={service}
                    onLeave={(leaveDate) => onLeave(leaveDate, key, service.id)}
                  />
                )}
              </AccordionDetails>
            ))}
          </Accordion>
        </AccordionGroup>
      ))}
    </Box>
  );
}
