import React from "react";
import TabList from "@mui/joy/TabList";
import Stack from "@mui/joy/Stack";
import Tab from "@mui/joy/Tab";
import Tabs from "@mui/joy/Tabs";
import Typography from "@mui/joy/Typography";
import { TabPanel } from "@mui/joy";
import CroTab from "./CroTab";
import ConsultationTab from "./ConsultationTab";
import OrdonnanceTab from "./OrdonnanceTab";
import DocumentLibreTab from "./DocumentLibreTab";
import MaterielIrradiationTab from "./MaterielIrradiationTab";
import ConsignesSortieTab from "./ConsignesSortieTab";

const tabs = [
  {
    label: "CRO",
    component: CroTab,
  },
  {
    label: "Consultation",
    component: ConsultationTab,
  },
  {
    label: "Ordonnances",
    component: OrdonnanceTab,
  },
  {
    label: "Documents Libre",
    component: DocumentLibreTab,
  },
  {
    label: "Consignes Sortie",
    component: ConsignesSortieTab,
  },
  {
    label: "Materiel Irradiation",
    component: MaterielIrradiationTab,
  },
];

export default function DocumentTypesTab() {
  return (
    <Stack direction="column" gap={2}>
      <Typography level="h4">Document Types</Typography>
      <Tabs orientation="vertical">
        <TabList>
          {tabs.map((tab, index) => (
            <Tab key={index} sx={{ width: "180px" }}>
              {tab.label}
            </Tab>
          ))}
        </TabList>
        {tabs.map((tab, index) => (
          <TabPanel key={index} value={index}>
            <tab.component />
          </TabPanel>
        ))}
      </Tabs>
    </Stack>
  );
}
