import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import CalendarPage from "./pages/Calendar/CalendarPage";
import Landing from "./pages/Landing";
import ConsultationPage from "./pages/PatientPage/Consultation/ConsultationPage";
import CreateConsultation from "./pages/PatientPage/Consultation/CreateConsultation";
import EditHistory from "./pages/PatientPage/History/EditHistory";
import HospitalisationPage from "./pages/PatientPage/Hospitalisation/HospitalisationPage";
import ListOfPatients from "./pages/PatientPage/ListOfPatients";
import CreateOperation from "./pages/PatientPage/Operation/CreateOperation";
import OperationPage from "./pages/PatientPage/Operation/OperationPage";
import PatientEditPage from "./pages/PatientPage/PatientEditPage";
import PatientPage from "./pages/PatientPage/PatientPage";
import PreferencePage from "./pages/Preferences/PreferencePage";
import ServicePage from "./pages/Service/ServicePage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <div>404</div>,
    children: [
      {
        path: "",
        element: <Landing />,
        id: "landing-page",
      },
      {
        path: "patient",
        element: <ListOfPatients />,
        id: "patient-list",
      },
      {
        path: "patient/:id",
        element: <PatientPage />,
        id: "patient-edit-by-id",
      },
      {
        path: "patient/:id/edit",
        element: <PatientEditPage />,
        id: "patient-view-by-id",
      },
      {
        path: "calendar",
        element: <CalendarPage />,
        id: "calendar-page",
      },
      {
        path: "service",
        element: <ServicePage />,
        id: "service",
      },
      {
        path: "consultation/create",
        element: <CreateConsultation />,
        id: "consultation-create",
      },
      {
        path: "consultation/:id",
        element: <ConsultationPage />,
        id: "consultation-view",
      },
      {
        path: "operation/create",
        element: <CreateOperation />,
        id: "operation-create",
      },
      {
        path: "operation/:operation_id",
        element: <OperationPage />,
        id: "operation-view",
      },
      {
        path: "patient/:patient_id/medical-history/edit",
        element: <EditHistory />,
        id: "edit-history-of-patient",
      },
      {
        path: "hospitalisation/:hospitalisation_id",
        element: <HospitalisationPage />,
        id: "hospitalisation-view",
      },
      {
        path: "hospitalisation/:hospitalisation_id/create-operation",
        element: <CreateOperation />,
        id: "hospitalisation-create-operation",
      },
      {
        path: "preferences",
        element: <PreferencePage />,
        id: "preferences-page",
      },
    ],
  },
]);
