import HistoryIcon from "@mui/icons-material/History";
import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import { useApi } from "../../../contexts/ApiContext";
import { FormattedHistory } from "../../../models/custom";
import { Patient } from "../../../models/types";

export default function MedicalHistory({ patient }: { patient: Patient }) {
  const api = useApi();
  const [lastUpdate, setLastUpdate] = React.useState<string | null>(null);

  const [formattedHistory, setFormattedHistory] =
    React.useState<FormattedHistory | null>(null);

  React.useEffect(() => {
    if (api) {
      Promise.all([
        api.getAntecedentsFields(patient.id).catch((err) => {
          console.error(err);
          return Promise.resolve(null);
        }),
        api
          .getAntecedents(patient.id)
          .then((res) => {
            // format last update date from timestampTZ to a more readable format
            const formatted = res.date_last_update_atcd
              ? new Date(res.date_last_update_atcd).toLocaleDateString()
              : null;
            setLastUpdate(formatted);
            return res;
          })
          .catch((err) => {
            return null;
          }),
      ]).then(([fieldsResponse, historyResponse]) => {
        // Now, `fields` and `history` should be populated
        if (fieldsResponse && historyResponse) {
          const formatted: FormattedHistory = {};
          // find all heads in the fields
          const heads = new Set<string>();
          Object.keys(fieldsResponse).forEach((key) => {
            heads.add(fieldsResponse[key].head);
          });
          // then group formatted history by head, and for each history value, find the corresponding field label based on the field and value keys
          // for each head, find all fields. then for each field, find the corresponding value in the history
          heads.forEach((head) => {
            // find all fields that have the same head
            const fields = Object.keys(fieldsResponse).filter(
              (key) => fieldsResponse[key].head === head,
            );
            const values: any = [];
            // find all values which key === field key
            fields.forEach((field) => {
              if (historyResponse[field]) {
                values.push({
                  label: fieldsResponse[field].label,
                  value: historyResponse[field],
                });
              }
            });
            // don't push head if there are no values
            if (values.length > 0) {
              formatted[head] = { values };
            }
          });
          // console.log("=======>", formatted);
          setFormattedHistory(formatted);
        }
      });
    }
  }, [api, patient]);

  const formatToDisplayValue = (value: any): React.ReactNode => {
    if (typeof value === "boolean") {
      return value ? "Oui" : "Non";
    } else if (typeof value === "string") {
      // format string to display \n as <br />
      // if the string contains \n, split it and return a div for each line
      if (value.includes("\n")) {
        const lines = value.split("\n");
        return lines.map((line, index) => (
          <Box
            key={index}
            sx={{
              lineHeight: "90%",
              marginBottom: "12px",
              // "&:not(:last-child)": { marginBottom: "8px" },
            }}
          >
            {line}
          </Box>
        ));
      } else {
        return value;
      }
    } else {
      return value;
    }
  };

  return (
    <Box>
      <Typography level="title-lg" startDecorator={<HistoryIcon />}>
        Antécédents
      </Typography>
      {!formattedHistory ? (
        <Stack
          sx={{ height: "150px" }}
          alignItems="center"
          justifyContent="center"
        >
          <Typography level="body-md" color="neutral">
            Aucun antécédent renseigné
          </Typography>
        </Stack>
      ) : (
        <>
          {lastUpdate && (
            <Typography level="body-sm">
              Dernière mise à jour le {lastUpdate}
            </Typography>
          )}
          <Box sx={{ mt: 2, fontSize: "0.9em" }}>
            {formattedHistory &&
              Object.keys(formattedHistory).map((head) => (
                <Sheet
                  key={head}
                  variant="outlined"
                  sx={{ mb: 1, p: 2, borderRadius: "sm" }}
                >
                  <Typography
                    level="title-sm"
                    textTransform="uppercase"
                    sx={{ opacity: 0.5 }}
                  >
                    {head}
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    {formattedHistory[head].values.map((value: any) => (
                      <Stack direction="row" gap={1} key={value.label}>
                        <Typography
                          level="body-sm"
                          sx={{ textTransform: "uppercase" }}
                        >
                          {value.label}
                        </Typography>
                        <Typography
                          level="body-sm"
                          alignSelf="center"
                          fontWeight={600}
                        >
                          {formatToDisplayValue(value.value)}
                        </Typography>
                      </Stack>
                    ))}
                  </Box>
                </Sheet>
              ))}
          </Box>
        </>
      )}
    </Box>
  );
}
