import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./Editor.css";

interface EditorProps {
  data: string;
  onChange: (value: string) => void;
  onReady?: (editor: ClassicEditor) => void;
  onFocus?: (editor: ClassicEditor) => void;
}

const Editor: React.FC<EditorProps> = ({
  data,
  onChange,
  onReady,
  onFocus,
}) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={data}
      onChange={(event, editor) => onChange(editor.getData())}
      onReady={onReady}
      onFocus={(event, editor) => onFocus && onFocus(editor)}
    />
  );
};

export default Editor;
