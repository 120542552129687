import Checkbox from "@mui/joy/Checkbox";
import Chip from "@mui/joy/Chip";
import Stack from "@mui/joy/Stack";
import React from "react";
import { useApi } from "../../contexts/ApiContext";
import { User } from "../../models/types";
import Divider from "@mui/joy/Divider";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import Tooltip from "@mui/joy/Tooltip";
import { Cancel, CancelOutlined } from "@mui/icons-material";

export default function DoctorSelectChips({
  value,
  onSelect,
  multiple,
}: {
  value?: number | number[];
  onSelect: (doctorId: number | number[]) => void;
  multiple?: boolean;
}) {
  const [selectedDr, setSelectedDr] = React.useState<number | number[] | null>(
    multiple ? (value as number[]) : (value as number),
  );
  const [linkedUsers, setLinkedUsers] = React.useState<User[]>([]);
  const api = useApi();

  React.useEffect(() => {
    api?.getLinkedUsers().then((users) => {
      setLinkedUsers(users.results);
      if (value) {
        setSelectedDr(value);
      } else {
        setSelectedDr(users.results[0].id);
      }
    });
  }, [api, value]);

  const areAllSelected = React.useMemo(() => {
    if (!Array.isArray(selectedDr) || linkedUsers.length === 0) return false;
    return linkedUsers.every((user) => selectedDr.includes(user.id));
  }, [selectedDr, linkedUsers]);

  return (
    <Stack sx={{ mt: 2 }} direction="row" gap={1} alignItems="center">
      {multiple && (
        <>
          <Tooltip
            title={areAllSelected ? "Désélectionner tous" : "Sélectionner tous"}
          >
            <Chip
              variant="outlined"
              color={areAllSelected ? "neutral" : "primary"}
              sx={{
                transition: "all 0.2s ease-in-out",
                "&:hover": {
                  bgcolor: areAllSelected
                    ? "primary.softHoverBg"
                    : "neutral.softHoverBg",
                  transform: "scale(1.02)",
                },
                width: "32px",
                justifyContent: "center",
                lineHeight: 1,
              }}
              onClick={() => {
                const newValue = areAllSelected
                  ? []
                  : linkedUsers.map((user) => user.id);
                onSelect(newValue);
              }}
            >
              {areAllSelected ? <CancelOutlined /> : <GroupIcon />}
            </Chip>
          </Tooltip>
          <Divider orientation="vertical" />
        </>
      )}
      {linkedUsers.map((user) => {
        const checked = Array.isArray(selectedDr)
          ? selectedDr.includes(user.id)
          : selectedDr === user.id;
        return (
          <Chip
            key={user.last_name?.toUpperCase() + " " + user.first_name}
            variant="soft"
            color={checked ? "primary" : "neutral"}
            sx={{
              bgcolor: checked ? "primary.softBg" : "neutral.softBg",
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                bgcolor: checked
                  ? "primary.softHoverBg"
                  : "neutral.softHoverBg",
                transform: "scale(1.02)",
              },
            }}
          >
            <Checkbox
              variant="outlined"
              color={checked ? "primary" : "neutral"}
              disableIcon
              overlay
              label={user.last_name?.toUpperCase() + " " + user.first_name}
              checked={checked}
              onChange={(event) => {
                const newValue = multiple
                  ? checked
                    ? (selectedDr as number[]).filter((id) => id !== user.id)
                    : [...(selectedDr as number[]), user.id]
                  : user.id;
                onSelect(newValue);
              }}
            />
          </Chip>
        );
      })}
    </Stack>
  );
}
