import Chip from "@mui/joy/Chip";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { Link } from "react-router-dom";
import { isLieu } from "../models/type_guards";
import { Consultation } from "../models/types";
import { formatDate, formatHour } from "../utils/utils";

interface ConsultationCardProps {
  consult: Consultation;
  hideDate?: boolean;
}

export default function ConsultationCard({
  consult,
  hideDate = false,
}: ConsultationCardProps) {
  return (
    <Link to={`/consultation/${consult.id}`} key={consult.id}>
      <Sheet
        color="primary"
        variant="soft"
        sx={{
          border: "1px solid transparent",
          borderRadius: "sm",
          p: 2,
          "&:hover": {
            borderColor: (theme) => theme.palette.primary[500],
          },
        }}
      >
        <Stack direction="column" gap={1}>
          <Stack direction="row" justifyContent="space-between">
            <div>
              {!hideDate && (
                <Typography level="body-xs">
                  {formatDate(consult.consultation_date)}
                </Typography>
              )}
              <Typography level="body-sm">
                {formatHour(consult.consultation_debut_prevu) +
                  " - " +
                  formatHour(consult.consultation_fin_prevu)}
              </Typography>
            </div>
            <Typography level="body-md" component={"div"}>
              {consult.premiere_consultation && (
                <Chip variant="outlined" color="success">
                  Première consultation
                </Chip>
              )}
              {consult.mode_consultation === "urgent" && (
                <Chip variant="outlined" color="danger">
                  Urgence
                </Chip>
              )}
            </Typography>
          </Stack>
          <Typography level="body-xs">{consult.motif_consultation}</Typography>
          <Typography
            level="body-sm"
            sx={{ fontStyle: "italic", textAlign: "right" }}
          >
            {(isLieu(consult.zkf_lieu) && consult.zkf_lieu?.nom_lieu) || ""}
          </Typography>
        </Stack>
      </Sheet>
    </Link>
  );
}
