import { useApi } from "../../../contexts/ApiContext";
import { useEffect, useState } from "react";
import { Operation } from "../../../models/types";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { Textarea } from "@mui/joy";
import { useSnackbar } from "../../../contexts/SnackbarContext";
import Button from "@mui/joy/Button";
import Checkbox from "@mui/joy/Checkbox";
import { ComplicationPostOp } from "../../../models/custom";

interface ComplicationsTabProps {
  operation: Operation;
}

export default function ComplicationsTab({ operation }: ComplicationsTabProps) {
  const api = useApi();
  const snackbar = useSnackbar();
  const [complications, setComplications] = useState<ComplicationPostOp>();

  useEffect(() => {
    api
      .getComplicationsPostOp(operation.id)
      .then((res) => setComplications(res[0]));
  }, [api, operation.id]);

  const handleSave = () => {
    complications &&
      api
        .updateComplicationsPostOp(complications, operation.id)
        .then((res) => {
          snackbar.show(
            "La modification des complications a réussie",
            "success",
          );
          setComplications(res);
        })
        .catch(() =>
          snackbar.show(
            "La modification des complications a échouée",
            "danger",
          ),
        );
  };

  const renderComplication = (
    field: keyof ComplicationPostOp,
    label: string,
  ) => (
    <Stack direction={"row"} alignItems={"center"}>
      <Typography width={"130px"}>{label}</Typography>
      <Checkbox
        checked={(complications?.[field] as boolean) || false}
        onClick={() =>
          setComplications((prevState) =>
            prevState
              ? { ...prevState, [field]: !complications?.[field] }
              : undefined,
          )
        }
      />
    </Stack>
  );

  if (!complications) return null;

  return (
    <Stack gap={2}>
      <Button variant={"soft"} color={"success"} onClick={handleSave}>
        Save
      </Button>
      {renderComplication("complication", "Complication")}
      {renderComplication("coeur", "Coeur")}
      {renderComplication("neurologique", "Neurologique")}
      {renderComplication("pneumologique", "Pneumologique")}
      {renderComplication("hemorragie", "Hemorragie")}
      {renderComplication("cicatrisation", "Vicatrisation")}
      {renderComplication("deces", "Deces")}
      {renderComplication("reintervention", "Reintervention")}
      <Stack gap={1}>
        <Typography>Details complication</Typography>
        <Textarea
          value={complications?.detail_complication || ""}
          onChange={(e) =>
            setComplications({
              ...complications,
              detail_complication: e.target.value,
            })
          }
          minRows={3}
        />
      </Stack>
    </Stack>
  );
}
