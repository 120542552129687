import { Box, Modal, ModalDialog, Sheet, Stack, Typography } from "@mui/joy";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import React from "react";
import {
  Calendar,
  dayjsLocalizer,
  Event,
  SlotInfo,
  View,
} from "react-big-calendar";
import DoctorSelectChips from "../../components/Forms/DoctorSelectChips";
import { useApi } from "../../contexts/ApiContext";
import { useSessionPreferences } from "../../contexts/SessionPreferencesContext";
import { useAuthenticatedUser } from "../../contexts/UserContext";
import { CalendarItem, Evenement } from "../../models/types";
import CalendarItemViewer from "./CalendarItemViewer";
import CreateSlotModal from "./CreateSlotModal";
import CreateEventForm from "./Forms/CreateEventForm";

const localizer = dayjsLocalizer(dayjs);
//set local to france => week starts on monday !!!!!
dayjs.locale("fr");

interface ColoredEvent extends Event {
  id: string;
  background_color?: string;
  text_color?: string;
  item_type?: CalendarItem["type"];
}

interface Resource {
  id: string;
  title: string;
}

const eventPropGetter = (event: ColoredEvent) => {
  const backgroundColor = event.background_color || "#3174ad"; // Default color if none is specified
  const color = event.text_color || "#fff"; // Default text color if none is specified
  return {
    style: {
      backgroundColor,
      color,
      borderColor: "#FFF",
      borderSize: 1,
      opacity: event.item_type === "vacation" ? 0.33 : 1,
    },
  };
};

export default function CalendarPage() {
  const { user } = useAuthenticatedUser();
  const api = useApi();
  const sessionPreferences = useSessionPreferences();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [events, setEvents] = React.useState<ColoredEvent[]>([]);
  const [backGroundEvents, setBackGroundEvents] = React.useState<
    ColoredEvent[]
  >([]);
  const [resources, setResources] = React.useState<Resource[]>([]);
  const [items, setItems] = React.useState<CalendarItem[]>([]);
  const [refresh, setRefresh] = React.useState<number>(new Date().getTime());
  const [selectedSlot, setSelectedSlot] = React.useState<SlotInfo | null>(null);
  const [selectedDoctors, setSelectedDoctors] = React.useState<number[]>(
    sessionPreferences.data.selectedUsers || [Number(user.id)],
  );

  const [view, setView] = React.useState<View>("week");

  //Also, pay attention that for the Month view it returns a structure like { start, end }, but for the Week view, you will receive an array [0, ..., 6] for all the days of week. And it does not work for the Schedule view.
  //https://stackoverflow.com/questions/57754702/how-to-get-the-first-and-last-visible-date-in-react-big-calendar
  const [range, setRange] = React.useState<
    | Date[]
    | {
        start: Date;
        end: Date;
      }
  >(() => {
    if (view === "week") {
      // one for each day, starting on Monday
      return Array.from({ length: 7 }, (_, i) =>
        dayjs().startOf("week").add(i, "day").toDate(),
      );
    } else if (view === "month") {
      // one for each day, starting on the first of the month
      return Array.from({ length: 31 }, (_, i) =>
        dayjs().startOf("month").add(i, "day").toDate(),
      );
    }
    return [];
  });

  const [clickedEvent, setClickedEvent] = React.useState<CalendarItem | null>(
    null,
  );
  const [createEvent, setCreateEvent] = React.useState<Evenement | null>(null);

  React.useEffect(() => {
    setItems([]);
    setEvents([]);
    setBackGroundEvents([]);
    setLoading(true);
    // fetch events from API
    // remove last 3 digits from the timestamp, as the API expects seconds
    // check if range is an object with start and end or an array of 7 dates
    let start = 0;
    let end: number = 0;
    if (Array.isArray(range)) {
      if (range.length === 1) {
        // only one day, set start and end to the same day but at 23:59:59
        start = Number(range[0].getTime().toString().slice(0, -3));
        end = Number(range[0].getTime().toString().slice(0, -3)) + 86399;
      } else {
        start = Number(range[0].getTime().toString().slice(0, -3));
        end = Number(range[range.length - 1].getTime().toString().slice(0, -3));
      }
    } else {
      start = Number(range.start.getTime().toString().slice(0, -3));
      end = Number(range.end.getTime().toString().slice(0, -3));
    }
    api
      ?.getCalendarItems(start, end, selectedDoctors)
      .then((items: CalendarItem[]) => {
        // for each item, convert to the format expected by react-big-calendar and add to events
        setItems(items);
        const newEvents = items
          .filter((item) => item.type !== "vacation" && item.zkf_user !== null)
          .map((item: CalendarItem) => ({
            id: item.id,
            title: item.title,
            start: new Date(item.start * 1000),
            end: new Date(item.end * 1000),
            zkf_user: item.zkf_user, //
            background_color: item.background_color,
            text_color: item.text_color,
            item_type: item.type,
            resourceId: item.zkf_user.toString(),
          }));
        setEvents(newEvents);

        const newBackGroundEvents = items
          .filter((item) => item.type === "vacation")
          .map((item: CalendarItem) => ({
            id: item.id,
            title: item.title,
            start: new Date(item.start * 1000),
            end: new Date(item.end * 1000),
            zkf_user: item.zkf_user,
            background_color: item.background_color,
            item_type: item.type,
            resourceId: item.zkf_user.toString(),
          }));
        setBackGroundEvents(newBackGroundEvents);

        // //define resources => group by resource (=user)
        // //NEED AMELIORATIONS ???
        // get all ids of users
        // only group by resource if the view is day
        if (view === "day") {
          // get all unique zkf_user strings from items
          const ids = Array.from(new Set(items.map((item) => item.zkf_user)));
          const r = ids.map((zkf_user) => ({
            id: zkf_user,
            title: zkf_user,
          }));
          setResources(r);
        } else {
          setResources([]);
        }

        // setResources(newResource);
        setLoading(false);
      });
  }, [api, range, refresh, selectedDoctors, view]);

  React.useEffect(() => {
    sessionPreferences.setData({
      ...sessionPreferences.data,
      selectedUsers: selectedDoctors,
    });
  }, [selectedDoctors, sessionPreferences]);

  const scrollToTime = () => {
    // scroll to the current time - 1 hour
    const now = new Date();
    now.setHours(now.getHours() - 1);
    return now;
  };

  const handleClickEvent = (event: ColoredEvent) => {
    const clickedEvent = events.find((e) => e.id === event.id);
    if (clickedEvent) {
      const item = items.find((i) => i.id === event.id);
      if (item) {
        setClickedEvent(item);
      }
    }
  };

  const handleOnNavigate = (date: Date) => {
    // update the range when navigating
    let newRange: Date[] = [];
    if (view === "week") {
      // one for each day, starting on Monday
      newRange = Array.from({ length: 7 }, (_, i) =>
        dayjs(date).startOf("week").add(i, "day").toDate(),
      );
    } else if (view === "month") {
      // one for each day, starting on the first of the month
      newRange = Array.from({ length: 31 }, (_, i) =>
        dayjs(date).startOf("month").add(i, "day").toDate(),
      );
    }
    setRange(newRange);
  };

  const handleSelectSlot = (slotInfo: SlotInfo) => {
    // If the end time equals the start time (single click),
    // set end time to start time + 15 minutes for consultations
    if (slotInfo.start.getTime() === slotInfo.end.getTime()) {
      slotInfo.end = new Date(slotInfo.start.getTime() + 15 * 60 * 1000);
    }
    setSelectedSlot(slotInfo);
  };

  const handleSelectDoctors = (ids: number | number[]) => {
    setSelectedDoctors(ids as number[]);
  };

  return (
    <Box>
      <Typography level="h1" sx={{ my: 2 }}>
        Agenda
      </Typography>
      <Stack
        direction="row"
        gap={2}
        sx={{ my: 2 }}
        justifyContent="space-between"
      >
        <DoctorSelectChips
          value={selectedDoctors}
          multiple
          onSelect={handleSelectDoctors}
        />
      </Stack>
      <Box
        sx={{
          opacity: loading ? 0.5 : 1,
          pointerEvents: loading ? "none" : "auto",
          position: "relative",
        }}
      >
        <Calendar
          localizer={localizer}
          events={events}
          {...(view === "day"
            ? {
                resources,
                resourceIdAccessor: "id",
                resourceTitleAccessor: "title",
              }
            : {})}
          backgroundEvents={backGroundEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ height: "calc(100vh - 200px" }}
          selectable
          onSelectSlot={handleSelectSlot}
          view={view}
          views={["week", "month"]}
          //Callback fired when the visible date range changes. Returns an Array of dates or an object with start and end dates for BUILTIN views.
          onRangeChange={(range) => setRange(range as Date[])}
          onView={(view) => {
            setView(view as View);
          }}
          onSelectEvent={handleClickEvent}
          step={15}
          timeslots={2}
          scrollToTime={scrollToTime()}
          defaultDate={new Date()}
          onNavigate={handleOnNavigate}
          eventPropGetter={eventPropGetter}
          culture="fr-FR"
        />
      </Box>
      <Modal open={!!selectedSlot} onClose={() => setSelectedSlot(null)}>
        <ModalDialog>
          <CreateSlotModal
            slot={selectedSlot!}
            onSave={() => {
              setRefresh(new Date().getTime());
            }}
            onClose={() => setSelectedSlot(null)}
          />
        </ModalDialog>
      </Modal>
      <Modal open={!!clickedEvent} onClose={() => setClickedEvent(null)}>
        {clickedEvent ? <CalendarItemViewer event={clickedEvent} /> : <></>}
      </Modal>
      <Modal open={!!createEvent} onClose={() => setCreateEvent(null)}>
        <Stack
          sx={{ height: "100vh" }}
          alignItems="center"
          justifyContent="center"
        >
          <Sheet sx={{ width: "800px", p: 2, borderRadius: "lg" }}>
            <CreateEventForm event={createEvent!} />
          </Sheet>
        </Stack>
      </Modal>
    </Box>
  );
}
