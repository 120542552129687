import { CompteRendu, Patient } from "../../models/types";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import Add from "@mui/icons-material/Add";
import ListOfFilesPreview from "../../components/File/ListOfFilesPreview";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import ModalComponent from "../../components/Modal/Modal";
import React, { useCallback, useEffect, useMemo } from "react";
import { useApi } from "../../contexts/ApiContext";
import { useSnackbar } from "../../contexts/SnackbarContext";
import Typography from "@mui/joy/Typography";
import PatientSelector from "../../components/Forms/PatientSelector";
import Input from "@mui/joy/Input";
import ContactSelector from "../../components/Forms/ContactSelector";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import SpecialiteMultiSelect from "../../components/Forms/SpecialiteMultiSelect";
import ModalUploader from "../../components/Uploader/ModalUploader";

interface DocumentsModalProps {
  patient: Patient;
  open: boolean;
  onClose: () => void;
}

export default function DocumentsModal({
  patient,
  open,
  onClose,
}: DocumentsModalProps) {
  const api = useApi();
  const snackbar = useSnackbar();

  const [openUpload, setOpenUpload] = React.useState(false);
  const [compteRendu, setCompteRendu] = React.useState<CompteRendu[]>([]);
  const [files, setFiles] = React.useState<File[]>([]);
  const [editCompteRendu, setEditCompteRendu] = React.useState<CompteRendu>();
  const [openEditModal, setOpenEditModal] = React.useState<boolean>(false);

  const getFilesFromCr = useCallback(async (cr: CompteRendu[]) => {
    const newFiles: File[] = [];
    for (const c of cr) {
      const response = await fetch(c.examen_file);
      const blob = await response.blob();
      const fileName = c.nom;
      newFiles.push(new File([blob], fileName, { type: blob.type }));
    }
    return newFiles;
  }, []);

  const addCrToFilesList = useCallback(
    async (cr: CompteRendu[]) => {
      const newFiles = await getFilesFromCr(cr);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      setCompteRendu((prevCompteRendu) => [...prevCompteRendu, ...cr]);
    },
    [getFilesFromCr],
  );

  const getDocuments = useCallback(async () => {
    if (!patient) return null;

    const cr = await api.listCompteRendu(patient.id);
    const newFiles = await getFilesFromCr(cr);
    setFiles(newFiles);
    setCompteRendu(cr);
  }, [api, getFilesFromCr, patient]);

  useEffect(() => {
    getDocuments();
  }, [getDocuments]);

  const onCloseDocuments = useCallback(() => {
    setFiles([]);
    setCompteRendu([]);
    onClose();
  }, [onClose]);

  const handleEdit = (index: number) => {
    setEditCompteRendu(compteRendu[index]);
    setOpenEditModal(true);
  };

  const handleDelete = (index: number) => {
    api
      .deleteCompteRendu(compteRendu[index].id)
      .then(() => {
        setCompteRendu(compteRendu.filter((cr, i) => i !== index));
        setFiles(files.filter((f, i) => i !== index));
        snackbar.show("Compte rendu supprimé", "success");
      })
      .catch(() =>
        snackbar.show(
          "Erreur lors de la suppression du compte rendu",
          "danger",
        ),
      );
  };

  const handleUpdateCompteRendu = useCallback(() => {
    editCompteRendu &&
      api
        .updateCompteRendu(editCompteRendu)
        .then((res) => {
          const index = compteRendu.findIndex((c) => c.id === res.id);
          if (res.zkf_patient === patient?.id) {
            setCompteRendu(
              compteRendu.map((cr) => (cr.id === res.id ? res : cr)),
            );
            setFiles(
              files.map((f, i) =>
                i === index ? new File([f], res.nom, { type: f.type }) : f,
              ),
            );
          } else {
            setCompteRendu(compteRendu.filter((cr, i) => i !== index));
            setFiles(files.filter((f, i) => i !== index));
          }
          snackbar.show("Compte rendu modifié", "success");
          setOpenEditModal(false);
        })
        .catch(() =>
          snackbar.show(
            "Erreur lors de la modification du compte rendu",
            "danger",
          ),
        );
  }, [api, compteRendu, editCompteRendu, files, patient?.id, snackbar]);

  const editModal = useMemo(() => {
    if (!patient || !editCompteRendu) return null;

    const width = "145px";

    return (
      <ModalComponent
        open={openEditModal}
        title={"Editer un compte rendu"}
        onClose={() => setOpenEditModal(false)}
        onValidate={handleUpdateCompteRendu}
        validateLabel={"Enregistrer"}
        canValidate={
          !!editCompteRendu?.nom &&
          !!editCompteRendu.type_compte_rendu &&
          !!editCompteRendu.auteur &&
          !!editCompteRendu.zkf_patient
        }
      >
        <Stack spacing={2}>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Typography width={width}>Patient</Typography>
            <PatientSelector
              value={editCompteRendu?.zkf_patient || ""}
              onSelect={(patient) =>
                setEditCompteRendu({
                  ...editCompteRendu,
                  zkf_patient: patient?.id,
                })
              }
            />
          </Stack>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Typography width={width}>Description</Typography>
            <Input
              sx={{ flex: 1 }}
              value={editCompteRendu?.nom}
              onChange={(e) =>
                setEditCompteRendu({ ...editCompteRendu, nom: e.target.value })
              }
            />
          </Stack>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Typography width={width}>Auteur</Typography>
            <ContactSelector
              value={editCompteRendu.auteur || ""}
              onSelect={(newValue) =>
                setEditCompteRendu({
                  ...editCompteRendu,
                  auteur: newValue?.id || "",
                })
              }
              patient={patient}
            />
          </Stack>
          <Stack direction="row" spacing={2} alignItems={"center"}>
            <Typography width={width}>Type compte rendu</Typography>
            <Select
              name="type"
              value={editCompteRendu.type_compte_rendu}
              onChange={(e, newValue) =>
                setEditCompteRendu({
                  ...editCompteRendu,
                  type_compte_rendu: newValue || "CONSULTATION",
                })
              }
              sx={{ flex: 1 }}
            >
              <Option value={"CONSULTATION"}>Consultation</Option>
              <Option value={"HOSPITALISATION"}>Hospitalisation</Option>
              <Option value={"EXAMEN_COMPLEMENTAIRE"}>
                Examen complementaire
              </Option>
              <Option value={"ORDONNANCE_TRAITEMENT"}>
                Ordonnance traitement
              </Option>
            </Select>
          </Stack>
          <Stack direction="row" spacing={2} alignItems={"center"}>
            <Typography width={width}>Specialites</Typography>
            <SpecialiteMultiSelect
              value={editCompteRendu.specialite || []}
              onSelect={(newValue) =>
                setEditCompteRendu({
                  ...editCompteRendu,
                  specialite: newValue?.map((spe) => spe.id) || [],
                })
              }
              width={"313px"}
            />
          </Stack>
          {editCompteRendu.type_compte_rendu !== "HOSPITALISATION" && (
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Typography width={width}>Date examen</Typography>
              <Input
                type={"date"}
                value={editCompteRendu?.date_examen || ""}
                onChange={(e) =>
                  setEditCompteRendu({
                    ...editCompteRendu,
                    date_examen: e.target.value,
                  })
                }
              />
            </Stack>
          )}
          {editCompteRendu.type_compte_rendu === "HOSPITALISATION" && (
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Typography width={width}>Date entree</Typography>
              <Input
                type={"date"}
                value={editCompteRendu?.date_entree || ""}
                onChange={(e) =>
                  setEditCompteRendu({
                    ...editCompteRendu,
                    date_entree: e.target.value,
                  })
                }
              />
            </Stack>
          )}
          {editCompteRendu.type_compte_rendu === "HOSPITALISATION" && (
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Typography width={width}>Date sortie</Typography>
              <Input
                type={"date"}
                value={editCompteRendu?.date_sortie || ""}
                onChange={(e) =>
                  setEditCompteRendu({
                    ...editCompteRendu,
                    date_sortie: e.target.value,
                  })
                }
              />
            </Stack>
          )}
        </Stack>
      </ModalComponent>
    );
  }, [editCompteRendu, handleUpdateCompteRendu, openEditModal, patient]);

  return (
    <ModalComponent
      open={open}
      onClose={onCloseDocuments}
      title={`Documents`}
      style={{ minWidth: "70%", minHeight: "60%" }}
      closeLabel={"Close"}
      patient={patient}
    >
      <Stack spacing={2} flex={1}>
        <Button onClick={() => setOpenUpload(true)} startDecorator={<Add />}>
          Ajouter Documents
        </Button>
        <ListOfFilesPreview
          files={files}
          endAction={(index) => (
            <Stack direction="row">
              <Edit
                sx={{ cursor: "pointer" }}
                onClick={() => handleEdit(index)}
              />
              <Delete
                sx={{ fill: "red", cursor: "pointer" }}
                onClick={() => handleDelete(index)}
              />
            </Stack>
          )}
        />
      </Stack>
      {editModal}
      {openUpload && (
        <ModalUploader
          open={open}
          onClose={() => setOpenUpload(false)}
          patient={patient}
          onSave={(cr) => addCrToFilesList(cr)}
        />
      )}
    </ModalComponent>
  );
}
