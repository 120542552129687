import { ArrowBack } from "@mui/icons-material";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import { Link, useParams } from "react-router-dom";
import DoctorSelect from "../../../components/Forms/DoctorSelect";
import { useCurrentPatient } from "../../../contexts/CurrentPatientContext";
import { User } from "../../../models/types";

export default function CreateOperation() {
  const [currentPatient] = useCurrentPatient();
  const { hospitalisation_id } = useParams<{ hospitalisation_id: string }>();

  const [doctorId, setDoctorId] = React.useState<number>(2);

  return (
    <Box>
      <Link to={`/patient/${currentPatient?.id}`}>
        <Typography level="body-xs" startDecorator={<ArrowBack />}>
          Retour à la page patient
        </Typography>
      </Link>

      <Typography level="h3" sx={{ mt: 4, mb: 4 }}>
        Créer une opération pour opération {hospitalisation_id}
      </Typography>

      <Stack gap={4}>
        <Stack gap={2}>
          <Input
            size="lg"
            placeholder="Tapez le nom de l'opération, ou bien choisissez un modèle déjà existant dans la liste qui s'affichera ci-dessous..."
          />
        </Stack>

        <Stack direction="row" gap={2} flexWrap="wrap">
          <Stack gap={1} sx={{ width: "250px" }}>
            <Typography level="body-md">Opérateur</Typography>
            <DoctorSelect
              value={doctorId}
              onSelect={(doctor) => setDoctorId((doctor as User).id)}
            />
          </Stack>
          <Stack gap={1} sx={{ width: "250px" }}>
            <Typography level="body-md">Date</Typography>
            <DatePicker />
          </Stack>
        </Stack>

        <Stack direction="column" gap={2} alignItems="flex-end">
          <Button variant="soft" color="primary">
            Créer l'opération
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
