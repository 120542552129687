import { Masks } from "@mui/icons-material";
import { Sheet } from "@mui/joy";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import CircularProgress from "@mui/joy/CircularProgress";
import Stack from "@mui/joy/Stack";
import ToggleButtonGroup from "@mui/joy/ToggleButtonGroup";
import Typography from "@mui/joy/Typography";
import moment from "moment";
import React, { useMemo } from "react";
import { useApi } from "../../contexts/ApiContext";
import { useAuthenticatedUser } from "../../contexts/UserContext";
import { OperationWithinHospitalisation } from "../../models/custom";
import {
  isOperationWithinHospitalisation,
  isPatient,
  isService,
  isUser,
} from "../../models/type_guards";
import { Hospitalisation, Patient } from "../../models/types";
import { router } from "../../router";
import { formatNameAndSurname, isToday, truncateText } from "../../utils/utils";
import ListOfServices from "./ListOfServices";

export interface HospitalisationByService {
  [serviceId: string]: Hospitalisation[];
}

interface OperationWithPatient {
  operation: OperationWithinHospitalisation;
  patient: Patient;
}

interface OperationsByDoctor {
  [doctorId: string]: OperationWithPatient[];
}

enum HospitalisationFilter {
  SPE = "SPE",
  PATIENT = "PATIENT",
  SYNTHESE = "SYNTHESE",
}

export default function Hospitalisations() {
  const [hospitalisation, setHospitalisation] =
    React.useState<HospitalisationByService>({});
  const [hospitalisationFiltered, setHospitalisationFiltered] =
    React.useState<HospitalisationByService>({});
  const [operations, setOperations] = React.useState<OperationsByDoctor>({});
  const api = useApi();
  const { user, profile } = useAuthenticatedUser();

  const [loading, setLoading] = React.useState<boolean>(true);
  const [filter, setFilter] = React.useState<HospitalisationFilter>(
    HospitalisationFilter.SPE,
  );

  React.useEffect(() => {
    async function fetchHospitalisations() {
      if (!api) return;
      try {
        const hospitalisations = await api.getServiceHospitalisations(1);
        const hospitalisationsByServices: HospitalisationByService = {};
        const ope: OperationsByDoctor = {};

        hospitalisations.forEach((h) => {
          // Check if the service_hospitalisation is a Service (and not an UUID)
          if (!isService(h.service_hospitalisation)) return;
          if (!isPatient(h.zkf_patient)) return;

          // Check h.operations is an array
          if (!Array.isArray(h.operations)) return;

          const serviceName = h.service_hospitalisation.nom_service;
          if (!hospitalisationsByServices[serviceName])
            hospitalisationsByServices[serviceName] = [];
          hospitalisationsByServices[serviceName].push(h);

          h.operations.forEach((o) => {
            if (!isOperationWithinHospitalisation(o)) return;
            if (!!o.operation_date && isToday(o.operation_date)) {
              if (!ope[o.redacteur_id]) ope[o.redacteur_id] = [];
              if (!isPatient(h.zkf_patient)) return;
              ope[o.redacteur_id].push({
                operation: o,
                patient: h.zkf_patient,
              });
            }
          });
        });
        setHospitalisation(hospitalisationsByServices);
        Object.keys(ope).forEach((k) => {
          ope[k].sort((a, b) =>
            moment(a.operation.operation_start) <
            moment(b.operation.operation_start)
              ? -1
              : 1,
          );
        });
        setOperations(ope);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    fetchHospitalisations();
  }, [api]);

  React.useEffect(() => {
    if (filter === HospitalisationFilter.SPE) {
      setHospitalisationFiltered(hospitalisation);
    }
    if (filter === HospitalisationFilter.PATIENT) {
      const filtered: HospitalisationByService = {};
      Object.keys(hospitalisation).forEach((key: string) => {
        const hospi = hospitalisation[key].filter((h: Hospitalisation) =>
          h.medecin_referent.some(
            (m) => isUser(m) && m.id.toString() === user.id.toString(),
          ),
        );
        if (hospi.length) filtered[key] = hospi;
      });
      setHospitalisationFiltered(filtered);
    }
    if (filter === HospitalisationFilter.SYNTHESE)
      setHospitalisationFiltered(hospitalisation);
  }, [filter, hospitalisation, user.id, profile.specialite_profile]);

  const listOfOperations = useMemo(
    () => (
      <Box width={"30%"}>
        <Typography
          mb={2}
          level="title-lg"
          color="success"
          startDecorator={<Masks />}
        >
          Operation du jour
        </Typography>
        <Stack direction="column" gap={1}>
          {operations && Object.keys(operations).length > 0 ? (
            Object.keys(operations).map((key) => (
              <Stack key={key}>
                <Typography level="title-lg">
                  Dr {operations[key][0].operation.redacteur_last_name}
                </Typography>
                {operations[key].map(({ operation, patient }) => (
                  <Sheet
                    variant="soft"
                    color="success"
                    sx={{
                      p: 2,
                      mt: 1,
                      borderRadius: "sm",
                      "&:hover": { cursor: "pointer" },
                    }}
                    key={operation.id}
                    onClick={() =>
                      router.navigate("/operation/" + operation.id)
                    }
                  >
                    <Typography level="body-sm">
                      {moment(operation.operation_start).format("HH:mm")} -{" "}
                      {formatNameAndSurname(patient.nom, patient.prenom)}
                    </Typography>
                    <Typography level="body-xs">
                      {truncateText(operation.titre_operation, 130)}
                    </Typography>
                  </Sheet>
                ))}
              </Stack>
            ))
          ) : (
            <Typography
              display={"flex"}
              height={"100px"}
              justifyContent={"center"}
              alignItems={"center"}
              level="body-md"
            >
              Pas d'opération
            </Typography>
          )}
        </Stack>
      </Box>
    ),
    [operations],
  );

  const onLeave = (
    leaveDate: string | undefined,
    key: string,
    serviceId: string,
  ) => {
    setHospitalisation((prevState) => ({
      ...prevState,
      [key]: prevState[key].map((s) => {
        if (s.id === serviceId) {
          return { ...s, sortie_date: leaveDate };
        }
        return s;
      }),
    }));
  };

  return (
    <Box>
      <Stack spacing={2}>
        <Typography level="h1" sx={{ mt: 2 }}>
          Services
        </Typography>
        <ToggleButtonGroup
          spacing={2}
          value={filter}
          onChange={(event, newValue) => {
            setFilter(newValue || HospitalisationFilter.SPE);
          }}
        >
          <Button value={HospitalisationFilter.SPE}>Spe</Button>
          <Button value={HospitalisationFilter.PATIENT}>
            Patients propres
          </Button>
          <Button value={HospitalisationFilter.SYNTHESE}>Synthese</Button>
        </ToggleButtonGroup>
      </Stack>

      {loading ? (
        <div
          style={{
            display: "flex",
            height: "500px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size="lg" />
        </div>
      ) : (
        <Stack
          direction="row"
          spacing={20}
          sx={{
            marginTop: "10px",
          }}
        >
          <ListOfServices
            hospitalisations={hospitalisationFiltered}
            onLeave={onLeave}
          />
          {listOfOperations}
        </Stack>
      )}
    </Box>
  );
}
