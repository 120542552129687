import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import React from "react";
import { useApi } from "../../contexts/ApiContext";
import { UserSites } from "../../models/types";
import Typography from "@mui/joy/Typography";
import Tooltip from "@mui/joy/Tooltip";

interface SiteSelectProps {
  value: UserSites["id"] | null;
  onSelect: (value: UserSites | null) => void;
  width?: string;
}

export default function SiteSelect({
  value,
  onSelect,
  width = "auto",
}: SiteSelectProps) {
  const api = useApi();

  const [sites, setSites] = React.useState<UserSites[]>([]);

  React.useEffect(() => {
    const fetch = async () => {
      const res = await api.getUserSites();
      setSites(res);
    };
    fetch();
  }, [api]);

  const siteValue = sites.find((s) => value === s.id)?.nom_site;

  return (
    <Tooltip title={siteValue}>
      <Select
        renderValue={() => <Typography noWrap>{siteValue}</Typography>}
        value={value || ""}
        onChange={(e, newValue) =>
          onSelect(sites.find((s) => newValue === s.id) || null)
        }
        style={{ width }}
      >
        {sites.map((s) => (
          <Option key={s.id} value={s.id}>
            {s.nom_site}
          </Option>
        ))}
      </Select>
    </Tooltip>
  );
}
