import Select from "@mui/joy/Select";
import { Service } from "../../models/custom";
import { useApi } from "../../contexts/ApiContext";
import React from "react";
import Option from "@mui/joy/Option";
import { UserSites } from "../../models/types";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import { Place } from "@mui/icons-material";
import Tooltip from "@mui/joy/Tooltip";

interface ServiceMultiSelectProps {
  value: Service["id"][] | null;
  onSelect: (value: Service[]) => void;
  width?: string;
}

export default function ServiceMultiSelect({
  value,
  onSelect,
  width = "auto",
}: ServiceMultiSelectProps) {
  const api = useApi();

  const [services, setServices] = React.useState<Service[]>([]);

  React.useEffect(() => {
    const fetch = async () => {
      const res = await api.listUserServices(true);
      setServices(res);
    };
    fetch();
  }, [api]);

  const servicesValue = services
    .filter((s) => value?.includes(s.id))
    .map((s) => s.nom_service)
    .join(", ");

  return (
    <Tooltip title={servicesValue}>
      <Select
        multiple
        value={value || []}
        renderValue={() => <Typography noWrap>{servicesValue}</Typography>}
        onChange={(e, newService) =>
          onSelect(services.filter((s) => newService.includes(s.id)))
        }
        style={{ width }}
      >
        {services.map((service) => (
          <Option key={service.id} value={service.id}>
            <Stack gap={0.5}>
              <Typography
                level="body-xs"
                startDecorator={<Place sx={{ fontSize: "12px" }} />}
              >
                {(service.site_service as unknown as UserSites).nom_site}
              </Typography>
              <Typography>{service.nom_service}</Typography>
            </Stack>
          </Option>
        ))}
      </Select>
    </Tooltip>
  );
}
