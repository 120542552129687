import React, { useEffect, useMemo } from "react";
import PreviewFile from "./PreviewFile";
import Stack from "@mui/joy/Stack";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import List from "@mui/joy/List";
import { ListItemDecorator } from "@mui/joy";
import ListItemContent from "@mui/joy/ListItemContent";
import Typography from "@mui/joy/Typography";
import Tooltip from "@mui/joy/Tooltip";

interface ListOfFilesPreviewProps {
  files: File[];
  decorator?: (index: number) => React.ReactNode;
  endAction?: (index: number) => React.ReactNode;
  onSelectedFileChange?: (file: File, index: number) => void;
  headers?: React.ReactNode;
}

const ListOfFilesPreview: React.FC<ListOfFilesPreviewProps> = ({
  files,
  decorator,
  endAction,
  onSelectedFileChange,
  headers,
}) => {
  const [selectedFile, setSelectedFile] = React.useState<File>();

  useEffect(() => {
    if (
      files.length > 0 &&
      (!selectedFile || !files.some((file) => file.name === selectedFile.name))
    )
      setSelectedFile(files[0]);
    onSelectedFileChange &&
      onSelectedFileChange(
        selectedFile || files[0],
        files.findIndex((file) => file === selectedFile),
      );
  }, [files, onSelectedFileChange, selectedFile]);

  const Preview = useMemo(
    () => <PreviewFile file={selectedFile || null} />,
    [selectedFile],
  );

  if (files.length === 0) return null;

  return (
    <Stack direction={"row"} spacing={5} flex={1}>
      <Stack>
        {headers}
        <List sx={{ maxWidth: "20%" }}>
          {files.map((file, index) => (
            <ListItem
              key={index}
              style={{ minWidth: "250px" }}
              variant={file === selectedFile ? "soft" : "plain"}
              endAction={endAction ? endAction(index) : null}
            >
              <ListItemButton onClick={() => setSelectedFile(file)}>
                {decorator && (
                  <ListItemDecorator>{decorator(index)}</ListItemDecorator>
                )}
                <ListItemContent>
                  <Tooltip title={file.name}>
                    <Typography
                      noWrap
                      style={{
                        width: decorator ? "150px" : "auto",
                        paddingRight: "20px",
                      }}
                    >
                      {file.name}
                    </Typography>
                  </Tooltip>
                </ListItemContent>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Stack>
      <div style={{ flex: 1 }}>{Preview}</div>
    </Stack>
  );
};

export default ListOfFilesPreview;
