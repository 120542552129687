import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import ArrowRight from "@mui/icons-material/ArrowRight";
import DoneIcon from "@mui/icons-material/Done";
import Event from "@mui/icons-material/Event";
import HailIcon from "@mui/icons-material/Hail";
import Masks from "@mui/icons-material/Masks";
import People from "@mui/icons-material/People";
import PunchClock from "@mui/icons-material/PunchClock";
import IconButton from "@mui/joy/IconButton";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Tooltip from "@mui/joy/Tooltip";
import Typography from "@mui/joy/Typography";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useApi } from "../contexts/ApiContext";
import { useSnackbar } from "../contexts/SnackbarContext";
import { CalendarItem, Consultation } from "../models/types";
import { router } from "../router";

dayjs.extend(customParseFormat);

function getEventIcon(type: CalendarItem["type"]) {
  switch (type.toLowerCase()) {
    case "consultation":
      return <People color="primary" />;
    case "operation":
      return <Masks color="success" />;
    default:
      return <Event color="warning" />;
  }
}

function getEventBorder(event: CalendarItem) {
  if (event.type.toLowerCase() === "consultation") {
    // if the consultation has started and ended
    // if the consultation has started and is not ended => yellow
    // if the consultation has not started => brown
    // if the patient hasnt arrive and consultation start is in the past => red
    switch ((event.event_data as unknown as Consultation).etat_consultation) {
      // (A_VENIR, “A venir”),
      // (ARRIVE, “Arrive”),
      // (DEBUTE, “Debutee”),
      // (FINIE, “Finie”),
      // (NON_VENU, “Non venu”),
      // (ANNULE, “annulée”),
      case "a_venir":
        return "4px solid #D97706";
      case "arrive":
        return "4px solid #FFD700";
      case "debutee":
        return "4px solid #008000";
      case "finie":
        return "4px solid #999999";
      case "non_venu":
        return "4px solid #FF0000";
      case "annule":
        return "4px solid #FF0000";
      default:
        return "4px solid #D97706";
    }
  }
}

interface LandingEventCardProps {
  event: CalendarItem;
  refresh: () => void;
}

export default function LandingEventCard({
  event,
  refresh,
}: LandingEventCardProps) {
  const api = useApi();
  const snackbar = useSnackbar();

  const handlePatientArrival = async () => {
    try {
      if (event.type.toLowerCase() === "consultation") {
        const currentTime = dayjs().format("HH:mm:ss");
        await api.patchConsultation(event.id, {
          consultation_arrive: currentTime,
        });
        refresh();
        snackbar.show("Heure d'arrivée du patient enregistrée", "success");
        // router.navigate(`/consultation/${event.id}`);
      }
    } catch (error: any) {
      snackbar.show(
        "Un problème est survenu lors de l'enregistrement de l'heure d'arrivée du patient",
        "danger",
      );
      console.error(error);
    }
  };

  const goToEvent = (event: CalendarItem) => {
    switch (event.type.toLowerCase()) {
      case "consultation":
        router.navigate(`/consultation/${event.id}`);
        break;
      case "operation":
        router.navigate(`/operation/${event.id}`);
        break;
    }
  };

  const handleConsultationStartOrEnd = async (event: CalendarItem) => {
    try {
      if (event.type.toLowerCase() === "consultation") {
        if (
          !(event.event_data as unknown as Consultation).consultation_debut_reel
        ) {
          api.patchConsultation(event.id, {
            consultation_debut_reel: dayjs().format("HH:mm:ss"),
          });
          snackbar.show("Consultation démarrée", "success");
          router.navigate(`/consultation/${event.id}`);
        } else {
          api.patchConsultation(event.id, {
            consultation_fin_reel: dayjs().format("HH:mm:ss"),
          });
          snackbar.show("Consultation terminée", "success");
          refresh();
        }
      }
    } catch (error: any) {
      snackbar.show(
        "Un problème est survenu lors de la mise à jour de la consultation",
        "danger",
      );
      console.error(error);
    }
  };

  return (
    <Sheet
      variant="outlined"
      sx={{
        px: 2,
        py: 1,
        borderRadius: "sm",
        borderLeft: getEventBorder(event),
      }}
    >
      <Stack gap={1}>
        <Stack direction="row" gap={1}>
          <div>{getEventIcon(event.type)}</div>
          <Stack direction="row" gap={1} style={{ flex: 1 }}>
            <div style={{ flex: 1 }}>
              <Stack gap={1}>
                <Typography level="title-sm">
                  {dayjs.unix(event.start).format("HH:mm")}
                </Typography>
              </Stack>
              <Stack gap={1}>
                <Typography level="body-sm">{event.title}</Typography>
              </Stack>
            </div>

            <Stack direction="row" gap={0.5} alignItems="center">
              {
                // only for consultation events and if the event is today
                event.type.toLowerCase() === "consultation" &&
                  dayjs.unix(event.start).isSame(dayjs(), "day") && (
                    <>
                      <Tooltip title="Enregistrer l'arrivée du patient">
                        <IconButton
                          size="sm"
                          variant="outlined"
                          onClick={() => handlePatientArrival()}
                          color={
                            !!(event.event_data as unknown as Consultation)
                              .consultation_arrive
                              ? "success"
                              : "neutral"
                          }
                          disabled={
                            !!(event.event_data as unknown as Consultation)
                              .consultation_arrive
                          }
                        >
                          <HailIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Commencer/Terminer la consultation">
                        <IconButton
                          size="sm"
                          variant="outlined"
                          onClick={() => handleConsultationStartOrEnd(event)}
                          color={
                            !!(event.event_data as unknown as Consultation)
                              .consultation_debut_reel
                              ? "warning"
                              : "success"
                          }
                          disabled={
                            !!(event.event_data as unknown as Consultation)
                              .consultation_fin_reel &&
                            !!(event.event_data as unknown as Consultation)
                              .consultation_debut_reel
                          }
                        >
                          {!(event.event_data as unknown as Consultation)
                            .consultation_debut_reel ? (
                            <DoneIcon />
                          ) : (
                            <PunchClock />
                          )}
                        </IconButton>
                      </Tooltip>
                    </>
                  )
              }
              <Tooltip title="Voir l'événement">
                <IconButton
                  size="sm"
                  variant="outlined"
                  onClick={() => goToEvent(event)}
                >
                  <ArrowRight />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" gap={2} justifyContent="flex-end">
          {(event.event_data as unknown as Consultation)
            .consultation_arrive && (
            <Typography level="body-xs" startDecorator={<HailIcon />}>
              Patient arrivé à{" "}
              <b>
                {
                  //formatting the time to HH:mm. Need to use dayjs and customParseFormat to parse the time
                  dayjs(
                    (event.event_data as unknown as Consultation)
                      .consultation_arrive,
                    "HH:mm:ss",
                  ).format("HH:mm")
                }
              </b>
            </Typography>
          )}
          {(event.event_data as unknown as Consultation)
            .consultation_debut_reel && (
            <Typography level="body-xs" startDecorator={<AlarmOnIcon />}>
              Débuté à{" "}
              {
                //formatting the time to HH:mm. Need to use dayjs and customParseFormat to parse the time
                dayjs(
                  (event.event_data as unknown as Consultation)
                    .consultation_debut_reel,
                  "HH:mm:ss",
                ).format("HH:mm")
              }
            </Typography>
          )}
          {(event.event_data as unknown as Consultation)
            .consultation_fin_reel && (
            <Typography level="body-xs" startDecorator={<DoneIcon />}>
              Terminé à{" "}
              {
                //formatting the time to HH:mm. Need to use dayjs and customParseFormat to parse the time
                dayjs(
                  (event.event_data as unknown as Consultation)
                    .consultation_fin_reel,
                  "HH:mm:ss",
                ).format("HH:mm")
              }
            </Typography>
          )}
        </Stack>
      </Stack>
    </Sheet>
  );
}
