import { ThemeProvider } from "@emotion/react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "ckeditor5/ckeditor5.css";
import { Outlet } from "react-router-dom";
import "./ckeditor.css";
import { CurrentPatientContextProvider } from "./contexts/CurrentPatientContext";
import { PrintManagerContextProvider } from "./contexts/PrintManagerContext";
import { SessionPreferencesProvider } from "./contexts/SessionPreferencesContext";
import { SnackbarContextProvider } from "./contexts/SnackbarContext";
import "./globals.scss";
import { customTheme } from "./JoyTheme";
import ConnectedLayout from "./layouts/ConnectedLayout";

export default function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <SnackbarContextProvider>
        <CurrentPatientContextProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <PrintManagerContextProvider>
              <SessionPreferencesProvider>
                <ConnectedLayout>
                  <Outlet />
                </ConnectedLayout>
              </SessionPreferencesProvider>
            </PrintManagerContextProvider>
          </LocalizationProvider>
        </CurrentPatientContextProvider>
      </SnackbarContextProvider>
    </ThemeProvider>
  );
}
