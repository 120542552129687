import Info from "@mui/icons-material/Info";
import Checkbox from "@mui/joy/Checkbox";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Stack from "@mui/joy/Stack";
import Textarea from "@mui/joy/Textarea";
import Typography from "@mui/joy/Typography";
import dayjs from "dayjs";
import DoctorSelect from "../../../components/Forms/DoctorSelect";
import HospitalisationServiceSelect from "../../../components/Forms/ServiceSelect";
import SpecialiteSelect from "../../../components/Forms/SpecialiteSelect";
import TagAutocomplete from "../../../components/Forms/TagAutocomplete";
import { Hospitalisation, User } from "../../../models/types";

interface HospitalisationFormProps {
  values: Partial<Hospitalisation>;
  setValues: (values: Partial<Hospitalisation>) => void;
}

export default function HospitalisationForm({
  values,
  setValues,
}: HospitalisationFormProps) {
  const handleAmbulatoireChange = (checked: boolean) => {
    if (checked) {
      setValues({
        ...values,
        hospitalisation_ambulatoire: true,
        sortie_date: dayjs(values.entree_date).format("YYYY-MM-DD"),
      });
    } else {
      setValues({
        ...values,
        hospitalisation_ambulatoire: false,
        sortie_date: "",
      });
    }
  };

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={2}>
        <FormControl sx={{ flex: "1 0 100px" }}>
          <FormLabel>
            Entrée<sup>*</sup>
          </FormLabel>
          <Input
            type="date"
            value={dayjs(values.entree_date).format("YYYY-MM-DD")}
            onChange={(e) =>
              setValues({
                ...values,
                entree_date: dayjs(e.target.value).format("YYYY-MM-DD"),
              })
            }
          />
        </FormControl>
        <FormControl sx={{ flex: "1 0 100px" }}>
          <FormLabel>Heure d'entrée</FormLabel>
          <Input
            type="time"
            value={values.entree_heure || ""}
            onChange={(e) =>
              setValues({
                ...values,
                entree_heure: e.target.value,
              })
            }
          />
        </FormControl>

        <FormControl sx={{ flex: "1 0 150px" }}>
          <FormLabel>Sortie</FormLabel>
          <Stack direction="row" gap={1} alignItems="center">
            {!values.hospitalisation_ambulatoire && (
              <Input
                type="date"
                value={
                  values.sortie_date
                    ? dayjs(values.sortie_date).format("YYYY-MM-DD")
                    : ""
                }
                placeholder="Non renseignée"
                onChange={(e) =>
                  setValues({
                    ...values,
                    sortie_date: e.target.value
                      ? dayjs(e.target.value).format("YYYY-MM-DD")
                      : "",
                  })
                }
              />
            )}
            <br />
            <Typography
              level="body-sm"
              startDecorator={
                <Checkbox
                  checked={values.hospitalisation_ambulatoire || false}
                  onChange={(e) => handleAmbulatoireChange(e.target.checked)}
                />
              }
            >
              Ambulatoire
            </Typography>
          </Stack>
        </FormControl>
        <FormControl sx={{ flex: "1 0 150px" }}>
          <FormLabel>
            Spécialité<sup>*</sup>
          </FormLabel>
          <SpecialiteSelect
            value={values.specialite_referente || null}
            onSelect={(spe) =>
              setValues({ ...values, specialite_referente: spe?.id })
            }
          />
        </FormControl>
      </Stack>
      <Stack direction="row" gap={2}>
        <FormControl
          sx={{ flex: "1 0 150px", overflow: "hidden", maxWidth: "100%" }}
        >
          <FormLabel>
            Service<sup>*</sup>
          </FormLabel>
          <HospitalisationServiceSelect
            value={values.service_hospitalisation || null}
            onSelect={(service) =>
              setValues({ ...values, service_hospitalisation: service.id })
            }
          />
        </FormControl>
        <FormControl
          sx={{ flex: "1 0 150px", overflow: "hidden", maxWidth: "100%" }}
        >
          <FormLabel>
            Médecin référent<sup>*</sup>
          </FormLabel>
          <DoctorSelect
            value={values.medecin_referent}
            onSelect={(medecin) =>
              setValues({
                ...values,
                medecin_referent: (medecin as User[]).map((m) => m.id),
              })
            }
            multiple
          />
        </FormControl>
        <FormControl
          sx={{ flex: "1 0 150px", overflow: "hidden", maxWidth: "100%" }}
        >
          <FormLabel>
            Mode d'entrée<sup>*</sup>
          </FormLabel>
          <Stack gap={0.5}>
            <Select
              value={values.mode_entree}
              onChange={(e, newMode) =>
                setValues({ ...values, mode_entree: newMode })
              }
            >
              <Option value={null}>Non renseigné</Option>
              <Option value="programme">Programmé</Option>
              <Option value="urgent">Urgent</Option>
              <Option value="transfert">Transfert</Option>
            </Select>
            {values.mode_entree === "transfert" && (
              <Input
                placeholder="Origine du transfert"
                value={values.origine_transfert || ""}
                onChange={(e) =>
                  setValues({ ...values, origine_transfert: e.target.value })
                }
              />
            )}
          </Stack>
        </FormControl>
      </Stack>
      <Stack direction="row" gap={2}>
        <FormControl sx={{ flex: 1 }}>
          <FormLabel>Motif hospitalisation</FormLabel>
          <Textarea
            minRows={2}
            maxRows={4}
            value={values.motif_hospitalisation || ""}
            onChange={(e) =>
              setValues({ ...values, motif_hospitalisation: e.target.value })
            }
          />
        </FormControl>
        <FormControl sx={{ flex: 1 }}>
          <FormLabel>Tags</FormLabel>
          <TagAutocomplete
            value={values.tags || []}
            onChange={(newValue) => setValues({ ...values, tags: newValue })}
          />
          <Typography
            sx={{ mt: 0.5 }}
            level="body-xs"
            startDecorator={<Info />}
          >
            Tapez le nom d'un tag, puis appuyez sur entrée pour l'ajouter
          </Typography>
        </FormControl>
      </Stack>
    </Stack>
  );
}
