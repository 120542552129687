import { Activite } from "../components/Quotes/EditClaim";
import { Lieu, OperationWithinHospitalisation, Service } from "./custom";
import { components } from "./ts";

export function isPatient(obj: any): obj is components["schemas"]["Patient"] {
  return obj && typeof obj === "object" && "nom" in obj && "prenom" in obj;
}

export function isSpecialite(
  obj: any,
): obj is components["schemas"]["Specialite"] {
  return obj && typeof obj === "object" && "spe" in obj;
}

export function isUser(obj: any): obj is components["schemas"]["User"] {
  return obj && typeof obj === "object" && "username" in obj;
}

export function isService(obj: any): obj is Service {
  return obj && typeof obj === "object" && "nom_service" in obj;
}

export function isLieu(obj: any): obj is Lieu {
  return obj && typeof obj === "object" && "nom_lieu" in obj;
}

export function isOperationWithinHospitalisation(
  obj: any,
): obj is OperationWithinHospitalisation {
  return obj && typeof obj === "object" && "zkf_redacteur_last_name" in obj;
}

export function isListOfOperationsWithinHospitalisation(
  obj: any,
): obj is OperationWithinHospitalisation[] {
  return Array.isArray(obj) && obj.every(isOperationWithinHospitalisation);
}

export function isActivite(obj: any): obj is Activite {
  return obj && typeof obj === "object" && "cod_activ" in obj;
}
