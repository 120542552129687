import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import React from "react";
import { useApi } from "../../contexts/ApiContext";
import { UserSites } from "../../models/types";
import Typography from "@mui/joy/Typography";
import Tooltip from "@mui/joy/Tooltip";

interface SiteMultiSelectProps {
  value: UserSites["id"][] | null;
  onSelect: (value: UserSites[] | null) => void;
  width?: string;
}

export default function SiteMultiSelect({
  value,
  onSelect,
  width = "auto",
}: SiteMultiSelectProps) {
  const api = useApi();

  const [sites, setSites] = React.useState<UserSites[]>([]);

  React.useEffect(() => {
    const fetch = async () => {
      const res = await api.getUserSites();
      setSites(res);
    };
    fetch();
  }, [api]);

  const sitesValue = sites
    .filter((s) => value?.includes(s.id))
    .map((s) => s.nom_site)
    .join(", ");

  return (
    <Tooltip title={sitesValue}>
      <Select
        multiple
        renderValue={() => <Typography noWrap>{sitesValue}</Typography>}
        value={value || []}
        onChange={(e, newValue) =>
          onSelect(sites.filter((s) => newValue?.includes(s.id)) || [])
        }
        style={{ width }}
      >
        {sites.map((s) => (
          <Option key={s.id} value={s.id}>
            {s.nom_site}
          </Option>
        ))}
      </Select>
    </Tooltip>
  );
}
