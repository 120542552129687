import { Add, Delete, Print, Visibility } from "@mui/icons-material";
import { IconButton, ModalClose, Sheet } from "@mui/joy";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Stack from "@mui/joy/Stack";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import dayjs from "dayjs";
import React from "react";
import PrinterAddIcon from "../../assets/icons/PrinterAddIcon";
import { useApi } from "../../contexts/ApiContext";
import { useSnackbar } from "../../contexts/SnackbarContext";
import { DocumentLibre, Ordonnance, Patient } from "../../models/types";
import OrdonnanceForm from "./OrdonnanceForm";
import Checkbox from "@mui/joy/Checkbox";
import { isPatient } from "../../models/type_guards";
import ModalComponent from "../Modal/Modal";
import PDFPreviewAndPrint from "../PDF/PDFPreview";

export interface OrdonnanceComponentsProps {
  zkf_patient: string;
  consultationId?: string;
  hospitalisationId?: string;
  operationId?: string;
}

export default function OrdonnanceList({
  consultationId,
  hospitalisationId,
  operationId,
  zkf_patient,
}: OrdonnanceComponentsProps) {
  const api = useApi();
  const snackbar = useSnackbar();

  const [ordonnances, setOrdonnances] = React.useState<Ordonnance[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [deletingId, setDeletingId] = React.useState<Ordonnance["id"] | null>(
    null,
  );
  const [viewingOrdonnance, setViewingOrdonnance] =
    React.useState<Ordonnance | null>(null);
  const [selectedOrdonnances, setSelectedOrdonnances] = React.useState<
    Set<Ordonnance>
  >(new Set());
  //modal to print docs
  const [openPrintModal, setOpenPrintModal] = React.useState<boolean>(false);
  const [fileBlobURL, setFileBlobURL] = React.useState<string>("");

  const fetchOrdonnances = async () => {
    setLoading(true);
    const o = await api.getOrdonnances(
      consultationId,
      hospitalisationId,
      operationId,
    );
    setOrdonnances(o.results);
    setLoading(false);
  };

  React.useEffect(() => {
    fetchOrdonnances();
  }, [api]);

  const deleteOrdonnance = async (id: Ordonnance["id"]) => {
    try {
      await api.deleteOrdonnance(id);
      fetchOrdonnances();
      snackbar.show("Ordonnance supprimée", "success");
    } catch (e) {
      console.error(e);
      snackbar.show("Erreur lors de la suppression de l'ordonnance", "danger");
    }
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedOrdonnances(new Set(ordonnances));
    } else {
      setSelectedOrdonnances(new Set());
    }
  };

  const handleSelectOrdonnance = (id: string, checked: boolean) => {
    if (checked) {
      const ordonnance = ordonnances.find((o) => o.id === id);
      if (ordonnance) {
        setSelectedOrdonnances(
          new Set([...Array.from(selectedOrdonnances), ordonnance]),
        );
      }
    } else {
      setSelectedOrdonnances(
        new Set(Array.from(selectedOrdonnances).filter((o) => o.id !== id)),
      );
    }
  };

  const addToPrintManager = async (o: Ordonnance) => {
    try {
      await api.addToPrintManager("ordonnance", o.id);
      snackbar.show("Document ajouté au gestionnaire d'impression.", "success");
    } catch (e) {
      console.error(e);
      snackbar.show("Erreur lors de l'ajout à l'impression.", "danger");
    }
  };

  const addSelectedToPrintManager = async () => {
    // await Promise.all(
    //   Array.from(selectedOrdonnances).map((id) => addToPrintManager(id)),
    // );
  };

  const printSelectedDocs = async (
    docs: Ordonnance[] = Array.from(selectedOrdonnances),
  ) => {
    api
      .printManagerPrintAll(
        docs.map((doc) => ({
          type_document: "ordonnance",
          id: doc.id,
          model_app_datas: doc.model_app_datas,
        })),
      )
      .then((blob) => {
        setFileBlobURL(window.URL.createObjectURL(blob));
        setOpenPrintModal(true);
      });
  };

  const renderPrintModal = () => (
    <ModalComponent
      open={openPrintModal}
      onClose={() => setOpenPrintModal(false)}
      title={"Imprimer"}
      style={{ minWidth: "60%", minHeight: "600px" }}
      withCloseButton={false}
    >
      <div style={{ flex: 1, height: "600px" }}>
        <PDFPreviewAndPrint fileUrl={fileBlobURL} />
      </div>
    </ModalComponent>
  );

  return (
    <>
      <Stack>
        <Stack
          sx={{ mb: 2 }}
          direction="row"
          gap={2}
          justifyContent="space-between"
        >
          <Typography level="h4">Ordonnances</Typography>
          <Button startDecorator={<Add />} onClick={() => setModalOpen(true)}>
            Créer une ordonnance
          </Button>
        </Stack>
        {!loading && ordonnances.length === 0 ? (
          <Stack
            direction="column"
            gap={2}
            alignItems="center"
            justifyContent="center"
          >
            <Typography level="body-lg">Il n'y a aucune ordonnance.</Typography>
            <Button
              startDecorator={<Add />}
              sx={{ width: 300 }}
              onClick={() => setModalOpen(true)}
            >
              Créer une ordonnance
            </Button>
          </Stack>
        ) : (
          <>
            {selectedOrdonnances.size > 0 && (
              <Stack
                sx={{ mb: 1 }}
                direction="row"
                gap={1}
                alignItems="flex-end"
                justifyContent="space-between"
              >
                <Typography level="body-sm">
                  {selectedOrdonnances.size} ordonnance(s) sélectionnée(s)
                </Typography>
                <Stack direction="row" gap={1}>
                  <Button
                    variant="soft"
                    startDecorator={<Print />}
                    size="sm"
                    onClick={() => printSelectedDocs()}
                  >
                    Imprimer la sélection
                  </Button>
                  {/* <Button
                    variant="soft"
                    startDecorator={<PrinterAddIcon />}
                    size="sm"
                    onClick={addSelectedToPrintManager}
                  >
                    Ajouter au gest. d'impression
                  </Button> */}
                </Stack>
              </Stack>
            )}
            <Table variant="outlined">
              <thead>
                <tr>
                  <th style={{ width: "50px" }}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Checkbox
                        checked={
                          ordonnances.length > 0 &&
                          selectedOrdonnances.size === ordonnances.length
                        }
                        indeterminate={
                          selectedOrdonnances.size > 0 &&
                          selectedOrdonnances.size < ordonnances.length
                        }
                        onChange={(event) =>
                          handleSelectAll(event.target.checked)
                        }
                      />
                    </Stack>
                  </th>
                  <th style={{ width: "100px" }}>Date</th>
                  <th>Titre</th>
                  <th style={{ width: "300px" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  ordonnances.length > 0 &&
                  ordonnances.map((o) => {
                    return (
                      <tr key={o.id}>
                        <td>
                          <Checkbox
                            checked={selectedOrdonnances.has(o)}
                            onChange={(event) =>
                              handleSelectOrdonnance(o.id, event.target.checked)
                            }
                          />
                        </td>
                        <td>
                          {dayjs(o.date_ordonnance).format("DD MMM YYYY")}
                        </td>
                        <td>{o.titre_ordonnance}</td>
                        <td>
                          <Stack
                            direction="row"
                            gap={1}
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <IconButton
                              sx={{
                                minWidth: "38px !important",
                              }}
                              variant="outlined"
                              onClick={() => {
                                setViewingOrdonnance(o);
                                setModalOpen(true);
                              }}
                            >
                              <Visibility />
                            </IconButton>
                            <IconButton
                              sx={{
                                minWidth: "38px !important",
                              }}
                              variant="outlined"
                              onClick={() => printSelectedDocs([o])}
                            >
                              <Print />
                            </IconButton>
                            <IconButton
                              sx={{
                                minWidth: "38px !important",
                              }}
                              variant="outlined"
                              onClick={() => addToPrintManager(o)}
                            >
                              <PrinterAddIcon />
                            </IconButton>
                            <IconButton
                              sx={{
                                minWidth: "38px !important",
                              }}
                              variant="outlined"
                              onClick={() => setDeletingId(o.id)}
                            >
                              <Delete />
                            </IconButton>
                          </Stack>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </>
        )}
      </Stack>
      {renderPrintModal()}
      {modalOpen && (
        <Modal
          open
          onClose={() => {
            setViewingOrdonnance(null);
            setModalOpen(false);
          }}
        >
          <ModalDialog>
            <OrdonnanceForm
              consultationId={consultationId}
              hospitalisationId={hospitalisationId}
              operationId={operationId}
              onCancel={() => {
                setViewingOrdonnance(null);
                setModalOpen(false);
              }}
              onCreated={() => {
                setViewingOrdonnance(null);
                setModalOpen(false);
                fetchOrdonnances();
              }}
              zkf_patient={
                isPatient(zkf_patient) ? zkf_patient.id : zkf_patient
              }
              ordonnance={viewingOrdonnance || undefined}
              onPrint={() => printSelectedDocs([viewingOrdonnance!])}
            />
          </ModalDialog>
        </Modal>
      )}
      {deletingId && (
        <Modal open onClose={() => setDeletingId(null)}>
          <ModalDialog variant="soft">
            <ModalClose />
            <Typography level="h4">
              Supprimer l'ordonnance <em></em>
            </Typography>
            <Typography level="body-md">
              Êtes-vous sûr de vouloir supprimer cette ordonnance ?
            </Typography>
            <Stack direction="row" gap={1} justifyContent="flex-end">
              <Button
                color="warning"
                onClick={() => {
                  deleteOrdonnance(deletingId);
                  setDeletingId(null);
                }}
                variant="outlined"
              >
                Supprimer
              </Button>
              <Button color="success" onClick={() => setDeletingId(null)}>
                Annuler
              </Button>
            </Stack>
          </ModalDialog>
        </Modal>
      )}
    </>
  );
}
