import React from "react";
import { useParams } from "react-router-dom";
import HistoryForm from "../../../components/History/HistoryForm";
import { useApi } from "../../../contexts/ApiContext";
import { Patient } from "../../../models/types";

export default function EditHistory() {
  const { patient_id } = useParams();
  const api = useApi();

  const [patient, setPatient] = React.useState<Patient | null>(null);

  React.useEffect(() => {
    api?.getPatient(patient_id!).then((res) => setPatient(res));
  }, [api, patient_id]);

  return (
    <div>{patient ? <HistoryForm p={patient} /> : <div>loading</div>}</div>
  );
}
