import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { CircularProgress } from "@mui/joy";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import dayjs from "dayjs";
import React from "react";
import DoctorSelectChips from "../components/Forms/DoctorSelectChips";
import { useApi } from "../contexts/ApiContext";
import { useSnackbar } from "../contexts/SnackbarContext";
import { useAuthenticatedUser } from "../contexts/UserContext";
import { CalendarItem, User } from "../models/types";
import { CurrentTime } from "../utils/utils";
import LandingEventCard from "./LandingEventCard";
import { useSessionPreferences } from "../contexts/SessionPreferencesContext";

export default function Landing() {
  const api = useApi();
  const auth = useAuthenticatedUser();
  const snackbar = useSnackbar();
  const sessionPreferences = useSessionPreferences();

  const [loading, setLoading] = React.useState<boolean>(true);

  const [selectedDr, setSelectedDr] = React.useState<User["id"][]>(
    sessionPreferences.data.selectedUsers,
  );
  const [linkedUsers, setLinkedUsers] = React.useState<User[]>([]);
  // events per user
  const [events, setEvents] = React.useState<{
    [key: number]: CalendarItem[];
  }>({
    [auth.user.id]: [],
  });
  const [date, setDate] = React.useState<string>(dayjs().format("YYYY-MM-DD"));
  const [lastRefresh, setLastRefresh] = React.useState<number>(0);
  const [loadingUsers, setLoadingUsers] = React.useState<boolean>(true);

  React.useEffect(() => {
    const fetchLinkedUsers = async () => {
      try {
        setLoadingUsers(true);
        const linkedUsers = await api.getLinkedUsers();
        setLinkedUsers(linkedUsers.results);
        setLoadingUsers(false);
      } catch (error: any) {
        snackbar.show(
          "Un problème est survenu lors du chargement des utilisateurs liés.",
          "danger",
        );
        console.error(error);
      }
    };

    fetchLinkedUsers();
  }, [api]);

  React.useEffect(() => {
    async function fetchConsultationsOfTheDay() {
      if (!api) return;
      try {
        const start = dayjs(date).subtract(1, "day").startOf("day").unix();
        const end = dayjs(date).subtract(1, "day").endOf("day").unix();
        // fetch linked users
        selectedDr.forEach(async (dr) => {
          const events = (await api.getCalendarItems(start, end, [dr])).filter(
            (event) =>
              event.type.toLowerCase() === "consultation" ||
              event.type.toLowerCase() === "operation",
          );
          // order events by start time
          events.sort((a, b) => {
            const aStart = dayjs(a.start).unix();
            const bStart = dayjs(b.start).unix();
            return aStart - bStart;
          });
          setEvents((prev) => ({
            ...prev,
            [dr]: events,
          }));
        });
        // remove all events from users that are not selected in the chips
        const usersToRemove = linkedUsers
          .filter((u) => !selectedDr.includes(u.id))
          .map((u) => u.id);
        usersToRemove.forEach((user) => {
          delete events[user];
        });
      } catch (error: any) {
        snackbar.show(
          "Un problème est survenu lors du chargement des données",
          "danger",
        );
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    fetchConsultationsOfTheDay();
  }, [api, selectedDr, date, lastRefresh]);

  React.useEffect(() => {
    sessionPreferences.setData({
      selectedUsers: selectedDr,
    });
  }, [selectedDr, sessionPreferences]);

  const handleRefresh = () => {
    setLoading(true);
    setLastRefresh(dayjs().unix());
  };

  const handleSelectUsers = (ids: User["id"] | User["id"][]) => {
    setSelectedDr(ids as User["id"][]);
  };

  return (
    <>
      <Box sx={{ pt: 2 }}>
        <Typography level="h4">
          Bonjour {auth.userDisplayName}, nous sommes le{" "}
          <b>{dayjs().format("DD MMMM YYYY")}</b>, il est <b>{CurrentTime()}</b>
        </Typography>
      </Box>
      <Divider sx={{ mx: -2, mt: 4, mb: 2 }} />
      <Box>
        {loadingUsers && loading ? (
          <CircularProgress />
        ) : (
          <Stack gap={1}>
            <Stack direction="row" gap={1}>
              <IconButton
                onClick={() => {
                  setDate(dayjs(date).subtract(1, "day").format("YYYY-MM-DD"));
                }}
              >
                <ArrowBack />
              </IconButton>
              <Input
                type="date"
                value={date}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setDate(e.target.value)
                }
              />
              <IconButton
                onClick={() => {
                  setDate(dayjs(date).add(1, "day").format("YYYY-MM-DD"));
                }}
              >
                <ArrowForward />
              </IconButton>
            </Stack>
            <DoctorSelectChips
              value={selectedDr}
              onSelect={handleSelectUsers}
              multiple
            />
            <Stack direction="row" gap={2} sx={{ pt: 2 }}>
              {Object.keys(events).map((id, key) => {
                const user = linkedUsers.find((u) => u.id === Number(id));
                return (
                  <Stack
                    sx={{
                      borderRight:
                        // if not last element then (theme) => "1px solid " + theme.palette.divider
                        key === Object.keys(events).length - 1
                          ? "none"
                          : (theme) => "1px solid " + theme.palette.divider,
                      pr: 2,
                    }}
                    flex={1}
                    key={key}
                    gap={0.5}
                  >
                    <Typography level="h4" sx={{ mb: 1 }}>
                      {user?.last_name} {user?.first_name}
                    </Typography>
                    {events[Number(id)].length === 0 ? (
                      <Typography
                        level="body-sm"
                        sx={{ textAlign: "center", mt: 8, mb: 8 }}
                      >
                        Aucun événement pour l'utilisateur
                      </Typography>
                    ) : (
                      events[Number(id)].map((event) => {
                        return (
                          <LandingEventCard
                            key={event.id}
                            event={event}
                            refresh={handleRefresh}
                          />
                        );
                      })
                    )}
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        )}
      </Box>
    </>
  );
}
