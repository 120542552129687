import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import React from "react";
import { useApi } from "../../contexts/ApiContext";
import { UserSites, UserSitesLieux } from "../../models/types";

interface LieuSelectProps {
  site: UserSites["id"];
  value: UserSitesLieux["id"] | null;
  onSelect: (value: UserSitesLieux | null) => void;
  width?: string;
}

export default function LieuSelect({
  site,
  value,
  onSelect,
  width = "auto",
}: LieuSelectProps) {
  const api = useApi();

  const [lieux, setLieus] = React.useState<UserSitesLieux[]>([]);

  React.useEffect(() => {
    const fetch = async () => {
      const res = await api.getSitePlaces(site);
      setLieus(res);
    };
    fetch();
  }, [api, site]);

  return (
    <Select
      value={value || ""}
      onChange={(e, newValue) =>
        onSelect(lieux.find((s) => newValue === s.id) || null)
      }
      style={{ width }}
    >
      {lieux.map((l) => (
        <Option key={l.id} value={l.id}>
          {l.nom_lieu}
        </Option>
      ))}
    </Select>
  );
}
