import Info from "@mui/icons-material/Info";
import Autocomplete from "@mui/joy/Autocomplete";
import Checkbox from "@mui/joy/Checkbox";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Stack from "@mui/joy/Stack";
import Textarea from "@mui/joy/Textarea";
import Typography from "@mui/joy/Typography";
import React from "react";
import ProtocoleSelect from "../../../components/Forms/ProtocoleSelect";
import { useApi } from "../../../contexts/ApiContext";
import { useSnackbar } from "../../../contexts/SnackbarContext";
import { Intervenant, Operation, User } from "../../../models/types";
import TagAutocomplete from "../../../components/Forms/TagAutocomplete";

interface OperationFormProps {
  values: Partial<Operation>;
  setValues: (values: Partial<Operation>) => void;
}

export default function OperationForm({
  values,
  setValues,
}: OperationFormProps) {
  const api = useApi();
  const snackbar = useSnackbar();
  const [intervenants, setIntervenants] = React.useState<{
    Anesthesiste: Intervenant[];
    Operateur: Intervenant[];
    Redacteur: User[];
    "Aide Operatoire": Intervenant[];
  }>({
    Anesthesiste: [],
    Operateur: [],
    Redacteur: [],
    "Aide Operatoire": [],
  });

  React.useEffect(() => {
    const fetchIntervants = async () => {
      try {
        const i = await api.getUserIntervenantsEvents();
        setIntervenants(i);
      } catch (e) {
        console.error(e);
        snackbar.show("Impossible de charger les intervenants", "danger");
      }
    };
    fetchIntervants();
  }, [api, snackbar]);

  return (
    <Stack direction="row" gap={2}>
      <Stack sx={{ flex: "0 0 calc(50% - 8px)", overflow: "hidden" }} gap={2}>
        <FormControl>
          <FormLabel>
            Titre<sup>*</sup>
          </FormLabel>
          <Input
            value={values.titre_operation}
            onChange={(e) =>
              setValues({ ...values, titre_operation: e.target.value })
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>
            Indication opératoire<sup>*</sup>
          </FormLabel>
          <Textarea
            minRows={2}
            maxRows={2}
            value={values.indication_operatoire}
            onChange={(e) =>
              setValues({
                ...values,
                indication_operatoire: e.target.value,
              })
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>Tags</FormLabel>
          <TagAutocomplete
            value={values.tags || []}
            onChange={(newValue) => setValues({ ...values, tags: newValue })}
          />
          <Typography
            sx={{ mt: 0.5 }}
            level="body-xs"
            startDecorator={<Info />}
          >
            Tapez le nom d'un tag, puis appuyez sur entrée pour l'ajouter
          </Typography>
        </FormControl>
      </Stack>
      <Stack sx={{ flex: "0 0 calc(50% - 8px)", overflow: "hidden" }} gap={2}>
        <FormControl>
          <FormLabel>
            Opérateurs<sup>*</sup>
          </FormLabel>
          <Select
            value={values.operateur_new}
            onChange={(e, value) => {
              setValues({ ...values, operateur_new: value });
            }}
            multiple
          >
            {intervenants?.Operateur &&
              intervenants.Operateur.map((i: Intervenant) => (
                <Option key={i.id} value={i.id}>
                  {i.nom} {i.prenom}
                </Option>
              ))}
          </Select>
          <Typography
            sx={{ mt: 0.5 }}
            level="body-xs"
            startDecorator={<Info />}
          >
            Vous pouvez selectionner un ou plusieurs opérateurs
          </Typography>
        </FormControl>
        <FormControl>
          <FormLabel>Commentaire pre-opératoire</FormLabel>
          <Textarea
            minRows={2}
            maxRows={2}
            value={values.commentaire_programmation_operation}
            onChange={(e) =>
              setValues({
                ...values,
                commentaire_programmation_operation: e.target.value,
              })
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>Protocole</FormLabel>
          <ProtocoleSelect
            value={values.protocoles || []}
            onChange={(ids) =>
              setValues({
                ...values,
                protocoles: ids,
              })
            }
          />
        </FormControl>
        <Typography
          sx={{ color: "text.primary" }}
          level="body-sm"
          startDecorator={
            <Checkbox
              checked={values.urgence === "urgence"}
              onChange={(e) => {
                e.target.checked
                  ? setValues({ ...values, urgence: "urgence" })
                  : setValues({ ...values, urgence: "programme" });
              }}
            />
          }
        >
          Urgence
        </Typography>
      </Stack>
    </Stack>
  );
}
