import { ArrowLeft } from "@mui/icons-material";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import Stack from "@mui/joy/Stack";
import Tab from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import TabPanel from "@mui/joy/TabPanel";
import Tabs from "@mui/joy/Tabs";
import Typography from "@mui/joy/Typography";
import React from "react";
import { useParams } from "react-router-dom";
import OrdonnanceList from "../../../components/Ordonnances/OrdonnanceList";
import PatientCard from "../../../components/PatientCard";
import { useApi } from "../../../contexts/ApiContext";
import { useSnackbar } from "../../../contexts/SnackbarContext";
import { Hospitalisation, Operation, Patient } from "../../../models/types";
import {
  isListOfOperationsWithinHospitalisation,
  isPatient,
} from "../../../models/type_guards";
import { router } from "../../../router";
import { formatDate } from "../../../utils/utils";
import ListOfOperations from "../ListOfOperations";
import HospitalisationEntreePanel from "./HospitalisationEntreePanel";

export default function HospitalisationPage() {
  const api = useApi();
  const snackbar = useSnackbar();

  const { hospitalisation_id } = useParams<{ hospitalisation_id: string }>();

  const [hospitalisation, setHospitalisation] =
    React.useState<Hospitalisation | null>(null);

  React.useEffect(() => {
    const fetch = async () => {
      if (!hospitalisation_id) return;
      try {
        const hospitalisation =
          await api.getHospitalisation(hospitalisation_id);
        setHospitalisation(hospitalisation);
      } catch (e) {
        console.error(e);
        snackbar.show(
          "Erreur lors du chargement de l'hospitalisation",
          "danger",
        );
      }
    };
    fetch();
  }, [api, hospitalisation_id, snackbar]);

  return hospitalisation ? (
    <Stack direction="row">
      <Box
        flex={1}
        sx={{
          mt: -2,
          pt: 2,
          borderRight: (theme) => "1px solid " + theme.palette.divider,
        }}
      >
        {hospitalisation.zkf_patient && (
          <Typography
            sx={{ cursor: "pointer" }}
            level="body-md"
            startDecorator={<ArrowLeft />}
            onClick={() =>
              isPatient(hospitalisation.zkf_patient) &&
              router.navigate("/patient/" + hospitalisation.zkf_patient.id)
            }
          >
            Retour à la fiche patient
          </Typography>
        )}
        <Stack
          sx={{
            pb: 2,
            mx: -2,
          }}
          direction="row"
          gap={2}
        >
          <div>
            {hospitalisation.zkf_patient &&
              isPatient(hospitalisation.zkf_patient) && (
                <PatientCard patient={hospitalisation.zkf_patient} />
              )}
          </div>
          <Box flex={1}>
            <div>
              <Chip
                size="sm"
                color="warning"
                startDecorator={<LocalHospitalIcon fontSize="small" />}
              >
                HOSPITALISATION
              </Chip>
              <Typography level="h3" sx={{ mb: 1 }} color="warning">
                {hospitalisation?.motif_hospitalisation}
              </Typography>
            </div>
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Typography level="body-xs" textTransform="uppercase">
                entrée
              </Typography>
              <Typography level="body-md">
                {formatDate(hospitalisation.entree_date)}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Typography level="body-xs" textTransform="uppercase">
                sortie
              </Typography>
              <Typography level="body-md">
                {hospitalisation.sortie_date
                  ? formatDate(hospitalisation.sortie_date)
                  : "En cours"}
              </Typography>
            </Stack>
          </Box>
        </Stack>
        <Tabs
          sx={{
            ml: -2,
            background: "white",
            borderTop: (theme) => "1px solid " + theme.palette.divider,
            "& button": { minWidth: "120px" },
          }}
        >
          <TabList>
            <Tab>Entrée</Tab>
            <Tab>Suivi</Tab>
            <Tab>Antécédents</Tab>
            <Tab>Photos</Tab>
            <Tab>Sortie</Tab>
            <Tab>CRH</Tab>
            <Tab>Ordonnances</Tab>
          </TabList>
          <TabPanel value={0}>
            <HospitalisationEntreePanel hospitalisation={hospitalisation} />
          </TabPanel>
          <TabPanel value={1}>Suivi</TabPanel>
          <TabPanel value={2}>Antécédents</TabPanel>
          <TabPanel value={3}>Photos</TabPanel>
          <TabPanel value={4}>Sortie</TabPanel>
          <TabPanel value={5}>CRH</TabPanel>
          <TabPanel value={6}>
            <>
              <OrdonnanceList
                hospitalisationId={hospitalisation.id}
                zkf_patient={hospitalisation.zkf_patient}
              />
            </>
          </TabPanel>
        </Tabs>
      </Box>
      <Box
        sx={{
          width: "400px",
          pl: 2,
          height: "calc(100vh - 16px)",
          overflowY: "scroll",
        }}
      >
        {isListOfOperationsWithinHospitalisation(
          hospitalisation.operations,
        ) && (
          <>
            <ListOfOperations
              operations={hospitalisation.operations}
              title="Opérations liées"
            />
          </>
        )}
      </Box>
    </Stack>
  ) : (
    <p>"Chargement..."</p>
  );
}
