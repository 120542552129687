import Alert from "@mui/joy/Alert";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Stack from "@mui/joy/Stack";
import React from "react";
import { useApi } from "../../contexts/ApiContext";
import { PatchedPatient } from "../../models/types";
import { router } from "../../router";

export function CreatePatient() {
  const api = useApi();

  const [error, setError] = React.useState<string | null>(null);
  const [email, setEmail] = React.useState<string | null>(null);
  const [phone, setPhone] = React.useState<string | null>(null);
  const [patient, setPatient] = React.useState<PatchedPatient>({
    nom: "",
    nom_naissance: "",
    prenom: "",
    sexe: "MALE",
    dob: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPatient({
      ...patient,
      [e.target.name]: e.target.value,
    });
  };

  const handleDobChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPatient({
      ...patient,
      dob: e.target.value,
    });
  };

  const handleChangeSex = (
    event: React.SyntheticEvent | null,
    newValue: "MALE" | "FEMALE" | null,
  ) => {
    newValue &&
      setPatient({
        ...patient,
        sexe: newValue,
      });
  };

  const onCreatePatient = async (patient: PatchedPatient) => {
    if (!api) return;
    try {
      const res = await api.createPatient(patient);
      if (email !== null && email !== undefined && email !== "") {
        await api.createEmail({ email: email }, res.id);
      }
      if (phone !== null && phone !== undefined && phone !== "") {
        await api.createPhone({ numero: phone }, res.id);
      }
      // Redirect to the newly created patient page
      router.navigate(`/patient/${res.id}`);
    } catch (e: any) {
      setError("Une erreur est survenue lors de la création du patient.");
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!patient.nom || !patient.prenom || !patient.dob) {
      setError("Nom, prénom et date de naissance sont obligatoires.");
      return;
    }
    if (!patient.nom_naissance) {
      patient.nom_naissance = patient.nom;
    }
    // Check dob format
    const dobRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!dobRegex.test(patient.dob)) {
      setError("Le format de la date de naissance est incorrect.");
      return;
    }

    onCreatePatient(patient);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack direction="column" gap={2} sx={{ mt: 2 }}>
        {error && <Alert color="danger">{error}</Alert>}
        <FormControl>
          <FormLabel>Nom</FormLabel>
          <Input
            required
            name="nom"
            value={patient.nom}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Prenom</FormLabel>
          <Input
            required
            name="prenom"
            value={patient.prenom}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Sexe</FormLabel>
          <Select name="sexe" value={patient.sexe} onChange={handleChangeSex}>
            <Option value="MALE">Homme</Option>
            <Option value="FEMALE">Femme</Option>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Date de naissance</FormLabel>
          <Input
            required
            type="date"
            name="dob"
            value={patient.dob}
            onChange={handleDobChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Email</FormLabel>
          <Input
            name="email"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email || ""}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Téléphone</FormLabel>
          <Input
            name="phone"
            type="tel"
            onChange={(e) => setPhone(e.target.value)}
            value={phone || ""}
          />
        </FormControl>
        <Button type="submit">Créer le patient</Button>
      </Stack>
    </form>
  );
}
