import { keyframes } from "@emotion/react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Badge from "@mui/joy/Badge";
import Drawer from "@mui/joy/Drawer";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import React from "react";
import { useApi } from "../../contexts/ApiContext";
import { Notification } from "../../models/types";
import NotificationsDrawer from "./NotificationsDrawer";

const bellAnimation = keyframes`
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(10px, 0);
  }
  20% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(5px, 0);
  }
  30% {
    transform: translate(0, 0);
  }
  32.5% {
    transform: translate(3px, 0);
  }
  35% {
    transform: translate(0, 0);
  }
    36% {
    transform: translate(2px, 0);
  }
  37% {
    transform: translate(0, 0);
  }
`;

export default function Notifications() {
  const api = useApi();

  const [open, setOpen] = React.useState(false);
  const [notif, setNotifications] = React.useState<Notification[]>([]);

  React.useEffect(() => {
    if (api) {
      api.getNotifications().then((res) => {
        setNotifications(res.results);
      });
    }
  }, [api]);

  return (
    <Badge
      badgeContent={notif.length}
      color="warning"
      sx={{
        animation:
          notif.length > 0 ? `${bellAnimation} 2s infinite` : "initial",
        "&:hover": { animation: "initial" },
      }}
    >
      <Drawer
        size="md"
        variant="plain"
        open={open}
        onClose={() => setOpen(false)}
        slotProps={{
          content: {
            sx: {
              bgcolor: "transparent",
              p: { md: 3, sm: 0 },
              boxShadow: "none",
            },
          },
        }}
      >
        <NotificationsDrawer notifications={notif} />
      </Drawer>
      <Sheet
        variant="outlined"
        sx={{
          p: 1,
          ml: -4,
          pl: 4,
          width: "calc(100% + 32px)",
          borderRadius: "sm",
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
          display: "flex",
          alignItems: "center",
          textWrap: "nowrap",
          transition: "all 0.3s",
          "&:hover": {
            cursor: "pointer",
            ml: -3,
          },
        }}
        onClick={() => setOpen(true)}
      >
        <Typography
          level="title-sm"
          startDecorator={
            notif.length > 0 ? (
              <NotificationsActiveIcon />
            ) : (
              <NotificationsIcon />
            )
          }
          component={"span"}
        >
          Notifications
        </Typography>
      </Sheet>
    </Badge>
  );
}
