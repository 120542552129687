import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PrinterAddIcon(props: SvgIconProps) {
  // same behaviour as mui icons, but with a png image
  return (
    <SvgIcon {...props}>
      <path
        fill-rule="evenodd"
        d="m22 11v6h-4v4h-12v-4h-4v-6c0-1.7 1.3-3 3-3h14c1.7 0 3 1.3 3 3zm-14 8h8v-5h-8zm12-8c0-0.6-0.4-1-1-1-0.6 0-1 0.4-1 1 0 0.6 0.4 1 1 1 0.6 0 1-0.4 1-1zm-14-8h12v4h-12z"
      />
      <path
        d="m17.7 23c-2.7 0-4.9-2.2-4.9-4.9 0-2.7 2.2-4.9 4.9-4.9 2.7 0 4.9 2.2 4.9 4.9 0 2.7-2.2 4.9-4.9 4.9z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="m18.7 15.2v2.2h2v1.7h-2v2.3h-1.9v-2.3h-2v-1.7h2v-2.2z"
        fill="white"
      />
    </SvgIcon>
  );
}
