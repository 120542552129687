import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { Patient } from "../../models/types";
import { ISODateToAge, niceFormattingOfName } from "../../utils/utils";

export default function PatientHeader({ patient }: { patient: Patient }) {
  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <Box>
          <Typography sx={{ flex: 1 }} level="title-lg">
            {niceFormattingOfName(patient)}{" "}
            {patient.nom_naissance !== patient.nom && (
              <Typography sx={{ fontWeight: "initial" }}>
                né {patient.nom_naissance}
              </Typography>
            )}
          </Typography>
          <Stack direction="column" sx={{ mt: 1 }}>
            {patient.dob && (
              <Stack direction="row" gap={1}>
                {/* <Typography level="body-sm">DDN</Typography> */}
                <Typography level="body-sm" sx={{ color: "black" }}>
                  {new Date(patient.dob).toLocaleDateString("fr")}

                  <b>
                    {" ("}
                    {ISODateToAge(patient.dob) + " ans"}
                    {")"}
                  </b>
                </Typography>
              </Stack>
            )}
            {/* {patient.sexe && (
              <Stack direction="row" gap={1}>
                <Typography level="body-sm">SEXE</Typography>
                <Typography level="body-sm" sx={{ color: "black" }}>
                  <b>{sexeToGender(patient.sexe)}</b>
                </Typography>
              </Stack>
            )} */}
            {/* {patient.numero_securite_sociale && (
              <Stack direction="row" gap={1}>
                <Typography level="body-sm">NSS</Typography>
                <Typography level="body-sm" sx={{ color: "black" }}>
                  <b>{patient.numero_securite_sociale}</b>
                </Typography>
              </Stack>
            )} */}

            {/* TODO */}
            {/* Ajouter les praticiens referents */}

            {/* {patient.praticien_referent && (
                <Stack direction="row" gap={1}>
                  <Typography level="body-sm">DR. RÉFÉRENTS</Typography>
                  <Stack direction="row" gap={0.5}>
                    {referentDoctors.map((doctor) => (
                      <Chip key={doctor} variant="outlined" size="sm">
                        {doctor}
                      </Chip>
                    ))}
                  </Stack>
                </Stack>
              )} */}
          </Stack>
        </Box>

        {/* TODO */}
        {/* Créer un popup avec les détails administratifs de patient + lien vers édition fiche administrative !  */}

        <Box sx={{ textAlign: "right" }}>
          {/* <Typography sx={{ mb: 2 }} level="title-lg">
            Coordonnées
          </Typography>
          <Typography level="body-sm">33 avenue des sorcières</Typography>
          <Typography level="body-sm">59000 Lille</Typography>
          <Typography sx={{ mt: 1 }} level="body-sm">
            03 20 00 00 00
          </Typography>
          <Typography level="body-sm">06 20 00 00 00</Typography> */}
        </Box>
      </Stack>
    </Box>
  );
}
