import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { ArrowLeftIcon } from "@mui/x-date-pickers";
import React, { useCallback, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useApi } from "../../../contexts/ApiContext";
import {
  Consultation,
  ModeConsultationEnum,
  Operation,
  PatchedConsultation,
  Patient,
  User,
  UserSites,
  UserSitesLieux,
} from "../../../models/types";
import {
  CurrentTime,
  formatDate,
  formatHour,
  isToday,
} from "../../../utils/utils";
import TabList from "@mui/joy/TabList";
import Tabs from "@mui/joy/Tabs";
import Tab from "@mui/joy/Tab";
import { TabPanel } from "@mui/joy";
import OrdonnanceList from "../../../components/Ordonnances/OrdonnanceList";
import { PeopleAlt } from "@mui/icons-material";
import { useSnackbar } from "../../../contexts/SnackbarContext";
import { isPatient } from "../../../models/type_guards";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import SiteSelect from "../../../components/Forms/SiteSelect";
import LieuSelect from "../../../components/Forms/LieuSelect";
import Checkbox from "@mui/joy/Checkbox";
import DocumentsModal from "../DocumentsModal";
import { router } from "../../../router";
import ResumeTab from "./ResumeTab";
import Tooltip from "@mui/joy/Tooltip";
import ListOfEvents from "../ListOfEvents";
import CourrierTab from "./CourrierTab";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import DoctorSelect from "../../../components/Forms/DoctorSelect";
import ComplicationsTab from "./ComplicationsTab";
import moment from "moment";
import { usePrintManager } from "../../../contexts/PrintManagerContext";
import PrinterAddIcon from "../../../assets/icons/PrinterAddIcon";
import Print from "@mui/icons-material/Print";
import PrintModal from "../../../components/PrintManager/PrintModal";

export default function ConsultationPage() {
  const api = useApi();
  const snackbar = useSnackbar();
  const { addToPrintManager } = usePrintManager();

  const { id } = useParams();
  const [consultation, setConsultation] = React.useState<Consultation | null>(
    null,
  );
  const [openDocuments, setOpenDocuments] = React.useState(false);
  const [edit, setEdit] = React.useState<boolean>(false);
  const [motif, setMotif] = React.useState<string>("");
  const [isFirstConsult, setIsFirstConsult] = React.useState<boolean>(false);
  const [date, setDate] = React.useState<string>("");
  const [debutPrevu, setDebutPrevu] = React.useState<string>("");
  const [debutReel, setDebutReel] = React.useState<string>("");
  const [finReel, setFinReel] = React.useState<string>("");
  const [site, setSite] = React.useState<UserSites | null>();
  const [lieu, setLieu] = React.useState<UserSitesLieux | null>();
  const [mode, setMode] = React.useState<ModeConsultationEnum>("programme");
  const [medecin, setMedecin] = React.useState<number>();
  const [sortedOperations, setSortedOperations] = React.useState<Operation[]>(
    [],
  );
  const [activeTab, setActiveTab] = React.useState<number>(0);
  const [init, setInit] = React.useState<boolean>(false);
  const [openPrintModal, setOpenPrintModal] = React.useState<boolean>(false);
  const [fileBlobURL, setFileBlobURL] = React.useState<string>("");

  const fetch = useCallback(async () => {
    if (!id) return;
    try {
      const consultation = await api.getConsultation(id, 1);
      if (!init) {
        setActiveTab(consultation.operation_liee?.length ? 3 : 0);
        setInit(true);
      }

      setConsultation(consultation);
    } catch (error) {
      console.error(error);
      snackbar.show(
        "Erreur lors de la récupération de la consultation",
        "danger",
      );
    }
  }, [api, id, init, snackbar]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    setSortedOperations(
      (consultation?.operation_liee as unknown as Operation[])?.sort((a, b) =>
        moment(a.operation_date).valueOf() < moment(b.operation_date).valueOf()
          ? -1
          : 1,
      ),
    );
  }, [consultation]);

  const handleCloseDocuments = useCallback(() => setOpenDocuments(false), []);

  if (!consultation) return null;

  const handleEdit = () => {
    setMotif(consultation.motif_consultation || "");
    setIsFirstConsult(consultation.premiere_consultation || false);
    setDate(consultation.consultation_date || "");
    setDebutPrevu(consultation.consultation_debut_prevu || "");
    setDebutReel(consultation.consultation_debut_reel || "");
    setFinReel(consultation.consultation_fin_reel?.slice(0, 8) || "");
    setSite(consultation.zkf_site as unknown as UserSites);
    setLieu(consultation.zkf_lieu as unknown as UserSitesLieux);
    setMode(consultation.mode_consultation || "programme");
    setMedecin((consultation.zkf_redacteur as unknown as User).id);
    setEdit(true);
  };

  const handleUpdateConsultation = () => {
    const newConsultation: PatchedConsultation = {
      id: consultation.id,
      motif_consultation: motif,
      premiere_consultation: isFirstConsult,
      consultation_date: date,
      consultation_debut_prevu: debutPrevu || null,
      consultation_debut_reel: debutReel || null,
      consultation_fin_reel: finReel || null,
      zkf_site: site?.id,
      zkf_lieu: lieu?.id,
      mode_consultation: mode,
      zkf_redacteur: medecin,
    };

    api
      .updateConsultation(newConsultation)
      .then(async () => {
        await fetch();
        setEdit(false);
      })
      .catch(() =>
        snackbar.show(
          "Erreur lors de la modification de la consultation",
          "danger",
        ),
      );
  };

  const handleCloturer = () => {
    api
      .updateConsultation({
        id: consultation.id,
        consultation_fin_reel: CurrentTime(),
      })
      .then(() => {
        snackbar.show("Consultation cloturer", "success");
        router.navigate("/");
      })
      .catch(() => snackbar.show("Erreur lors de la cloture", "danger"));
  };

  const print = async () => {
    api
      .printManagerPrintAll([
        {
          id: consultation.id,
          model_app_datas: consultation.model_app_datas,
          type_document: "convocation_consultation",
        },
      ])
      .then((blob) => {
        setFileBlobURL(window.URL.createObjectURL(blob));
        setOpenPrintModal(true);
      });
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <Link
          to={`/patient/${isPatient(consultation.zkf_patient) ? consultation.zkf_patient.id : consultation.zkf_patient}`}
        >
          <Typography
            sx={{ mb: 2 }}
            level="body-xs"
            startDecorator={<ArrowLeftIcon />}
          >
            Retour à la page du patient
          </Typography>
        </Link>
        <Stack direction="row" spacing={2}>
          <Button onClick={() => setOpenDocuments(true)}>Documents</Button>
          <Button startDecorator={<Print />} onClick={print}>
            Convocation
          </Button>
          <Button
            startDecorator={<PrinterAddIcon />}
            onClick={() =>
              addToPrintManager("convocation_consultation", consultation.id)
            }
          >
            Convocation
          </Button>
          {consultation.consultation_date &&
            isToday(consultation.consultation_date) && (
              <Button onClick={handleCloturer}>Cloturer</Button>
            )}
        </Stack>
      </Stack>
      <div>
        <Chip
          size="sm"
          color="primary"
          startDecorator={<PeopleAlt fontSize="small" />}
        >
          CONSULTATION
        </Chip>
        <Stack direction="row" gap={1} alignItems={"center"}>
          {!edit && (
            <Typography level="h3">
              {consultation.motif_consultation}
            </Typography>
          )}
          {edit && (
            <Input
              value={motif}
              onChange={(e) => setMotif(e.target.value)}
              sx={{ width: "400px" }}
            />
          )}
          {!edit && consultation.mode_consultation === "urgent" && (
            <Chip variant="outlined" color="danger">
              Urgence
            </Chip>
          )}
          {!edit && consultation.premiere_consultation && (
            <Chip variant="outlined" color="warning">
              Première consultation
            </Chip>
          )}
          {edit && (
            <Checkbox
              checked={isFirstConsult}
              onClick={() => setIsFirstConsult(!isFirstConsult)}
              label={"Première consultation"}
            />
          )}
          <Stack direction="row" gap={1} flex={1} justifyContent={"end"}>
            {!edit && (
              <Button variant={"soft"} color={"neutral"} onClick={handleEdit}>
                Edit
              </Button>
            )}
            {edit && (
              <Button
                variant={"soft"}
                color={"success"}
                onClick={handleUpdateConsultation}
              >
                Save
              </Button>
            )}
            {edit && (
              <Button
                variant={"soft"}
                color={"neutral"}
                onClick={() => setEdit(false)}
              >
                Cancel
              </Button>
            )}
          </Stack>
        </Stack>
      </div>
      <Stack sx={{ mt: 1 }} gap={0.25}>
        {edit && (
          <Stack direction="row" gap={2}>
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Typography level="body-xs" textTransform="uppercase">
                MODE
              </Typography>
              <Select
                value={mode}
                onChange={(e, newValue) => setMode(newValue || "programme")}
              >
                <Option value={"programme"}>Programmé</Option>
                <Option value={"urgent"}>Urgent</Option>
              </Select>
            </Stack>
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Typography level="body-xs" textTransform="uppercase">
                MÉDECIN
              </Typography>
              <DoctorSelect
                value={medecin}
                onSelect={(value) => setMedecin((value as User).id)}
              />
            </Stack>
          </Stack>
        )}
        <Stack direction={"row"} gap={2}>
          {consultation.consultation_date && (
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Typography level="body-xs" textTransform="uppercase">
                DATE
              </Typography>
              {!edit && (
                <Typography level="body-md">
                  {formatDate(consultation.consultation_date)}
                </Typography>
              )}
              {edit && (
                <Input
                  type={"date"}
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              )}
            </Stack>
          )}
          {(consultation.consultation_debut_prevu || edit) && (
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Typography level="body-xs" textTransform="uppercase">
                DÉBUT PRÉVU
              </Typography>
              {!edit && (
                <Typography level="body-md">
                  {formatHour(consultation.consultation_debut_prevu)}
                </Typography>
              )}
              {edit && (
                <Input
                  type={"time"}
                  value={debutPrevu}
                  onChange={(e) => setDebutPrevu(e.target.value)}
                />
              )}
            </Stack>
          )}
        </Stack>
        <Stack direction={"row"} gap={2}>
          {(consultation.consultation_debut_reel || edit) && (
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Typography level="body-xs" textTransform="uppercase">
                DÉBUT RÉEL
              </Typography>
              {!edit && (
                <Typography level="body-md">
                  {formatHour(consultation.consultation_debut_reel)}
                </Typography>
              )}
              {edit && (
                <Input
                  type={"time"}
                  value={debutReel}
                  onChange={(e) => setDebutReel(e.target.value)}
                />
              )}
            </Stack>
          )}
          {(consultation.consultation_fin_reel || edit) && (
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Typography level="body-xs" textTransform="uppercase">
                FIN RÉEL
              </Typography>
              {!edit && (
                <Typography level="body-md">
                  {formatHour(consultation.consultation_fin_reel)}
                </Typography>
              )}
              {edit && (
                <Input
                  type={"time"}
                  value={finReel}
                  onChange={(e) => setFinReel(e.target.value)}
                />
              )}
            </Stack>
          )}
        </Stack>
        {(consultation.zkf_site || edit) && (
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography level="body-xs" textTransform="uppercase">
              SITE
            </Typography>
            {!edit && (
              <Typography level="body-md">
                {(consultation.zkf_site as unknown as UserSites).nom_site}
                &nbsp;—&nbsp;
                {consultation.zkf_lieu &&
                  (consultation.zkf_lieu as unknown as UserSitesLieux).nom_lieu}
              </Typography>
            )}
            {edit && (
              <SiteSelect
                value={site?.id || null}
                onSelect={(newValue) => setSite(newValue)}
              />
            )}
            {edit && (
              <Typography level="body-xs" textTransform="uppercase">
                LIEU
              </Typography>
            )}
            {edit && site && (
              <LieuSelect
                site={site.id}
                value={lieu?.id || null}
                onSelect={(newValue) => setLieu(newValue)}
              />
            )}
          </Stack>
        )}
      </Stack>
      <Stack mt={4} direction={"row"} gap={0.5}>
        <ListOfEvents
          patient={consultation.zkf_patient as unknown as Patient}
          width={"200px"}
        />
        <Tabs
          sx={{ flex: 1 }}
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue as number)}
        >
          <TabList>
            <Tab value={0}>Résumé</Tab>
            <Tab value={1}>Courrier</Tab>
            <Tab value={2}>Ordonnances</Tab>
            {sortedOperations?.map((ope, i) => (
              <Tooltip
                key={i}
                title={`${formatDate(ope.operation_date)} - ${ope.titre_operation}`}
              >
                <Tab
                  sx={{
                    maxWidth: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    display: "inline-block",
                  }}
                  value={i + 3}
                >
                  {ope.titre_operation}
                </Tab>
              </Tooltip>
            ))}
          </TabList>
          <TabPanel value={0}>
            <ResumeTab consultation={consultation} onSave={fetch} />
          </TabPanel>
          <TabPanel value={1}>
            <CourrierTab consultation={consultation} onSave={fetch} />
          </TabPanel>
          <TabPanel value={2}>
            <OrdonnanceList
              consultationId={consultation.id}
              zkf_patient={consultation.zkf_patient}
            />
          </TabPanel>
          {sortedOperations?.map((ope, i) => (
            <TabPanel value={i + 3} key={i}>
              <ComplicationsTab operation={ope as unknown as Operation} />
            </TabPanel>
          ))}
        </Tabs>
      </Stack>
      {openDocuments && (
        <DocumentsModal
          open={openDocuments}
          onClose={handleCloseDocuments}
          patient={consultation.zkf_patient as unknown as Patient}
        />
      )}
      {
        <PrintModal
          open={openPrintModal}
          onClose={() => setOpenPrintModal(false)}
          fileUrl={fileBlobURL}
        />
      }
    </Box>
  );
}
