import {
  Consultation,
  ConsultationType,
  PatchedConsultation,
  StatutCourrierConsultationEnum,
} from "../../../models/types";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { Email } from "@mui/icons-material";
import Button from "@mui/joy/Button";
import Print from "@mui/icons-material/Print";
import PrinterAddIcon from "../../../assets/icons/PrinterAddIcon";
import React, { useCallback, useEffect, useMemo } from "react";
import Editor from "../../../components/Editor/Editor";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import { useApi } from "../../../contexts/ApiContext";
import { useSnackbar } from "../../../contexts/SnackbarContext";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { usePrintManager } from "../../../contexts/PrintManagerContext";
import PrintModal from "../../../components/PrintManager/PrintModal";

interface CourrierTabProps {
  consultation: Consultation;
  onSave?: () => void;
}

export default function CourrierTab({
  consultation,
  onSave,
}: CourrierTabProps) {
  const api = useApi();
  const snackbar = useSnackbar();
  const { addToPrintManager } = usePrintManager();
  const [edit, setEdit] = React.useState(false);
  const [courrier, setCourrier] = React.useState<string>("");
  const [basPage, setBasPage] = React.useState<string>("");
  const [editorRef, setEditorRef] = React.useState<ClassicEditor>();
  const [consultations, setConsultations] = React.useState<ConsultationType[]>(
    [],
  );
  const [openPrintModal, setOpenPrintModal] = React.useState<boolean>(false);
  const [fileBlobURL, setFileBlobURL] = React.useState<string>("");

  useEffect(() => {
    api.getConsultationType().then(setConsultations);
  }, [api]);

  const insertTextToEditor = useCallback(
    (text: string) => {
      if (editorRef) {
        editorRef.model.change(() => {
          const selection = editorRef.model.document.selection;
          // Convert the HTML string to a CKEditor view fragment
          const viewFragment = editorRef.data.processor.toView(text);
          // Convert the view fragment to a model fragment
          const modelFragment = editorRef.data.toModel(viewFragment);
          editorRef.model.insertContent(modelFragment, selection);
          editorRef.focus();
        });
      }
    },
    [editorRef],
  );

  const templates = useMemo(
    () => (
      <Box
        sx={{
          minWidth: "250px",
          background: (theme) => theme.palette.background.level1,
          borderTop: "1px solid #e3e3e3",
          borderBottom: "1px solid #e3e3e3",
          borderRadius: "8px",
          pl: 1,
          pr: 1,
          overflow: "scroll",
          flex: 1,
        }}
      >
        <Typography level="title-md" p={1}>
          Ajouter un champs
        </Typography>
        <Divider sx={{ mb: 1 }} />
        {consultations.map((consultation) => (
          <Typography
            key={consultation.id}
            level={"body-xs"}
            sx={(theme) => ({
              "&:hover": {
                backgroundColor: theme.palette.background.level2,
                cursor: "pointer",
              },
              p: "2px",
              borderRadius: "sm",
            })}
            onClick={() => insertTextToEditor(consultation.corps || "")}
          >
            {consultation.titre}
          </Typography>
        ))}
      </Box>
    ),
    [consultations, insertTextToEditor],
  );

  const handleEdit = useCallback(() => {
    setCourrier(consultation.courrier_consultation || "");
    setBasPage(consultation.courrier_consultation_bas_page || "");
    setEdit(true);
  }, [consultation]);

  const handleStatus = useCallback(
    (newValue: StatutCourrierConsultationEnum) => {
      api
        .updateConsultation({
          id: consultation.id,
          statut_courrier_consultation: newValue,
        })
        .then(() => {
          onSave && onSave();
        })
        .catch(() =>
          snackbar.show(
            "Erreur lors de la modification du statut du courrier",
            "danger",
          ),
        );
    },
    [api, consultation.id, onSave, snackbar],
  );

  const handleSave = useCallback(() => {
    const newConsultation: PatchedConsultation = {
      id: consultation.id,
      courrier_consultation: courrier,
      courrier_consultation_bas_page: basPage,
    };

    api
      .updateConsultation(newConsultation)
      .then(() => {
        onSave && onSave();
        setEdit(false);
      })
      .catch(() =>
        snackbar.show(
          "Erreur lors de la modification de la consultation",
          "danger",
        ),
      );
  }, [api, basPage, consultation.id, courrier, onSave, snackbar]);

  const print = async () => {
    api
      .printManagerPrintAll([
        {
          id: consultation.id,
          model_app_datas: consultation.model_app_datas,
          type_document: "courrier_consultation",
        },
      ])
      .then((blob) => {
        setFileBlobURL(window.URL.createObjectURL(blob));
        setOpenPrintModal(true);
      });
  };

  return (
    <Stack gap={2}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack direction={"row"} gap={0.5} alignItems={"center"}>
          <Typography startDecorator={<Email />}>Courrier</Typography>
          <Button variant={"plain"} color={"neutral"} onClick={print}>
            <Print />
          </Button>
          <Button
            variant={"plain"}
            color={"neutral"}
            onClick={() =>
              addToPrintManager("courrier_consultation", consultation.id)
            }
          >
            <PrinterAddIcon />
          </Button>
          <Select
            value={consultation.statut_courrier_consultation}
            onChange={(e, newValue) => handleStatus(newValue || "non_statue")}
          >
            <Option value={"non_statue"}>Non statué</Option>
            <Option value={"non_fait"}>Non fait</Option>
            <Option value={"attente_validation"}>Attente validation</Option>
            <Option value={"valide"}>Validé</Option>
            <Option value={"envoye"}>Envoyé</Option>
          </Select>
        </Stack>
        <Stack direction={"row"} gap={1}>
          {!edit && (
            <Button color={"neutral"} variant={"soft"} onClick={handleEdit}>
              Edit
            </Button>
          )}
          {edit && (
            <Button variant={"soft"} color={"success"} onClick={handleSave}>
              Save
            </Button>
          )}
          {edit && (
            <Button
              variant={"soft"}
              color={"neutral"}
              onClick={() => setEdit(false)}
            >
              Cancel
            </Button>
          )}
        </Stack>
      </Stack>
      <Stack gap={1}>
        <Typography>Courrier</Typography>
        {!edit && (
          <div
            style={{
              fontSize: "12px",
              height: "300px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "8px",
              overflow: "scroll",
            }}
            dangerouslySetInnerHTML={{
              __html: consultation.courrier_consultation || "",
            }}
          />
        )}
        {edit && (
          <Stack direction={"row"} gap={2}>
            <div style={{ height: "300px" }}>
              <Editor
                data={courrier}
                onChange={setCourrier}
                onReady={setEditorRef}
              />
            </div>
            {templates}
          </Stack>
        )}
      </Stack>
      <Stack gap={1}>
        <Typography>Bas de page</Typography>
        {!edit && (
          <div
            style={{
              fontSize: "12px",
              height: "200px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "8px",
            }}
            dangerouslySetInnerHTML={{
              __html: consultation.courrier_consultation_bas_page || "",
            }}
          />
        )}
        {edit && (
          <div style={{ height: "200px" }}>
            <Editor data={basPage} onChange={setBasPage} />
          </div>
        )}
      </Stack>
      {
        <PrintModal
          open={openPrintModal}
          onClose={() => setOpenPrintModal(false)}
          fileUrl={fileBlobURL}
        />
      }
    </Stack>
  );
}
