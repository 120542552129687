import Stack from "@mui/joy/Stack";
import {
  Consultation,
  Operation,
  PatchedConsultation,
  Patient,
} from "../../../models/types";
import Typography from "@mui/joy/Typography";
import React, { useCallback, useEffect } from "react";
import Button from "@mui/joy/Button";
import Editor from "../../../components/Editor/Editor";
import { useApi } from "../../../contexts/ApiContext";
import { useSnackbar } from "../../../contexts/SnackbarContext";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import { formatDate } from "../../../utils/utils";
import Select from "@mui/joy/Select";
import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";

interface ResumeTabProps {
  consultation: Consultation;
  onSave?: () => void;
}

export default function ResumeTab({ consultation, onSave }: ResumeTabProps) {
  const api = useApi();
  const snackbar = useSnackbar();
  const [edit, setEdit] = React.useState<boolean>(false);
  const [motif, setMotif] = React.useState<string>("");
  const [observation, setObservation] = React.useState<string>("");
  const [operations, setOperations] = React.useState<Operation[]>([]);

  useEffect(() => {
    setMotif(consultation.motif_consultation || "");
  }, [consultation, edit]);

  useEffect(() => {
    consultation?.zkf_patient &&
      api
        .getOperationsByPatient(
          (consultation?.zkf_patient as unknown as Patient).id,
        )
        .then(setOperations);
  }, [api, consultation]);

  const handleEdit = useCallback(() => {
    setObservation(consultation.observation_consultation || "");
    setEdit(true);
  }, [consultation]);

  const handleSave = useCallback(() => {
    const newConsultation: PatchedConsultation = {
      id: consultation.id,
      motif_consultation: motif,
      observation_consultation: observation,
    };

    api
      .updateConsultation(newConsultation)
      .then(() => {
        onSave && onSave();
        snackbar.show(
          "La modification de la consultation a réussie",
          "success",
        );
        setEdit(false);
      })
      .catch(() =>
        snackbar.show(
          "Erreur lors de la modification de la consultation",
          "danger",
        ),
      );
  }, [api, consultation.id, motif, observation, onSave, snackbar]);

  const handleLinkOperation = useCallback(
    (selectedOperations: string[]) => {
      api
        .updateConsultation({
          id: consultation?.id,
          operation_liee: selectedOperations,
        })
        .then(() => {
          snackbar.show("Opérations liées mise a jour", "success");
          onSave && onSave();
        })
        .catch(() =>
          snackbar.show(
            "Erreur lors de la sauvegarde des opérations liées",
            "danger",
          ),
        );
    },
    [api, consultation?.id, onSave, snackbar],
  );

  return (
    <Stack gap={2} flex={1}>
      <Stack direction={"row"} gap={1} justifyContent={"end"}>
        {!edit && (
          <Button color={"neutral"} variant={"soft"} onClick={handleEdit}>
            Edit
          </Button>
        )}
        {edit && (
          <Button variant={"soft"} color={"success"} onClick={handleSave}>
            Save
          </Button>
        )}
        {edit && (
          <Button
            variant={"soft"}
            color={"neutral"}
            onClick={() => setEdit(false)}
          >
            Cancel
          </Button>
        )}
      </Stack>
      <Stack gap={1}>
        <Typography>Motif</Typography>
        <Input
          disabled={!edit}
          value={motif}
          onChange={(e) => setMotif(e.target.value)}
        />
      </Stack>
      <Stack gap={1}>
        <Typography>Operations Liees</Typography>
        <Select
          multiple
          value={
            (consultation.operation_liee as unknown as Operation[]).map(
              (o) => o.id,
            ) || []
          }
          renderValue={(selected) => (
            <Box sx={{ display: "flex", gap: "0.25rem" }} flexWrap={"wrap"}>
              {selected.map((selectedOption) => (
                <Chip
                  key={selectedOption.id}
                  variant="soft"
                  color="primary"
                  sx={{
                    maxWidth: "300px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {selectedOption.label}
                </Chip>
              ))}
            </Box>
          )}
          onChange={(e, newValue) => handleLinkOperation(newValue)}
        >
          {operations.map((operation) => (
            <Option key={operation.id} value={operation.id}>
              {formatDate(operation.operation_date)} -{" "}
              {operation.titre_operation}
            </Option>
          ))}
        </Select>
      </Stack>
      <Stack gap={1}>
        <Typography>Observation</Typography>
        {!edit && (
          <div
            style={{
              fontSize: "12px",
              height: "500px",
              overflow: "scroll",
            }}
            dangerouslySetInnerHTML={{
              __html: consultation.observation_consultation || "",
            }}
          />
        )}
        {edit && (
          <div style={{ flex: 1, maxHeight: "calc(100% - 32px)" }}>
            <Editor data={observation} onChange={setObservation} />
          </div>
        )}
      </Stack>
    </Stack>
  );
}
