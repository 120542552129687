import PDFPreviewAndPrint from "../PDF/PDFPreview";
import ModalComponent from "../Modal/Modal";
import React from "react";

interface PrintModalProps {
  open: boolean;
  onClose: () => void;
  fileUrl: string;
}

export default function PrintModal({
  open,
  onClose,
  fileUrl,
}: PrintModalProps) {
  return (
    <ModalComponent
      open={open}
      onClose={onClose}
      title={"Imprimer"}
      style={{ minWidth: "60%", minHeight: "600px" }}
      withCloseButton={false}
    >
      <div style={{ flex: 1, height: "600px" }}>
        <PDFPreviewAndPrint fileUrl={fileUrl} onPrint={onClose} />
      </div>
    </ModalComponent>
  );
}
