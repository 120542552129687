import React from "react";
import { User, Profile, PreferenceUserSerialyzer } from "../models/types";
import { useApi } from "./ApiContext";
import { useOidc } from "@axa-fr/react-oidc";

interface UserContextProps {
  user: User | null;
  profile: Profile | null;
  preferences: PreferenceUserSerialyzer | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  setProfile: React.Dispatch<React.SetStateAction<Profile | null>>;
  setPreferences: React.Dispatch<
    React.SetStateAction<PreferenceUserSerialyzer | null>
  >;
  loading: boolean;
}

interface AuthenticatedUserProps {
  user: User;
  profile: Profile;
  preferences: PreferenceUserSerialyzer | null;
  userDisplayName: string;
}

const UserContext = React.createContext<UserContextProps | undefined>(
  undefined,
);

interface UserContextProviderProps {
  children: React.ReactNode;
}

export function UserContextProvider({ children }: UserContextProviderProps) {
  const api = useApi();
  const [user, setUser] = React.useState<User | null>(null);
  const [profile, setProfile] = React.useState<Profile | null>(null);
  const [preferences, setPreferences] =
    React.useState<PreferenceUserSerialyzer | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    const initializeUser = async () => {
      try {
        const userData = await api.getMyself();
        setUser(userData);
        const userProfile = await api.getUserProfile(userData.profile_id);
        setProfile(userProfile);
        const userPreference = await api.getUserPreferences(
          userData.preferences_id,
        );
        setPreferences(userPreference);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      } finally {
        setLoading(false);
      }
    };

    initializeUser();
  }, [api]);

  return (
    <UserContext.Provider
      value={{
        user,
        profile,
        preferences,
        setUser,
        setProfile,
        setPreferences,
        loading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const useUser = () => {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserContextProvider");
  }
  return context;
};

export const useAuthenticatedUser = (): AuthenticatedUserProps => {
  const user = useUser();
  const userDisplayName = getTitleAndName(user.user, user.profile);

  if (!user || !user.user || !user.profile) {
    throw new Error("User is not authenticated");
  }

  return {
    user: user.user,
    profile: user.profile,
    preferences: user.preferences,
    userDisplayName,
  };
};

function getTitleAndName(user: User | null, profile: Profile | null): string {
  if (user && profile) {
    return profile?.titre
      ? profile.titre + " " + user.last_name
      : user.last_name + " " + user.first_name;
  } else {
    return "";
  }
}
