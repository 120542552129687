import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import dayjs from "dayjs";
import React from "react";
import DoctorSelect from "../../components/Forms/DoctorSelect";
import TagAutocomplete from "../../components/Forms/TagAutocomplete";
import PatientCard from "../../components/PatientCard";
import { useApi } from "../../contexts/ApiContext";
import { useCurrentPatient } from "../../contexts/CurrentPatientContext";
import { useSnackbar } from "../../contexts/SnackbarContext";
import { useAuthenticatedUser } from "../../contexts/UserContext";
import {
  Consultation,
  ModeConsultationEnum,
  PatchedConsultation,
  Patient,
  User,
} from "../../models/types";
import ConsultationForm from "./Forms/ConsultationForm";

const defaultErrors = {
  start: false,
  end: false,
  selectedPatient: false,
  date: false,
  selectedDr: false,
};

export default function CreateConsultationModal({
  onSave,
  onClose,
}: {
  onSave: () => void;
  onClose: () => void;
}) {
  const api = useApi();
  const snackbar = useSnackbar();
  const { user } = useAuthenticatedUser();

  const [currentPatient] = useCurrentPatient();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selectedPatient] = React.useState<Patient | null>(currentPatient);
  const [date, setDate] = React.useState(dayjs().format("YYYY-MM-DD"));
  const [start, setStart] = React.useState(dayjs().format("HH:mm"));
  const [end, setEnd] = React.useState(
    dayjs().add(15, "minutes").format("HH:mm"),
  );
  const [selectedDr, setSelectedDr] = React.useState<number>(Number(user.id));
  const [errors, setErrors] = React.useState(defaultErrors);
  const [consultationFormValues, setConsultationFormValues] = React.useState<
    Partial<Consultation>
  >({
    id: "",
    tags: [],
    zkf_site: "",
    // zkf_lieu: "",
    zkf_patient: "",
    motif_consultation: "",
    consultation_date: "",
    consultation_debut_prevu: "",
    consultation_fin_prevu: "",
    mode_consultation: "programme",
    zkf_redacteur: Number(user.id),
  });

  const checkAndSave = async () => {
    // Reset errors before validation
    setErrors(defaultErrors);

    // Perform validation
    const newErrors = {
      start: dayjs(`2099-12-31 ${start}`).isValid() === false || start === "",
      end: dayjs(`2099-12-31 ${end}`).isValid() === false || end === "",
      selectedPatient: selectedPatient === null,
      date: dayjs(date).isValid() === false || date === "",
      selectedDr: selectedDr === null,
    };

    setErrors(newErrors);

    // Check if there are any validation errors
    if (
      !newErrors.start &&
      !newErrors.end &&
      !newErrors.selectedPatient &&
      !newErrors.date &&
      !newErrors.selectedDr
    ) {
      setLoading(true);

      try {
        // Construct payload with proper field formatting
        const payload = {
          mode_consultation:
            consultationFormValues.mode_consultation ||
            ("programme" as ModeConsultationEnum),
          zkf_site: consultationFormValues.zkf_site,
          zkf_lieu: consultationFormValues.zkf_lieu,
          zkf_patient: selectedPatient!.id,
          motif_consultation: consultationFormValues.motif_consultation || "",
          consultation_date: dayjs(date).format("YYYY-MM-DD"),
          consultation_debut_prevu: `${start}:00`,
          consultation_fin_prevu: `${end}:00`,
          tags: [],
          zkf_redacteur: consultationFormValues.zkf_redacteur || undefined,
        } as PatchedConsultation;

        // Attempt to call the API to save the consultation
        await api.createConsultation(payload);

        snackbar.show("Consultation créée avec succès", "success");

        // Call the onSave callback to refresh the list and close the modal
        onSave();
        onClose();
      } catch (error) {
        snackbar.show(
          "Erreur lors de la création de la consultation",
          "danger",
        );
      } finally {
        setLoading(false);
      }
    } else {
      snackbar.show(
        "Impossible de sauvegarder, veuillez corriger les erreurs.",
        "warning",
      );
    }
  };

  return (
    <Stack sx={{ height: "100vh" }} alignItems="center" justifyContent="center">
      <Sheet sx={{ borderRadius: "lg", p: 2, width: "90vw" }}>
        <Typography level="title-lg">Créer une consultation</Typography>
        <ModalClose onClick={onClose} />{" "}
        {/* Trigger onClose to close the modal */}
        <Sheet
          color="neutral"
          variant="soft"
          sx={{
            mt: 2,
            borderRadius: "lg",
            border: "1px solid transparent",
            borderColor: (theme) =>
              errors.selectedPatient
                ? theme.palette.danger[400]
                : "transparent",
            pointerEvents: loading ? "none" : "auto",
            opacity: loading ? 0.7 : 1,
          }}
        >
          {/* Assuming PatientCard is displaying selected patient */}
          <PatientCard patient={selectedPatient} />
          {errors.selectedPatient && (
            <Typography color="danger" level="body-xs" sx={{ ml: 2, mb: 1 }}>
              Veuillez sélectionner un patient
            </Typography>
          )}
        </Sheet>
        <Stack sx={{ mt: 2 }} direction="row" gap={4}>
          <Stack direction="column" gap={2} sx={{ width: 500 }}>
            <FormControl>
              <FormLabel>Docteur</FormLabel>
              <DoctorSelect
                value={selectedDr}
                onSelect={(doctor: User | User[]) => {
                  setSelectedDr((doctor as User).id); // Ensure correct ID selection
                }}
                disabled={loading}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Date</FormLabel>
              <Input
                type="date"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                error={errors.date}
                disabled={loading}
              />
            </FormControl>
            <Stack direction="row" gap={2}>
              <FormControl>
                <FormLabel>Début</FormLabel>
                <Input
                  type="time"
                  value={start}
                  onChange={(e) => setStart(e.target.value)}
                  error={errors.start}
                  disabled={loading}
                />
              </FormControl>
              <Stack flex={1} direction="column" gap={0.5}>
                <Typography level="title-md">Fin</Typography>
                <Input
                  type="time"
                  value={end}
                  onChange={(e) => setEnd(e.target.value)}
                  error={errors.end}
                  disabled={loading}
                />
              </Stack>
            </Stack>
            <FormControl>
              <FormLabel>Tags</FormLabel>
              <TagAutocomplete
                value={consultationFormValues.tags || []}
                onChange={(tags) => setConsultationFormValues({ tags })}
                helperText
              />
            </FormControl>
          </Stack>
          <Sheet
            sx={{ p: 2, borderRadius: "lg", flex: 1 }}
            color="primary"
            variant="soft"
          >
            <ConsultationForm
              values={consultationFormValues as Consultation}
              setValues={(values) => setConsultationFormValues(values)}
            />
          </Sheet>
        </Stack>
        <Stack sx={{ mt: 2 }} direction="row" justifyContent="flex-end" gap={2}>
          <Button
            startDecorator={<SaveIcon />}
            color="primary"
            variant="soft"
            onClick={checkAndSave}
            loading={loading}
          >
            Enregistrer
          </Button>
        </Stack>
      </Sheet>
    </Stack>
  );
}
