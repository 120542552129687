import { useApi } from "../../../../contexts/ApiContext";
import { useSnackbar } from "../../../../contexts/SnackbarContext";
import ListOfDocuments from "./ListOfDocuments";
import { ConsignesSortie } from "../../../../models/types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ModalComponent from "../../../../components/Modal/Modal";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Input from "@mui/joy/Input";
import Divider from "@mui/joy/Divider";
import Editor from "../../../../components/Editor/Editor";
import { useUser } from "../../../../contexts/UserContext";
import FieldsPanel from "./fieldsPanel";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function ConsignesSortieTab() {
  const api = useApi();
  const user = useUser();
  const snackbar = useSnackbar();

  const [consignes, setConsignes] = useState<ConsignesSortie[]>([]);
  const [editConsigne, setEditConsigne] = useState<ConsignesSortie>();
  const [titre, setTitre] = useState<string>("");
  const [text, setText] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [editorRef, setEditorRef] = React.useState<ClassicEditor>();

  useEffect(() => {
    api.getConsignesSortie().then((res) => setConsignes(res));
  }, [api]);

  const handleEdit = useCallback((consigne?: ConsignesSortie) => {
    setTitre(consigne?.titre_consigne || "");
    setText(consigne?.texte_suivi || "");
    setEditConsigne(consigne);
    setOpenModal(true);
  }, []);

  const handleDelete = useCallback(
    (id: string) => {
      api
        .deleteConsignesSortie(id)
        .then(() => setConsignes(consignes.filter((c) => c.id !== id)))
        .catch(() =>
          snackbar.show("Impossible de supprimer la consigne", "danger"),
        );
    },
    [api, consignes, snackbar],
  );

  const handleSave = useCallback(() => {
    const newConsigne: Partial<ConsignesSortie> = {
      titre_consigne: titre,
      texte_suivi: text,
      zkf_user: user.user?.id,
    };
    if (editConsigne) {
      api
        .updateConsignesSortie({ id: editConsigne.id, ...newConsigne })
        .then((res) => {
          setConsignes((prevState) =>
            prevState.map((c) => (c.id === editConsigne.id ? res : c)),
          );
          setOpenModal(false);
        })
        .catch(() =>
          snackbar.show("Impossible de modifier la consigne", "danger"),
        );
    } else {
      api
        .createConsignesSortie(newConsigne)
        .then((res) => {
          setConsignes((prevState) => [...prevState, res]);
          setOpenModal(false);
        })
        .catch(() =>
          snackbar.show("Impossible de créer la consigne", "danger"),
        );
    }
  }, [api, editConsigne, snackbar, text, titre, user.user?.id]);

  const editModal = useMemo(
    () => (
      <ModalComponent
        open={openModal}
        title={`${editConsigne ? "Editer" : "Créer"} une consigne`}
        onClose={() => setOpenModal(false)}
        validateLabel={"Enregistrer"}
        onValidate={handleSave}
        canValidate={!!titre && !!text}
        style={{ minWidth: "80%", minHeight: "80%" }}
      >
        <Stack spacing={2} flex={1}>
          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <Typography>Titre</Typography>
            <Input
              sx={{ flex: 1 }}
              value={titre}
              onChange={(e) => setTitre(e.target.value)}
            />
          </Stack>
          <Divider />
          <Stack flex={1}>
            <Stack direction={"row"} spacing={2} flex={1}>
              <div style={{ flex: 1 }}>
                <Editor data={text} onChange={setText} onReady={setEditorRef} />
              </div>
              <FieldsPanel
                editor={editorRef}
                filters={["general", "patient", "operation_short"]}
                style={{ height: "540px" }}
              />
            </Stack>
          </Stack>
        </Stack>
      </ModalComponent>
    ),
    [editConsigne, editorRef, handleSave, openModal, text, titre],
  );

  return (
    <>
      <ListOfDocuments
        documents={consignes}
        buttonLabel={"Créer une consigne"}
        editDocument={handleEdit}
        deleteDocument={handleDelete}
        documentLabelField={"titre_consigne"}
        documentCorpsField={"texte_suivi"}
      />
      {editModal}
    </>
  );
}
