import Masks from "@mui/icons-material/Masks";
import OpenInNew from "@mui/icons-material/OpenInNew";
import People from "@mui/icons-material/People";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Chip from "@mui/joy/Chip";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import ConsultationCard from "../../components/ConsultationCard";
import { useApi } from "../../contexts/ApiContext";
import { CalendarItem, Consultation, Operation } from "../../models/types";
import { router } from "../../router";
import OperationView from "./Views/OperationView";

function eventChip(type: "operation" | "consultation") {
  const color = type === "operation" ? "success" : "primary";
  const icon = type === "operation" ? <Masks /> : <People />;
  const text = type === "operation" ? "Opération" : "Consultation";
  return (
    <Chip color={color} startDecorator={icon}>
      {text}
    </Chip>
  );
}

export default function CalendarItemViewer({ event }: { event: CalendarItem }) {
  const [data, setData] = React.useState<Operation | Consultation | null>(null);
  const [type, setType] = React.useState<string>(
    event.type.toLocaleLowerCase(),
  );
  const api = useApi();

  React.useEffect(() => {
    if (type === "operation") {
      api?.getOperation(event.id).then(setData);
    } else if (type === "consultation") {
      api?.getConsultation(event.id).then(setData);
    }
  }, [event, api, type]);

  const goToEventPage = () => {
    router.navigate(`/${type}/${event.id}`);
  };

  const accessButtonText = () => {
    return type === "operation"
      ? "Accéder à l'opération"
      : "Accéder à la consultation";
  };

  return (
    <Stack
      sx={{ height: "100vh" }}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Sheet
        sx={{
          borderRadius: "lg",
          p: 2,
          width: "800px",
          position: "relative",
        }}
      >
        <Stack direction="column">
          <Stack
            direction="row"
            sx={{ mr: 5 }}
            justifyContent="space-between"
            alignItems="flex-end"
          >
            {eventChip(type as "operation" | "consultation")}
            <Stack direction="row" spacing={1}>
              <Button
                variant="soft"
                size="sm"
                color="primary"
                onClick={() => goToEventPage()}
                startDecorator={<OpenInNew />}
              >
                Aller à la fiche patient
              </Button>
              <Button
                variant="soft"
                size="sm"
                color="success"
                onClick={() => goToEventPage()}
                startDecorator={<OpenInNew />}
              >
                {accessButtonText()}
              </Button>
            </Stack>
          </Stack>
          <Typography level="h4" sx={{ mt: 1 }}>
            {event.title}
          </Typography>

          <ModalClose sx={{ position: "absolute", mt: 0.75, mr: 0.75 }} />
        </Stack>
        {data && (
          <Box sx={{ mt: 2 }}>
            {type === "operation" && (
              <OperationView operation={data as Operation} />
            )}
            {type === "consultation" && (
              <ConsultationCard consult={data as Consultation} />
            )}
          </Box>
        )}
      </Sheet>
    </Stack>
  );
}
