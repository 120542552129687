import React from "react";
import { useApi } from "../../contexts/ApiContext";
import ModalComponent from "../Modal/Modal";
import { Contact, Patient } from "../../models/types";
import Table from "@mui/joy/Table";
import Button from "@mui/joy/Button";
import Add from "@mui/icons-material/Add";
import Typography from "@mui/joy/Typography";
import CorrespondantModalForm from "./CorrespondantModalForm";
import Stack from "@mui/joy/Stack";
import ContactSelector from "../Forms/ContactSelector";
import { useSnackbar } from "../../contexts/SnackbarContext";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";

interface ContactModalProps {
  open: boolean;
  onClose: () => void;
  patient: Patient;
}

const ContactModal: React.FC<ContactModalProps> = ({
  open,
  onClose,
  patient,
}) => {
  const api = useApi();
  const snackbar = useSnackbar();

  const [openCorrespondant, setOpenCorrespondant] =
    React.useState<boolean>(false);
  const [openEditCorrespondant, setOpenEditCorrespondant] =
    React.useState<boolean>(false);
  const [contacts, setContacts] = React.useState<Contact[]>([]);
  const [selectedContact, setSelectedContact] =
    React.useState<Contact | null>();

  React.useEffect(() => {
    async function fetchContactsByPatient() {
      try {
        const res = await api.listContactsByPatient(patient.id);
        setContacts(res);
      } catch (error) {
        console.error(error);
      }
    }

    fetchContactsByPatient();
  }, [api, patient.id]);

  const onSaveContact = (contact: Contact) => {
    setContacts((prevState) => [...prevState, contact]);
  };

  const onCloseCorrespondant = () => {
    setSelectedContact(null);
    setOpenCorrespondant(false);
    setOpenEditCorrespondant(false);
  };

  const handleAddContact = () => {
    selectedContact &&
      api
        .linkContactToPatient(patient.id, [
          ...contacts.map((c) => c.id),
          selectedContact.id,
        ])
        .then(() => {
          setContacts((prevState) => [...prevState, selectedContact]);
          snackbar.show("Le contact a été ajouté", "success");
          onCloseCorrespondant();
        })
        .catch(() =>
          snackbar.show("Erreur lors de l'ajout du contact", "danger"),
        );
  };

  const handleEdit = (contact: Contact) => {
    setSelectedContact(contact);
    setOpenEditCorrespondant(true);
  };

  const handleDelete = (id: Contact["id"]) => {
    api
      .linkContactToPatient(
        patient.id,
        contacts.filter((c) => c.id !== id).map((c) => c.id),
      )
      .then(() => {
        setContacts((prevState) =>
          prevState.filter((contact) => contact.id !== id),
        );
        snackbar.show("Le contact a été supprimé", "success");
      })
      .catch(() =>
        snackbar.show("Erreur lors de la suppression du contact", "danger"),
      );
  };

  return (
    <ModalComponent
      open={open}
      onClose={onClose}
      closeLabel={"Close"}
      title={`Liste des contacts`}
      style={{ minWidth: "90%", minHeight: "80%" }}
      patient={patient}
    >
      <Stack direction={"column"} spacing={2}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={() => setOpenCorrespondant(true)}
            startDecorator={<Add />}
          >
            Ajouter Correspondant
          </Button>
        </div>
        {contacts.length > 0 ? (
          <Table sx={{ borderRadius: "sm", mt: 2 }} variant="outlined">
            <thead>
              <tr>
                <th>Nom</th>
                <th>Prénom</th>
                <th>Spécialites</th>
                <th style={{ width: "50px" }} />
              </tr>
            </thead>
            <tbody>
              {contacts.map((contact) => (
                <tr key={contact.id}>
                  <td>{contact.nom}</td>
                  <td>{contact.prenom}</td>
                  <td>{contact.correspondant?.specialite_string}</td>
                  <td>
                    <Stack direction={"row"} gap={0.5} alignItems={"center"}>
                      <Edit
                        cursor={"pointer"}
                        onClick={() => handleEdit(contact)}
                      />
                      <Delete
                        sx={{ fill: "red" }}
                        cursor={"pointer"}
                        onClick={() => handleDelete(contact.id)}
                      />
                    </Stack>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Typography
            display={"flex"}
            flex={1}
            alignItems={"center"}
            alignSelf={"center"}
          >
            Pas de correspondant pour ce patient
          </Typography>
        )}
      </Stack>
      {openCorrespondant && (
        <ModalComponent
          open={openCorrespondant}
          onClose={onCloseCorrespondant}
          patient={patient}
          title={"Ajouter un contact"}
          validateLabel={"Ajouter"}
          onValidate={handleAddContact}
          canValidate={
            !!selectedContact &&
            !contacts.some((c) => c.id === selectedContact.id)
          }
        >
          <Stack gap={2} pt={4} pb={4} width={"100%"}>
            <Typography level={"body-sm"}>
              Sélectionner un contact existant ou créer un nouveau
            </Typography>
            <ContactSelector
              value={selectedContact?.id || ""}
              onSelect={(contact, isNew) => {
                if (isNew && contact) {
                  onSaveContact(contact);
                  onCloseCorrespondant();
                } else {
                  setSelectedContact(contact);
                }
              }}
              patient={patient}
            />
            {contacts.some((c) => c.id === selectedContact?.id) && (
              <Typography
                color={"danger"}
                level={"body-sm"}
                alignSelf={"center"}
              >
                Déjà dans la liste des contacts du patient
              </Typography>
            )}
          </Stack>
        </ModalComponent>
      )}
      {openEditCorrespondant && (
        <CorrespondantModalForm
          contact={selectedContact as Contact}
          open={openEditCorrespondant}
          onClose={onCloseCorrespondant}
          patient={patient}
          onSave={onSaveContact}
        />
      )}
    </ModalComponent>
  );
};

export default ContactModal;
