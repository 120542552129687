import React, { useEffect, useMemo } from "react";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import Delete from "@mui/icons-material/Delete";
import Divider from "@mui/joy/Divider";
import Button from "@mui/joy/Button";
import Edit from "@mui/icons-material/Edit";
import PreviewFile from "../../../../components/File/PreviewFile";
import { useSnackbar } from "../../../../contexts/SnackbarContext";
import {
  ConsignesSortie,
  ConsultationType,
  DocumentLibreTemplate,
  OrdonnanceTemplate,
} from "../../../../models/types";

interface DocumentsProps<
  T extends
    | ConsignesSortie
    | ConsultationType
    | DocumentLibreTemplate
    | OrdonnanceTemplate,
> {
  documents: T[];
  buttonLabel: string;
  editDocument: (document?: T) => void;
  deleteDocument: (documentId: string) => void;
  documentLabelField: keyof T;
  documentCorpsField: keyof T;
  documentFilePathField?: keyof T | "";
}

export default function ListOfDocuments<
  T extends
    | ConsignesSortie
    | ConsultationType
    | DocumentLibreTemplate
    | OrdonnanceTemplate,
>({
  documents,
  buttonLabel,
  editDocument,
  deleteDocument,
  documentLabelField,
  documentCorpsField,
  documentFilePathField,
}: DocumentsProps<T>) {
  const snackbar = useSnackbar();
  const [currentDocument, setCurrentDocument] = React.useState<T | null>();
  const [file, setFile] = React.useState<File | null>();

  useEffect(() => {
    setCurrentDocument(documents.find((d) => d.id === currentDocument?.id));
  }, [documents, currentDocument]);

  useEffect(() => {
    const filePath =
      documentFilePathField &&
      currentDocument &&
      (currentDocument[documentFilePathField] as string);
    if (filePath !== file?.name) {
      setFile(null);
      filePath &&
        fetch(filePath).then(async (response) => {
          if (response.status !== 200) {
            snackbar.show("Impossible d'afficher fichier", "danger");
            return;
          }
          const blob = await response.blob();
          setFile(new File([blob], filePath, { type: blob.type }));
        });
    }
  }, [currentDocument, documentFilePathField, file?.name, snackbar]);

  const iconStyle = {
    color: "initial",
    cursor: "pointer",
  };

  const preview = useMemo(() => {
    if (!currentDocument) return null;

    const filePath = documentFilePathField
      ? currentDocument[documentFilePathField]
      : "";
    const corps = currentDocument[documentCorpsField];

    if (file) {
      return <PreviewFile file={file} height={"calc(100% - 68px)"} />;
    }

    if (corps && !filePath)
      return (
        <div
          style={{
            borderRadius: "8px",
            border: "1px solid #ddd",
            padding: "8px",
            height: "calc(100% - 68px)",
            overflow: "scroll",
          }}
          dangerouslySetInnerHTML={{
            __html: corps as string,
          }}
        />
      );
  }, [currentDocument, documentCorpsField, documentFilePathField, file]);

  return (
    <Stack
      sx={{
        width: "100%",
        height: "70vh",
        border: "1px solid #e3e3e3",
        borderRadius: "8px",
        p: 2,
      }}
      spacing={2}
    >
      <Stack direction="row" alignItems="center" justifyContent="end">
        <Button onClick={() => editDocument()}>{buttonLabel}</Button>
      </Stack>
      <Divider />
      <Stack sx={{ width: "100%", height: "100%" }} direction="row" gap={2}>
        <Stack
          padding={1}
          spacing={2}
          sx={{
            height: "calc(100% - 68px)",
            width: "350px",
            overflow: "scroll",
          }}
        >
          {documents.map((doc) => (
            <Stack
              key={doc.id}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
              sx={(theme) =>
                currentDocument?.id === doc.id
                  ? { backgroundColor: theme.palette.background.level2 }
                  : {}
              }
            >
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <Typography
                  sx={(theme) => ({
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: theme.palette.background.level2,
                    },
                  })}
                  level="body-sm"
                  lineHeight={1}
                  onClick={() => setCurrentDocument(doc)}
                >
                  {`${doc[documentLabelField]}`}
                </Typography>
              </Stack>
              <Stack direction={"row"} gap={0.5}>
                <Edit sx={iconStyle} onClick={() => editDocument(doc)} />
                <Delete
                  sx={{ fill: "red", cursor: "pointer" }}
                  onClick={() => deleteDocument(doc.id)}
                />
              </Stack>
            </Stack>
          ))}
        </Stack>
        <div style={{ flex: 1 }}>{preview}</div>
      </Stack>
    </Stack>
  );
}
