import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { TipsAndUpdates } from "@mui/icons-material";
import ArrowForward from "@mui/icons-material/ArrowForward";
import FileCopy from "@mui/icons-material/FileCopy";
import Print from "@mui/icons-material/Print";
import Save from "@mui/icons-material/Save";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import ModalClose from "@mui/joy/ModalClose";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import React from "react";
import PrinterAddIcon from "../../assets/icons/PrinterAddIcon";
import { useApi } from "../../contexts/ApiContext";
import { useSnackbar } from "../../contexts/SnackbarContext";
import { OperationWithinHospitalisation } from "../../models/custom";
import { isListOfOperationsWithinHospitalisation } from "../../models/type_guards";
import { Ordonnance, OrdonnanceTemplate, User } from "../../models/types";
import DoctorSelect from "../Forms/DoctorSelect";
import { OrdonnanceComponentsProps } from "./OrdonnanceList";
import { usePrintManager } from "../../contexts/PrintManagerContext";

interface OrdonnanceFormProps extends OrdonnanceComponentsProps {
  onCancel: () => void;
  onCreated: () => void;
  ordonnance?: Ordonnance;
  onPrint: () => void;
}

export default function OrdonnanceForm({
  consultationId,
  hospitalisationId,
  operationId,
  zkf_patient,
  onCancel,
  onCreated,
  ordonnance,
  onPrint,
}: OrdonnanceFormProps) {
  const api = useApi();
  const snackbar = useSnackbar();
  const printManager = usePrintManager();

  const [content, setContent] = React.useState(
    ordonnance?.corps_ordonnance || "",
  );
  const [templates, setTemplates] = React.useState<OrdonnanceTemplate[]>([]);
  const [title, setTitle] = React.useState(ordonnance?.titre_ordonnance || "");
  const [redactor, setRedactor] = React.useState<number | null>(
    ordonnance?.redacteur || null,
  );
  const [operations, setOperations] = React.useState<
    OperationWithinHospitalisation[] | null
  >(null);
  const [search, setSearch] = React.useState("");
  const [isTitleCustomized, setIsTitleCustomized] = React.useState(
    !!ordonnance?.titre_ordonnance,
  );
  const [date, setDate] = React.useState(
    ordonnance?.date_ordonnance || dayjs().format("YYYY-MM-DD"),
  );
  const [usedTemplates, setUsedTemplates] = React.useState<
    OrdonnanceTemplate["id"][]
  >([]);

  React.useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const t = await api.getOrdonnanceTemplates(
          consultationId,
          hospitalisationId,
        );
        // filter templates that are suggested, and put the suggested ones at the top of the list
        const suggestedTemplates = t.filter((template) => template.suggested);
        const otherTemplates = t.filter((template) => !template.suggested);
        setTemplates([...suggestedTemplates, ...otherTemplates]);
      } catch (e) {
        console.error(e);
        snackbar.show(
          "Erreur lors de la récupération des modèles d'ordonnance",
          "danger",
        );
      }
    };
    const fetchOperations = async () => {
      try {
        if (hospitalisationId) {
          const hospitalisation =
            await api.getHospitalisation(hospitalisationId);
          if (
            isListOfOperationsWithinHospitalisation(hospitalisation.operations)
          ) {
            setOperations(hospitalisation.operations);
          }
        }
        // if (consultationId) {
        //   const operation =
        //     await api.getOperationsOfHospitalisation(consultationId);
        //   setOperations(operation);
        // }
      } catch (e) {
        console.error(e);
        snackbar.show(
          "Erreur lors de la récupération des opérations liées",
          "danger",
        );
      }
    };
    fetchTemplates();
    fetchOperations();
  }, [api]);

  React.useEffect(() => {
    if (!isTitleCustomized && !title && content !== "") {
      setTitle("Ordonnance libre");
      setIsTitleCustomized(true);
    }
  }, [isTitleCustomized, title, content]);

  const handleTemplateSelect = (template: OrdonnanceTemplate | null) => {
    if (template) {
      setContent((prevContent) => {
        const separator = prevContent ? "<p></p>" : "";
        return prevContent + separator + (template.corps_ordonnance || "");
      });
      setUsedTemplates((prevUsedTemplates) => [
        ...prevUsedTemplates,
        template.id,
      ]);

      if (!isTitleCustomized) {
        setTitle((currentTitle) => {
          if (!currentTitle) return template.titre_ordonnance;
          return `${currentTitle} & ${template.titre_ordonnance}`;
        });
      }
    }
  };

  const handleOperationInsert = (operation: OperationWithinHospitalisation) => {
    setContent((prevContent) => {
      const separator = prevContent ? "<p></p>" : "";
      const operationDate = dayjs(operation.operation_date)
        .locale("fr")
        .format("DD MMMM YYYY");
      const operationLine = `- ${operation.titre_operation} (${operationDate})`;

      // Check if "Opérations liées" section already exists
      if (prevContent.includes("Opérations liées :")) {
        // Insert the new operation at the end of the list
        return prevContent + "<p>" + operationLine + "</p>";
      }

      // Create new section with header and first operation
      return (
        prevContent +
        separator +
        "<p><strong><u>Opérations liées :</u></strong></p>" +
        "<p>" +
        operationLine +
        "</p>"
      );
    });
  };

  const saveOrdonnance = async () => {
    const payload: Partial<Ordonnance> = {
      corps_ordonnance: content,
      titre_ordonnance: title,
      zkf_consultation: consultationId,
      zkf_hospitalisation: hospitalisationId,
      zkf_operation: operationId,
      date_ordonnance: date,
      redacteur: redactor,
      zkf_patient: zkf_patient,
      zkf_ordo_template: usedTemplates[0],
    };

    try {
      if (ordonnance) {
        await api.putOrdonnance(ordonnance.id, {
          ...ordonnance,
          ...payload,
        });
        snackbar.show("Ordonnance mise à jour avec succès", "success");
      } else {
        await api.postOrdonnance(payload);
        snackbar.show("Ordonnance créée avec succès", "success");
      }
      onCreated();
    } catch (e) {
      console.error(e);
      snackbar.show(
        `Erreur lors de la ${ordonnance ? "mise à jour" : "création"} de l'ordonnance`,
        "danger",
      );
    }
  };

  const isFormValid = () => {
    return title.trim() !== "" && content.trim() !== "";
  };

  function addToPrintManager() {
    printManager.addToPrintManager("ordonnance", ordonnance!.id);
    snackbar.show("Ordonnance ajoutée au gestionnaire d'impression", "success");
  }

  return (
    <Stack
      sx={{
        width: "calc(100vw - 32px)",
        height: "calc(100vh - 32px)",
        overflow: "hidden",
        pr: 6,
      }}
      direction="column"
      gap={2}
    >
      <Box sx={{ flex: "1 1 0", overflow: "hidden" }}>
        <ModalClose />
        <Typography level="h4" sx={{ mb: 2 }}>
          {ordonnance ? "Modifier l'ordonnance" : "Nouvelle ordonnance"}
        </Typography>

        <Stack
          direction="row"
          gap={2}
          sx={{
            height: "calc(100% - 48px)",
            overflow: "hidden",
          }}
        >
          {/* Left Column */}
          <Stack
            gap={2}
            sx={{
              width: 300,
              overflow: "auto",
              pr: 2,
            }}
          >
            <FormControl>
              <FormLabel>Ajouter un modèle</FormLabel>
              <Input
                placeholder="Angiographie de la jambe droite..."
                startDecorator={<FileCopy sx={{ fontSize: "md" }} />}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Box
                sx={{
                  mt: 1,
                  height: 250,
                  overflow: "auto",
                  border: "1px solid",
                  borderColor: "divider",
                  borderRadius: "sm",
                }}
              >
                <Stack>
                  {templates
                    .filter((template) =>
                      template.titre_ordonnance
                        .toLowerCase()
                        .includes(search.toLowerCase()),
                    )
                    .map((template) => (
                      <Stack
                        key={template.id}
                        direction="row"
                        alignItems="center"
                        gap={0.5}
                        sx={{
                          p: 1,
                          "&:hover": {
                            bgcolor: "background.level1",
                          },
                          borderBottom: "1px solid",
                          borderColor: "divider",
                        }}
                      >
                        <Box sx={{ flex: 1 }}>
                          <Typography
                            level="body-sm"
                            lineHeight="1"
                            startDecorator={
                              template.suggested ? (
                                <TipsAndUpdates />
                              ) : undefined
                            }
                          >
                            <Stack>
                              {template.suggested && (
                                <Typography level="body-xs">suggeré</Typography>
                              )}
                              {template.titre_ordonnance}
                            </Stack>
                          </Typography>
                        </Box>
                        <IconButton
                          size="sm"
                          variant="plain"
                          color="neutral"
                          onClick={() => handleTemplateSelect(template)}
                        >
                          <ArrowForward />
                        </IconButton>
                      </Stack>
                    ))}
                </Stack>
              </Box>
            </FormControl>

            <FormControl>
              <FormLabel>Date de l'ordonnance</FormLabel>
              <Input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Rédacteur</FormLabel>
              <DoctorSelect
                value={redactor}
                onSelect={(redactor) => setRedactor((redactor as User).id)}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Opérations liées</FormLabel>
              <Stack gap={1}>
                {operations && operations.length > 0 ? (
                  <Box
                    sx={{
                      border: "1px solid",
                      borderColor: "divider",
                      borderRadius: "sm",
                    }}
                  >
                    {operations.map((operation) => (
                      <Stack
                        key={operation.id}
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{
                          p: 1,
                          "&:hover": {
                            bgcolor: "background.level1",
                          },
                          borderBottom: "1px solid",
                          borderColor: "divider",
                          "&:last-child": {
                            borderBottom: "none",
                          },
                        }}
                      >
                        <Box sx={{ flex: 1 }}>
                          <Typography level="body-sm">
                            {operation.titre_operation}
                          </Typography>
                          <Typography level="body-xs">
                            {dayjs(operation.operation_date).format(
                              "DD/MM/YYYY",
                            )}
                          </Typography>
                        </Box>
                        <Button
                          size="sm"
                          variant="plain"
                          color="neutral"
                          onClick={() => handleOperationInsert(operation)}
                        >
                          <ArrowForward />
                        </Button>
                      </Stack>
                    ))}
                  </Box>
                ) : (
                  <Typography level="body-sm">
                    Aucune opération liée trouvée.
                  </Typography>
                )}
              </Stack>
            </FormControl>
          </Stack>

          {/* Right Column */}
          <Stack sx={{ flex: 1, overflow: "hidden" }} gap={2}>
            <FormControl>
              <FormLabel>Titre de l'ordonnance</FormLabel>
              <Input
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setIsTitleCustomized(true);
                }}
                slotProps={{
                  input: {
                    onBlur: (e) => {
                      if (!e.target.value.trim()) {
                        setIsTitleCustomized(false);
                      }
                    },
                  },
                }}
              />
            </FormControl>

            <Box sx={{ flex: 1, overflow: "hidden" }}>
              <CKEditor
                editor={ClassicEditor}
                data={content}
                config={{
                  initialData: content,
                }}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "200%",
                      editor.editing.view.document.getRoot()!,
                    );
                  });
                }}
                onChange={(e, editor) => {
                  setContent(editor.getData());
                }}
              />
            </Box>
          </Stack>
        </Stack>
      </Box>

      <Stack
        direction="row"
        gap={1}
        justifyContent="flex-end"
        sx={{
          borderTop: "1px solid",
          borderColor: "divider",
          pt: 2,
        }}
      >
        <Button
          variant="outlined"
          color="neutral"
          startDecorator={<Print />}
          disabled={!isFormValid()}
          onClick={() => {
            onPrint();
            onCancel();
          }}
        >
          Imprimer
        </Button>
        <Button
          variant="outlined"
          color="neutral"
          startDecorator={<PrinterAddIcon />}
          disabled={!isFormValid()}
          onClick={() => {
            addToPrintManager();
          }}
        >
          Ajouter au gest. d'impression
        </Button>
        <Button
          startDecorator={<Save />}
          onClick={saveOrdonnance}
          disabled={!isFormValid()}
        >
          {ordonnance ? "Mettre à jour" : "Ajouter l'ordonnance"}
        </Button>
      </Stack>
    </Stack>
  );
}
