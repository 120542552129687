import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import React from "react";

interface BulkEditModalProps {
  onEdit: (value: number, asPercent?: boolean) => void;
}

export default function BulkEditModal({ onEdit }: BulkEditModalProps) {
  const [fixed, setFixed] = React.useState<number>(0);
  const [percent, setPercent] = React.useState<number>(0);

  const send = () => {
    //if valeur and percentage=>use percentage
    if (fixed && percent) {
      onEdit(percent, true);
    }
    //if valeur=>use valeur
    else if (fixed) {
      onEdit(fixed);
    }
    //if percentage=>use percentage
    else if (percent) {
      onEdit(percent, true);
    }
    //else=>use percent = 0%
    else {
      onEdit(percent, true);
    }
  };

  return (
    <Stack direction="column" spacing={2}>
      <FormControl>
        <FormLabel>Appliquer un dépassement fixe pour chaque acte</FormLabel>
        <Input
          endDecorator="€"
          value={fixed}
          onChange={(e) => {
            setFixed(Number(e.target.value));
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Appliquer un dépassement en % global</FormLabel>
        <Input
          endDecorator="%"
          value={percent}
          onChange={(e) => {
            setPercent(Number(e.target.value));
          }}
        />
      </FormControl>
      <Button onClick={send}>Enregistrer</Button>
    </Stack>
  );
}
