import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import Print from "@mui/icons-material/Print";
import Search from "@mui/icons-material/Search";
import Autocomplete from "@mui/joy/Autocomplete";
import Button from "@mui/joy/Button";
import CircularProgress from "@mui/joy/CircularProgress";
import DialogTitle from "@mui/joy/DialogTitle";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalOverflow from "@mui/joy/ModalOverflow";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import dayjs from "dayjs";
import debounce from "lodash/debounce";
import React from "react";
import ActeExploration from "../../../../components/Quotes/ActeExploration";
import BulkEditModal from "../../../../components/Quotes/BulkEditModal";
import ClaimList from "../../../../components/Quotes/ClaimList";
import EditClaim, {
  Activite,
  Grille,
} from "../../../../components/Quotes/EditClaim";
import { usePrintManager } from "../../../../contexts/PrintManagerContext";
import { useApi } from "../../../../contexts/ApiContext";
import { useSnackbar } from "../../../../contexts/SnackbarContext";
import { useAuthenticatedUser } from "../../../../contexts/UserContext";
import { isActivite } from "../../../../models/type_guards";
import { Acte, Devis, OperationDevisCcam } from "../../../../models/types";
import { roundTo2Decimals } from "../../../../utils/utils";
import ModalComponent from "../../../../components/Modal/Modal";
import PDFPreviewAndPrint from "../../../../components/PDF/PDFPreview";

// import { set } from "lodash";

interface QuoteEditProps {
  operationId: string;
  patientId: string;
}

export default function QuoteEdit({ operationId, patientId }: QuoteEditProps) {
  // Context
  const api = useApi();
  const { profile } = useAuthenticatedUser();
  const snackbar = useSnackbar();
  const { refreshPrintManager } = usePrintManager();

  // Get user's preference
  const defaultUserActivite =
    isActivite(profile.activite_ccam) && profile.activite_ccam?.cod_activ;
  const defaultUserGrille = profile.conventionnement || null;

  // States
  const [claims, setClaims] = React.useState<OperationDevisCcam[]>([]);
  const [activiteOptions, setActiviteOptions] = React.useState<Activite[]>([]);
  const [grilleOptions, setGrilleOptions] = React.useState<Grille[]>([]);
  const [quotes, setQuotes] = React.useState<Devis[]>([]);
  const [creatingQuote, setCreatingQuote] = React.useState(false);

  // Modal states
  // An "acte" is selected from the search result -> it is not a claim yet
  // A claim is selected from the list of "actes" in the quote
  const [bulkActionModalOpen, setBulkActionModalOpen] =
    React.useState<boolean>(false);
  const [loadingActeRequest, setLoadingActeRequest] =
    React.useState<boolean>(false);
  const [loadingOptions, setLoadingOptions] = React.useState<boolean>(false);
  const [selectedActe, setSelectedActe] = React.useState<Acte | null>(null);
  const [selectedClaim, setSelectedClaim] =
    React.useState<OperationDevisCcam | null>(null);
  const [selectedQuote, setSelectedQuote] = React.useState<Devis | null>(null);

  // Autocomplete states
  const [options, setOptions] = React.useState<Acte[]>([]);
  const [inputValue, setInputValue] = React.useState<string>("");

  const onSelectNewClaim = async (newClaim: Acte) => {
    if (!newClaim.cod_acte) return;
    setLoadingActeRequest(true);
    setSelectedActe(newClaim);
  };

  const onAddActe = async (
    acte: Acte,
    associatedActes: Acte[],
    modificateurs?: string,
  ) => {
    if (selectedQuote) {
      const acteActivite = activiteOptions.find(
        (a) => a.id === defaultUserActivite,
      );
      const acteGrille = grilleOptions.find((g) => g.id === defaultUserGrille);
      console.log("cgrille", defaultUserGrille);
      console.log(profile);
      const payloadPrincipal = {
        acte_ccam: acte.cod_acte,
        acte_nom_long: acte.nom_long,
        acte_activite: acteActivite ? Number(acteActivite.cod_activ) : 1,
        acte_grille: acteGrille ? Number(acteGrille.cod_grille) : 1,
        acte_phase: 0,
        prix_base: acte.pu_base,
        depassement_honoraire_acte: "0",
        acte_modificateur: modificateurs || "",
      } as OperationDevisCcam;

      const actePrincipal = await api.postClaimOnQuote(
        payloadPrincipal,
        selectedQuote.id,
      );

      const promises: Promise<OperationDevisCcam>[] = [];
      associatedActes.forEach((acte) => {
        // For each associated acte, create a claim linked to the principal claim (not acte)
        const payload = {
          acte_ccam: acte.cod_acte,
          acte_nom_long: acte.nom_long,
          acte_activite: acteActivite ? Number(acteActivite.cod_activ) : 1,
          acte_grille: acteGrille ? Number(acteGrille.cod_grille) : 1,
          acte_phase: 0,
          prix_base: acte.pu_base,
          depassement_honoraire_acte: "0",
          zkf_acte: actePrincipal.id,
        } as OperationDevisCcam;
        promises.push(api.postClaimOnQuote(payload, selectedQuote.id));
      });

      try {
        if (selectedQuote) {
          await Promise.all(promises);
          await fetchClaims(selectedQuote.id);
          snackbar.show("Acte(s) ajouté(s) au devis", "success");
        }
      } catch (error: any) {
        console.error(error);
        snackbar.show("Erreur lors de l'ajout de l'acte", "danger");
      } finally {
        setInputValue("");
        setLoadingActeRequest(true);
        setSelectedActe(null);
        handleSelectedActeModalClose();
      }
    }
  };

  const fetchCCAM = React.useCallback(
    async (query: string) => {
      setLoadingOptions(true);
      if (query.length === 0) {
        setOptions([]);
        return;
      }
      try {
        const res = await api.searchCCAM(query);
        setOptions(res);
      } catch (error: any) {
        console.error(error);
        snackbar.show("Erreur lors de la recherche", "danger");
      } finally {
        setLoadingOptions(false);
      }
    },
    [api, snackbar],
  );

  const debouncedFetchCCAM = React.useMemo(
    () => debounce(fetchCCAM, 300),
    [fetchCCAM],
  );

  //Print devis
  const [fileBlobURL, setFileBlobURL] = React.useState<string>("");
  const [openPrintModal, setOpenPrintModal] = React.useState<boolean>(false);

  React.useEffect(() => {
    return () => {
      debouncedFetchCCAM.cancel();
    };
  }, [debouncedFetchCCAM]);

  const handleDeleteClaim = async (claim: OperationDevisCcam) => {
    try {
      await api.removeClaimFromQuote(claim.id);
      snackbar.show("Acte supprimé du devis", "success");
      // filter out the claim from the list, and also all the associated claims
      setClaims(
        claims.filter((c) => c.id !== claim.id && c.zkf_acte !== claim.id),
      );
    } catch (error) {
      console.error(error);
      snackbar.show("Erreur lors de la suppression de l'acte", "danger");
    }
  };

  const handleEditClaim = async (claim: OperationDevisCcam) => {
    if (!selectedQuote) return;
    try {
      const updatedClaim = await api.updateClaimOnQuote(claim);
      snackbar.show("Acte modifié", "success");
      // Update the claim in the list
      setClaims(
        claims.map((c) => {
          if (c.id === claim.id) {
            return claim;
          }
          return c;
        }),
      );
      setSelectedClaim(updatedClaim);
    } catch (error) {
      console.error(error);
      snackbar.show("Erreur lors de la modification de l'acte", "danger");
    }
  };

  const onEditClaim = (claim: OperationDevisCcam) => {
    setSelectedClaim(claim);
  };

  const fetchClaims = React.useCallback(
    async (quoteId: string) => {
      try {
        const claims = await api.getClaimsFromQuote(quoteId);
        // Sort claims by prix de base
        setClaims(
          claims.sort(
            (a, b) =>
              parseFloat(b.prix_base || "0") - parseFloat(a.prix_base || "0"),
          ),
        );
      } catch (e) {
        console.warn(e);
        snackbar.show("Erreur lors de la récupération des actes", "danger");
      }
    },
    [api, snackbar],
  );

  const fetchQuotes = React.useCallback(async () => {
    try {
      const quotes = await api.getListQuotes(operationId);
      setQuotes(quotes.results);
      if (quotes.results.length > 0) {
        setSelectedQuote(quotes.results[0]);
        fetchClaims(quotes.results[0].id);
      }
    } catch (e) {
      console.warn(e);
      snackbar.show("Erreur lors de la récupération des devis", "danger");
    }
  }, [api, operationId, snackbar, fetchClaims]);

  const createQuote = async () => {
    try {
      setCreatingQuote(true);
      const quote = await api.createQuote(operationId, patientId);
      // then fetch quotes again
      // add the quote to the list
      setQuotes([...quotes, quote]);
      setSelectedQuote(quote);
      snackbar.show("Devis créé avec succès", "success");
      setCreatingQuote(false);
    } catch (e) {
      setCreatingQuote(false);
      console.warn("error creating quote");
      snackbar.show("Erreur lors de la création du devis", "danger");
    }
  };

  React.useEffect(() => {
    // fetch quotes to init the list
    fetchQuotes();
  }, [fetchQuotes]);

  React.useEffect(() => {
    const fetchActiviteAndGrille = async () => {
      try {
        const res = await api.getModificateur();
        setActiviteOptions(
          res.activite.map((a: any) => {
            return {
              id: a.id,
              cod_activ: a.cod_activ,
              libelle: a.libelle,
            };
          }),
        );
        setGrilleOptions(
          res.grille.map((g: any) => {
            return {
              id: g.id,
              cod_grille: g.cod_grille,
              libelle: g.libelle,
            };
          }),
        );
      } catch (error: any) {
        console.error(error);
        snackbar.show("Erreur lors de la récupération des actes", "danger");
      }
    };
    fetchActiviteAndGrille();
  }, [api, snackbar]);

  const handleDeleteQuote = async () => {
    if (selectedQuote) {
      try {
        await api.deleteQuote(selectedQuote.id);
        snackbar.show("Devis supprimé", "success");
        setSelectedQuote(null);
        setClaims([]);
        setQuotes(quotes.filter((q) => q.id !== selectedQuote.id));
        fetchQuotes();
      } catch (error) {
        console.error(error);
        snackbar.show("Erreur lors de la suppression du devis", "danger");
      }
    }
  };

  const handleBulkHonoraireEdit = async (
    value: number,
    asPercent?: boolean,
  ) => {
    // for each claim, update the depassement_honoraire_acte field with the new value
    if (!selectedQuote) return;
    const updatedClaims = claims.map((claim) => {
      let newClaim = { ...claim };
      if (asPercent) {
        newClaim.depassement_honoraire_acte = String(
          roundTo2Decimals((Number(claim.prix_modificateurs) * value) / 100),
        );
      } else {
        newClaim.depassement_honoraire_acte = String(roundTo2Decimals(value));
      }
      return newClaim;
    });
    // then update the claims by calling the API for each claim
    try {
      await Promise.all(
        updatedClaims.map((claim) => api.updateClaimOnQuote(claim)),
      );
      setClaims(updatedClaims);
      snackbar.show("Dépassement honoraires modifiés", "success");
      setBulkActionModalOpen(false);
    } catch (error) {
      console.error(error);
      snackbar.show("Erreur lors de la modification des honoraires", "danger");
    }
  };

  const handleSelectedActeModalClose = () => {
    setSelectedActe(null);
    setInputValue("");
    setLoadingActeRequest(false);
  };

  const handleAddToPrintManager = () => {
    if (!selectedQuote) return;
    // add all claims to the print manager
    try {
      api.addToPrintManager("devis", selectedQuote.id);
      snackbar.show("Actes ajoutés au gestionnaire d'impression", "success");
      refreshPrintManager();
    } catch (error) {
      console.error(error);
      snackbar.show(
        "Erreur lors de l'ajout des actes au gestionnaire d'impression",
        "danger",
      );
    }
  };

  const printDevis = async () => {
    if (!selectedQuote) return;
    api
      .printManagerPrintAll([
        {
          id: selectedQuote.id,
          model_app_datas: selectedQuote?.model_app_datas,
          type_document: "devis",
        },
      ])
      .then((blob) => {
        setFileBlobURL(window.URL.createObjectURL(blob));
        setOpenPrintModal(true);
      });
  };

  const renderPrintModal = () => (
    <ModalComponent
      open={openPrintModal}
      onClose={() => setOpenPrintModal(false)}
      title={"Imprimer"}
      style={{ minWidth: "60%", minHeight: "600px" }}
      withCloseButton={false}
    >
      <div style={{ flex: 1, height: "600px" }}>
        <PDFPreviewAndPrint fileUrl={fileBlobURL} />
      </div>
    </ModalComponent>
  );

  return (
    <>
      {selectedQuote ? (
        <Stack direction="row" gap={4}>
          <Stack flex={1} direction="column" spacing={2}>
            <div>
              <Stack direction="column" gap={2}>
                <Typography level="h4">
                  Devis du {selectedQuote.date_devis}
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <FormControl>
                    <FormLabel>Ajouter un acte au devis</FormLabel>
                    <Stack direction="row" gap={1} alignItems="center">
                      <Autocomplete
                        sx={{ minWidth: 500 }}
                        placeholder="Entrez du texte pour lancer la recherche"
                        noOptionsText={
                          !!inputValue
                            ? loadingOptions
                              ? "Chargement..."
                              : "Aucun résultat"
                            : "Recherchez un acte dans la barre ci-dessus"
                        }
                        options={options}
                        getOptionLabel={(option) =>
                          `${option.cod_acte} - ${option.nom_long}`
                        }
                        inputValue={inputValue}
                        onInputChange={(event, newValue) => {
                          setInputValue(newValue);
                          debouncedFetchCCAM(newValue);
                        }}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            onSelectNewClaim(newValue);
                          }
                        }}
                        disabled={loadingActeRequest}
                        startDecorator={<Search />}
                      />
                    </Stack>
                  </FormControl>
                  {claims.length > 1 && (
                    <div>
                      <Button
                        variant="soft"
                        startDecorator={<Edit />}
                        size="sm"
                        onClick={() => setBulkActionModalOpen(true)}
                      >
                        Modification groupée
                      </Button>
                    </div>
                  )}
                </Stack>
              </Stack>
              {claims.length === 0 ? (
                <Stack
                  sx={{ height: 300 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    level="title-md"
                    sx={{
                      textAlign: "center",
                      alignItems: "center",
                    }}
                  >
                    Aucun acte présent sur le devis
                  </Typography>
                  <Typography
                    level="body-sm"
                    sx={{
                      textAlign: "center",
                      alignItems: "center",
                    }}
                  >
                    Vous pouvez ajouter un acte en utilisant la barre de
                    recherche à droite
                  </Typography>
                </Stack>
              ) : (
                <ClaimList
                  claims={claims}
                  onEditClaim={onEditClaim}
                  onDeleteClaim={handleDeleteClaim}
                />
              )}
              <Modal
                open={bulkActionModalOpen}
                onClose={() => setBulkActionModalOpen(false)}
              >
                <ModalOverflow>
                  <ModalDialog sx={{ width: "500px" }}>
                    <ModalClose />
                    <DialogTitle>
                      Modification groupée des honoraires
                    </DialogTitle>
                    <BulkEditModal onEdit={handleBulkHonoraireEdit} />
                  </ModalDialog>
                </ModalOverflow>
              </Modal>
              <Modal
                open={!!selectedActe}
                onClose={handleSelectedActeModalClose}
              >
                <ModalOverflow>
                  <ModalDialog sx={{ width: "800px" }}>
                    <ModalClose />
                    {selectedActe && (
                      <ActeExploration
                        acte={selectedActe}
                        onAddActe={onAddActe}
                      />
                    )}
                  </ModalDialog>
                </ModalOverflow>
              </Modal>
              <Modal
                open={!!selectedClaim}
                onClose={() => setSelectedClaim(null)}
              >
                <ModalOverflow>
                  <ModalDialog sx={{ width: "500px" }}>
                    <ModalClose />
                    {selectedClaim && (
                      <EditClaim
                        claim={selectedClaim}
                        onSaveClaim={handleEditClaim}
                        activiteOptions={activiteOptions}
                        grilleOptions={grilleOptions}
                      />
                    )}
                  </ModalDialog>
                </ModalOverflow>
              </Modal>
            </div>
          </Stack>
          <Stack direction="column" gap={2} sx={{ width: "33%" }}>
            <Sheet
              variant="outlined"
              sx={{ p: 2, minWidth: "350px", borderRadius: "sm" }}
            >
              <Stack direction="column" gap={2}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography level="title-md">Date du devis</Typography>
                  <Button
                    variant="soft"
                    startDecorator={<Add />}
                    size="sm"
                    onClick={createQuote}
                  >
                    Créer un devis
                  </Button>
                </Stack>
                <Select
                  value={selectedQuote}
                  onChange={(e, newQuote) => {
                    setSelectedQuote(newQuote);
                    if (newQuote) {
                      fetchClaims(newQuote.id);
                    }
                  }}
                  renderValue={
                    (selected) =>
                      selected
                        ? `Devis du ${selected.value.date_devis}`
                        : "Select a quote" // Custom text
                  }
                >
                  {quotes.map((quote) => (
                    <Option key={quote.id} value={quote}>
                      <Stack direction="column">
                        <Typography level="body-xs">
                          Créé le{" "}
                          {dayjs(quote.created_on).format("DD/MM/YYYY à HH:mm")}
                        </Typography>
                        <Typography level="body-md">
                          Devis du {quote.date_devis}
                        </Typography>
                      </Stack>
                    </Option>
                  ))}
                </Select>
              </Stack>
            </Sheet>
            <Stack gap={1}>
              <Stack direction="row" gap={1}>
                <Button
                  sx={{ flex: 1 }}
                  size="sm"
                  color="neutral"
                  variant="soft"
                  startDecorator={<Print />}
                  onClick={handleAddToPrintManager}
                >
                  Ajouter au gest. d'impressions
                </Button>
                <Button
                  sx={{ flex: 1 }}
                  size="sm"
                  color="primary"
                  variant="soft"
                  startDecorator={<Print />}
                  onClick={printDevis}
                >
                  Imprimer
                </Button>
              </Stack>
              <Button
                color="danger"
                variant="soft"
                size="sm"
                startDecorator={<Delete />}
                onClick={handleDeleteQuote}
              >
                Supprimer le devis
              </Button>
            </Stack>
          </Stack>
          {renderPrintModal()}
        </Stack>
      ) : (
        <Stack
          sx={{ minHeight: 300 }}
          direction="column"
          gap={2}
          textAlign="center"
          justifyContent="center"
          alignItems="center"
        >
          <Stack direction="column" gap={2}>
            <Typography level="body-lg">
              Il n'y a pas de devis pour cette opération.
            </Typography>
            <Button
              startDecorator={
                creatingQuote ? <CircularProgress size="sm" /> : <Add />
              }
              disabled={creatingQuote}
              onClick={createQuote}
            >
              Créer un devis
            </Button>
          </Stack>
        </Stack>
      )}
    </>
  );
}
