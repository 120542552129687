import React from "react";
import { User } from "../models/types";

export type SessionPreferences = {
  selectedUsers: User["id"][];
};

type SessionPreferencesContextType = {
  data: SessionPreferences;
  setData: (data: Partial<SessionPreferences>) => void;
};

const SessionPreferencesContext =
  React.createContext<SessionPreferencesContextType | null>(null);

export const useSessionPreferences = () => {
  const context = React.useContext(SessionPreferencesContext);
  if (!context) {
    throw new Error(
      "useSessionPreferences must be used within a SessionPreferencesProvider",
    );
  }
  return context;
};

export const SessionPreferencesProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [d, setD] = React.useState<SessionPreferences>({
    selectedUsers: [],
  });

  const setData = (newData: Partial<SessionPreferences>) => {
    // allow user to set only one property at a time
    setD((prevData) => ({ ...prevData, ...newData }));
    localStorage.setItem("session_preferences", JSON.stringify(d));
  };

  React.useEffect(() => {
    // load data from local storage
    const storedData = localStorage.getItem("session_preferences");
    if (storedData) {
      setD(JSON.parse(storedData));
    }
  }, []);

  return (
    <SessionPreferencesContext.Provider value={{ data: d, setData }}>
      {children}
    </SessionPreferencesContext.Provider>
  );
};

export default SessionPreferencesContext;
