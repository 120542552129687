import Add from "@mui/icons-material/Add";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Checkbox from "@mui/joy/Checkbox";
import Sheet from "@mui/joy/Sheet";
import Skeleton from "@mui/joy/Skeleton";
import Stack from "@mui/joy/Stack";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import React from "react";
import { useApi } from "../../contexts/ApiContext";
import { useSnackbar } from "../../contexts/SnackbarContext";
import { ActeSearchResponse } from "../../models/custom";
import { Acte } from "../../models/types";
import { formatNumbersToFrench } from "../../utils/utils";
import ModificateursInput from "../Forms/ModificateursInput";
import { useAuthenticatedUser } from "../../contexts/UserContext";

interface ActeExplorationProps {
  acte: Acte;
  onAddActe: (
    acte: Acte,
    associatedActes: Acte[],
    modificateurs: string,
  ) => void;
}

export default function ActeExploration({
  acte,
  onAddActe,
}: ActeExplorationProps) {
  const api = useApi();
  const snackbar = useSnackbar();
  const { profile } = useAuthenticatedUser();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [selectedAssociations, setSelectedAssociations] = React.useState<
    Acte[]
  >([]);
  const [options, setOptions] = React.useState<ActeSearchResponse | null>(null);
  const [completedActe, setCompletedActe] = React.useState<Acte | null>(null);
  const [modificateurs, setModificateurs] = React.useState<string[] | null>(
    null,
  );
  const [selectedModificateurs, setSelectedModificateurs] = React.useState<
    string[]
  >([]);

  React.useEffect(() => {
    const fetchAssociations = async () => {
      setLoading(true);
      try {
        const res = await api.getCCAM(acte.cod_acte!);
        setOptions(res);
        // get complementary data for the acte, such as price
        setCompletedActe(
          res.acte.filter((a) => a.cod_acte === acte.cod_acte)[0],
        );
        // get available modificateurs for the acte
        const m = await api.getModificateur(
          acte.cod_acte!,
          // user?.profile?.activite_ccam?.cod_activ || undefined,
          "1",
        );
        // console.log(m.R_activite_modificateur);
        setModificateurs(
          m.R_activite_modificateur.map((mod: any) => mod.modifi_cod).sort(),
        );
      } catch (error) {
        snackbar.show(
          "Erreur lors de la récupération des associations",
          "danger",
        );
      } finally {
        setLoading(false);
      }
    };
    fetchAssociations();
  }, [acte, api, snackbar]);

  const handleSubmit = () => {
    if (completedActe) {
      onAddActe(
        completedActe,
        selectedAssociations,
        selectedModificateurs.join(","),
      );
    }
  };

  return (
    <Stack direction="column" spacing={2}>
      <Typography level="h4">Ajouter un acte au devis</Typography>
      {loading ? (
        <Box sx={{ height: 120 }}>
          <Skeleton
            animation="wave"
            sx={{
              height: "120px",
              width: "calc(100% - 40px)",
            }}
          />
        </Box>
      ) : (
        <Sheet sx={{ p: 2, borderRadius: "md" }} variant="soft">
          <Stack gap={1}>
            <Typography level="body-xs">ACTE PRINCIPAL</Typography>
            <Typography level="body-md">{acte.nom_long}</Typography>
            <Stack
              direction="row"
              gap={2}
              justifyContent="space-between"
              alignItems="flex-end"
            >
              {modificateurs ? (
                <Stack gap={0.5}>
                  <Typography level="body-sm">
                    <small>MODIFICATEURS</small>&nbsp;
                  </Typography>
                  <ModificateursInput
                    value={selectedModificateurs.join(",")}
                    onChange={(value) => {
                      setSelectedModificateurs(value.split(","));
                    }}
                    modificateurs={modificateurs || []}
                    defaultValues={
                      profile.modificateurs_default
                        ? profile.modificateurs_default.split(",")
                        : []
                    }
                  />
                </Stack>
              ) : (
                "loding"
              )}
              <Typography level="body-sm" textAlign="right">
                <small>PRIX BASE</small>&nbsp;
                {formatNumbersToFrench(Number(completedActe?.pu_base || 0))}€
              </Typography>
            </Stack>
          </Stack>
        </Sheet>
      )}
      <Typography level="title-lg">Ajouter des actes associés</Typography>
      <Box sx={{ minHeight: 400 }}>
        {loading ? (
          <Stack gap={1}>
            {Array.from({ length: 7 }).map((_, i) => (
              <Box key={i} sx={{ height: 50 }}>
                <Skeleton
                  animation="wave"
                  sx={{
                    height: "50px",
                    width: "calc(100% - 40px)",
                  }}
                />
              </Box>
            ))}
          </Stack>
        ) : options && options.associations.length > 0 ? (
          <Stack direction="column" spacing={2}>
            <Table>
              <thead>
                <tr>
                  <th style={{ width: "50px" }}></th>
                  <th style={{ width: "100px" }}>Code</th>
                  <th>Libellé</th>
                  <th style={{ width: "150px" }}>Modificateurs</th>
                  <th style={{ width: "100px" }}>Prix base</th>
                </tr>
              </thead>
              <tbody>
                {options!.associations.map((association, index) => (
                  <tr key={index}>
                    <td>
                      <Checkbox
                        checked={
                          selectedAssociations.findIndex(
                            (a) => a.cod_acte === association.cod_acte,
                          ) > -1
                        }
                        onChange={(e) => {
                          // if checked, add the acte to the list of associations in newActeWidthAssociations
                          // otherwise remove it
                          if (e.target.checked) {
                            setSelectedAssociations([
                              ...selectedAssociations,
                              association,
                            ]);
                          } else {
                            setSelectedAssociations(
                              selectedAssociations.filter(
                                (a) => a.cod_acte !== association.cod_acte,
                              ),
                            );
                          }
                        }}
                      />
                    </td>
                    <td>{association.cod_acte}</td>
                    <td>
                      <Typography level="body-sm">
                        {association.nom_long}
                      </Typography>
                    </td>
                    <td></td>
                    <td>
                      {formatNumbersToFrench(Number(association.pu_base))}€
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Stack>
        ) : (
          <Typography level="body-md">Aucune association trouvée</Typography>
        )}
      </Box>
      <Stack direction="row" justifyContent="flex-end">
        {loading ? (
          <Box sx={{ height: 35, width: 100 }}>
            <Skeleton
              animation="wave"
              sx={{
                height: "35px",
                width: "100px",
              }}
            />
          </Box>
        ) : (
          <Button onClick={handleSubmit} startDecorator={<Add />}>
            Ajouter au devis
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
