import { useApi } from "../../../../contexts/ApiContext";
import { useSnackbar } from "../../../../contexts/SnackbarContext";
import ListOfDocuments from "./ListOfDocuments";
import { ConsultationType } from "../../../../models/types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ModalComponent from "../../../../components/Modal/Modal";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Input from "@mui/joy/Input";
import Divider from "@mui/joy/Divider";
import Editor from "../../../../components/Editor/Editor";
import { useUser } from "../../../../contexts/UserContext";
import FieldsPanel from "./fieldsPanel";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function ConsultationTab() {
  const api = useApi();
  const user = useUser();
  const snackbar = useSnackbar();

  const [consultationsType, setConsultationsType] = useState<
    ConsultationType[]
  >([]);
  const [editConsultationType, setEditConsultationType] =
    useState<ConsultationType>();
  const [titre, setTitre] = useState<string>("");
  const [corps, setCorps] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [editorRef, setEditorRef] = React.useState<ClassicEditor>();

  useEffect(() => {
    api.getConsultationType().then((res) => setConsultationsType(res));
  }, [api]);

  const handleEdit = useCallback((consultation?: ConsultationType) => {
    setTitre(consultation?.titre || "");
    setCorps(consultation?.corps || "");
    setEditConsultationType(consultation);
    setOpenModal(true);
  }, []);

  const handleDelete = useCallback(
    (id: string) => {
      api
        .deleteConsultationType(id)
        .then(() =>
          setConsultationsType(consultationsType.filter((c) => c.id !== id)),
        )
        .catch(() =>
          snackbar.show("Impossible de supprimer la consultation", "danger"),
        );
    },
    [api, consultationsType, snackbar],
  );

  const handleSave = useCallback(() => {
    const newConsultation: Partial<ConsultationType> = {
      titre,
      corps,
      zkf_user: user.user?.id,
    };
    if (editConsultationType) {
      api
        .updateConsultationType({
          id: editConsultationType.id,
          ...newConsultation,
        })
        .then((res) => {
          setConsultationsType((prevState) =>
            prevState.map((c) => (c.id === editConsultationType.id ? res : c)),
          );
          setOpenModal(false);
        })
        .catch(() =>
          snackbar.show("Impossible de modifier la consultation", "danger"),
        );
    } else {
      api
        .createConsultationType(newConsultation)
        .then((res) => {
          setConsultationsType((prevState) => [...prevState, res]);
          setOpenModal(false);
        })
        .catch(() =>
          snackbar.show("Impossible de créer la consultation", "danger"),
        );
    }
  }, [titre, corps, user.user?.id, editConsultationType, api, snackbar]);

  const editModal = useMemo(
    () => (
      <ModalComponent
        open={openModal}
        title={`${editConsultationType ? "Editer" : "Créer"} une consultation`}
        onClose={() => setOpenModal(false)}
        validateLabel={"Enregistrer"}
        onValidate={handleSave}
        canValidate={!!titre && !!corps}
        style={{ minWidth: "80%", minHeight: "80%" }}
      >
        <Stack spacing={2} flex={1}>
          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <Typography>Titre</Typography>
            <Input
              sx={{ flex: 1 }}
              value={titre}
              onChange={(e) => setTitre(e.target.value)}
            />
          </Stack>
          <Divider />
          <Stack direction={"row"} spacing={2} flex={1}>
            <div style={{ flex: 1 }}>
              <Editor data={corps} onChange={setCorps} onReady={setEditorRef} />
            </div>
            <FieldsPanel
              editor={editorRef}
              filters={["general", "patient"]}
              style={{ maxHeight: "540px" }}
            />
          </Stack>
        </Stack>
      </ModalComponent>
    ),
    [openModal, editConsultationType, handleSave, titre, corps, editorRef],
  );

  return (
    <>
      <ListOfDocuments
        documents={consultationsType}
        buttonLabel={"Créer une consultation"}
        editDocument={handleEdit}
        deleteDocument={handleDelete}
        documentLabelField={"titre"}
        documentCorpsField={"corps"}
      />
      {editModal}
    </>
  );
}
