import Button from "@mui/joy/Button";
import CircularProgress from "@mui/joy/CircularProgress";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import { useApi } from "../../contexts/ApiContext";
import { useSnackbar } from "../../contexts/SnackbarContext";
import { OperationDevisCcam } from "../../models/types";
import InputDepassementHonoraires from "../Forms/InputDepassementHonoraires";
import ModificateursInput from "../Forms/ModificateursInput";
import debounce from "lodash/debounce";
import { clear } from "console";

export interface Activite {
  id: string;
  cod_activ: string;
  libelle: string;
}

export interface Grille {
  id: string;
  cod_grille: string;
  libelle: string;
}

interface EditClaimProps {
  claim: OperationDevisCcam;
  onSaveClaim: (claim: OperationDevisCcam) => void;
  activiteOptions: Activite[];
  grilleOptions: Grille[];
}

export default function EditClaim({
  claim,
  onSaveClaim,
  activiteOptions,
  grilleOptions,
}: EditClaimProps) {
  const api = useApi();
  const snackbar = useSnackbar();
  const base = Number(claim.prix_modificateurs);

  const [editedClaim, setEditedClaim] =
    React.useState<OperationDevisCcam>(claim);
  const [modificateurs, setModificateurs] = React.useState<string[]>([]);
  const [loadingModificateurs, setLoadingModificateurs] =
    React.useState<boolean>(false);
  const [saving, setSaving] = React.useState<boolean>(false);

  React.useEffect(() => {
    const fetchModificateurs = async (code: string, activite: string) => {
      setLoadingModificateurs(true);
      try {
        const res = await api.getModificateur(code, activite);
        setModificateurs(
          res.R_activite_modificateur.map((mod: any) => mod.modifi_cod).sort(),
        );
      } catch (error: any) {
        console.error(error);
        snackbar.show(
          "Erreur lors de la récupération des modificateurs",
          "danger",
        );
      } finally {
        setLoadingModificateurs(false);
      }
    };

    if (claim.acte_ccam) {
      fetchModificateurs(claim.acte_ccam, `${claim.acte_activite}` || "1");
    }
  }, [claim, api, snackbar]);

  const handleClaimEdit = async (newClaim: OperationDevisCcam) => {
    onSaveClaim(newClaim);
  };

  React.useEffect(() => {
    setEditedClaim(claim);
    setSaving(false);
  }, [claim]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setSaving(true);
        onSaveClaim(editedClaim);
      }}
    >
      <Stack direction="column" gap={2} sx={{ p: 2 }}>
        <Typography level="title-lg">
          {claim.acte_ccam} - {claim.acte_nom_long}
        </Typography>
        {activiteOptions.length > 0 && (
          <FormControl>
            <FormLabel>Activité</FormLabel>
            <Select
              value={claim.acte_activite || 1}
              onChange={(e, newVal) => {
                handleClaimEdit({
                  ...claim,
                  acte_activite: newVal,
                });
              }}
            >
              {activiteOptions.map((activite: Activite, index) => (
                <Option key={index} value={Number(activite.cod_activ)}>
                  {activite.cod_activ} - {activite.libelle}
                </Option>
              ))}
            </Select>
          </FormControl>
        )}

        {grilleOptions.length > 0 && (
          <FormControl>
            <FormLabel>Grille</FormLabel>
            <Select
              value={claim.acte_grille}
              onChange={(e, newVal) => {
                handleClaimEdit({
                  ...claim,
                  acte_grille: newVal,
                });
              }}
            >
              {grilleOptions.map((grille: Grille, index) => (
                <Option key={index} value={Number(grille.cod_grille)}>
                  {grille.cod_grille} - {grille.libelle}
                </Option>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl>
          <FormLabel>Phase</FormLabel>
          <Select
            value={claim.acte_phase}
            onChange={(e, newVal) => {
              handleClaimEdit({
                ...claim,
                acte_phase: newVal,
              });
            }}
          >
            <Option value={0}>0</Option>
            <Option value={1}>1</Option>
            <Option value={2}>2</Option>
            <Option value={3}>3</Option>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Association des actes</FormLabel>
          <Select
            value={claim.acte_association || 1}
            onChange={(e, newVal) => {
              handleClaimEdit({
                ...claim,
                acte_association: newVal,
              });
            }}
          >
            <Option value={1}>1</Option>
            <Option value={2}>2</Option>
            <Option value={3}>3</Option>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Modificateur(s)</FormLabel>
          {loadingModificateurs && <CircularProgress size="sm" />}
          {modificateurs.length === 0 && !loadingModificateurs && (
            <Typography level="body-sm">Pas de modificateurs</Typography>
          )}

          {modificateurs.length > 0 && (
            <ModificateursInput
              value={claim.acte_modificateur || ""}
              onChange={(newVal) => {
                handleClaimEdit({
                  ...claim,
                  acte_modificateur: newVal,
                });
              }}
              modificateurs={modificateurs}
            />
          )}
        </FormControl>
        <FormControl>
          <FormLabel>Dépassement d'honoraire</FormLabel>
          <Sheet
            sx={{ p: 1.5, borderRadius: "sm", pb: 2, pr: 3 }}
            variant="soft"
          >
            <InputDepassementHonoraires
              value={Number(editedClaim.depassement_honoraire_acte || 0)}
              base={base}
              onChange={(newVal) => {
                setEditedClaim({
                  ...claim,
                  depassement_honoraire_acte: String(newVal),
                });
              }}
            />
          </Sheet>
        </FormControl>
        <Button type="submit" disabled={saving}>
          {saving ? <CircularProgress size="sm" /> : "Enregistrer"}
        </Button>
      </Stack>
    </form>
  );
}
