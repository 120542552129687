import React, { useEffect, useState } from "react";
import { useApi } from "../../../../contexts/ApiContext";
import { Field, NameFilterField } from "../../../../models/custom";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import { capitalizeFirstLetter } from "../../../../utils/utils";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Writer } from "ckeditor5";

interface FieldsPanelProps {
  editor?: ClassicEditor;
  filters?: NameFilterField[];
  style?: any;
}

export default function FieldsPanel({
  editor,
  filters,
  style = {},
}: FieldsPanelProps) {
  const api = useApi();
  const [fields, setFields] = useState<Field[]>([]);

  useEffect(() => {
    api
      .getPatientFields()
      .then((res) =>
        setFields(
          res.filter((f) =>
            filters?.length ? filters.includes(f.name_filter) : true,
          ),
        ),
      );
  }, [api, filters]);

  const insertTextToEditor = (text: string) => {
    if (editor) {
      editor.model.change((writer: Writer) => {
        const selection = editor.model.document.selection;
        const position = selection.getFirstPosition();
        writer.insertText(text, position!);
        editor.focus();
      });
    }
  };

  return (
    <Box
      sx={{
        maxWidth: "250px",
        background: (theme) => theme.palette.background.level1,
        borderTop: "1px solid #e3e3e3",
        borderBottom: "1px solid #e3e3e3",
        borderRadius: "8px",
        pl: 1,
        pr: 1,
        overflow: "scroll",
        flex: 1,
        ...style,
      }}
    >
      <Typography level="title-md" p={1}>
        Ajouter un champs
      </Typography>
      <Divider />
      {fields.map((field, index) => (
        <Stack key={index} p={1} gap={"3px"}>
          <Typography level={"title-sm"}>
            {capitalizeFirstLetter(field.nom)}
          </Typography>
          {field.fields.map((f, i) => (
            <Typography
              key={i}
              level={"body-xs"}
              ml={1}
              sx={(theme) => ({
                "&:hover": {
                  backgroundColor: theme.palette.background.level2,
                  cursor: "pointer",
                },
                p: "2px",
                borderRadius: "sm",
              })}
              onClick={() => insertTextToEditor(f.value)}
            >
              {f.label}
            </Typography>
          ))}
        </Stack>
      ))}
    </Box>
  );
}
