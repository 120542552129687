import { CalendarItem, Patient } from "../../models/types";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useApi } from "../../contexts/ApiContext";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import moment from "moment";
import ModalComponent from "../../components/Modal/Modal";
import Box from "@mui/joy/Box";
import ToggleButtonGroup from "@mui/joy/ToggleButtonGroup";
import Button from "@mui/joy/Button";
import ArrowRight from "@mui/icons-material/ArrowRight";
import { CssVarsProvider } from "@mui/joy/styles";

interface ListOfEventsProps {
  patient: Patient;
  width?: string;
}

export default function ListOfEvents({
  patient,
  width = "auto",
}: ListOfEventsProps) {
  const api = useApi();
  const [events, setEvents] = useState<CalendarItem[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [filter, setFilter] = React.useState<string[]>([
    "Consultation",
    "Hospitalisation",
    "Operation",
  ]);
  const [activeEvent, setActiveEvent] = React.useState<string>();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    api.getPatientEvents(patient.id).then(setEvents);
  }, [api, patient.id]);

  useEffect(() => {
    if (!open) {
      setFilter(["Consultation", "Hospitalisation", "Operation"]);
      setActiveEvent("");
    }
  }, [open]);

  const handleScroll = useCallback(() => {
    const container = ref.current;
    if (container) {
      const containerTop = container.getBoundingClientRect().top;

      const selectActiveEvent = (event: CalendarItem) => {
        const targetElement = container.querySelector(
          `#A${event.id}`,
        ) as HTMLElement;
        if (targetElement) {
          const elementRect = targetElement.getBoundingClientRect();
          const elementTopRelativeToContainer = elementRect.top - containerTop;

          // Check if the element is visible in the middle of the container
          if (
            elementTopRelativeToContainer >= 0 &&
            elementTopRelativeToContainer <= 50
          ) {
            setActiveEvent(event.id);
          } else {
            (event.operations as CalendarItem[])?.forEach(selectActiveEvent);
          }
        } else {
          (event.operations as CalendarItem[])?.forEach(selectActiveEvent);
        }
      };

      events.forEach(selectActiveEvent);
    }
  }, [events]);

  useEffect(() => {
    handleScroll();
  }, [filter, handleScroll]);

  const eventCompteRendu = (event: CalendarItem) => (
    // Add 'A' on id to avoid id started by number*
    <CssVarsProvider key={event.id}>
      <Box
        key={event.id}
        id={`A${event.id}`}
        padding={2}
        border={"1px solid"}
        borderRadius={"8px"}
        borderColor={`${colorByType(event.type)}.500`}
        position={"relative"}
        sx={{ backgroundColor: "inherit" }}
      >
        <Typography
          position={"absolute"}
          top={"-10px"}
          left={"10px"}
          padding={"0 8px"}
          fontSize="12px"
          color={colorByType(event.type)}
          sx={{ backgroundColor: "inherit" }}
        >
          {event.type} - {moment(event.start * 1000).format("DD/MM/YYYY")}
        </Typography>
        <Stack spacing={1} sx={{ backgroundColor: "inherit" }}>
          <Typography level={"title-md"}>{event.title}</Typography>
          <div
            style={{
              fontSize: "12px",
            }}
            dangerouslySetInnerHTML={{
              __html: event.corps,
            }}
          />
          {(event.operations as CalendarItem[])?.map(renderEvent)}
        </Stack>
      </Box>
    </CssVarsProvider>
  );

  const renderEvent = (event: CalendarItem): ReactNode => {
    return filter.includes(event.type)
      ? eventCompteRendu(event)
      : (event.operations as CalendarItem[])?.map(renderEvent);
  };

  const formatDate = (date: number) => moment(date * 1000).format("DD/MM/YYYY");

  const colorByType = (type: string) => {
    switch (type) {
      case "Consultation":
        return "primary";
      case "Hospitalisation":
        return "warning";
      case "Operation":
        return "success";
      default:
        return undefined;
    }
  };

  const eventCard = (event: CalendarItem) => (
    <Stack direction="row">
      {activeEvent === event.id && (
        <ArrowRight color={colorByType(event.type)} />
      )}
      <Typography
        key={event.id}
        sx={{
          fontSize: "10px",
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
        flexWrap={"wrap"}
        color={colorByType(event.type)}
        onClick={() => handleEventClick(event)}
      >
        {formatDate(event.start)} - {event.title}
      </Typography>
    </Stack>
  );

  const renderEventCard = (event: CalendarItem) => {
    return (
      <Stack gap={0.5} key={event.id}>
        {filter.includes(event.type) && eventCard(event)}
        {(event.operations as CalendarItem[])?.map((e) => renderEventCard(e))}
      </Stack>
    );
  };

  const modal = (
    <ModalComponent
      open={open}
      title={"Antécédents"}
      onClose={() => setOpen(false)}
      closeLabel={"Close"}
      style={{ width: "80%", height: "800px" }}
    >
      <Stack spacing={2} sx={{ backgroundColor: "inherit" }} flex={1}>
        <ToggleButtonGroup
          spacing={2}
          value={filter}
          onChange={(event, newValue) => setFilter(newValue || "")}
          sx={{ justifyContent: "center" }}
        >
          <Button color={"primary"} value={"Consultation"}>
            Consultation
          </Button>
          <Button color={"warning"} value={"Hospitalisation"}>
            Hospitalisation
          </Button>
          <Button color={"success"} value={"Operation"}>
            Operation
          </Button>
        </ToggleButtonGroup>
        <Stack
          direction={"row"}
          sx={{ backgroundColor: "inherit" }}
          spacing={1}
        >
          <Stack gap={0.5} minWidth={width} maxWidth={width}>
            {events.map(renderEventCard)}
          </Stack>
          <Stack
            ref={ref}
            pt={1}
            flex={1}
            spacing={2}
            sx={{ backgroundColor: "inherit" }}
            height={"598px"}
            overflow={"scroll"}
            onScroll={handleScroll}
          >
            {events.map(renderEvent)}
          </Stack>
        </Stack>
      </Stack>
    </ModalComponent>
  );

  const handleEventClick = (event: CalendarItem) => {
    setOpen(true);

    setTimeout(() => {
      const container = ref.current;
      const element = container?.querySelector(`#A${event.id}`) as HTMLElement;
      if (element && container) {
        const containerTop = container.getBoundingClientRect().top;
        const elementTop = element.getBoundingClientRect().top;
        const relativePosition = elementTop - containerTop;
        ref.current?.scrollTo({
          top: relativePosition + container.scrollTop - 15,
          behavior: "smooth",
        });
      } else {
        console.error(`Element with id "${event.id}" not found.`);
      }
    }, 0);
  };

  return (
    <Stack gap={0.5} minWidth={width} maxWidth={width}>
      <Typography level={"body-sm"}>Antécédents</Typography>
      {events.map(renderEventCard)}
      {modal}
    </Stack>
  );
}
