import Input from "@mui/joy/Input";
import Slider from "@mui/joy/Slider";
import Stack from "@mui/joy/Stack";
import React from "react";
import { roundTo2Decimals } from "../../utils/utils";

interface InputDepassementHonorairesProps {
  value: number;
  base: number;
  onChange: (value: number) => void;
}

export default function InputDepassementHonoraires({
  value,
  base,
  onChange,
}: InputDepassementHonorairesProps) {
  const [percent, setPercent] = React.useState<number>(
    Math.round((value / base) * 100),
  );

  const handlePercentChange = (value: number) => {
    setPercent(value);
    onChange(roundTo2Decimals(Math.round((base * value) / 100)));
  };

  const handleFixedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    setPercent(Math.round((value / base) * 100));
    onChange(value);
  };

  return (
    <Stack gap={2}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Input
          sx={{ width: "50%" }}
          type="number"
          value={value}
          onChange={handleFixedChange}
          startDecorator="€"
        />
        <small>base {base}€</small>
      </Stack>
      <Stack direction="row" gap={1} alignItems="center">
        <Input
          sx={{ width: "100px" }}
          size="sm"
          type="number"
          value={percent}
          startDecorator="%"
          onChange={(e) => handlePercentChange(e.target.valueAsNumber)}
        />
        <Slider
          value={percent}
          min={0}
          max={400}
          step={1}
          onChange={(_, value) => handlePercentChange(value as number)}
          marks={[
            { value: 100, label: "100%" },
            { value: 200, label: "200%" },
            { value: 400, label: "400%" },
          ]}
        />
      </Stack>
    </Stack>
  );
}
