import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useApi } from "../../../../contexts/ApiContext";
import { useUser } from "../../../../contexts/UserContext";
import {
  CategorieOrdonnance,
  OrdonnanceTemplate,
} from "../../../../models/types";
import ListOfDocuments from "./ListOfDocuments";
import { useSnackbar } from "../../../../contexts/SnackbarContext";
import ModalComponent from "../../../../components/Modal/Modal";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Input from "@mui/joy/Input";
import Divider from "@mui/joy/Divider";
import Editor from "../../../../components/Editor/Editor";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import OperatorSelector from "../../../../components/Forms/OperatorSelector";
import SpecialiteMultiSelect from "../../../../components/Forms/SpecialiteMultiSelect";

export default function OrdonnanceTab() {
  const api = useApi();
  const user = useUser();
  const snackbar = useSnackbar();

  const [ordonnances, setOrdonnances] = useState<OrdonnanceTemplate[]>([]);
  const [editOrdonnance, setEditOrdonnance] = useState<OrdonnanceTemplate>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [content, setContent] = React.useState<string>("");
  const [titre, setTitre] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [categorie, setCategorie] =
    React.useState<CategorieOrdonnance | null>();
  const [categories, setCategories] = React.useState<CategorieOrdonnance[]>([]);
  const [selectedSpecialites, setSelectedSpecialites] = React.useState<
    string[]
  >([]);
  const [duplicateUsers, setDuplicateUsers] = React.useState<number[]>([]);

  useEffect(() => {
    if (api && user.user?.id) {
      api
        .getOrdonnancesTemplate(user.user?.id)
        .then((res) => setOrdonnances(res));
      api.getOrdonnanceCategories().then((res) => setCategories(res));
    }
  }, [api, user.user?.id]);

  const handleDelete = (id: string) => {
    api
      .deleteOrdonnanceTemplate(id)
      .then(() => setOrdonnances(ordonnances.filter((o) => o.id !== id)))
      .catch(() =>
        snackbar.show("Impossible de supprimer l'ordonnance", "danger"),
      );
  };

  const handleSave = useCallback(() => {
    const newOrdo: Partial<OrdonnanceTemplate> = {
      titre_ordonnance: titre,
      description_ordonnance: description,
      categorie_ordonnance: categorie?.id,
      corps_ordonnance: content,
      specialite: selectedSpecialites,
      zkf_user: user.user?.id,
    };
    if (editOrdonnance) {
      api
        .updateOrdonnanceTemplate({ id: editOrdonnance.id, ...newOrdo })
        .then((res) => {
          setOrdonnances((prevState) =>
            prevState.map((o) => (o.id === editOrdonnance.id ? res : o)),
          );
          setOpenModal(false);
        })
        .catch(() =>
          snackbar.show("Impossible de modifier l'ordonnance", "danger"),
        );
    } else {
      api
        .createOrdonnanceTemplate(newOrdo, duplicateUsers)
        .then((res) => {
          setOrdonnances((prevState) => [...prevState, res]);
          setOpenModal(false);
        })
        .catch(() =>
          snackbar.show("Impossible de créer l'ordonnance", "danger"),
        );
    }
  }, [
    api,
    categorie?.id,
    content,
    description,
    duplicateUsers,
    editOrdonnance,
    selectedSpecialites,
    snackbar,
    titre,
    user.user?.id,
  ]);

  const editModal = useMemo(() => {
    return (
      <ModalComponent
        open={openModal}
        title={`${editOrdonnance ? "Editer" : "Créer"} une ordonnance`}
        onClose={() => setOpenModal(false)}
        validateLabel={"Enregistrer"}
        onValidate={handleSave}
        canValidate={
          !!titre &&
          !!description &&
          !!categorie &&
          selectedSpecialites &&
          selectedSpecialites.length > 0
        }
        style={{ minWidth: "80%", minHeight: "80%" }}
      >
        <Stack spacing={2} flex={1}>
          <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
            <Stack spacing={1} width={"300px"}>
              <Typography>Titre</Typography>
              <Input value={titre} onChange={(e) => setTitre(e.target.value)} />
            </Stack>
            <Stack spacing={1} width={"350px"}>
              <Typography>Description</Typography>
              <Input
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Stack>
            <Stack spacing={1}>
              <Typography>Categorie</Typography>
              <Select
                renderValue={() => categorie?.categorie || ""}
                value={categorie}
                onChange={(e, newValue) => setCategorie(newValue)}
                style={{ width: "250px" }}
              >
                {categories.map((c) => (
                  <Option key={c.id} value={c}>
                    {c.categorie}
                  </Option>
                ))}
              </Select>
            </Stack>
            <Stack spacing={1}>
              <Typography>Spécialités</Typography>
              <SpecialiteMultiSelect
                value={selectedSpecialites}
                onSelect={(spe) =>
                  setSelectedSpecialites(spe?.map((spe) => spe.id) || [])
                }
                width={"250px"}
              />
            </Stack>
            <Stack spacing={1} width={"350px"}>
              <Typography>Dupliquer pour les utilisateurs</Typography>
              <OperatorSelector
                onSelect={(users) => setDuplicateUsers(users)}
                exludeOperators={user.user?.id ? [user.user?.id] : []}
              />
            </Stack>
          </Stack>
          <Divider />
          <Stack flex={1}>
            <Editor data={content} onChange={setContent} />
          </Stack>
        </Stack>
      </ModalComponent>
    );
  }, [
    categorie,
    categories,
    content,
    description,
    editOrdonnance,
    handleSave,
    openModal,
    selectedSpecialites,
    titre,
    user.user?.id,
  ]);

  const handleEdit = (ordonnance?: OrdonnanceTemplate) => {
    setContent(ordonnance?.corps_ordonnance || "");
    setTitre(ordonnance?.titre_ordonnance || "");
    setDescription(ordonnance?.description_ordonnance || "");
    setCategorie(
      categories.find((c) => c.id === ordonnance?.categorie_ordonnance),
    );
    setSelectedSpecialites(ordonnance?.specialite || []);
    setEditOrdonnance(ordonnance);
    setOpenModal(true);
  };

  return (
    <>
      <ListOfDocuments
        documents={ordonnances}
        buttonLabel={"Créer une ordonnance"}
        editDocument={handleEdit}
        deleteDocument={handleDelete}
        documentLabelField={"titre_ordonnance"}
        documentCorpsField={"corps_ordonnance"}
      />
      {editModal}
    </>
  );
}
