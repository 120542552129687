import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import React from "react";

export default function TimeField({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) {
  const [error, setError] = React.useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    // Regex to match time format HH:mm:ss
    const timeFormat = /^([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;

    if (timeFormat.test(value)) {
      setError(false);
      onChange(value);
    } else {
      setError(true);
    }
  };

  return (
    <>
      <Input
        type="text"
        sx={{ background: "white" }}
        value={value}
        onChange={handleInputChange}
        placeholder="HH:mm:ss"
        error={error}
      />
      {error && (
        <Typography level="body-xs" color="danger">
          Format de l'heure invalide (HH:mm:ss)
        </Typography>
      )}
    </>
  );
}
