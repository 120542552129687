import { useOidc, useOidcAccessToken } from "@axa-fr/react-oidc";
import React from "react";
import { ApiClient } from "../clients/ApiClient";

const ApiContext = React.createContext<ApiClient | null>(null);

interface ApiContextProviderProps {
  children: React.ReactNode;
}

export function ApiContextProvider({ children }: ApiContextProviderProps) {
  const { accessToken } = useOidcAccessToken();
  const { login, isAuthenticated } = useOidc();

  React.useEffect(() => {
    if (!isAuthenticated && !process.env.REACT_APP_DISABLE_AUTH) {
      login();
    }
  }, [isAuthenticated, login]);

  const api = React.useMemo(() => {
    const currentUrl = window.location.hostname;
    const apiBaseUrl =
      currentUrl === "localhost" || currentUrl.startsWith("localhost:")
        ? "http://localhost:8000/api"
        : `${window.location.origin}/api`;

    return new ApiClient(
      apiBaseUrl,
      process.env.REACT_APP_DISABLE_AUTH ? undefined : accessToken,
    );
  }, [accessToken]);

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
}

export const useApi = () => {
  const context = React.useContext(ApiContext);
  if (context === null) {
    throw new Error("useApi must be used within an ApiContextProvider");
  }
  return context;
};
