import { OidcProvider } from "@axa-fr/react-oidc";
import "@fontsource/open-sans";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import {
  AuthenticationError,
  AuthenticationLoading,
  SessionExpired,
} from "./components/AuthenticationStates";
import { ApiContextProvider } from "./contexts/ApiContext";
import { UserContextProvider } from "./contexts/UserContext";
import "./globals.scss";
import reportWebVitals from "./reportWebVitals";
import { router } from "./router";

const configuration = {
  client_id: "meiflow_client",
  redirect_uri: window.location.origin + "/authentication/callback",
  silent_redirect_uri:
    window.location.origin + "/authentication/silent-callback",
  scope: "openid",
  authority: "https://sso.preprod.meiflow.fr/realms/meiflow_realm",
  service_worker_only: false,
  demonstrating_proof_of_possession: false,
};

if (process.env.REACT_APP_DISABLE_AUTH) {
  configuration.authority = "http://localhost:8000";
}

const Root = () => {
  return (
    <React.StrictMode>
      <OidcProvider
        configuration={configuration}
        loadingComponent={AuthenticationLoading}
        authenticatingErrorComponent={AuthenticationError}
        sessionLostComponent={SessionExpired}
        callbackSuccessComponent={AuthenticationLoading}
        authenticatingComponent={AuthenticationLoading}
      >
        <ApiContextProvider>
          <UserContextProvider>
            <RouterProvider router={router} />
          </UserContextProvider>
        </ApiContextProvider>
      </OidcProvider>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
