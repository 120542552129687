import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { useUser } from "../../../contexts/UserContext";
import Input from "@mui/joy/Input";
import Checkbox from "@mui/joy/Checkbox";
import Editor from "../../../components/Editor/Editor";
import React, { FormEvent, useEffect, useMemo, useState } from "react";
import {
  EnteteDocument,
  PreferenceUserSerialyzer,
  Profile,
  User,
} from "../../../models/types";
import Card from "@mui/joy/Card";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import Button from "@mui/joy/Button";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import ServiceMultiSelect from "../../../components/Forms/ServiceMultiSelect";
import SpecialiteMultiSelect from "../../../components/Forms/SpecialiteMultiSelect";
import SpecialiteSelect from "../../../components/Forms/SpecialiteSelect";
import SiteMultiSelect from "../../../components/Forms/SiteMultiSelect";
import { useApi } from "../../../contexts/ApiContext";
import { useSnackbar } from "../../../contexts/SnackbarContext";
import CcamActiviteSelect from "../../../components/Forms/CcamActiviteSelect";
import RedacteurMultiSelector from "../../../components/Forms/RedacteurMultiSelector";
import Save from "@mui/icons-material/Save";

export default function ProfileEditTab() {
  const api = useApi();
  const snackbar = useSnackbar();
  const userContext = useUser();
  const [user, setUser] = useState<User | null>(userContext.user);
  const [profile, setProfile] = useState<Profile | null>(userContext.profile);
  const [preferences, setPreferences] =
    useState<PreferenceUserSerialyzer | null>(userContext.preferences);
  const emptyEntete: Partial<EnteteDocument> = useMemo(
    () => ({ titre: "", texte: "" }),
    [],
  );
  const [entete, setEntete] = useState<Partial<EnteteDocument>>();

  useEffect(() => {
    if (profile?.entete) {
      api
        .getEnteteDocument(profile?.entete)
        .then((res) => setEntete(res || emptyEntete));
    } else {
      setEntete(emptyEntete);
    }
  }, [api, emptyEntete, profile?.entete]);

  const handleUpdate = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    user &&
      api
        .updateUser(user)
        .then((res) => {
          userContext.setUser(res);
          snackbar.show(
            "La modification des settings utilisateur a réussie",
            "success",
          );
        })
        .catch(() =>
          snackbar.show("La modification de l'utilisateur a échouée", "danger"),
        );
    if (profile) {
      if (profile.entete) {
        entete &&
          api
            .updateEnteteDocument(entete)
            .then(() =>
              snackbar.show("La modification de l'entete a réussie", "success"),
            )
            .catch(() =>
              snackbar.show("La modification de l'entete a échouée", "danger"),
            );
      } else {
        entete &&
          api
            .createEnteteDocument(entete)
            .then((res) => {
              profile.entete = res.id;
              snackbar.show("La création de l'entete à réussie", "success");
            })
            .catch(() =>
              snackbar.show("La création de l'entete à échouer", "danger"),
            );
      }
      api
        .updateProfile(profile)
        .then((res) => {
          userContext.setProfile(res);
          snackbar.show(
            "La modification du profil utilisateur a réussie",
            "success",
          );
        })
        .catch(() =>
          snackbar.show("La modification du profile a échouée", "danger"),
        );
    }
    preferences &&
      api
        .updatePreferencesUser({
          id: preferences.id,
          impression_recto_verso: preferences.impression_recto_verso,
        })
        .then((res) => {
          userContext.setPreferences(res);
          snackbar.show(
            "La modification des préferences utilisateur a réussie",
            "success",
          );
        })
        .catch(() =>
          snackbar.show(
            "La modification des préferences utilisateur a échouée",
            "danger",
          ),
        );
  };

  if (!user || !profile || !preferences) return null;

  const width = "250px";

  return (
    <Stack direction="column" spacing={4} width={"max-content"}>
      <Typography level="h4">Édition du profil</Typography>
      <form onSubmit={handleUpdate}>
        <Card>
          <CardOverflow
            sx={{ borderBottom: "1px solid", borderColor: "divider" }}
          >
            <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
              <Button
                type="submit"
                size="sm"
                variant="solid"
                startDecorator={<Save />}
              >
                Sauvegarder
              </Button>
            </CardActions>
          </CardOverflow>
          <Stack
            gap={3}
            sx={{ display: { xs: "none", md: "flex" }, my: 1 }}
            flexWrap={"wrap"}
          >
            <Stack direction={"row"} gap={3}>
              <FormControl>
                <FormLabel>Nom</FormLabel>
                <Input
                  required
                  name={"nom"}
                  value={user?.last_name || ""}
                  onChange={(e) =>
                    setUser({ ...user, last_name: e.target.value })
                  }
                  style={{ width }}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Prenom</FormLabel>
                <Input
                  required
                  name={"prenom"}
                  value={user?.first_name || ""}
                  onChange={(e) =>
                    setUser({ ...user, first_name: e.target.value })
                  }
                  style={{ width }}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Titre</FormLabel>
                <Select
                  required
                  name={"titre"}
                  value={profile?.titre || "Dr"}
                  onChange={(e, newValue) =>
                    setProfile({ ...profile, titre: newValue || "" })
                  }
                >
                  <Option value={"Dr"}>Dr</Option>
                  <Option value={"Mme"}>Mme</Option>
                  <Option value={"Mr"}>Mr</Option>
                </Select>
              </FormControl>
            </Stack>
            <Stack direction={"row"} gap={3}>
              <FormControl>
                <FormLabel>Praticiens lies</FormLabel>
                <RedacteurMultiSelector
                  value={profile.praticiens_lies || []}
                  onSelect={(r) =>
                    setProfile({
                      ...profile,
                      praticiens_lies: r?.map((r) => r.id) || [],
                    })
                  }
                  width={width}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Sites</FormLabel>
                <SiteMultiSelect
                  width={width}
                  value={profile?.site || []}
                  onSelect={(sites) =>
                    setProfile({
                      ...profile,
                      site: sites?.map((s) => s.id) || [],
                    })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Groupes facturation</FormLabel>
                <Input
                  name={"groupe_facturation"}
                  value={profile?.groupe_facturation || ""}
                  style={{ width }}
                />
              </FormControl>
            </Stack>
            <Stack direction={"row"} gap={3}>
              <FormControl>
                <FormLabel>Specialites</FormLabel>
                <SpecialiteMultiSelect
                  width={width}
                  value={profile?.specialite_profile || []}
                  onSelect={(specialites) =>
                    setProfile({
                      ...profile,
                      specialite_profile: specialites?.map((s) => s.id) || [],
                    })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Specialite active</FormLabel>
                <SpecialiteSelect
                  width={width}
                  value={profile?.specialite_active || ""}
                  onSelect={(specialite) =>
                    setProfile({
                      ...profile,
                      specialite_active: specialite?.id || "",
                    })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Services</FormLabel>
                <ServiceMultiSelect
                  width={width}
                  value={profile?.service || ""}
                  onSelect={(services) =>
                    setProfile({
                      ...profile,
                      service: services.map((s) => s.id),
                    })
                  }
                />
              </FormControl>
            </Stack>
            <Stack direction={"row"} gap={3}>
              <FormControl>
                <FormLabel>Activite CCAM</FormLabel>
                <CcamActiviteSelect
                  value={profile?.activite_ccam || ""}
                  onSelect={(c) =>
                    setProfile({ ...profile, activite_ccam: c?.id })
                  }
                  width={width}
                />
              </FormControl>
              <FormControl>
                <FormLabel>RPPS</FormLabel>
                <Input
                  type={"number"}
                  name={"rpps"}
                  value={profile?.rpps || ""}
                  onChange={(e) =>
                    setProfile({ ...profile, rpps: Number(e.target.value) })
                  }
                  style={{ width }}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Modificateurs default</FormLabel>
                <Input
                  name={"modificateurs_default"}
                  value={profile?.modificateurs_default || ""}
                  onChange={(e) =>
                    setProfile({
                      ...profile,
                      modificateurs_default: e.target.value,
                    })
                  }
                  style={{ width }}
                />
              </FormControl>
            </Stack>
            <Stack direction={"row"} gap={3} alignItems={"center"}>
              <FormControl>
                <Checkbox
                  checked={preferences?.impression_recto_verso}
                  onChange={(e) =>
                    setPreferences({
                      ...preferences,
                      impression_recto_verso: e.target.checked,
                    })
                  }
                  label={"Impression recto/verso"}
                />
              </FormControl>
            </Stack>
            <Stack direction={"row"} gap={3}>
              <FormControl>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <FormLabel>Entete</FormLabel>
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Typography level={"body-sm"}>Titre</Typography>
                    <Input
                      sx={{ width }}
                      value={entete?.titre || ""}
                      onChange={(e) =>
                        setEntete({ ...entete, titre: e.target.value })
                      }
                    />
                  </Stack>
                </Stack>
                <Editor data={entete?.texte || ""} onChange={() => null} />
              </FormControl>
              <FormControl>
                <FormLabel>Entete ordonnance</FormLabel>
                <Editor
                  data={profile?.entete_ordonnance || ""}
                  onChange={() => null}
                />
              </FormControl>
            </Stack>
          </Stack>
          <CardOverflow sx={{ borderTop: "1px solid", borderColor: "divider" }}>
            <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
              <Button
                type="submit"
                size="sm"
                variant="solid"
                startDecorator={<Save />}
              >
                Sauvegarder
              </Button>
            </CardActions>
          </CardOverflow>
        </Card>
      </form>
    </Stack>
  );
}
