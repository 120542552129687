import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import React from "react";
import { useApi } from "../../contexts/ApiContext";
import { R_activite } from "../../models/types";

interface CcamActiviteSelectProps {
  value: R_activite["id"] | null;
  onSelect: (value: R_activite | null) => void;
  width?: string;
}

export default function CcamActiviteSelect({
  value,
  onSelect,
  width = "auto",
}: CcamActiviteSelectProps) {
  const api = useApi();
  const [ccamActivites, setCcamActivites] = React.useState<R_activite[]>([]);

  React.useEffect(() => {
    const fetch = async () => {
      const res = await api.getCcamActivite();
      setCcamActivites(res);
    };
    fetch();
  }, [api]);

  return (
    <Select
      value={value}
      onChange={(e, newCcamActivite) => {
        onSelect(
          ccamActivites.find((s) => s.id === newCcamActivite) as R_activite,
        );
      }}
      style={{ width }}
    >
      {ccamActivites.map((ccamActivite) => (
        <Option key={ccamActivite.id} value={ccamActivite.id}>
          {ccamActivite.libelle}
        </Option>
      ))}
    </Select>
  );
}
