import Autocomplete from "@mui/joy/Autocomplete";
import React from "react";
import { useApi } from "../../contexts/ApiContext";
import { UserSites } from "../../models/types";
import { useSnackbar } from "../../contexts/SnackbarContext";

type SiteId = UserSites["id"];

export default function ConsultingSiteSelector({
  onSelect,
  initialValue,
}: {
  onSelect: (site_id: UserSites["id"]) => void;
  style?: React.CSSProperties;
  initialValue?: UserSites["id"];
}) {
  const api = useApi();
  const snackbar = useSnackbar();

  const [sites, setSites] = React.useState<UserSites[]>([]);
  const [selectedSite, setSelectedSite] = React.useState<UserSites | null>(
    null,
  );

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const sites = await api?.getUserSites();
        setSites(sites);
        if (sites.length > 0) {
          setSelectedSite(sites[0]);
          onSelect(sites[0].id);
        }
      } catch (e) {
        snackbar.show("Impossible de récupérer les sites", "danger");
      }
    };
    fetch();
  }, [api]);

  React.useEffect(() => {
    const value = initialValue
      ? sites.find((s) => s.id === initialValue) || sites[0]
      : sites[0];
    setSelectedSite(value);
    onSelect(value?.id);
  }, [initialValue, onSelect, sites]);

  return (
    <Autocomplete
      sx={{ flex: 1 }}
      value={selectedSite!}
      onChange={(e, newValue) => {
        onSelect(newValue?.id as SiteId);
        setSelectedSite(newValue);
      }}
      disabled={sites.length === 0}
      options={sites}
      getOptionLabel={(option) => option.nom_site}
      disableClearable
    />
  );
}
