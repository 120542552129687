import { MetaData } from "../components/Uploader/DocumentUploader";
import {
  ActeSearchResponse,
  ComplicationPostOp,
  CROType,
  Absence as CustomAbsence,
  CustomContact,
  Vacation as CustomVacation,
  Field,
  FormField,
  OperationTemplateFieldsResponse,
  OperationTemplatesResponse,
  PeriOpData,
  PrintData,
  PrintManagerFile,
  Service,
  UnixTimestamp,
} from "../models/custom";
import {
  Absence,
  Acte,
  Adresse,
  CalendarItem,
  CategorieOrdonnance,
  CompteRendu,
  ConsignesSortie,
  Consultation,
  ConsultationType,
  Contact,
  Devis,
  DocumentLibre,
  DocumentLibreTemplate,
  Email,
  EnteteDocument,
  FullTag,
  Hospitalisation,
  Intervenant,
  MatrielIrradiation,
  Notification,
  Operation,
  OperationDevisCcam,
  Ordonnance,
  OrdonnanceTemplate,
  PaginatedConsultationList,
  PaginatedDevisList,
  PaginatedHospitalisationList,
  PaginatedPatientList,
  PatchedAdresse,
  PatchedConsultation,
  PatchedEmail,
  PatchedHospitalisation,
  PatchedPatient,
  PatchedTelephone,
  Patient,
  PreferenceUserSerialyzer,
  Profile,
  Protocole,
  R_activite,
  Specialite,
  Telephone,
  User,
  UserSites,
  UserSitesLieux,
  Vacation,
  Ville,
} from "../models/types";
import { BaseClient } from "./BaseClient";

export interface PaginatedResponse<T> {
  count: number;
  next: string;
  previous: string;
  results: T[];
}

interface LoginResponse {
  token: string;
  user_id: number;
}

export class ApiClient extends BaseClient {
  constructor(baseUrl: string, accessToken?: string) {
    super(baseUrl, async () => {
      return accessToken ? `Bearer ${accessToken}` : null;
    });
  }

  async listPatients(page?: number): Promise<PaginatedResponse<Patient>> {
    return this.httpGet(`/patient${page && page > 0 ? `?page=${page}` : ""}`);
  }
  async getPatient(id: string): Promise<Patient> {
    return this.httpGet(`/patient/${id}`);
  }
  async createPatient(patient: PatchedPatient): Promise<Patient> {
    return this.httpPost("/patient/", patient);
  }
  async updatePatient(patient: Patient): Promise<Patient> {
    return this.httpPut(`/patient/${patient.id}`, patient);
  }
  async searchPatients(query: string): Promise<PaginatedPatientList> {
    return this.httpGet(`/patient/search?query=${query}`);
  }
  async loginUser(username: string, password: string): Promise<LoginResponse> {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    try {
      return this.httpPostFormData(`/token-auth/`, formData, undefined, true);
    } catch (error) {
      if (error instanceof Error) {
        this.reportError(error);
      }
      throw new Error("An error occurred");
    }
  }
  async logoutUser(): Promise<void> {
    return localStorage.removeItem("token");
  }
  async getUser(id: number): Promise<User> {
    return await this.httpGet(`/user/${id}`);
  }
  async getUserProfile(id: string): Promise<Profile> {
    return await this.httpGet(`/user/profile/${id}`);
  }
  async getUserSites(): Promise<UserSites[]> {
    // need to be authed, returns list of sites for current user
    return await this.httpGet("/user/site");
  }
  async getSitePlaces(site_id: string): Promise<UserSitesLieux[]> {
    return await this.httpGet(`/user/site/${site_id}/lieu`);
  }
  async createConsultation(
    data: PatchedConsultation,
    params: { name: string; value: string }[] = [],
  ): Promise<Consultation> {
    // TODO type post requests
    const url =
      "/consultation/" +
      (params.length > 0
        ? "?" + params.map((p) => `${p.name}=${p.value}`).join("&")
        : "");
    return await this.httpPost(url, data);
  }
  async getConsultation(id: string, depth = 0): Promise<Consultation> {
    return await this.httpGet(`/consultation/${id}?depth=${depth}`);
  }
  async updateConsultation(
    consultation: PatchedConsultation,
  ): Promise<Consultation> {
    return this.httpPatch(`/consultation/${consultation.id}`, consultation);
  }
  async listConsultation(
    patientId: string,
  ): Promise<PaginatedConsultationList> {
    return await this.httpGet(`/consultation?patient=${patientId}&depth=1`);
  }

  async getAntecedents(
    patient_id: string,
    context?: string,
    id?: string,
  ): Promise<any> {
    return this.httpGet(
      `/antecedent/${patient_id}/chirurgie_vasculaire${
        context ? `/${context}` : ""
      }${id ? `/${id}` : ""}`,
    );
  }
  async getAntecedentsFields(
    patient_id: string,
    context?: string,
    id?: string,
  ): Promise<{ [key: string]: FormField }> {
    return this.httpOptions(
      `/antecedent/${patient_id}/chirurgie_vasculaire${
        context ? `/${context}` : ""
      }${id ? `/${id}` : ""}`,
    ).then((res) => {
      return res.actions["PUT"];
    });
  }
  //get hospitalisations of a patient
  async getHospitalisation(
    hospitalisation_id: string,
  ): Promise<Hospitalisation> {
    return this.httpGet(`/hospitalisation/${hospitalisation_id}`);
  }
  async getOperation(operation_id: string): Promise<Operation> {
    return this.httpGet(`/operation/${operation_id}`);
  }
  async createOperation(payload: Partial<Operation>): Promise<Operation> {
    return this.httpPost("/operation/", payload);
  }
  async updateOperation(operation: Operation): Promise<Operation> {
    return this.httpPut("/operation/" + operation.id, operation);
  }
  // async listOperations(patientId: string): Promise<Operation[]> {
  //   return this.httpGet(`/operation?patient=${patientId}`);
  // }
  async getOperationsOfHospitalisation(
    hospitalisation_id: string,
  ): Promise<Operation[]> {
    return this.httpGet(`/hospitalisation/${hospitalisation_id}`);
  }
  async getOperationsOfConsultation(
    consultation_id: string,
  ): Promise<Operation[]> {
    return this.httpGet(`/operation?consultation=${consultation_id}`);
  }
  async listHospitalisations(
    patientId: string,
  ): Promise<PaginatedHospitalisationList> {
    return this.httpGet(`/hospitalisation?patient=${patientId}&depth=1`);
  }
  async getOperationTemplates(
    specialite?: string,
    template_name?: string,
    operation_id?: string,
  ): Promise<OperationTemplatesResponse> {
    return this.httpGet(
      `/operation-templates` +
        (specialite
          ? `/${specialite}` +
            (template_name
              ? `/${template_name}` + (operation_id ? `/${operation_id}` : "")
              : "")
          : ""),
    );
  }
  async getOperationsTemplateFields(
    specialite: string,
    template_name: string,
  ): Promise<{ [key: string]: FormField }> {
    return await this.httpOptions(
      `/operation-templates/${specialite}/${template_name}`,
    ).then((res: OperationTemplateFieldsResponse) => {
      return Promise.resolve(res.actions["POST"]);
    });
  }
  //Get current hospitalisations of a service
  async getServiceHospitalisations(depth?: number): Promise<Hospitalisation[]> {
    const url = `/hospitalisation/service${depth ? `?depth=${depth}` : ""}`;
    return this.httpGet(url);
  }
  async getLinkedUsers(): Promise<PaginatedResponse<User>> {
    return this.httpGet("/user/linked-users");
  }

  // need to rewrite the back and front end:
  //events have to be mixed together and sorted by time.
  //One column for each praticien !
  async getUserEvents(): Promise<{
    Operation: Operation[];
    Consultation: Consultation[];
    RendezVous: any[];
  }> {
    return this.httpGet("/user/user-events");
  }
  async getOperationPeriOp(
    operation_id: string,
  ): Promise<{ [key: string]: Operation[] | PeriOpData[] }> {
    return this.httpGet(`/operation/${operation_id}/peri-op`);
  }
  async getCROTypes(): Promise<CROType[]> {
    return this.httpGet("/operation/cro-type");
  }
  async createCROTypes(cro: Partial<CROType>): Promise<CROType> {
    return this.httpPost("/operation/cro-type", cro);
  }
  async updateCROTypes(cro: Partial<CROType>): Promise<CROType> {
    return this.httpPatch(`/operation/cro-type/${cro.id}`, cro);
  }
  async deleteCROTypes(id: string): Promise<void> {
    return this.httpDelete(`/operation/cro-type/${id}`);
  }
  async createOperationTemplate(
    specialite: string,
    template_name: string,
    data: any,
  ): Promise<any> {
    return this.httpPost(
      `/operation-templates/${specialite}/${template_name}`,
      data,
    );
  }
  async updateOperationTemplate(
    specialite: string,
    template_name: string,
    id: string,
    data: any,
  ): Promise<any> {
    return this.httpPut(
      `/operation-templates/${specialite}/${template_name}/${id}`,
      data,
    );
  }
  async listUserInterveants(): Promise<Intervenant[]> {
    return this.httpGet("/user/intervenant");
  }
  async getUserIntervenantsEvents(): Promise<{
    Anesthesiste: Intervenant[];
    Operateur: Intervenant[];
    Redacteur: User[];
    "Aide Operatoire": Intervenant[];
  }> {
    return this.httpGet("/user/intervenants-event");
  }
  async createHospitalisation(
    h: Partial<Hospitalisation>,
  ): Promise<Hospitalisation> {
    return this.httpPost("/hospitalisation/", h);
  }
  async updateHospitalisation(
    hospitalisationId: string,
    h: PatchedHospitalisation,
  ): Promise<Hospitalisation> {
    return this.httpPatch(`/hospitalisation/${hospitalisationId}`, h);
  }
  async getCroContentWithTemplate(
    operation_id: string,
    cro_type_id: string,
  ): Promise<{ cro_type: string }> {
    return this.httpGet(
      `/operation/${operation_id}/${cro_type_id}/generate-cro`,
    );
  }
  async addToPrintManager(
    doc_type: string,
    doc_id: string,
  ): Promise<PrintManagerFile> {
    return this.httpPost(`/print-manager/`, {
      uuid: doc_id,
      type_document: doc_type.toLowerCase(),
    });
  }
  // returns application/pdf file for selected docs ou ordonnances outside du gestionnaire d'impression
  async printManagerPrintAll(docs: PrintData[]): Promise<Blob> {
    return this.httpPostBlobResponse("/print-manager/print-selected", docs);
  }
  //returns application/pdf file for selected documents du gestionnaire d'impression
  async printManagerPrint(docs: string[]): Promise<Blob> {
    return this.httpPostBlobResponse("/print-manager/print", docs);
  }

  async deletePrintManagerDoc(uuid?: string): Promise<void> {
    // if uuid is not provided, we delete all docs
    if (uuid) {
      return this.httpDelete(`/print-manager/${uuid}`);
    } else {
      return this.httpDelete("/print-manager");
    }
  }
  async getPrintManagerQueue(): Promise<PrintManagerFile[]> {
    return this.httpGet("/print-manager");
  }
  async getCalendarItems(
    start: UnixTimestamp,
    end: UnixTimestamp,
    users: number[],
  ): Promise<CalendarItem[]> {
    if (start && end) {
      return this.httpGet(
        `/agenda/items?start=${start}&end=${end}&users=${users.join(",")}`,
      );
    } else {
      return this.httpGet(`/agenda/items`);
    }
  }
  async getNotifications(): Promise<PaginatedResponse<Notification>> {
    return this.httpGet("/notification");
  }
  async markNotificationAsRead(id: string): Promise<void> {
    return this.httpPut(`/notification/${id}`, {});
  }
  async getProtocoles(): Promise<Protocole[]> {
    return this.httpGet(`/protocole`);
  }
  async getModificateur(code?: string, activite?: string): Promise<any> {
    // TODO: Update with the correct type
    let url = "/ccam/modificateur";
    if (code && activite) {
      url += `?aa_code=${code}&activite=${activite}`;
    }
    return this.httpGet(url);
  }
  async searchCCAM(query: string): Promise<Acte[]> {
    return this.httpGet(`/ccam/search?query=${query}`);
  }
  async getCCAM(code: string): Promise<ActeSearchResponse> {
    return this.httpGet(`/ccam/acte/${code}`);
  }
  async getMyself(): Promise<User> {
    return this.httpGet(`/user/me`);
  }
  async getListQuotes(operationId: string): Promise<PaginatedResponse<Devis>> {
    // TODO: Update with the correct type
    return this.httpGet(`/comptabilite/devis?operation=${operationId}`);
  }
  async printQuoteURl(quoteId: string): Promise<string> {
    return this.httpGet(`/devis/print-devis/${quoteId}/print`);
  }
  async removeClaimFromQuote(claimId: string): Promise<void> {
    return this.httpDelete(`/comptabilite/code/${claimId}/`);
  }
  async updateClaimOnQuote(
    claim: OperationDevisCcam,
  ): Promise<OperationDevisCcam> {
    return this.httpPut(`/comptabilite/code/${claim.id}/`, claim);
  }
  async postClaimOnQuote(
    claim: OperationDevisCcam,
    devisId: string,
  ): Promise<OperationDevisCcam> {
    return this.httpPost(`/comptabilite/code?devis=${devisId}`, claim);
  }
  async getQuotes(operationId: string): Promise<PaginatedDevisList> {
    return this.httpGet(`/comptabilite/devis?operation=${operationId}`);
  }
  async getClaimsFromQuote(quoteId: string): Promise<OperationDevisCcam[]> {
    return this.httpGet(`/comptabilite/code?devis=${quoteId}`);
  }
  async createQuote(operationId: string, patientId: string): Promise<Devis> {
    const date = new Date();
    const body = {
      // as ISO YYYY-MM-DD
      date_devis: date.toISOString().split("T")[0],
      depassement_honoraire: "",
      zkf_operation: operationId,
      zkf_patient: patientId,
    };
    return this.httpPost(`/comptabilite/devis/`, body);
  }
  async deleteQuote(quoteId: string): Promise<void> {
    return this.httpDelete(`/comptabilite/devis/${quoteId}/`);
  }
  async getCoverageOptions(): Promise<PaginatedResponse<any>> {
    // TODO: Update with the correct type
    return this.httpGet("/patient/coverage");
  }
  async getOrdonnances(
    consultationId?: string,
    hospitalisationId?: string,
    operationId?: string,
  ): Promise<PaginatedResponse<Ordonnance>> {
    let query = "";
    if (consultationId) {
      query = "?consultation=" + consultationId;
    } else if (hospitalisationId) {
      query = "?hospitalisation=" + hospitalisationId;
    } else if (operationId) {
      query = "?operation=" + operationId;
    }
    return this.httpGet(`/ordonnance/${query}`);
  }
  async getOrdonnanceTemplates(
    consultationId?: string,
    hospitalisationId?: string,
  ): Promise<OrdonnanceTemplate[]> {
    const query = consultationId
      ? `?consultation=${consultationId}`
      : hospitalisationId
        ? `?hospitalisation=${hospitalisationId}`
        : "";
    return this.httpGet(`/ordonnance/template${query}`);
  }
  async postOrdonnance(data: Partial<Ordonnance>): Promise<Ordonnance> {
    return this.httpPost(`/ordonnance/`, data);
  }
  async putOrdonnance(id: string, data: Ordonnance): Promise<Ordonnance> {
    return this.httpPut(`/ordonnance/${id}`, data);
  }
  async deleteOrdonnance(id: string): Promise<void> {
    return this.httpDelete(`/ordonnance/${id}`);
  }
  reportError(error: Error): void {
    console.error(error);
  }

  async listUserSpecialites(): Promise<Specialite[]> {
    return this.httpGet(`/user/specialites`);
  }

  async listContacts(): Promise<Contact[]> {
    return this.httpGet(`/contact`);
  }

  async listContactsByPatient(patientId: string): Promise<Contact[]> {
    return this.httpGet(`/contact/?patient=${patientId}`);
  }

  async createContact(contact: Partial<CustomContact>): Promise<Contact> {
    return this.httpPost(`/contact/`, contact);
  }

  async updateContact(contact: Partial<CustomContact>): Promise<Contact> {
    return this.httpPatch(`/contact/`, contact);
  }

  async listCompteRendu(patientId: string): Promise<CompteRendu[]> {
    return this.httpGet(`/compte-rendu/?patient=${patientId}`);
  }

  async createCompteRendu(
    compteRendus: MetaData,
    patientId: string,
  ): Promise<CompteRendu> {
    const formData = new FormData();
    formData.append("zkf_patient", patientId);
    formData.append("nom", compteRendus.nom || "");
    formData.append("zkf_auteur", compteRendus.auteur?.id || "");
    formData.append("type_compte_rendu", compteRendus.type || "");
    compteRendus.file && formData.append("examen_file", compteRendus.file);
    compteRendus?.specialites?.forEach((spe) =>
      formData.append("specialite", spe.id),
    );
    compteRendus.dateExamen &&
      formData.append("date_examen", compteRendus.dateExamen);
    compteRendus.dateEntree &&
      formData.append("date_entree", compteRendus.dateEntree);
    compteRendus.dateSortie &&
      formData.append("date_sortie", compteRendus.dateSortie);
    formData.append("notification", `${compteRendus.notification}`);
    return this.httpPostFormData(`/compte-rendu/`, formData);
  }

  async updateCompteRendu(compteRendu: CompteRendu): Promise<CompteRendu> {
    const formData = new FormData();
    compteRendu.zkf_patient &&
      formData.append("zkf_patient", compteRendu.zkf_patient);
    formData.append("nom", compteRendu.nom || "");
    formData.append("zkf_auteur", compteRendu.auteur || "");
    formData.append("type_compte_rendu", compteRendu.type_compte_rendu || "");
    compteRendu?.specialite?.forEach((spe) =>
      formData.append("specialite", spe),
    );
    compteRendu.date_examen &&
      formData.append("date_examen", compteRendu.date_examen);
    compteRendu.date_entree &&
      formData.append("date_entree", compteRendu.date_entree);
    compteRendu.date_sortie &&
      formData.append("date_sortie", compteRendu.date_sortie);
    return this.httpPatchFormData(`/compte-rendu/${compteRendu.id}`, formData);
  }

  async deleteCompteRendu(compteRenduId: string): Promise<void> {
    return this.httpDelete(`/compte-rendu/${compteRenduId}`);
  }

  async patchConsultation(
    consultatioId: string,
    body: any, // format HH:MM:SS
  ): Promise<Consultation> {
    return this.httpPatch(`/consultation/${consultatioId}`, body);
  }

  async listPhonesByContact(contactId: string): Promise<Telephone[]> {
    return this.httpGet(`/contact/telephone?contact=${contactId}`);
  }

  async listEmailsByContact(contactId: string): Promise<Email[]> {
    return this.httpGet(`/contact/email?contact=${contactId}`);
  }

  async listAddressesByContact(contactId: string): Promise<Adresse[]> {
    return this.httpGet(`/contact/adresse?contact=${contactId}`);
  }

  async listPhonesByPatient(patientId: string): Promise<Telephone[]> {
    return this.httpGet(`/contact/telephone?patient=${patientId}`);
  }

  async listEmailsByPatient(patientId: string): Promise<Email[]> {
    return this.httpGet(`/contact/email?patient=${patientId}`);
  }

  async listAddressesByPatient(patientId: string): Promise<Adresse[]> {
    return this.httpGet(`/contact/adresse?patient=${patientId}`);
  }

  async createPhone(
    telephone: PatchedTelephone,
    patientId: string,
  ): Promise<Telephone> {
    const query = patientId ? `?patient=${patientId}` : "";
    return this.httpPost(`/contact/telephone${query}`, telephone);
  }

  async createEmail(email: PatchedEmail, patientId: string): Promise<Email> {
    const query = patientId ? `?patient=${patientId}` : "";
    return this.httpPost(`/contact/email${query}`, email);
  }

  async createAdresse(
    adresse: PatchedAdresse,
    patientId: string,
  ): Promise<Adresse> {
    const query = patientId ? `?patient=${patientId}` : "";
    return this.httpPost(`/contact/adresse${query}`, adresse);
  }

  async linkContactToPatient(
    patientId: string,
    contacts: string[],
  ): Promise<void> {
    return this.httpPatch(`/patient/${patientId}/`, {
      correspondants: contacts,
    });
  }

  async searchVilleByZipCode(zipCode: string): Promise<Ville[]> {
    return this.httpGet(`/contact/ville?zip_code=${zipCode}`);
  }

  async listDocumentLibreTemplates(
    userId: number,
  ): Promise<DocumentLibreTemplate[]> {
    return this.httpGet(`/document-libre/template?user=${userId}`);
  }

  async createDocumentLibreTemplate(
    document: Partial<DocumentLibreTemplate>,
    file?: File,
    duplicateUser: number[] = [],
  ): Promise<DocumentLibreTemplate> {
    const formData = new FormData();
    document.titre_document &&
      formData.append("titre_document", document.titre_document);
    document.description_document &&
      formData.append("description_document", document.description_document);
    document.corps_document &&
      formData.append("corps_document", document.corps_document);
    document.specialite?.forEach((spe) => formData.append("specialite", spe));
    document.zkf_user &&
      formData.append("zkf_user", document.zkf_user.toString());
    file && formData.append("document_type_file", file);
    duplicateUser.forEach((user) =>
      formData.append("duplicate_user", user.toString()),
    );
    return this.httpPostFormData(`/document-libre/template`, formData);
  }

  async updateDocumentLibreTemplate(
    document: Partial<DocumentLibreTemplate>,
  ): Promise<DocumentLibreTemplate> {
    return this.httpPatch(`/document-libre/template/${document.id}`, document);
  }

  async deleteDocumentLibreTemplate(id: string): Promise<void> {
    return this.httpDelete(`/document-libre/template/${id}`);
  }

  async listDocumentsByOperation(
    operationId: string,
  ): Promise<DocumentLibre[]> {
    return this.httpGet(`/document-libre?operation=${operationId}`);
  }

  async createDocument(
    document: Partial<DocumentLibre>,
  ): Promise<DocumentLibre> {
    return this.httpPost(`/document-libre/`, document);
  }

  async deleteDocument(documentId: string): Promise<void> {
    return this.httpDelete(`/document-libre/${documentId}`);
  }

  async listUserServices(deep: boolean = false): Promise<Service[]> {
    return this.httpGet(`/user/service?depth=${deep ? 1 : 0}`);
  }

  async getOrdonnanceCategories(): Promise<CategorieOrdonnance[]> {
    return this.httpGet(`/ordonnance/categorie`);
  }

  async getOrdonnancesTemplate(userId: number): Promise<OrdonnanceTemplate[]> {
    return this.httpGet(`/ordonnance/template?user=${userId}`);
  }

  async createOrdonnanceTemplate(
    ordonnance: Partial<OrdonnanceTemplate>,
    duplicateUsers: number[] = [],
  ): Promise<OrdonnanceTemplate> {
    return this.httpPost(`/ordonnance/template/`, {
      ...ordonnance,
      duplicate_user: duplicateUsers,
    });
  }

  async updateOrdonnanceTemplate(
    ordonnance: Partial<OrdonnanceTemplate>,
  ): Promise<OrdonnanceTemplate> {
    return this.httpPatch(`/ordonnance/template/${ordonnance.id}`, ordonnance);
  }

  async deleteOrdonnanceTemplate(id: string): Promise<void> {
    return this.httpDelete(`/ordonnance/template/${id}`);
  }

  async getMaterielIrradiation(): Promise<MatrielIrradiation[]> {
    return this.httpGet(`/operation/appareil-scopie`);
  }

  async createMaterielIrradiation(
    materiel: Partial<MatrielIrradiation>,
  ): Promise<MatrielIrradiation> {
    return this.httpPost(`/operation/appareil-scopie`, materiel);
  }

  async updateMaterielIrradiation(
    materiel: Partial<MatrielIrradiation>,
  ): Promise<MatrielIrradiation> {
    return this.httpPatch(
      `/operation/appareil-scopie/${materiel.id}`,
      materiel,
    );
  }

  async deleteMaterielIrradiation(id: string): Promise<void> {
    return this.httpDelete(`/operation/appareil-scopie/${id}`);
  }

  async getConsignesSortie(): Promise<ConsignesSortie[]> {
    return this.httpGet(`/hospitalisation/consigne_sortie`);
  }

  async createConsignesSortie(
    consigne: Partial<ConsignesSortie>,
  ): Promise<ConsignesSortie> {
    return this.httpPost(`/hospitalisation/consigne_sortie/`, consigne);
  }

  async updateConsignesSortie(
    consigne: Partial<ConsignesSortie>,
  ): Promise<ConsignesSortie> {
    return this.httpPatch(
      `/hospitalisation/consigne_sortie/${consigne.id}`,
      consigne,
    );
  }

  async deleteConsignesSortie(id: string): Promise<void> {
    return this.httpDelete(`/hospitalisation/consigne_sortie/${id}`);
  }

  async getConsultationType(): Promise<ConsultationType[]> {
    return this.httpGet(`/consultation/consultation-type`);
  }

  async createConsultationType(
    consultation: Partial<ConsultationType>,
  ): Promise<ConsultationType> {
    return this.httpPost(`/consultation/consultation-type/`, consultation);
  }

  async updateConsultationType(
    consultation: Partial<ConsultationType>,
  ): Promise<ConsultationType> {
    return this.httpPatch(
      `/consultation/consultation-type/${consultation.id}`,
      consultation,
    );
  }

  async deleteConsultationType(id: string): Promise<void> {
    return this.httpDelete(`/consultation/consultation-type/${id}`);
  }

  async getVacations(): Promise<CustomVacation[]> {
    return this.httpGet(`/agenda/vacation?depth=1`);
  }

  async createVacation(vacation: Partial<Vacation>): Promise<Vacation> {
    return this.httpPost(`/agenda/vacation/`, vacation);
  }

  async updateVacation(vacation: Partial<Vacation>): Promise<Vacation> {
    return this.httpPatch(`/agenda/vacation/${vacation.id}/`, vacation);
  }

  async deleteVacation(id: Vacation["id"]): Promise<void> {
    return this.httpDelete(`/agenda/vacation/${id}/`);
  }

  async getAbsences(): Promise<CustomAbsence[]> {
    return this.httpGet(`/agenda/leave?depth=1`);
  }

  async createAbsence(vacation: Partial<Absence>): Promise<Absence> {
    return this.httpPost(`/agenda/leave/`, vacation);
  }

  async updateAbsence(vacation: Partial<Absence>): Promise<Absence> {
    return this.httpPatch(`/agenda/leave/${vacation.id}/`, vacation);
  }

  async deleteAbsence(id: Absence["id"]): Promise<void> {
    return this.httpDelete(`/agenda/leave/${id}/`);
  }

  async getPatientFields(): Promise<Field[]> {
    return this.httpGet(`/settings/model-fields`);
  }

  async getUserPreferences(id: string): Promise<PreferenceUserSerialyzer> {
    return this.httpGet(`/user/preferences-user/${id}`);
  }

  async updateUser(user: User): Promise<User> {
    return this.httpPatch(`/user/${user.id}/`, user);
  }

  async updateProfile(profile: Profile): Promise<Profile> {
    return this.httpPatch(`/user/profile/${profile.id}`, profile);
  }

  async updatePreferencesUser(
    preferences: Partial<PreferenceUserSerialyzer>,
  ): Promise<PreferenceUserSerialyzer> {
    return this.httpPatch(
      `/user/preferences-user/${preferences.id}`,
      preferences,
    );
  }

  async getCcamActivite(): Promise<R_activite[]> {
    return this.httpGet(`/api/ccam/r_activite`);
  }

  async getEnteteDocument(id: string): Promise<EnteteDocument> {
    return this.httpGet(`/api/user/entete-document/${id}`);
  }

  async createEnteteDocument(
    entete: Partial<EnteteDocument>,
  ): Promise<EnteteDocument> {
    return this.httpPost(`/api/user/entete-document/`, entete);
  }

  async updateEnteteDocument(
    entete: Partial<EnteteDocument>,
  ): Promise<EnteteDocument> {
    return this.httpPatch(`/api/user/entete-document/${entete.id}`, entete);
  }

  async getPatientEvents(id: string): Promise<CalendarItem[]> {
    return this.httpGet(`/patient/${id}/events`);
  }

  async getOperationsByPatient(id: string): Promise<Operation[]> {
    return this.httpGet(`/operation?patient=${id}`);
  }

  async getComplicationsPostOp(id: string): Promise<ComplicationPostOp[]> {
    return this.httpGet(`/operation/complications-post-op?operation=${id}`);
  }

  async updateComplicationsPostOp(
    complications: ComplicationPostOp,
    operationId: string,
  ): Promise<ComplicationPostOp> {
    return this.httpPatch(
      `/operation/complications-post-op/${complications.id}?operation=${operationId}`,
      complications,
    );
  }
  async getTags(): Promise<FullTag[]> {
    return this.httpGet(`/api/tag`);
  }
}
