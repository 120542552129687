import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import React from "react";
import { useApi } from "../../contexts/ApiContext";
import { Specialite } from "../../models/types";
import Typography from "@mui/joy/Typography";
import Tooltip from "@mui/joy/Tooltip";

interface SpecialiteMultiSelectProps {
  value: Specialite["id"][] | null;
  onSelect: (value: Specialite[] | null) => void;
  width?: string;
}

export default function SpecialiteMultiSelect({
  value,
  onSelect,
  width = "auto",
}: SpecialiteMultiSelectProps) {
  const api = useApi();

  const [specialites, setSpecialites] = React.useState<Specialite[]>([]);

  React.useEffect(() => {
    const fetch = async () => {
      const res = await api.listUserSpecialites();
      setSpecialites(res);
    };
    fetch();
  }, [api]);

  const specialitesValue = specialites
    .filter((spe) => value?.includes(spe.id))
    .map((spe) => spe.spe)
    .join(", ");

  return (
    <Tooltip title={specialitesValue}>
      <Select
        multiple
        renderValue={() => <Typography noWrap>{specialitesValue}</Typography>}
        value={value || []}
        onChange={(e, newValue) =>
          onSelect(
            specialites.filter((spe) => newValue?.includes(spe.id)) || [],
          )
        }
        style={{ width }}
      >
        {specialites.map((spe) => (
          <Option key={spe.id} value={spe.id}>
            {spe.spe}
          </Option>
        ))}
      </Select>
    </Tooltip>
  );
}
