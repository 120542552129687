import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useApi } from "../../../../contexts/ApiContext";
import { useUser } from "../../../../contexts/UserContext";
import { DocumentLibreTemplate } from "../../../../models/types";
import ListOfDocuments from "./ListOfDocuments";
import { useSnackbar } from "../../../../contexts/SnackbarContext";
import ModalComponent from "../../../../components/Modal/Modal";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Input from "@mui/joy/Input";
import Divider from "@mui/joy/Divider";
import Editor from "../../../../components/Editor/Editor";
import Button from "@mui/joy/Button";
import { Clear, CloudUploadOutlined } from "@mui/icons-material";
import { VisuallyHiddenInput } from "@chakra-ui/react";
import PreviewFile from "../../../../components/File/PreviewFile";
import OperatorSelector from "../../../../components/Forms/OperatorSelector";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FieldsPanel from "./fieldsPanel";
import SpecialiteMultiSelect from "../../../../components/Forms/SpecialiteMultiSelect";

export default function DocumentLibreTab() {
  const api = useApi();
  const user = useUser();
  const snackbar = useSnackbar();

  const [documentsLibre, setDocumentsLibre] = useState<DocumentLibreTemplate[]>(
    [],
  );
  const [editDocument, setEditDocument] = useState<DocumentLibreTemplate>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [content, setContent] = React.useState<string>("");
  const [titre, setTitre] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [selectedSpecialites, setSelectedSpecialites] = React.useState<
    string[]
  >([]);
  const [file, setFile] = React.useState<File | undefined>();
  const [duplicateUsers, setDuplicateUsers] = React.useState<number[]>([]);
  const [editorRef, setEditorRef] = React.useState<ClassicEditor>();

  useEffect(() => {
    if (api && user.user?.id) {
      api
        .listDocumentLibreTemplates(user.user?.id)
        .then((res) => setDocumentsLibre(res));
    }
  }, [api, user.user?.id]);

  const handleDelete = useCallback(
    (id: string) => {
      api
        .deleteDocumentLibreTemplate(id)
        .then(() =>
          setDocumentsLibre(documentsLibre.filter((d) => d.id !== id)),
        )
        .catch(() =>
          snackbar.show("Impossible de supprimer le template", "danger"),
        );
    },
    [api, documentsLibre, snackbar],
  );

  const handleSave = useCallback(() => {
    const newDoc: Partial<DocumentLibreTemplate> = {
      titre_document: titre,
      description_document: description,
      corps_document: content,
      specialite: selectedSpecialites,
      zkf_user: user.user?.id,
    };
    if (editDocument) {
      api
        .updateDocumentLibreTemplate({ id: editDocument.id, ...newDoc })
        .then((res) => {
          setDocumentsLibre((prevState) =>
            prevState.map((d) => (d.id === editDocument.id ? res : d)),
          );
          setOpenModal(false);
        })
        .catch(() =>
          snackbar.show("Impossible de modifier le template", "danger"),
        );
    } else {
      api
        .createDocumentLibreTemplate(newDoc, file, duplicateUsers)
        .then((res) => {
          setDocumentsLibre((prevState) => [...prevState, res]);
          setOpenModal(false);
        })
        .catch(() =>
          snackbar.show("Impossible de créer le template", "danger"),
        );
    }
  }, [
    api,
    content,
    description,
    duplicateUsers,
    editDocument,
    file,
    selectedSpecialites,
    snackbar,
    titre,
    user.user?.id,
  ]);

  const previewFile = useMemo(
    () => file && <PreviewFile file={file} />,
    [file],
  );

  const editModal = useMemo(() => {
    return (
      <ModalComponent
        open={openModal}
        title={`${editDocument ? "Editer" : "Créer"} un document libre`}
        onClose={() => setOpenModal(false)}
        validateLabel={"Enregistrer"}
        onValidate={handleSave}
        canValidate={!!titre && (!!file || !!content)}
        style={{ minWidth: "80%", minHeight: "80%" }}
      >
        <Stack spacing={2} flex={1}>
          <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
            <Stack spacing={1} width={"300px"}>
              <Typography>Titre</Typography>
              <Input value={titre} onChange={(e) => setTitre(e.target.value)} />
            </Stack>
            <Stack spacing={1} width={"350px"}>
              <Typography>Description</Typography>
              <Input
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Stack>
            <Stack spacing={1}>
              <Typography>Spécialités</Typography>
              <SpecialiteMultiSelect
                value={selectedSpecialites}
                onSelect={(spe) =>
                  setSelectedSpecialites(spe?.map((spe) => spe.id) || [])
                }
                width={"250px"}
              />
            </Stack>
            <Stack spacing={1} width={"350px"}>
              <Typography>File</Typography>
              <Button
                component="label"
                variant={"outlined"}
                startDecorator={<CloudUploadOutlined />}
              >
                Upload a file
                <VisuallyHiddenInput
                  type="file"
                  accept={".pdf,.doc,.docx"}
                  onChange={(e) =>
                    e.target.files?.[0] && setFile(e.target.files[0])
                  }
                />
              </Button>
              {file && (
                <Typography
                  level={"body-xs"}
                  endDecorator={
                    <Clear
                      sx={{ cursor: "pointer" }}
                      onClick={() => setFile(undefined)}
                    />
                  }
                >
                  {file.name}
                </Typography>
              )}
            </Stack>
            <Stack spacing={1} width={"350px"}>
              <Typography>Dupliquer pour les utilisateurs</Typography>
              <OperatorSelector
                onSelect={(users) => setDuplicateUsers(users)}
                exludeOperators={user.user?.id ? [user.user?.id] : []}
              />
            </Stack>
          </Stack>
          <Divider />
          <Stack flex={1}>
            {file ? (
              previewFile
            ) : (
              <Stack direction={"row"} spacing={2} flex={1}>
                <div style={{ flex: 1 }}>
                  <Editor
                    data={content}
                    onChange={setContent}
                    onReady={setEditorRef}
                  />
                </div>
                <FieldsPanel
                  editor={editorRef}
                  filters={["general", "patient", "operation_short"]}
                  style={{ height: "450px" }}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      </ModalComponent>
    );
  }, [
    content,
    description,
    editDocument,
    editorRef,
    file,
    handleSave,
    openModal,
    previewFile,
    selectedSpecialites,
    titre,
    user.user?.id,
  ]);

  const handleEdit = useCallback((document?: DocumentLibreTemplate) => {
    setContent(document?.corps_document || "");
    setTitre(document?.titre_document || "");
    setDescription(document?.description_document || "");
    setSelectedSpecialites(document?.specialite || []);
    setFile(undefined);
    setEditDocument(document);
    setOpenModal(true);
  }, []);

  return (
    <>
      <ListOfDocuments
        documents={documentsLibre}
        buttonLabel={"Créer un document"}
        editDocument={handleEdit}
        deleteDocument={handleDelete}
        documentLabelField={"titre_document"}
        documentCorpsField={"corps_document"}
        documentFilePathField={"document_type_file"}
      />
      {editModal}
    </>
  );
}
