import { Add, Masks } from "@mui/icons-material";
import { Box, Sheet } from "@mui/joy";
import Button from "@mui/joy/Button";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { OperationWithinHospitalisation } from "../../models/custom";
import { router } from "../../router";
import { formatDate, truncateText } from "../../utils/utils";

interface ListOfOperationsProps {
  operations: OperationWithinHospitalisation[];
  hideHeader?: boolean;
  title?: string;
}

export default function ListOfOperations({
  operations,
  hideHeader,
  title = "Opérations",
}: ListOfOperationsProps) {
  return (
    <Box>
      {!hideHeader && (
        <Stack
          sx={{ mb: 2 }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            level="title-lg"
            color="success"
            startDecorator={<Masks />}
          >
            {title}
          </Typography>
          {/* 
          TODO: implement form to create an operation withing this hospitalisation
          <Stack direction="row" gap={0.5}>
            <Button
              variant="soft"
              startDecorator={<Add />}
              color="success"
              size="sm"
              onClick={() => router.navigate("/hospitalisation/create")}
            >
              Créer
            </Button>
          </Stack> */}
        </Stack>
      )}
      <Stack direction="column" gap={1}>
        {operations && operations.length > 0 ? (
          operations.map((ope: OperationWithinHospitalisation) => (
            <Sheet
              variant="soft"
              color="success"
              sx={{
                p: 2,
                borderRadius: "sm",
                "&:hover": { cursor: "pointer" },
              }}
              key={ope.id}
              onClick={() => router.navigate("/operation/" + ope.id)}
            >
              <Typography level="body-xs">
                {formatDate(ope.operation_date)}
              </Typography>
              <Typography level="body-sm">
                {truncateText(ope.titre_operation, 130)}
              </Typography>
            </Sheet>
          ))
        ) : (
          <Stack
            direction="column"
            gap={1}
            sx={{
              alignItems: "center",
              justifyContent: "center",
              height: "100px",
            }}
          >
            <Typography level="body-md">Pas d'opération</Typography>
          </Stack>
        )}
      </Stack>
    </Box>
  );
}
