import DoneIcon from "@mui/icons-material/Done";
import Checkbox from "@mui/joy/Checkbox";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import React from "react";

interface ModificateursInputProps {
  value: string;
  onChange: (value: string) => void;
  modificateurs: string[];
  defaultValues?: string[];
}

export default function ModificateursInput({
  value,
  onChange,
  modificateurs,
  defaultValues,
}: ModificateursInputProps) {
  const addOrRemove = (item: string) => {
    if (value.includes(item)) {
      onChange(
        value
          .split(",")
          .filter((v) => v !== item)
          .join(","),
      );
    } else {
      onChange(value ? `${value},${item}` : item);
    }
  };
  React.useEffect(() => {
    //set default values
    if (defaultValues) {
      onChange(defaultValues.join(","));
    }
  }, [defaultValues]);
  return (
    <div role="group" aria-labelledby="rank">
      <List
        orientation="horizontal"
        wrap
        sx={{
          "--List-gap": "8px",
          "--ListItem-radius": "20px",
          "--ListItem-minHeight": "32px",
          "--ListItem-gap": "4px",
        }}
      >
        {modificateurs.map((item, index) => (
          <ListItem sx={{ minWidth: 32, textAlign: "center" }} key={index}>
            {value.includes(item) && (
              <DoneIcon
                fontSize="medium"
                color="primary"
                sx={{
                  ml: -0.5,
                  zIndex: 2,
                  pointerEvents: "none",
                }}
              />
            )}
            <Checkbox
              size="sm"
              disableIcon
              overlay
              label={item}
              checked={value.includes(item)}
              variant={value.includes(item) ? "soft" : "outlined"}
              onChange={() => {
                addOrRemove(item);
              }}
              slotProps={{
                action: ({ checked }) => ({
                  sx: checked
                    ? {
                        border: "1px solid",
                        borderColor: "primary.500",
                      }
                    : {},
                }),
              }}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
}
