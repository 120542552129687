import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import Chip from "@mui/joy/Chip";
import IconButton from "@mui/joy/IconButton";
import Stack from "@mui/joy/Stack";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import React from "react";
import { OperationDevisCcam } from "../../models/types";
import { formatNumbersToFrench, roundTo2Decimals } from "../../utils/utils";

interface ClaimsWithChildren extends OperationDevisCcam {
  children: OperationDevisCcam[];
}

interface RowProps {
  row: ClaimsWithChildren;
  initialOpen?: boolean;
  onEditActe: (claim: OperationDevisCcam) => void;
  onDeleteActe: (claim: OperationDevisCcam) => void;
  isChildren?: boolean;
}

function Row({
  row,
  initialOpen,
  onEditActe,
  onDeleteActe,
  isChildren,
}: RowProps) {
  const [open, setOpen] = React.useState<boolean>(initialOpen || false);
  const price =
    Number(row.prix_modificateurs) + Number(row.depassement_honoraire_acte);
  const [hasChildren, setHasChildren] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (row.children && row.children.length > 0 && open) {
      setHasChildren(true);
    } else {
      setHasChildren(false);
    }
  }, [row.children, open]);

  return (
    <React.Fragment>
      <tr
        style={{
          marginLeft: isChildren ? "20px" : "0",
          backgroundColor: isChildren ? "#f9f9f9" : "white",
        }}
      >
        <td
          style={{
            borderBottomColor: hasChildren ? "transparent" : "inherit",
          }}
        >
          {!isChildren && (
            <IconButton
              aria-label="expand row"
              variant="plain"
              color="neutral"
              size="sm"
              onClick={() => setOpen(!open)}
              disabled={row.children.length === 0}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </td>
        <td
          style={{ borderBottomColor: hasChildren ? "transparent" : "inherit" }}
        >
          <Typography level="body-sm">{row.acte_ccam}</Typography>
        </td>
        <td
          style={{ borderBottomColor: hasChildren ? "transparent" : "inherit" }}
        >
          <Stack gap={0.5}>
            <Typography level="body-xs">{row.acte_nom_long}</Typography>

            <Stack direction="row" gap={2}>
              {!!row.acte_modificateur && (
                <Stack direction="row" gap={0.5}>
                  <Typography level="body-xs">
                    <small style={{ opacity: 0.75 }}>MODIFICATEURS(S)</small>
                  </Typography>
                  {row.acte_modificateur.split(",").map((modif, index) => (
                    <Chip size="sm" variant="outlined" key={index}>
                      {modif}
                    </Chip>
                  ))}
                </Stack>
              )}
              {!!row.acte_activite && (
                <Typography
                  level="body-xs"
                  startDecorator={
                    <small style={{ opacity: 0.75 }}>ACTIVITÉ</small>
                  }
                >
                  {row.acte_activite}
                </Typography>
              )}
              {!!row.acte_association && (
                <Typography
                  level="body-xs"
                  startDecorator={
                    <small style={{ opacity: 0.75 }}>ASSOCIATION</small>
                  }
                >
                  {row.acte_association}
                </Typography>
              )}
            </Stack>
          </Stack>
        </td>
        <td
          style={{
            borderBottomColor: hasChildren ? "transparent" : "inherit",
            textAlign: "right",
          }}
        >
          {formatNumbersToFrench(Number(row.prix_modificateurs))}€ (
          {formatNumbersToFrench(Number(row.prix_base))} )€
        </td>
        <td
          style={{
            borderBottomColor: hasChildren ? "transparent" : "inherit",
            textAlign: "right",
          }}
        >
          {formatNumbersToFrench(price)} €
          {Number(row.depassement_honoraire_acte) > 0 && (
            <Typography level="body-xs" color="warning">
              (+{row.depassement_honoraire_acte} €)
            </Typography>
          )}
        </td>
        <td
          style={{ borderBottomColor: hasChildren ? "transparent" : "inherit" }}
        >
          <Stack direction="row" spacing={0.5} justifyContent="center">
            <IconButton size="sm" onClick={() => onEditActe(row)}>
              <EditIcon />
            </IconButton>
            <IconButton size="sm" onClick={() => onDeleteActe(row)}>
              <DeleteIcon />
            </IconButton>
          </Stack>
        </td>
      </tr>
      {open &&
        row.children &&
        row.children.length > 0 &&
        row.children.map((child, key) => (
          <tr
            key={key}
            style={{
              backgroundColor: "#f9f9f9",
            }}
          >
            <td></td>
            <td>
              <SubdirectoryArrowRightIcon
                sx={{ ml: "-20px", mt: "2px", position: "absolute" }}
              />
              <Typography level="body-sm">{child.acte_ccam}</Typography>
            </td>
            <td>
              <Typography level="body-xs">{child.acte_nom_long}</Typography>
            </td>
            <td style={{ textAlign: "right" }}>
              {formatNumbersToFrench(Number(child.prix_base))} €
            </td>
            <td style={{ textAlign: "right" }}>
              {formatNumbersToFrench(
                Number(child.prix_base) +
                  Number(child.depassement_honoraire_acte),
              )}
              €
              {Number(child.depassement_honoraire_acte) > 0 && (
                <Typography level="body-xs" color="warning">
                  (+{child.depassement_honoraire_acte} €)
                </Typography>
              )}
            </td>
            <td>
              <Stack direction="row" spacing={0.5} justifyContent="center">
                <IconButton size="sm" onClick={() => onEditActe(child)}>
                  <EditIcon />
                </IconButton>
                <IconButton size="sm" onClick={() => onDeleteActe(child)}>
                  <DeleteIcon />
                </IconButton>
              </Stack>
            </td>
          </tr>
        ))}
    </React.Fragment>
  );
}

interface ClaimListProps {
  claims: OperationDevisCcam[];
  onEditClaim: (claim: OperationDevisCcam) => void;
  onDeleteClaim: (claim: OperationDevisCcam) => void;
}

export default function ClaimList({
  claims,
  onEditClaim,
  onDeleteClaim,
}: ClaimListProps) {
  const totalBase = roundTo2Decimals(
    claims.reduce((acc, claim) => acc + Number(claim.prix_modificateurs), 0),
  );
  let totalDepassement = roundTo2Decimals(
    claims.reduce(
      (acc, claim) => acc + Number(claim.depassement_honoraire_acte),
      0,
    ),
  );
  const total = roundTo2Decimals(totalBase + totalDepassement);

  // We need to group claims by their zkf_acte and then remove children from the claims list
  const [groupedClaims, setGroupedClaims] = React.useState<
    ClaimsWithChildren[]
  >([]);
  React.useEffect(() => {
    const claimsThatHaveParent = claims.filter((claim) => claim.zkf_acte);
    const parentClaims = claims.filter((claim) => !claim.zkf_acte);
    // from the claims that have a parent, we group their children with them in the children property
    const groupedClaims = parentClaims.map((parent) => ({
      ...parent,
      children: claimsThatHaveParent.filter(
        (claim) => claim.zkf_acte === parent.id,
      ),
    }));
    setGroupedClaims(groupedClaims);
  }, [claims]);

  return (
    <Table
      variant="outlined"
      aria-label="collapsible table"
      sx={{
        // no borderTop if there are children
        mt: 2,
      }}
    >
      <thead>
        <tr>
          <th style={{ width: 40 }} aria-label="empty" />
          <th style={{ width: 80 }}>Code</th>
          <th>Libellé</th>
          <th style={{ width: 100, textAlign: "right" }}>Prix</th>
          <th style={{ width: 100, textAlign: "right" }}>Total</th>
          <th style={{ width: 100, textAlign: "center" }}>Actions</th>
        </tr>
      </thead>
      <tbody>
        {groupedClaims
          .sort((a, b) => Number(b.prix_base) - Number(a.prix_base))
          .map((claim, index) => (
            <Row
              key={index}
              row={claim}
              initialOpen
              onEditActe={(claim) => onEditClaim(claim)}
              onDeleteActe={(claim) => onDeleteClaim(claim)}
            />
          ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={6}>
            <Stack direction="row" justifyContent="flex-end" gap={1}>
              <Typography
                level="body-xs"
                startDecorator={<span style={{ opacity: 0.75 }}>TOTAL SS</span>}
              >
                {formatNumbersToFrench(totalBase)} €
              </Typography>
            </Stack>
          </td>
        </tr>
        <tr>
          <td colSpan={6}>
            <Stack direction="row" justifyContent="flex-end" gap={1}>
              <Typography
                level="body-xs"
                startDecorator={
                  <span style={{ opacity: 0.75 }}>DÉPASSEMENTS</span>
                }
              >
                {formatNumbersToFrench(totalDepassement)} €
              </Typography>
            </Stack>
          </td>
        </tr>
        <tr>
          <td colSpan={6}>
            <Stack direction="row" justifyContent="flex-end" gap={1}>
              <Typography
                level="body-sm"
                startDecorator={<span style={{ opacity: 0.75 }}>TOTAL</span>}
              >
                {formatNumbersToFrench(total)} €
              </Typography>
            </Stack>
          </td>
        </tr>
      </tfoot>
    </Table>
  );
}
