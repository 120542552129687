import Add from "@mui/icons-material/Add";
import LocalHospital from "@mui/icons-material/LocalHospital";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button/Button";
import Chip from "@mui/joy/Chip";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { Hospitalisation } from "../../models/types";
import { router } from "../../router";
import { formatDate } from "../../utils/utils";
import React from "react";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import CreateHospitalisationModal from "./Hospitalisation/CreateHospitalisationModal";

interface ListOfHospitalisationsProps {
  hospitalisations: Hospitalisation[];
  refresh: () => void;
  hideHeader?: boolean;
}

export default function ListOfHospitalisations({
  hospitalisations,
  hideHeader,
  refresh,
}: ListOfHospitalisationsProps) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  hospitalisations.sort(
    (a, b) =>
      new Date(b.entree_date!).getTime() - new Date(a.entree_date!).getTime(),
  );

  const handleOpenCreateHospitalisationModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseCreateHospitalisationModal = () => {
    setIsModalOpen(false);
  };

  const handleOnSave = () => {
    handleCloseCreateHospitalisationModal();
    refresh();
  };

  return (
    <>
      <Box>
        {!hideHeader && (
          <Stack
            sx={{ mb: 2 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              level="title-lg"
              color="warning"
              startDecorator={<LocalHospital />}
            >
              Hospitalisations
            </Typography>
            <Button
              variant="soft"
              startDecorator={<Add />}
              color="warning"
              size="sm"
              onClick={handleOpenCreateHospitalisationModal}
            >
              Créer
            </Button>
          </Stack>
        )}
        <Stack direction="column" gap={1}>
          {hospitalisations && hospitalisations.length === 0 ? (
            <Stack
              sx={{ height: "120px" }}
              alignItems="center"
              justifyContent="center"
            >
              <Typography level="body-md" color="neutral">
                Aucune hospitalisation
              </Typography>
            </Stack>
          ) : (
            hospitalisations.map((hospi: Hospitalisation) => (
              <Sheet
                key={hospi.id}
                color="warning"
                variant="soft"
                sx={{
                  border: "1px solid transparent",
                  borderRadius: "sm",
                  p: 2,
                  "&:hover": {
                    borderColor: (theme) => theme.palette.warning[500],
                    cursor: "pointer",
                  },
                }}
                onClick={() => router.navigate(`/hospitalisation/${hospi.id}`)}
              >
                <Stack direction="column" gap={1}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography level="body-md">
                      {formatDate(hospi.entree_date)}
                      {hospi.entree_heure && ":" + hospi.entree_heure} —{" "}
                      {formatDate(hospi.sortie_date)}
                    </Typography>
                    {hospi.operations.length > 0 && (
                      <Typography level="body-md">
                        <Chip variant="outlined">
                          {hospi.operations.length} opération liée
                          {hospi.operations.length > 1 && "s"}
                        </Chip>
                      </Typography>
                    )}
                  </Stack>
                  <Typography level="body-xs">
                    {hospi.motif_hospitalisation}
                  </Typography>
                  <Typography
                    level="body-sm"
                    sx={{ fontStyle: "italic", textAlign: "right" }}
                  ></Typography>
                </Stack>
              </Sheet>
            ))
          )}
        </Stack>
      </Box>
      {isModalOpen && (
        <Modal
          component="div"
          open={isModalOpen}
          onClose={handleCloseCreateHospitalisationModal}
        >
          <ModalDialog>
            <CreateHospitalisationModal onSave={handleOnSave} />
          </ModalDialog>
        </Modal>
      )}
    </>
  );
}
