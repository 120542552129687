import React from "react";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import CircularProgress from "@mui/joy/CircularProgress";
import Button from "@mui/joy/Button";
import History from "@mui/icons-material/History";
import Stack from "@mui/joy/Stack";
import { ErrorOutline } from "@mui/icons-material";

interface AuthPageProps {
  title: string;
  description: string;
  showButton?: boolean;
  loading?: boolean;
  icon?: React.ReactNode;
}

const AuthPage: React.FC<AuthPageProps> = ({
  title,
  description,
  showButton = false,
  loading = false,
  icon = <></>,
}) => (
  <Box
    sx={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      bgcolor: "background.surface",
      gap: 2,
      p: 2,
    }}
  >
    <Stack gap={2} justifyContent="center" alignItems="center">
      <Box
        sx={{
          "& > svg": {
            fontSize: 200,
            color: (theme) => theme.palette.primary[300],
          },
        }}
      >
        {icon}
      </Box>

      <Typography level="h2" component="h1">
        {title}
      </Typography>

      <Typography level="body-md" textAlign="center">
        {description}
      </Typography>

      {loading && <CircularProgress size="lg" />}

      {showButton && (
        <Button onClick={() => window.location.reload()}>Se connecter</Button>
      )}
    </Stack>
  </Box>
);

export const AuthenticationLoading = () => (
  <AuthPage
    title="Authentification en cours"
    description="Veuillez patienter pendant la connexion..."
    loading={true}
    icon={<CircularProgress />}
  />
);

export const AuthenticationError = () => (
  <AuthPage
    title="Erreur d'authentification"
    description="Une erreur est survenue lors de la connexion."
    showButton={true}
    icon={<ErrorOutline />}
  />
);

export const SessionExpired = () => (
  <AuthPage
    title="Session expirée"
    description="Votre session a expiré. Veuillez vous reconnecter."
    showButton={true}
    icon={<History />}
  />
);

export const AuthenticationNotAuthenticated = () => (
  <AuthPage
    title="Non authentifié"
    description="Vous devez vous connecter pour accéder à l'application."
    showButton={true}
  />
);
