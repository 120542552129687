import Add from "@mui/icons-material/Add";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Print from "@mui/icons-material/Print";
import Save from "@mui/icons-material/Save";
import Search from "@mui/icons-material/Search";
import Autocomplete from "@mui/joy/Autocomplete";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Checkbox from "@mui/joy/Checkbox";
import Input from "@mui/joy/Input";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Textarea from "@mui/joy/Textarea";
import Typography from "@mui/joy/Typography";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import PrinterAddIcon from "../../../../assets/icons/PrinterAddIcon";
import Editor from "../../../../components/Editor/Editor";
import { useApi } from "../../../../contexts/ApiContext";
import { usePrintManager } from "../../../../contexts/PrintManagerContext";
import { useSnackbar } from "../../../../contexts/SnackbarContext";
import { CROType } from "../../../../models/custom";
import { Intervenant, Operation } from "../../../../models/types";
import { formatNameAndSurname } from "../../../../utils/utils";

interface EditCROProps {
  operation: Operation;
}

export default function EditCRO({ operation }: EditCROProps) {
  // Context
  // const { addToPrintManager } = usePrintManager();
  const api = useApi();
  const snackbar = useSnackbar();

  // States
  const [croTypes, setCroTypes] = React.useState<CROType[]>([]);
  const [content, setContent] = React.useState<string>(operation.cro || "");
  const [intervenants, setIntervenants] = React.useState<{
    Operateur: Intervenant[];
    Anesthesiste: Intervenant[];
    "Aide Operatoire": Intervenant[];
  }>({
    Operateur: [],
    Anesthesiste: [],
    "Aide Operatoire": [],
  });
  const [selectedOperateurs, setSelectedOperateurs] = React.useState<
    Intervenant[]
  >([]);
  const [selectedAnesthesistes, setSelectedAnesthesistes] = React.useState<
    Intervenant[]
  >([]);
  const [selectedAidesOperatoire, setSelectedAidesOperatoire] = React.useState<
    Intervenant[]
  >([]);
  const [indicationOperatoire, setIndicationOperatoire] = React.useState<
    string | null
  >(operation.indication_operatoire || "");
  const [commentaires, setCommentaires] = React.useState<string>(
    operation.commentaire_operation || "",
  );
  const [start, setStart] = React.useState<Dayjs>(
    operation.operation_start
      ? dayjs(operation.operation_start)
      : dayjs().add(-1, "hour"),
  );
  const [end, setEnd] = React.useState<Dayjs>(
    operation.operation_end ? dayjs(operation.operation_end) : dayjs(),
  );
  const [urgency, setUrgency] = React.useState<boolean>(
    operation.urgence === "urgence" || false,
  );
  const [displayedCroList, setDisplayedCroList] = React.useState<CROType[]>([]);

  // Handlers
  const handleCroSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value.toLowerCase();
    setDisplayedCroList(
      croTypes.filter((cro) =>
        cro.cro_type_titre.toLowerCase().includes(search),
      ),
    );
  };

  const insertCROType = async (cro_id: string) => {
    const cro = croTypes.find((cro) => cro.id === cro_id);
    const cro_content = await api?.getCroContentWithTemplate(
      operation.id,
      cro_id,
    );
    if (cro && cro_content) {
      setContent(content ? content + "<br />" : "" + cro_content.cro_type);
    }
  };

  const postCRO = async () => {
    const newOp: Operation = {
      ...operation,
      cro: content,
      operateur_new: selectedOperateurs
        ? selectedOperateurs.map((o) => o.id)
        : undefined,
      anesthesiste_new: selectedAnesthesistes
        ? selectedAnesthesistes.map((o) => o.id)
        : undefined,
      aides_operatoire: selectedAidesOperatoire
        ? selectedAidesOperatoire.map((o) => o.id)
        : undefined,
      indication_operatoire: indicationOperatoire || "",
      commentaire_operation: commentaires || "",
      // format dayjs to HH:MM:SS
      operation_start: start.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      operation_end: end.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      urgence: urgency ? "urgence" : "programme",
    };
    try {
      await api.updateOperation(newOp);
      snackbar.show("CRO enregistré avec succès", "success");
    } catch (e) {
      console.error(e);
      snackbar.show("Erreur lors de l'enregistrement du CRO", "danger");
    }
  };

  const printCro = async () => {
    // first save, then when saved, print by opening new tab to
    // http://localhost:8000/operation/print-cro/{$CRO_ID}/print
    await postCRO();
    window.open(
      `http://localhost:8000/operation/print-cro/${operation.id}/print`,
      "_blank",
    );
  };

  //old way !
  // const addPrintToQueue = async () => {
  //   await postCRO();
  //   // call the api to add file to queue, get the file
  //   // addToPrintManager("cro", operation.id);
  //   snackbar.show("CRO ajouté au gestionnaire d'impression", "success");
  // };

  const addToPrintManager = () => {
    api
      .addToPrintManager("cro", operation.id)
      .then(() =>
        snackbar.show("CRO ajouté au gestionnaire d'impression", "success"),
      )
      .catch(() =>
        snackbar.show(
          "Erreur lors de l'ajout au gestionnaire d'impression",
          "danger",
        ),
      );
  };

  React.useEffect(() => {
    try {
      // fetch cro types
      api?.getCROTypes().then((res) => {
        setCroTypes(res);
        setDisplayedCroList(res);
      });
      api?.getUserIntervenantsEvents().then((res) => {
        setIntervenants(res);
        if (operation.aides_operatoire) {
          setSelectedAidesOperatoire(
            res["Aide Operatoire"].filter((o) =>
              operation.aides_operatoire?.includes(o.id),
            ),
          );
        }
        if (operation.operateur_new) {
          setSelectedOperateurs(
            res.Operateur.filter((o) =>
              operation.operateur_new?.includes(o.id),
            ),
          );
        }
        if (operation.anesthesiste_new) {
          setSelectedAnesthesistes(
            res.Anesthesiste.filter((o) =>
              operation.anesthesiste_new?.includes(o.id),
            ),
          );
        }
      });
    } catch (e) {
      console.error(e);
      snackbar.show("Erreur lors du chargement des données", "danger");
    }
  }, [api, operation, snackbar]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography level="h4">Edition du CRO</Typography>
        <Stack direction="row" gap={0.5}>
          <Button
            variant="soft"
            color="neutral"
            startDecorator={<Print />}
            onClick={() => printCro()}
          >
            Imprimer
          </Button>
          <Button
            variant="soft"
            color="success"
            startDecorator={<PrinterAddIcon />}
            onClick={() => addToPrintManager()}
          >
            Ajouter au gest. d'impression
          </Button>
          <Button
            variant="soft"
            color="primary"
            onClick={() => postCRO()}
            startDecorator={<Save />}
          >
            Enregistrer
          </Button>
        </Stack>
      </Stack>

      <Stack
        sx={{ mt: 2 }}
        direction="row"
        gap={2}
        flexWrap={"wrap"}
        alignItems="flex-start"
      >
        <Stack
          sx={{ width: "20%", minWidth: "200px" }}
          flex={1}
          direction="column"
          gap={1}
        >
          <Typography level="body-md">Opérateur</Typography>
          <Autocomplete
            placeholder="Rechercher un opérateur"
            options={intervenants?.Operateur || []}
            getOptionLabel={(o: any) => formatNameAndSurname(o.nom, o.prenom)}
            value={selectedOperateurs}
            getOptionKey={(o: any) => o.id}
            multiple
            onChange={(e, selected) => {
              setSelectedOperateurs(selected);
            }}
          />
        </Stack>
        <Stack
          flex={1}
          sx={{ width: "20%", minWidth: "200px" }}
          direction="column"
          gap={1}
        >
          <Typography level="body-md">Anesthesiste</Typography>
          <Autocomplete
            placeholder="Rechercher un anesthesiste"
            options={intervenants?.Anesthesiste || []}
            getOptionLabel={(o: any) => formatNameAndSurname(o.nom, o.prenom)}
            value={selectedAnesthesistes}
            getOptionKey={(o: any) => o.id}
            multiple
            onChange={(e, selected) => {
              setSelectedAnesthesistes(selected);
            }}
          />
        </Stack>
        <Stack direction="column" gap={1} flex={1}>
          <Typography level="body-md">Aides opératoires</Typography>
          <Autocomplete
            placeholder="Rechercher une aide opératoire"
            options={intervenants?.["Aide Operatoire"] || []}
            getOptionLabel={(o: any) => formatNameAndSurname(o.nom, o.prenom)}
            value={selectedAidesOperatoire}
            getOptionKey={(o: any) => o.id}
            multiple
            onChange={(e, selected) => {
              setSelectedAidesOperatoire(selected);
            }}
          />
        </Stack>
        <Stack sx={{ width: "100%" }} direction="row" gap={2}>
          <Stack flex={1} direction="column" gap={1}>
            <Typography level="body-md">Indication opératoire</Typography>
            <Textarea
              maxRows={2}
              minRows={2}
              endDecorator={
                <Button
                  variant="soft"
                  color="neutral"
                  size="sm"
                  startDecorator={<Add />}
                >
                  Dernière consultation
                </Button>
              }
              value={indicationOperatoire || ""}
              onChange={(e) => setIndicationOperatoire(e.target.value)}
            />
          </Stack>
          <Stack flex={1} direction="column" gap={1}>
            <Typography level="body-md">Commentaires</Typography>
            <Textarea
              maxRows={3.5}
              minRows={3.5}
              value={commentaires}
              onChange={(e) => setCommentaires(e.target.value)}
            />
          </Stack>
        </Stack>
        <Stack
          sx={{ width: "100%" }}
          direction="row"
          gap={2}
          alignItems="flex-end"
        >
          <Stack
            sx={{ width: "20%", minWidth: "200px" }}
            direction="column"
            gap={1}
          >
            <Typography level="body-md">Début opération</Typography>
            <Input
              type="datetime-local"
              value={start.format("YYYY-MM-DDTHH:mm")}
              onChange={(e) => {
                setStart(dayjs(e.target.value));
              }}
            />
          </Stack>
          <Stack
            sx={{ width: "20%", minWidth: "200px" }}
            direction="column"
            gap={1}
          >
            <Typography level="body-md">Fin opération</Typography>
            <Input
              type="datetime-local"
              value={end.format("YYYY-MM-DDTHH:mm")}
              onChange={(e) => {
                setEnd(dayjs(e.target.value));
              }}
            />
          </Stack>
          <Sheet
            variant="soft"
            color="warning"
            sx={{ p: 1, borderRadius: "sm" }}
          >
            <Checkbox
              overlay
              label="Urgence"
              color="warning"
              checked={urgency}
              onChange={(e) => setUrgency(e.target.checked)}
            />
          </Sheet>
        </Stack>
      </Stack>
      <Typography level="h4" sx={{ mt: 6 }}>
        Edition du contenu
      </Typography>
      <Stack sx={{ mt: 2 }} direction="row" gap={4}>
        <Stack direction="column" gap={1}>
          <Input
            size="sm"
            type="text"
            placeholder="Rechercher un CRO type"
            onChange={handleCroSearch}
            startDecorator={<Search />}
          />
          <Stack
            sx={{ width: "300px", maxHeight: "500px", overflow: "scroll" }}
            direction="column"
            gap={0.5}
          >
            {displayedCroList &&
              displayedCroList.map((cro) => (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  gap={1}
                  key={cro.id}
                  onClick={() => insertCROType(cro.id)}
                  sx={{
                    p: 0.5,
                    borderRadius: "8px",
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: (theme) =>
                        theme.palette.background.level1,
                    },
                  }}
                >
                  <Typography level="body-sm">{cro.cro_type_titre}</Typography>
                  <ArrowRightAltIcon />
                </Stack>
              ))}
          </Stack>
        </Stack>
        <Box flex={1}>
          <Editor data={content} onChange={setContent} />
        </Box>
      </Stack>
      <Stack sx={{ mt: 4 }} direction="row" gap={1} justifyContent="flex-end">
        <Button
          startDecorator={<Save />}
          variant="soft"
          onClick={() => postCRO()}
        >
          Enregistrer
        </Button>
      </Stack>
    </>
  );
}
