import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { Hospitalisation } from "../../../models/types";
import { formatDate } from "../../../utils/utils";

export default function HospitalisationEntreePanel({
  hospitalisation,
}: {
  hospitalisation: Hospitalisation;
}) {
  return (
    <Box>
      <Stack direction="column" gap={1}>
        <Typography level="body-md">
          {formatDate(hospitalisation.entree_date)} —{" "}
          {hospitalisation.entree_heure}
        </Typography>
        <Typography level="body-md">
          {hospitalisation.motif_hospitalisation}
        </Typography>
      </Stack>
    </Box>
  );
}
