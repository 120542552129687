import Select from "@mui/joy/Select";
import { Service } from "../../models/custom";
import { useApi } from "../../contexts/ApiContext";
import React from "react";
import Option from "@mui/joy/Option";
import { UserSites } from "../../models/types";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import { Place } from "@mui/icons-material";

interface ServiceSelectProps {
  value: Service["id"] | null;
  onSelect: (value: Service) => void;
}

export default function ServiceSelect({ value, onSelect }: ServiceSelectProps) {
  const api = useApi();

  const [services, setServices] = React.useState<Service[]>([]);

  React.useEffect(() => {
    const fetch = async () => {
      const res = await api.listUserServices(true);
      setServices(res);
    };
    fetch();
  }, [api]);

  return (
    <Select
      value={value}
      onChange={(e, newService) => {
        if (newService) {
          onSelect(services.find((s) => s.id === newService) as Service);
        }
      }}
    >
      <Option value={null}>Non renseigné</Option>
      {services.map((service) => (
        <Option key={service.id} value={service.id}>
          <Stack gap={0.5}>
            <Typography
              level="body-xs"
              startDecorator={<Place sx={{ fontSize: "12px" }} />}
            >
              {(service.site_service as unknown as UserSites).nom_site}
            </Typography>
            <Typography>{service.nom_service}</Typography>
          </Stack>
        </Option>
      ))}
    </Select>
  );
}
