import Autocomplete from "@mui/joy/Autocomplete";
import Chip from "@mui/joy/Chip";
import Stack from "@mui/joy/Stack";
import React from "react";
import { User } from "../../models/types";
import { useApi } from "../../contexts/ApiContext";
import DeleteIcon from "@mui/icons-material/Delete";

export default function UserPicker({
  value,
  onChange,
}: {
  value?: number[];
  onChange: (u: number[]) => void;
}) {
  const [selectedUsers, setSelectedUsers] = React.useState<User[]>([]);
  const [userIdList, setUserIdList] = React.useState<number[]>([]);
  const [userList, setUserList] = React.useState<User[]>([]);
  const api = useApi();

  React.useEffect(() => {
    api?.getLinkedUsers().then((users) => {
      setUserList(users.results);
      setUserIdList(users.results.map((u) => u.id));
      if (value) {
        setSelectedUsers(users.results.filter((u) => value.includes(u.id)));
      } else {
        setSelectedUsers([users.results[0]]);
      }
    });
  }, [api]);

  const removeUser = (id: number) => {
    const users = selectedUsers.filter((u) => u.id !== id);
    setSelectedUsers(users);
    onChange(users.map((u) => u.id));
  };

  return (
    <Stack direction="column" gap={0.5}>
      <Autocomplete
        value={null}
        onChange={(e, u: User | null) => {
          // add user to selectedUsers
          if (u) {
            const users = [...selectedUsers, u];
            setSelectedUsers(users);
            onChange(users.map((u) => u.id));
          }
        }}
        options={userList}
      />
      <Stack direction="row" gap={0.5}>
        {selectedUsers.map((u) => (
          // list of chip with user name
          <Chip
            key={u.id}
            endDecorator={
              <DeleteIcon fontSize="small" onClick={() => removeUser(u.id)} />
            }
          />
        ))}
      </Stack>
    </Stack>
  );
}
