import { ArrowLeft, Masks, Warning, WarningAmber } from "@mui/icons-material";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import Chip from "@mui/joy/Chip";
import Stack from "@mui/joy/Stack";
import Tab from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import TabPanel from "@mui/joy/TabPanel";
import Tabs from "@mui/joy/Tabs";
import Typography from "@mui/joy/Typography";
import { CalendarIcon, ClockIcon } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React from "react";
import { useParams } from "react-router-dom";
import PatientCard from "../../../components/PatientCard";
import { useApi } from "../../../contexts/ApiContext";
import { Consultation, Operation, Patient } from "../../../models/types";
import { router } from "../../../router";
import { formatDate } from "../../../utils/utils";
import EditCRO from "./Panels/EditCRO";
import OperationPerOp from "./Panels/OperationPerOp";
import OperationPreOp from "./Panels/OperationPreOp";
import QuoteEdit from "./Panels/QuoteEdit";
import Documents from "./Panels/Documents";

export default function OperationPage() {
  const { operation_id } = useParams<{ operation_id: string }>();
  const api = useApi();

  const [operation, setOperation] = React.useState<Operation | null>(null);
  const [patient, setPatient] = React.useState<Patient | null>(null);
  const [consultPreOp, setConsultPreOp] = React.useState<Consultation | null>(
    null,
  );

  React.useEffect(() => {
    if (api && operation_id) {
      api.getOperation(operation_id).then((res) => {
        setOperation(res);
        api.getPatient(res.zkf_patient).then((res) => setPatient(res));
        if (res.zkf_consultation_pre_op) {
          api
            .getConsultation(res.zkf_consultation_pre_op)
            .then((res) => setConsultPreOp(res));
        }
      });
    }
  }, [api, operation_id]);

  if (!operation || !patient) return null;

  return (
    <div>
      <Typography
        sx={{ cursor: "pointer" }}
        onClick={() =>
          router.navigate("/hospitalisation/" + operation?.zkf_hospitalisation)
        }
        level="body-md"
        startDecorator={<ArrowLeft />}
      >
        Retour à l'hospitalisation
      </Typography>

      <Stack
        sx={{
          p: 2,
          pb: 4,
          mx: -2,
          borderBottom: (theme) => "1px solid " + theme.palette.divider,
        }}
        direction="row"
        alignItems="flex-start"
        gap={2}
      >
        {patient && <PatientCard patient={patient} />}
        <Stack flex={1} direction="column" gap={0.5}>
          <Stack direction="row" gap={0.5}>
            <Chip color="success" startDecorator={<Masks fontSize="small" />}>
              Opération
            </Chip>
            {operation?.complication && (
              <Chip
                color="danger"
                startDecorator={<Warning fontSize="small" />}
              >
                Complication
              </Chip>
            )}
            {operation?.urgence && (
              <Chip
                color="warning"
                startDecorator={<WarningAmber fontSize="small" />}
              >
                Urgence
              </Chip>
            )}
          </Stack>
          <Typography level="title-lg" color="success">
            {operation?.titre_operation}
          </Typography>
          <Stack direction="row" gap={2}>
            <Typography
              level="body-md"
              startDecorator={<CalendarIcon fontSize="small" />}
            >
              {formatDate(operation?.operation_date)}
            </Typography>
            <Typography
              level="body-md"
              startDecorator={<ClockIcon fontSize="small" />}
            >
              {dayjs(operation?.operation_start).format("HH:mm")} —{" "}
              {dayjs(operation?.operation_end).format("HH:mm")}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="column" gap={0.5} textAlign="right">
          {consultPreOp && (
            <Typography level="body-xs">
              {dayjs(consultPreOp.consultation_date).diff(
                dayjs(operation?.operation_date),
                "days",
              )}{" "}
              jours après la consultation pré-opératoire.
            </Typography>
          )}
          {operation?.consentement_pre_operatoire_recupere ? (
            <Chip
              color="success"
              size="sm"
              startDecorator={<HistoryEduIcon fontSize="small" />}
            >
              Consentement pré-opératoire récupéré
            </Chip>
          ) : (
            <Chip
              color="warning"
              size="sm"
              startDecorator={<HistoryEduIcon fontSize="small" />}
            >
              Consentement pré-opératoire non récupéré
            </Chip>
          )}
        </Stack>
      </Stack>

      <Tabs sx={{ mx: -2, minHeight: "80vh", background: "white" }}>
        <TabList
          sx={{
            // min width for tab is 150px
            "& > button": {
              minWidth: "120px",
            },
          }}
        >
          <Tab variant="plain" color="neutral" value={0}>
            Pre op
          </Tab>
          <Tab variant="plain" color="neutral" value={1}>
            Per op
          </Tab>
          <Tab variant="plain" color="neutral" value={2}>
            DMI
          </Tab>
          <Tab variant="plain" color="neutral" value={3}>
            CRO
          </Tab>
          <Tab variant="plain" color="neutral" value={4}>
            Codage CCAM
          </Tab>
          <Tab variant="plain" color="neutral" value={5}>
            Post op
          </Tab>
          <Tab variant="plain" color="neutral" value={6}>
            Comptabilité
          </Tab>
          <Tab variant="plain" color="neutral" value={7}>
            Documents
          </Tab>
        </TabList>
        <TabPanel value={0}>
          {operation && <OperationPreOp operation={operation!} />}
        </TabPanel>
        <TabPanel sx={{ background: "white" }} value={1}>
          {operation && <OperationPerOp operation={operation!} />}
        </TabPanel>
        <TabPanel value={2}>DMI</TabPanel>
        <TabPanel value={3}>
          {operation && <EditCRO operation={operation} />}
        </TabPanel>
        <TabPanel value={4}>Codage CCAM</TabPanel>
        <TabPanel value={5}>Post op</TabPanel>
        <TabPanel value={6}>
          <QuoteEdit operationId={operation.id} patientId={patient.id} />
        </TabPanel>
        <TabPanel value={7}>
          <Documents operation={operation} />
        </TabPanel>
      </Tabs>
    </div>
  );
}
