import { useApi } from "../../../../contexts/ApiContext";
import { useUser } from "../../../../contexts/UserContext";
import { useSnackbar } from "../../../../contexts/SnackbarContext";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CROType, NameFilterField } from "../../../../models/custom";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import Box from "@mui/joy/Box";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import ModalComponent from "../../../../components/Modal/Modal";
import Input from "@mui/joy/Input";
import Editor from "../../../../components/Editor/Editor";
import FieldsPanel from "./fieldsPanel";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Specialite } from "../../../../models/types";

export default function CroTab() {
  const api = useApi();
  const user = useUser();
  const snackbar = useSnackbar();

  const [croType, setCroType] = useState<CROType[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [titre, setTitre] = useState<string>("");
  const [descriptif, setDescriptif] = useState<string>("");
  const [indicationOpe, setIndicationOpe] = useState<string>("");
  // const [nomModelSpe, setNomModeleSpe] = useState<string>("");
  const [croText, setCroText] = useState<string>("");
  const [editCro, setEditCro] = useState<CROType>();
  const [selectedCro, setSelecteCro] = useState<CROType>();
  const [currentEditorRef, setCurrentEditorRef] =
    React.useState<ClassicEditor>();
  const [specialites, setSpecialites] = useState<Specialite[]>([]);

  useEffect(() => {
    api.getCROTypes().then(setCroType);
    api.listUserSpecialites().then(setSpecialites);
  }, [api]);

  useEffect(() => {
    setSelecteCro(croType.find((c) => c.id === selectedCro?.id));
  }, [croType, selectedCro]);

  const handleEdit = useCallback((cro?: CROType) => {
    setTitre(cro?.cro_type_titre || "");
    setDescriptif(cro?.cro_type_titre_descriptif || "");
    setIndicationOpe(cro?.cro_type_indication_operatoire || "");
    // setNomModeleSpe(cro?.nom_modele_specialite || "");
    setCroText(cro?.cro_type || "");
    setEditCro(cro);
    setOpenModal(true);
  }, []);

  const handleDelete = useCallback(
    (id: string) => {
      api
        .deleteCROTypes(id)
        .then(() => setCroType(croType.filter((c) => c.id !== id)))
        .catch(() => snackbar.show("Impossible de supprimer le CRO", "danger"));
    },
    [api, croType, snackbar],
  );

  const handleSave = useCallback(() => {
    const newCro: Partial<CROType> = {
      cro_type_titre: titre,
      cro_type_titre_descriptif: descriptif,
      cro_type_indication_operatoire: indicationOpe,
      // nom_modele_specialite: nomModelSpe,
      cro_type: croText,
      zkf_user: user.user?.id,
    };
    if (editCro) {
      api
        .updateCROTypes({ id: editCro.id, ...newCro })
        .then((res) => {
          setCroType((prevState) =>
            prevState.map((c) => (c.id === editCro.id ? res : c)),
          );
          setOpenModal(false);
        })
        .catch(() => snackbar.show("Impossible de modifier le CRO", "danger"));
    } else {
      api
        .createCROTypes(newCro)
        .then((res) => {
          setCroType((prevState) => [...prevState, res]);
          setOpenModal(false);
        })
        .catch(() => snackbar.show("Impossible de créer le CRO", "danger"));
    }
  }, [
    api,
    croText,
    descriptif,
    editCro,
    indicationOpe,
    // nomModelSpe,
    snackbar,
    titre,
    user.user?.id,
  ]);

  const preview = useMemo(
    () =>
      !selectedCro ? null : (
        <Stack sx={{ height: "100%", overflow: "auto" }} width="50%" gap={1}>
          <Typography>Indication Operatoire</Typography>
          <div
            style={{
              borderRadius: "8px",
              border: "1px solid #ddd",
              padding: "8px",
              overflow: "scroll",
            }}
            dangerouslySetInnerHTML={{
              __html: selectedCro.cro_type_indication_operatoire,
            }}
          />
          <Typography mt={2}>CRO</Typography>
          <div
            style={{
              borderRadius: "8px",
              border: "1px solid #ccc",
              padding: "8px",
              overflow: "scroll",
            }}
            dangerouslySetInnerHTML={{
              __html: selectedCro.cro_type,
            }}
          />
        </Stack>
      ),
    [selectedCro],
  );

  const editModal = useMemo(() => {
    const filters: NameFilterField[] = [
      "general",
      "patient",
      "operation_short",
    ];
    specialites
      .filter((spe) => user.profile?.specialite_profile.includes(spe.id))
      .forEach((spe) => {
        filters.push(spe.spe as NameFilterField);
      });

    return (
      <ModalComponent
        open={openModal}
        title={`${editCro ? "Editer" : "Créer"} un CRO Type`}
        onClose={() => setOpenModal(false)}
        validateLabel={"Enregistrer"}
        onValidate={handleSave}
        canValidate={!!titre && !!croText}
        style={{ minWidth: "90%", minHeight: "80%" }}
      >
        <Stack spacing={2} flex={1}>
          <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
            <Stack spacing={1} width={"300px"}>
              <Typography>Titre</Typography>
              <Input value={titre} onChange={(e) => setTitre(e.target.value)} />
            </Stack>
            <Stack spacing={1} width={"350px"}>
              <Typography>Descriptif</Typography>
              <Input
                value={descriptif}
                onChange={(e) => setDescriptif(e.target.value)}
              />
            </Stack>
            {/*<Stack spacing={1} width={"350px"}>*/}
            {/*  <Typography>Nom Modele Specialite</Typography>*/}
            {/*  <Input*/}
            {/*    value={nomModelSpe}*/}
            {/*    onChange={(e) => setNomModeleSpe(e.target.value)}*/}
            {/*  />*/}
            {/*</Stack>*/}
          </Stack>
          <Divider />
          <Stack direction={"row"} flex={1} spacing={2}>
            <Stack width={"calc(50% - 125px)"} height={"100%"}>
              <Typography mb={1}>Indication Operatoire</Typography>
              <Editor
                data={indicationOpe}
                onChange={setIndicationOpe}
                onFocus={setCurrentEditorRef}
              />
            </Stack>
            <Stack width={"calc(50% - 125px)"} height={"100%"}>
              <Typography mb={1}>CRO</Typography>
              <Editor
                data={croText}
                onChange={setCroText}
                onFocus={setCurrentEditorRef}
              />
            </Stack>
            <FieldsPanel
              editor={currentEditorRef}
              filters={filters}
              style={{ height: "490px", marginTop: "30px !important" }}
            />
          </Stack>
        </Stack>
      </ModalComponent>
    );
  }, [
    croText,
    currentEditorRef,
    descriptif,
    editCro,
    handleSave,
    indicationOpe,
    // nomModelSpe,
    openModal,
    specialites,
    titre,
    user.profile?.specialite_profile,
  ]);

  const croList = useMemo(
    () =>
      croType.length === 0 ? (
        <div>Aucun Materiels</div>
      ) : (
        <Table
          sx={{ borderRadius: "sm" }}
          stickyHeader
          hoverRow
          variant="outlined"
        >
          <thead>
            <tr>
              <th>Titre</th>
              <th>Descriptif</th>
              <th style={{ width: "60px" }}></th>
            </tr>
          </thead>
          <tbody>
            {croType.map((cro) => (
              <tr
                key={cro.id}
                tabIndex={-1}
                onClick={() => setSelecteCro(cro)}
                style={
                  cro.id === selectedCro?.id
                    ? ({
                        "--TableCell-dataBackground":
                          "var(--joy-palette-neutral-200, #DDE7EE)",
                      } as React.CSSProperties)
                    : {}
                }
              >
                <td>{cro.cro_type_titre}</td>
                <td>{cro.cro_type_titre_descriptif}</td>
                <td>
                  <Stack direction="row" justifyContent={"center"} gap={0.5}>
                    <Edit
                      sx={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEdit(cro);
                      }}
                    />
                    <Delete
                      sx={{ fill: "red", cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(cro.id);
                      }}
                    />
                  </Stack>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ),
    [croType, handleDelete, handleEdit, selectedCro],
  );

  return (
    <Stack
      sx={{
        width: "100%",
        height: "70vh",
        border: "1px solid #e3e3e3",
        borderRadius: "8px",
        p: 2,
      }}
      spacing={2}
    >
      <Stack direction="row" alignItems="center" justifyContent="end">
        <Button onClick={() => handleEdit()}>Créer un CRO Type</Button>
      </Stack>
      <Divider />
      <Stack direction={"row"} spacing={2} height={"calc(100% - 68px)"}>
        <Box sx={{ height: "100%", overflow: "auto" }} width="50%">
          {croList}
        </Box>
        {preview}
      </Stack>
      {editModal}
    </Stack>
  );
}
