import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Vacation as CustomVacation } from "../../../models/custom";
import { Vacation } from "../../../models/types";
import { useApi } from "../../../contexts/ApiContext";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import Divider from "@mui/joy/Divider";
import Button from "@mui/joy/Button";
import Add from "@mui/icons-material/Add";
import { RepetitionSemaineEnum, TypeVacationEnum } from "../../../models/types";
import ModalComponent from "../../../components/Modal/Modal";
import Input from "@mui/joy/Input";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import ConsultingSiteSelector from "../../../components/Forms/ConsultingSiteSelector";
import Box from "@mui/joy/Box";
import { useUser } from "../../../contexts/UserContext";
import { useSnackbar } from "../../../contexts/SnackbarContext";
import { HistoryToggleOff } from "@mui/icons-material";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";

export default function Vacations() {
  const api = useApi();
  const user = useUser();
  const snackbar = useSnackbar();
  const [vacations, setVacations] = useState<CustomVacation[]>([]);
  const [editVacation, setEditVacation] = useState<CustomVacation>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [debutPeriod, setDebutPeriod] = useState("");
  const [finPeriod, setFinPeriod] = useState("");
  const [debutVacation, setDebutVacation] = useState("");
  const [finVacation, setFinVacation] = useState("");
  const [jourSemaine, setJourSemaine] = useState<number>(1);
  const [repetition, setRepetition] = useState<RepetitionSemaineEnum>("TOUTES");
  const [type, setType] = useState<TypeVacationEnum>("CONSULTATION");
  const [site, setSite] = useState<string>();
  const [openHistoryModal, setOpenHistoryModal] = useState<boolean>(false);

  const refreshVacations = useCallback(
    () => api.getVacations().then((res) => setVacations(res)),
    [api],
  );

  useEffect(() => {
    refreshVacations();
  }, [api, refreshVacations]);

  const today = useMemo(() => new Date(), []);

  const vacationsFuture = useMemo(
    () =>
      vacations.filter(
        (vac) => new Date(vac.debut_periode).getTime() > today.getTime(),
      ),
    [today, vacations],
  );

  const vacationsEnCours = useMemo(
    () =>
      vacations.filter(
        (vac) =>
          new Date(vac.debut_periode).getTime() <= today.getTime() &&
          (!vac.fin_periode ||
            new Date(vac.fin_periode).getTime() > today.getTime()),
      ),
    [today, vacations],
  );

  const handleEdit = (vacation?: CustomVacation) => {
    setDebutPeriod(vacation?.debut_periode || "");
    setFinPeriod(vacation?.fin_periode || "");
    setDebutVacation(vacation?.debut_vacation || "");
    setFinVacation(vacation?.fin_vacation || "");
    setJourSemaine(vacation?.jour_semaine || 1);
    setRepetition(vacation?.repetition_semaine || "TOUTES");
    setType(vacation?.type_vacation || "CONSULTATION");
    setSite(vacation?.site.id);
    setEditVacation(vacation);
    setOpenModal(true);
  };

  const handleDelete = useCallback(
    (id: string) => {
      api
        .deleteVacation(id)
        .then(() => setVacations(vacations.filter((v) => v.id !== id)))
        .catch(() =>
          snackbar.show("Impossible de supprimer la vacation", "danger"),
        );
    },
    [api, snackbar, vacations],
  );

  const handleSave = useCallback(() => {
    const mewVacation: Partial<Vacation> = {
      debut_periode: debutPeriod,
      fin_periode: finPeriod || null,
      debut_vacation: debutVacation,
      fin_vacation: finVacation,
      jour_semaine: jourSemaine,
      repetition_semaine: repetition,
      type_vacation: type,
      zkf_user: user.user?.id,
      site,
    };
    if (editVacation) {
      api
        .updateVacation({ id: editVacation.id, ...mewVacation })
        .then(() => {
          refreshVacations();
          setOpenModal(false);
        })
        .catch(() =>
          snackbar.show("Impossible de modifier la vacation", "danger"),
        );
    } else {
      api
        .createVacation(mewVacation)
        .then(() => {
          refreshVacations();
          setOpenModal(false);
        })
        .catch(() =>
          snackbar.show("Impossible de créer la vacation", "danger"),
        );
    }
  }, [
    api,
    debutPeriod,
    debutVacation,
    editVacation,
    finPeriod,
    finVacation,
    jourSemaine,
    refreshVacations,
    repetition,
    site,
    snackbar,
    type,
    user.user?.id,
  ]);

  const convertJourSemaine = (jour: number) => {
    switch (jour) {
      case 1:
        return "Lundi";
      case 2:
        return "Mardi";
      case 3:
        return "Mercredi";
      case 4:
        return "Jeudi";
      case 5:
        return "Vendredi";
      case 6:
        return "Samedi";
      case 7:
        return "Dimanche";
    }
    return "";
  };

  const editModal = useMemo(
    () => (
      <ModalComponent
        open={openModal}
        title={`${editVacation ? "Editer" : "Créer"} une vacation`}
        onClose={() => setOpenModal(false)}
        validateLabel={"Enregistrer"}
        onValidate={handleSave}
        canValidate={
          !!debutPeriod && !!debutVacation && !!finVacation && !!site
        }
      >
        <Stack spacing={2} flex={1}>
          <Box
            padding={2}
            style={{
              position: "relative",
              border: "1px solid #ccc",
              borderRadius: "8px",
            }}
          >
            <Typography
              sx={{
                position: "absolute",
                top: "-10px",
                left: "10px",
                backgroundColor: "white",
                padding: "0 8px",
                fontSize: "12px",
              }}
            >
              Periode
            </Typography>
            <Stack spacing={3} direction={"row"}>
              <Stack
                direction={"row"}
                width={"50%"}
                spacing={1}
                alignItems={"center"}
              >
                <Typography>Debut</Typography>
                <Input
                  type={"date"}
                  value={debutPeriod}
                  slotProps={{
                    input: {
                      max: finPeriod,
                    },
                  }}
                  onChange={(e) => setDebutPeriod(e.target.value)}
                />
              </Stack>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Typography>Fin</Typography>
                <Input
                  type={"date"}
                  value={finPeriod}
                  slotProps={{
                    input: {
                      min: debutPeriod,
                    },
                  }}
                  onChange={(e) => setFinPeriod(e.target.value)}
                />
              </Stack>
            </Stack>
          </Box>
          <Box
            padding={2}
            style={{
              position: "relative",
              border: "1px solid #ccc",
              borderRadius: "8px",
            }}
          >
            <Typography
              sx={{
                position: "absolute",
                top: "-10px",
                left: "10px",
                backgroundColor: "white",
                padding: "0 8px",
                fontSize: "12px",
              }}
            >
              Vacation
            </Typography>
            <Stack spacing={3} direction={"row"}>
              <Stack
                direction={"row"}
                width={"50%"}
                spacing={1}
                alignItems={"center"}
              >
                <Typography>Debut</Typography>
                <Input
                  type={"time"}
                  value={debutVacation}
                  slotProps={{
                    input: {
                      max: finVacation,
                    },
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!finVacation || finVacation > value)
                      setDebutVacation(value);
                    else
                      snackbar.show(
                        "Le debut de vacation doit etre avant la fin",
                        "danger",
                      );
                  }}
                />
              </Stack>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Typography>Fin</Typography>
                <Input
                  type={"time"}
                  value={finVacation}
                  slotProps={{
                    input: {
                      min: debutVacation,
                    },
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!debutVacation || debutVacation < value)
                      setFinVacation(value);
                    else
                      snackbar.show(
                        "La fin de vacation doit etre apres le debut",
                        "danger",
                      );
                  }}
                />
              </Stack>
            </Stack>
          </Box>
          <Stack spacing={1} direction={"row"} alignItems={"center"}>
            <Typography width={"80px"}>Type</Typography>
            <Select
              value={type}
              onChange={(e, newValue) => setType(newValue || "CONSULTATION")}
              style={{ flex: 1 }}
            >
              <Option value={"CONSULTATION"}>Consultation</Option>
              <Option value={"OPERATION"}>Operation</Option>
            </Select>
          </Stack>
          <Stack spacing={1} direction={"row"} alignItems={"center"}>
            <Typography width={"80px"}>Jour</Typography>
            <Select
              value={jourSemaine}
              onChange={(e, newValue) => setJourSemaine(newValue || 1)}
              style={{ flex: 1 }}
            >
              {[1, 2, 3, 4, 5, 6, 7].map((i) => (
                <Option key={i} value={i}>
                  {convertJourSemaine(i)}
                </Option>
              ))}
            </Select>
          </Stack>
          <Stack spacing={1} direction={"row"} alignItems={"center"}>
            <Typography width={"80px"}>Repetition</Typography>
            <Select
              value={repetition}
              onChange={(e, newValue) => setRepetition(newValue || "TOUTES")}
              style={{ flex: 1 }}
            >
              <Option value={"TOUTES"}>TOUTES</Option>
              <Option value={"PAIRE"}>PAIRE</Option>
              <Option value={"IMPAIRE"}>IMPAIRE</Option>
            </Select>
          </Stack>
          <Stack spacing={1} direction={"row"} alignItems={"center"}>
            <Typography width={"80px"}>Site</Typography>
            <ConsultingSiteSelector
              onSelect={setSite}
              initialValue={site}
              style={{ flex: 1 }}
            />
          </Stack>
        </Stack>
      </ModalComponent>
    ),
    [
      debutPeriod,
      debutVacation,
      editVacation,
      finPeriod,
      finVacation,
      handleSave,
      jourSemaine,
      openModal,
      repetition,
      site,
      snackbar,
      type,
    ],
  );

  const vacationCard = useCallback(
    (vacation: CustomVacation) => (
      <Sheet key={vacation.id} sx={{ width: "300px", padding: "10px" }}>
        <Stack
          justifyContent={"space-between"}
          direction={"row"}
          alignItems={"center"}
        >
          <Typography
            level={"title-sm"}
          >{`${vacation.zkf_user.last_name} ${vacation.zkf_user.first_name}`}</Typography>
          {vacation.zkf_user.id === user.user?.id && (
            <Stack direction={"row"} gap={0.5}>
              <Edit
                sx={{ cursor: "pointer" }}
                onClick={() => handleEdit(vacation)}
              />
              <Delete
                sx={{ fill: "red", cursor: "pointer" }}
                onClick={() => handleDelete(vacation.id)}
              />
            </Stack>
          )}
        </Stack>
        <Typography level={"body-xs"}>
          Type: {vacation.type_vacation}
        </Typography>
        <Typography level={"body-xs"}>
          Periode: {vacation.debut_periode}
          {vacation.fin_periode ? ` - ${vacation.fin_periode}` : ""}
        </Typography>
        <Typography level={"body-xs"}>
          Horaire: {vacation.debut_vacation} - {vacation.fin_vacation}
        </Typography>
        <Typography level={"body-xs"}>
          Semaine: {vacation.repetition_semaine}
        </Typography>
        <Typography level={"body-xs"}>
          Jour: {convertJourSemaine(vacation.jour_semaine)}
        </Typography>
        <Typography level={"body-xs"}>
          Site: {vacation.site.nom_site}
        </Typography>
      </Sheet>
    ),
    [handleDelete, user.user?.id],
  );

  const historyModal = useMemo(
    () => (
      <ModalComponent
        open={openHistoryModal}
        title={`Historique des vacations`}
        onClose={() => setOpenHistoryModal(false)}
        closeLabel={"Fermer"}
        style={{ minWidth: "80%" }}
      >
        <Stack
          direction={"row"}
          gap={1}
          flexWrap={"wrap"}
          height={"500px"}
          overflow={"scroll"}
        >
          {vacations.map(vacationCard)}
        </Stack>
      </ModalComponent>
    ),
    [openHistoryModal, vacationCard, vacations],
  );

  return (
    <Stack spacing={2}>
      <Box display={"flex"} justifyContent={"end"}>
        <Stack spacing={1} direction={"row"}>
          <Button startDecorator={<Add />} onClick={() => handleEdit()}>
            Ajouter Vacation
          </Button>
          <Button
            startDecorator={<HistoryToggleOff />}
            onClick={() => setOpenHistoryModal(true)}
          >
            Historique
          </Button>
        </Stack>
      </Box>
      <Divider />
      <Stack direction={"row"} spacing={2}>
        <Stack spacing={2} width={"50%"} alignItems={"center"}>
          <Typography level={"h4"}>Vacations en Cours</Typography>
          {vacationsEnCours.map(vacationCard)}
        </Stack>
        <Stack spacing={2} alignItems={"center"}>
          <Typography level={"h4"}>Vacations Future</Typography>
          {vacationsFuture.map(vacationCard)}
        </Stack>
      </Stack>
      {editModal}
      {historyModal}
    </Stack>
  );
}
