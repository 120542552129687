import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import Stack from "@mui/joy/Stack";
import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "../../contexts/ApiContext";
import { Consultation, Hospitalisation, Patient } from "../../models/types";
import MedicalHistory from "./History/MedicalHistory";
import ListOfConsultations from "./ListOfConsultations";
import ListOfHospitalisations from "./ListOfHospitalisations";
import PatientHeader from "./PatientHeader";
import Button from "@mui/joy/Button";
import { router } from "../../router";
import ContactModal from "../../components/Correspondant/ContactModal";
import DocumentsModal from "./DocumentsModal";

export default function PatientPage() {
  const { id } = useParams();
  const api = useApi();

  const [p, setPatient] = React.useState<Patient>();
  const [consultations, setConsultations] = React.useState<Consultation[]>([]);
  const [hospitalisations, setHospitalisations] = React.useState<
    Hospitalisation[]
  >([]);
  const [openContacts, setOpenContacts] = React.useState(false);
  const [openDocuments, setOpenDocuments] = React.useState(false);

  React.useEffect(() => {
    if (id && api) {
      api.getPatient(id).then((res) => {
        setPatient(res);
        // also setting current patient context
      });
    }
  }, [id, api]);

  const fetchConsultations = React.useCallback(async () => {
    if (!id) return;
    try {
      const res = await api.listConsultation(id);
      setConsultations(res.results);
    } catch (e) {
      console.error(e);
    }
  }, [api, id]);

  const fetchHospitalisations = React.useCallback(async () => {
    if (!id) return;
    try {
      const res = await api.listHospitalisations(id);
      setHospitalisations(res.results);
    } catch (e) {
      console.error(e);
    }
  }, [api, id]);

  React.useEffect(() => {
    fetchConsultations();
    fetchHospitalisations();
  }, [api, id, fetchConsultations, fetchHospitalisations]);

  const handleCloseDocuments = useCallback(() => setOpenDocuments(false), []);

  return p ? (
    <Box>
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <PatientHeader patient={p} />
        <Stack direction="row" spacing={2}>
          <Button onClick={() => setOpenContacts(true)}>Contacts</Button>
          <Button onClick={() => setOpenDocuments(true)}>Documents</Button>
          <Button onClick={() => router.navigate(`/patient/${p.id}/edit`)}>
            Détails administratifs
          </Button>
        </Stack>
      </Stack>
      <Divider sx={{ mx: -2, mt: 2 }} />
      <Stack sx={{ mx: -2, minHeight: "80vh" }} direction="row">
        <Box sx={{ flex: 1, p: 2 }}>
          <MedicalHistory patient={p} />
        </Box>
        <Box
          sx={{
            flex: 1,
            p: 2,
            borderLeft: (theme) => "1px solid " + theme.palette.divider,
          }}
        >
          <ListOfConsultations
            consultations={consultations || []}
            refresh={fetchConsultations}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            p: 2,
            borderLeft: (theme) => "1px solid " + theme.palette.divider,
          }}
        >
          <ListOfHospitalisations
            hospitalisations={hospitalisations || []}
            refresh={fetchHospitalisations}
          />
        </Box>
      </Stack>
      {openDocuments && (
        <DocumentsModal
          open={openDocuments}
          onClose={handleCloseDocuments}
          patient={p}
        />
      )}
      {openContacts && (
        <ContactModal
          patient={p}
          open={openContacts}
          onClose={() => setOpenContacts(false)}
        />
      )}
    </Box>
  ) : (
    <h1>Chargement</h1>
  );
}
