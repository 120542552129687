import TabPanel from "@mui/joy/TabPanel";
import { Adresse, Email, Telephone } from "../../models/types";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";

export const emptyEmail: Email = {
  email: "",
  categorie: "",
  preferred: false,
} as Email;
export const emptyTelephone: Telephone = {
  numero: "",
  categorie: "PORTABLE",
  preferred: false,
} as Telephone;
export const emptyAdresse: Adresse = {
  numero_rue: "",
  rue: "",
  complement_adresse: "",
  code_postal: 0,
  ville: "",
  preferred: false,
} as Adresse;

export const panelRender = (
  tabIndex: number,
  addAction: () => void,
  content: React.ReactNode,
) => (
  <TabPanel value={tabIndex} style={{ overflowX: "auto", maxHeight: "400px" }}>
    <Stack spacing={2}>
      {content}
      <Button
        startDecorator={<AddIcon />}
        onClick={addAction}
        variant="outlined"
      >
        Ajouter
      </Button>
    </Stack>
  </TabPanel>
);
