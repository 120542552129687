import Add from "@mui/icons-material/Add";
import LocalHospital from "@mui/icons-material/LocalHospital";
import Masks from "@mui/icons-material/Masks";
import Save from "@mui/icons-material/Save";
import Button from "@mui/joy/Button";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import dayjs from "dayjs";
import React from "react";
import { useApi } from "../../../contexts/ApiContext";
import { useCurrentPatient } from "../../../contexts/CurrentPatientContext";
import { useSnackbar } from "../../../contexts/SnackbarContext";
import { useAuthenticatedUser } from "../../../contexts/UserContext";
import { Hospitalisation, Operation } from "../../../models/types";
import HospitalisationForm from "../../Calendar/Forms/HospitalisationForm";
import OperationForm from "../../Calendar/Forms/OperationForm";

interface CreateHospitalisationModalProps {
  onSave?: () => void;
}

const isMandatoryFieldValid = (field: any): boolean => {
  if (field === undefined || field === null || field === "") return false;
  return true;
};

export default function CreateHospitalisationModal({
  onSave,
}: CreateHospitalisationModalProps) {
  const api = useApi();
  const { profile } = useAuthenticatedUser();
  const snackbar = useSnackbar();
  const currentPatient = useCurrentPatient();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [operationFormValues, setOperationFormValues] =
    React.useState<Partial<Operation> | null>(null);
  const [hospitalisationFormValues, setHospitalisationFormValues] =
    React.useState<Partial<Hospitalisation>>({
      zkf_patient: currentPatient[0]?.id,
      entree_date: dayjs().format("YYYY-MM-DD"),
      specialite_referente: profile?.specialite_active!,
    });

  const initOperationFormValues = () => {
    setOperationFormValues({
      zkf_patient: currentPatient[0]?.id,
      tags: [],
      operation_date: dayjs().format("YYYY-MM-DD"),
      operation_start: dayjs().format(),
      operation_end: dayjs().format(),
    });
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const hospitalisation = await api.createHospitalisation({
        ...hospitalisationFormValues,
        zkf_redacteur: profile?.id,
        sortie_date:
          hospitalisationFormValues.hospitalisation_ambulatoire ||
          hospitalisationFormValues.sortie_date === ""
            ? null
            : hospitalisationFormValues.sortie_date,
      });

      if (operationFormValues !== null) {
        const payload: Partial<Operation> = {
          ...operationFormValues,
          zkf_hospitalisation: hospitalisation.id,
          zkf_redacteur: profile?.id,
        };
        await api?.createOperation(payload);
      }

      snackbar.show("Hospitalisation créée avec succès", "success");
      onSave?.();
    } catch (error) {
      console.error("Error saving hospitalisation:", error);
      snackbar.show(
        "Erreur lors de la création de l'hospitalisation",
        "danger",
      );
    } finally {
      setLoading(false);
    }
  };

  const canSave = () => {
    if (operationFormValues === null) {
      return (
        isMandatoryFieldValid(hospitalisationFormValues.entree_date) &&
        isMandatoryFieldValid(hospitalisationFormValues.zkf_patient) &&
        isMandatoryFieldValid(hospitalisationFormValues.mode_entree) &&
        isMandatoryFieldValid(
          hospitalisationFormValues.service_hospitalisation,
        ) &&
        isMandatoryFieldValid(hospitalisationFormValues.specialite_referente) &&
        isMandatoryFieldValid(hospitalisationFormValues.medecin_referent)
      );
    } else {
      return (
        isMandatoryFieldValid(hospitalisationFormValues.entree_date) &&
        isMandatoryFieldValid(hospitalisationFormValues.zkf_patient) &&
        isMandatoryFieldValid(hospitalisationFormValues.mode_entree) &&
        isMandatoryFieldValid(
          hospitalisationFormValues.service_hospitalisation,
        ) &&
        isMandatoryFieldValid(hospitalisationFormValues.specialite_referente) &&
        isMandatoryFieldValid(hospitalisationFormValues.medecin_referent) &&
        isMandatoryFieldValid(operationFormValues.operation_date) &&
        isMandatoryFieldValid(operationFormValues.operation_start) &&
        isMandatoryFieldValid(operationFormValues.operation_end) &&
        isMandatoryFieldValid(operationFormValues.zkf_patient) &&
        isMandatoryFieldValid(operationFormValues.titre_operation) &&
        isMandatoryFieldValid(operationFormValues.operateur_new) &&
        isMandatoryFieldValid(operationFormValues.indication_operatoire)
      );
    }
  };

  return (
    <Stack gap={1}>
      <Typography
        level="title-lg"
        startDecorator={<LocalHospital />}
        sx={{ mb: 1 }}
      >
        Ajouter une hospitalisation
      </Typography>
      <ModalClose />

      <Sheet sx={{ p: 2, borderRadius: "lg" }} variant="soft" color="warning">
        <Typography
          level="title-md"
          startDecorator={<LocalHospital />}
          sx={{ mb: 1 }}
        >
          Hospitalisation
        </Typography>
        <HospitalisationForm
          values={hospitalisationFormValues}
          setValues={(values) => setHospitalisationFormValues(values)}
        />
      </Sheet>

      {!operationFormValues && (
        <Stack direction="row" gap={2}>
          <Button
            onClick={() => initOperationFormValues()}
            startDecorator={<Add />}
          >
            <Masks />
            &nbsp; Créer une opération
          </Button>
        </Stack>
      )}

      {operationFormValues !== null && (
        <Sheet sx={{ p: 2, borderRadius: "lg" }} variant="soft" color="success">
          <Typography
            level="title-md"
            startDecorator={<Masks />}
            sx={{ mb: 1 }}
          >
            Opération
          </Typography>
          <OperationForm
            values={operationFormValues}
            setValues={(values) => setOperationFormValues(values)}
          />
        </Sheet>
      )}

      <Stack sx={{ mt: 2 }} direction="row" justifyContent="flex-end" gap={2}>
        <Stack alignItems="flex-end" gap={0.5}>
          {!canSave() && (
            <Typography level="body-xs" color="neutral">
              <sup>*</sup> Champ obligatoire
            </Typography>
          )}
          <Button
            startDecorator={<Save />}
            color="primary"
            variant="outlined"
            onClick={handleSave}
            loading={loading}
            disabled={!canSave()}
          >
            Enregistrer
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
