import SaveIcon from "@mui/icons-material/Save";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import FormComponent from "../../../../components/Forms/FormComponent";
import { useApi } from "../../../../contexts/ApiContext";
import { useSnackbar } from "../../../../contexts/SnackbarContext";
import {
  FormField,
  OperationTemplatesResponse,
  PeriOpData,
} from "../../../../models/custom";
import { Operation } from "../../../../models/types";

const excluded_fields = [
  "id",
  "patient",
  "created_at",
  "updated_at",
  "deleted_at",
  "zkf_patient",
  "zkf_operation",
];

interface Template {
  label: string;
  value: string;
}

export default function OperationPerOp({
  operation,
}: {
  operation: Operation;
}) {
  const api = useApi();
  const snackbar = useSnackbar();

  const [templates, setTemplates] = React.useState<Template[] | null>(null);
  const [templatesFields, setTemplatesFields] = React.useState<
    Map<string, { [key: string]: FormField }> // map of template_name -> fields
  >(new Map());

  // mapping values of the selected templates, keeping in mind that there can be multiple instances of the same template
  // example: VeineSuperficielleLaser has two instances, so we need to store them in an array
  const [data, setData] = React.useState<Map<string, PeriOpData[]>>(new Map());

  React.useEffect(() => {
    const fetchTemplateField = async (
      template: Template | null,
    ): Promise<void> => {
      if (template) {
        // check if the template already exists in data, if we don't have the fields, we need to fetch them
        if (data.has(template.value)) {
          return Promise.resolve();
        }
        // also get the fields for the selected template
        api
          ?.getOperationsTemplateFields("chirurgie_vasculaire", template.value)
          .then((res) => {
            // make sure we don't delete the other templates fields
            // store fields in map as template_name -> fields
            // all fields which tsiming_operation = pre_op
            const fields: { [key: string]: FormField } = {};
            Object.keys(res).forEach((key) => {
              if (excluded_fields.includes(key)) {
                return;
              }
              if (["per_op", null].includes(res[key].timing_operation)) {
                fields[key] = res[key];
              }
            });

            setTemplatesFields((prev) => {
              const newMap = new Map(prev);
              newMap.set(template.value, fields);
              return newMap;
            });

            return Promise.resolve();
          })
          .catch((err) => {
            console.error(err);
            snackbar.show(
              "Erreur lors de la récupération des champs",
              "danger",
            );
            return Promise.reject();
          });
      } else {
        return Promise.reject();
      }
    };
    async function getPeriOp(): Promise<void> {
      if (operation) {
        api
          ?.getOperationPeriOp(operation.id)
          .then((res) => {
            // remove "Operation" key from object, and check for the other ones which are templates and store them in selectedTemplates
            // for example there can be multiple instances of the same template, so we need to store them in an array
            // and their values in a map
            Object.keys(res).forEach((key) => {
              // define the type as sure that key is not "Operation"
              if (key !== "Operation" && res[key].length > 0) {
                // we could have multiple instances of the same template,
                // so we need to store them in an array
                const temp: PeriOpData[] = [];
                res[key].forEach((instance) => {
                  temp.push(instance as PeriOpData);
                });
                setData((prev) => {
                  const newMap = new Map(prev);
                  newMap.set(key, temp);
                  return newMap;
                });
                // for each template, we need to fetch the fields
                fetchTemplateField({ label: key, value: key });
              }
            });
            return Promise.resolve();
          })
          .catch((err) => {
            console.error(err);
            return Promise.reject();
          });
      } else {
        return Promise.reject();
      }
    }
    getPeriOp().then(() => {
      api?.getOperationTemplates().then((res: OperationTemplatesResponse) => {
        const temp: { label: string; value: string }[] = [];
        Object.keys(res.models).forEach((key) => {
          temp.push({ label: res.models[key], value: key });
        });
        setTemplates(temp);
      });
    });
  }, [api, operation]);

  const update = () => {
    // tricky here, but, if in our templates, we have an ID, we need to update the instance with the ID
    // if we don't have an ID, we need to create a new instance and then update with the returned id, it's two different routes
    Array.from(data).forEach(([templateName, instances]) => {
      instances.forEach((instance) => {
        if (instance.id) {
          // update the instance
          api
            ?.updateOperationTemplate(
              "chirurgie_vasculaire",
              templateName,
              instance.id as string,
              instance,
            )
            .then((res) => {
              console.log("updated", res);
              snackbar.show("Modifications enregistrées", "success");
            })
            .catch((err) => {
              console.error(err);
              snackbar.show("Erreur lors de l'enregistrement", "danger");
            });
        } else {
          // create a new instance
          api
            ?.createOperationTemplate(
              "chirurgie_vasculaire",
              templateName,
              instance,
            )
            .then((res) => {
              console.log("created", res);
              // update our local data with the new id
              setData((prev) => {
                const newMap = new Map(prev);
                const temp = newMap.get(templateName);
                if (temp) {
                  temp[temp.length - 1].id = res.id;
                }
                newMap.set(templateName, temp!);
                return newMap;
              });
              snackbar.show("Modifications enregistrées", "success");
            })
            .catch((err) => {
              console.error(err);
              snackbar.show("Erreur lors de l'enregistrement", "danger");
            });
        }
      });
    });
  };

  return !!operation ? (
    <Stack
      direction="column"
      justifyContent="space-between"
      gap={2}
      sx={{ minHeight: "80vh" }}
    >
      <div>
        <Stack direction="row" justifyContent="space-between" gap={2}>
          <Stack direction="row" gap={2}>
            <Typography level="title-lg">
              Modification des champs PER-opératoires
            </Typography>
          </Stack>
          <Button
            variant="soft"
            startDecorator={<SaveIcon />}
            onClick={() => update()}
          >
            Enregistrer
          </Button>
        </Stack>

        <Stack direction="row" gap={2} sx={{ mt: 2 }} alignItems="flex-start">
          <Stack
            sx={{ width: "100%" }}
            direction="row"
            gap={2}
            flexWrap="wrap"
            alignItems="flex-start"
          >
            {Array.from(data).length > 0 ? (
              Array.from(data).map(([templateName, instances]) => {
                return instances.map((instance: PeriOpData, index) => {
                  return (
                    <Sheet
                      key={templateName + index}
                      sx={{
                        p: 2,
                        borderRadius: "sm",
                        minWidth: "calc(50% - 8px)",
                        maxWidth: "calc(50% - 8px)",
                        flex: 1,
                      }}
                      variant="outlined"
                    >
                      <Typography level="title-md">
                        {templates &&
                          templates.find((t) => t.value === templateName)
                            ?.label}
                      </Typography>
                      <Stack sx={{ mt: 2 }} direction="column" gap={2}>
                        {Object.keys(
                          templatesFields.get(templateName) || {},
                        ).map((fieldKey) => {
                          const field =
                            templatesFields.get(templateName)?.[fieldKey];
                          if (!field) return null;
                          return (
                            <FormComponent
                              key={fieldKey}
                              input={field}
                              value={instance[fieldKey]}
                              onUpdate={(value) => {
                                setData((prev) => {
                                  const newMap = new Map(prev);
                                  const temp = newMap.get(templateName);
                                  if (temp) {
                                    temp[index][fieldKey] = value;
                                  }
                                  newMap.set(templateName, temp!);
                                  return newMap;
                                });
                              }}
                            />
                          );
                        })}
                      </Stack>
                    </Sheet>
                  );
                });
              })
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "70vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <div>
                  <Typography level="title-lg">Aucun template</Typography>
                  <Typography level="body-sm">
                    Ajoutez un template pour commencer
                  </Typography>
                </div>
              </Box>
            )}
          </Stack>
        </Stack>
      </div>
      <Stack direction="row" justifyContent="flex-end" gap={2}>
        <Button
          variant="soft"
          startDecorator={<SaveIcon />}
          onClick={() => update()}
        >
          Enregistrer
        </Button>
      </Stack>
    </Stack>
  ) : (
    <p>Loading</p>
  );
}
