import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import PersonIcon from "@mui/icons-material/Person";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Chip from "@mui/joy/Chip";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Tooltip from "@mui/joy/Tooltip";
import Typography from "@mui/joy/Typography";
import moment from "moment";
import React from "react";
import { useApi } from "../contexts/ApiContext";
import { useSnackbar } from "../contexts/SnackbarContext";
import { isUser } from "../models/type_guards";
import { Hospitalisation, Patient } from "../models/types";
import { router } from "../router";
import {
  ISODateToAge,
  formatDate,
  formatNameAndSurname,
  isToday,
} from "../utils/utils";
import CurrentPatientManager from "./CurrentPatientManager";

interface PatientCardProps {
  patient?: Patient | null;
  width?: string;
  hospitalisation?: Hospitalisation;
  onLeave?: (leaveDate: string | undefined) => void;
}

export default function PatientCard({
  patient,
  width = "300px",
  hospitalisation,
  onLeave,
}: PatientCardProps) {
  const api = useApi();
  const snackbar = useSnackbar();
  const [open, setOpen] = React.useState(false);
  // const [leave, setLeave] = React.useState<dayjs.Dayjs>(dayjs());
  const [leave, setLeave] = React.useState<string>();

  const updateSortieDate = async () => {
    if (!hospitalisation) return;

    const h = await api?.updateHospitalisation(hospitalisation.id, {
      sortie_date: leave,
    });
    if (h) {
      onLeave && onLeave(leave);
      snackbar.show("Date de sortie mise a jour", "success");
    } else {
      snackbar.show(
        "Erreur lors de la mise a jour de la Date de sortie",
        "danger",
      );
      window.alert("error creating hospitalisation");
    }
  };

  if (!patient)
    return (
      <Sheet sx={{ px: 2, borderRadius: "sm", width }} variant="soft">
        <CurrentPatientManager />
      </Sheet>
    );

  let color: "neutral" | "primary" | "warning" | "success" = "neutral";
  if (hospitalisation?.hospitalisation_ambulatoire) color = "primary";
  else if (hospitalisation?.entree_date && isToday(hospitalisation.entree_date))
    color = "success";
  else if (hospitalisation?.sortie_date && isToday(hospitalisation.sortie_date))
    color = "warning";

  return (
    <Sheet
      sx={{ p: 1.5, borderRadius: "sm", width }}
      variant="soft"
      color={color}
    >
      <Stack>
        <Stack direction="row" gap={0.5}>
          <PersonIcon />
          <div>
            <Typography
              level="title-md"
              sx={{ cursor: "pointer" }}
              onClick={() => router.navigate(`/patient/${patient.id}`)}
            >
              {formatNameAndSurname(patient.nom, patient.prenom)}
              {patient.nom_naissance &&
                patient.nom_naissance !== patient.nom &&
                " née " + patient.nom_naissance.toUpperCase()}
            </Typography>
            {hospitalisation && (
              <Typography
                color="warning"
                level="title-sm"
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  router.navigate(`/hospitalisation/${hospitalisation.id}`)
                }
              >
                {hospitalisation.motif_hospitalisation}
              </Typography>
            )}
            {hospitalisation && (
              <Typography level="body-sm">
                Entrée: {formatDate(hospitalisation.entree_date)}
              </Typography>
            )}
            <Stack direction="row" gap={1} alignItems="center">
              <Typography level="body-xs">
                {patient.sexe === "MALE" ? "Homme" : "Femme"}
              </Typography>
              <Typography level="body-xs">
                {ISODateToAge(patient.dob)} ans
              </Typography>
              {patient.etat && patient.etat === "DCD" && (
                <Chip size="sm" color="warning">
                  Décédé
                </Chip>
              )}
            </Stack>
            {!hospitalisation && (
              <Stack direction="column">
                {patient.numero_securite_sociale && (
                  <Stack direction="row" gap={0.5}>
                    <Typography level="body-xs" fontWeight={600}>
                      NSS:
                    </Typography>
                    <Typography level="body-xs" fontWeight={400}>
                      {patient.numero_securite_sociale}
                    </Typography>
                  </Stack>
                )}
                {patient.couverture_maladie && (
                  <Stack direction="row" gap={0.5}>
                    <Typography level="body-xs" fontWeight={600}>
                      Couverture:
                    </Typography>
                    {patient.couverture_maladie && (
                      <Typography level="body-xs" fontWeight={400}>
                        {patient.couverture_maladie}
                      </Typography>
                    )}
                  </Stack>
                )}
              </Stack>
            )}
          </div>
          {hospitalisation && (
            <Stack
              direction="column"
              flex={"1"}
              flexWrap={"wrap"}
              alignContent={"end"}
              marginRight={"12px"}
              justifyContent={"space-between"}
            >
              {hospitalisation.medecin_referent.map(
                (m) =>
                  isUser(m) && (
                    <Typography key={m.id} level="body-sm">
                      {m.last_name}
                    </Typography>
                  ),
              )}
              <Stack direction={"row-reverse"}>
                <Tooltip title={"Date de sortie"}>
                  <IconButton
                    variant="plain"
                    onClick={() => {
                      setLeave(moment().format("YYYY-MM-DD"));
                      setOpen(true);
                    }}
                  >
                    <ExitToAppRoundedIcon sx={{ fill: "red" }} />
                  </IconButton>
                </Tooltip>
                <Modal open={open} onClose={() => setOpen(false)}>
                  <ModalDialog>
                    <Typography level="title-md">Date de sortie</Typography>
                    <Input
                      type="date"
                      value={leave}
                      onChange={(e) => setLeave(e.target.value)}
                    />
                    <Box
                      sx={{
                        mt: 1,
                        display: "flex",
                        gap: 1,
                        flexDirection: { xs: "column", sm: "row-reverse" },
                      }}
                    >
                      <Button
                        variant="solid"
                        color="primary"
                        onClick={() => {
                          setOpen(false);
                          updateSortieDate();
                        }}
                      >
                        Ok
                      </Button>
                    </Box>
                  </ModalDialog>
                </Modal>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Sheet>
  );
}
