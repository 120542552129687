import CloseIcon from "@mui/icons-material/Close";
import Delete from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import Checkbox from "@mui/joy/Checkbox";
import Divider from "@mui/joy/Divider";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
  Contact,
  Patient,
  Specialite,
  TypeCompteRenduEnum,
} from "../../models/types";
import ListOfFilesPreview from "../File/ListOfFilesPreview";
import CircularProgress from "@mui/joy/CircularProgress";
import SpecialiteMultiSelect from "../Forms/SpecialiteMultiSelect";
import ContactSelector from "../Forms/ContactSelector";

export interface MetaData {
  file?: File;
  nom?: string;
  auteur?: Contact;
  type?: TypeCompteRenduEnum;
  specialites?: Specialite[];
  dateExamen?: string;
  dateEntree?: string;
  dateSortie?: string;
  notification: boolean;
}

interface DocumentUploaderProps {
  onChange?: (files: File[], metaData: MetaData[]) => void;
  isLoading: boolean;
  patient: Patient;
}

export const isValidMetaData = (metaData: MetaData) =>
  metaData &&
  metaData.nom &&
  metaData.auteur &&
  metaData.type &&
  metaData.specialites &&
  metaData.specialites.length &&
  ((metaData.type !== "HOSPITALISATION" && metaData.dateExamen) ||
    (metaData.dateEntree && metaData.dateSortie));

const DocumentUploader: React.FC<DocumentUploaderProps> = ({
  onChange,
  isLoading,
  patient,
}) => {
  const [files, setFiles] = React.useState<File[]>([]);
  const [selectedFileIndex, setSelectedFileIndex] = React.useState<number>();
  const [metaData, setMetaData] = React.useState<MetaData[]>([]);

  React.useEffect(
    () => onChange && onChange(files, metaData),
    [files, metaData, onChange],
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
      "application/pdf": [],
      "application/msword": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles);
      setMetaData(
        acceptedFiles.map(() => ({
          nom: "",
          type: undefined,
          specialites: [],
          auteur: undefined,
          dateEntree: undefined,
          dateSortie: undefined,
          dateExamen: undefined,
          notification: true,
        })),
      );
      setSelectedFileIndex(0);
    },
  });

  const removeFile = (index: number) => {
    setMetaData(metaData.filter((m, i) => i !== index));
    setFiles(files.filter((f, i) => i !== index));
  };

  const dragAndDropRender = React.useMemo(
    () => (
      <div
        {...getRootProps()}
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px",
          borderWidth: "2px",
          borderRadius: "2px",
          borderColor: "#eeeeee",
          borderStyle: "dashed",
          backgroundColor: "#fafafa",
          color: "#bdbdbd",
          outline: "none",
          transition: "border .24s ease-in-out",
        }}
      >
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
    ),
    [getInputProps, getRootProps],
  );

  const updateMetaData = (
    field: string,
    value: string | Specialite[] | Contact | null,
  ) => {
    setMetaData((prevData) =>
      prevData.map((data, index) =>
        index !== selectedFileIndex ? data : { ...data, [field]: value },
      ),
    );
  };

  const mandatoryField = <div style={{ color: "red" }}>*</div>;

  const inputRender = (
    title: string,
    field: string,
    type: string = "text",
    isRequired: boolean = true,
  ) => {
    if (!selectedFileIndex || !metaData[selectedFileIndex]) return null;

    // @ts-ignore
    const value = metaData[selectedFileIndex][field];
    return (
      <Stack direction="column" spacing={1}>
        <Typography endDecorator={isRequired ? mandatoryField : null}>
          {title}
        </Typography>
        <Input
          type={type}
          name={field}
          value={value || ""}
          onChange={(e) => updateMetaData(field, e.target.value)}
        />
      </Stack>
    );
  };

  const metaDataRender = () => {
    if (
      !selectedFileIndex ||
      metaData.length === 0 ||
      !metaData[selectedFileIndex]
    )
      return null;

    return (
      <Stack spacing={2}>
        <Stack direction={"row"} spacing={2}>
          {inputRender("Description", "nom")}
          <Stack direction="column" spacing={1}>
            <Typography endDecorator={mandatoryField}>Auteur</Typography>
            <ContactSelector
              value={metaData[selectedFileIndex].auteur?.id || ""}
              onSelect={(newValue) => updateMetaData("auteur", newValue)}
              patient={patient}
            />
          </Stack>
          <Stack direction="column" spacing={1}>
            <Typography endDecorator={mandatoryField}>
              Type compte rendu
            </Typography>
            <Select
              name="type"
              value={metaData[selectedFileIndex]?.type || ""}
              onChange={(e, newValue) => updateMetaData("type", newValue || "")}
            >
              <Option value={"CONSULTATION"}>Consultation</Option>
              <Option value={"HOSPITALISATION"}>Hospitalisation</Option>
              <Option value={"EXAMEN_COMPLEMENTAIRE"}>
                Examen complementaire
              </Option>
              <Option value={"ORDONNANCE_TRAITEMENT"}>
                Ordonnance traitement
              </Option>
            </Select>
          </Stack>
        </Stack>
        <Stack direction={"row"} spacing={2}>
          <Stack direction="column" spacing={1}>
            <Typography endDecorator={mandatoryField}>Specialites</Typography>
            <SpecialiteMultiSelect
              value={
                metaData[selectedFileIndex]?.specialites?.map(
                  (spe) => spe.id,
                ) || []
              }
              onSelect={(newValue) =>
                updateMetaData("specialites", newValue || [])
              }
              width={"200px"}
            />
          </Stack>
          {metaData[selectedFileIndex]?.type !== "HOSPITALISATION" &&
            inputRender("Date examen", "dateExamen", "date", false)}
          {metaData[selectedFileIndex]?.type === "HOSPITALISATION" &&
            inputRender("Date entree", "dateEntree", "date", false)}
          {metaData[selectedFileIndex]?.type === "HOSPITALISATION" &&
            inputRender("Date sortie", "dateSortie", "date", false)}
        </Stack>
      </Stack>
    );
  };

  const onSelectedFileChange = useCallback(
    (file: File, index: number) => setSelectedFileIndex(index),
    [],
  );

  return (
    <Stack spacing={2} flex={1}>
      {metaDataRender()}
      <Divider />
      {metaData.length === 0 ? (
        dragAndDropRender
      ) : isLoading ? (
        <CircularProgress />
      ) : (
        <ListOfFilesPreview
          files={files}
          headers={
            <Stack direction={"row"} marginLeft={1.5} alignItems={"center"}>
              <Checkbox
                checked={metaData.every((m) => m.notification)}
                indeterminate={
                  !metaData.every((m) => m.notification) &&
                  metaData.some((m) => m.notification)
                }
                onChange={(e) =>
                  setMetaData((prevData) =>
                    prevData.map((p) => ({
                      ...p,
                      notification: e.target.checked,
                    })),
                  )
                }
              />
              <Typography marginLeft={0.5}>Notif</Typography>
              <Typography marginLeft={2}>Nom du fichier</Typography>
            </Stack>
          }
          decorator={(index) => (
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={1}
              marginRight={2}
            >
              <Checkbox
                onClick={(event) => event.stopPropagation()}
                checked={metaData[index].notification}
                onChange={(event) => {
                  setMetaData((prevData) => {
                    const updatedForms = [...prevData];
                    updatedForms[index].notification = event.target.checked;
                    return updatedForms;
                  });
                }}
              />
              {isValidMetaData(metaData[index]) ? (
                <DoneIcon style={{ fill: "green" }} />
              ) : (
                <CloseIcon style={{ fill: "red" }} />
              )}
            </Stack>
          )}
          endAction={(index) => (
            <IconButton
              aria-label={"Delete"}
              size={"sm"}
              color={"danger"}
              onClick={(event) => {
                event.stopPropagation();
                removeFile(index);
              }}
            >
              <Delete />
            </IconButton>
          )}
          onSelectedFileChange={onSelectedFileChange}
        />
      )}
    </Stack>
  );
};

export default DocumentUploader;
