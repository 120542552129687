import Autocomplete from "@mui/joy/Autocomplete";
import React, { useEffect, useState } from "react";
import { useApi } from "../../contexts/ApiContext";
import { User } from "../../models/types";
import { useUser } from "../../contexts/UserContext";

interface RedacteurMultiSelectorProps {
  value: User["id"][] | null;
  onSelect: (value: User[]) => void;
  width?: string;
  excludeCurrentUser?: boolean;
}

export default function RedacteurMultiSelector({
  value,
  onSelect,
  width = "auto",
  excludeCurrentUser = true,
}: RedacteurMultiSelectorProps) {
  const api = useApi();
  const user = useUser();
  const [redacteurs, setRedacteurs] = useState<User[]>([]);

  useEffect(() => {
    api
      .getUserIntervenantsEvents()
      .then((res) =>
        setRedacteurs(
          res.Redacteur.filter(
            (r) => !excludeCurrentUser || r.id !== user.user?.id,
          ),
        ),
      );
  }, [api, excludeCurrentUser, user.user?.id]);

  return (
    <Autocomplete
      sx={{ width }}
      value={redacteurs.filter((r) => value?.includes(r.id))}
      multiple
      disableCloseOnSelect
      onChange={(e, newValue) => onSelect(newValue || [])}
      options={redacteurs}
      getOptionLabel={(option) => `${option.last_name} ${option.first_name}`}
    />
  );
}
