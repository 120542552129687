import { LocalHospital } from "@mui/icons-material";
import Add from "@mui/icons-material/Add";
import Masks from "@mui/icons-material/Masks";
import PeopleAlt from "@mui/icons-material/PeopleAlt";
import Save from "@mui/icons-material/Save";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import ToggleButtonGroup from "@mui/joy/ToggleButtonGroup";
import Typography from "@mui/joy/Typography";
import dayjs from "dayjs";
import React from "react";
import { SlotInfo } from "react-big-calendar";
import CurrentPatientManager from "../../components/CurrentPatientManager";
import DoctorSelect from "../../components/Forms/DoctorSelect";
import { useApi } from "../../contexts/ApiContext";
import { useCurrentPatient } from "../../contexts/CurrentPatientContext";
import { useSnackbar } from "../../contexts/SnackbarContext";
import {
  Consultation,
  Hospitalisation,
  Operation,
  User,
} from "../../models/types";
import { Mode } from "../PatientPage/Consultation/CreateConsultation";
import ConsultationForm from "./Forms/ConsultationForm";
import HospitalisationForm from "./Forms/HospitalisationForm";
import OperationForm from "./Forms/OperationForm";

const radioValues = [
  { label: "Consultation", value: "consultation", icon: <PeopleAlt /> },
  // { label: "Opération", value: "operation", icon: <Masks /> },
  {
    label: "Hospitalisation & Opération",
    value: "hospitalisation",
    icon: <Masks />,
  },
];

interface CreateSlotModalProps {
  slot: SlotInfo;
  onSave: () => void;
  onClose: () => void;
}

const isMandatoryFieldValid = (field: any): boolean => {
  if (field === undefined || field === null || field === "") return false;
  return true;
};

export default function CreateSlotModal({
  slot,
  onSave,
  onClose,
}: CreateSlotModalProps) {
  const api = useApi();
  const snackbar = useSnackbar();
  const currentPatient = useCurrentPatient();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [type, setType] = React.useState<
    "consultation" | "operation" | "hospitalisation"
  >("consultation");
  const [date, setDate] = React.useState(
    dayjs(slot.start).format("YYYY-MM-DD"),
  );
  const [start, setStart] = React.useState(dayjs(slot.start).format("HH:mm"));
  const [end, setEnd] = React.useState(dayjs(slot.end).format("HH:mm"));
  const [selectedDr, setSelectedDr] = React.useState<number | null>(2);
  const [consultationFormValues, setConsultationFormValues] = React.useState<
    Partial<Consultation>
  >({
    mode_consultation: Mode.PROGRAMME,
    zkf_site: "",
    zkf_lieu: "",
    motif_consultation: "",
    operation_liee: [],
  });
  const [operationFormValues, setOperationFormValues] =
    React.useState<Partial<Operation> | null>(null);
  const [hospitalisationFormValues, setHospitalisationFormValues] =
    React.useState<Partial<Hospitalisation>>({
      zkf_patient: currentPatient[0]?.id,
      entree_date: dayjs(date).format("YYYY-MM-DD"),
    });

  const checkAndSave = async () => {
    if (type === "consultation") {
      setLoading(true);
      try {
        await api?.createConsultation({
          ...consultationFormValues,
          consultation_debut_prevu: start,
          consultation_fin_prevu: end,
          zkf_patient: currentPatient[0]?.id,
          consultation_date: date,
          tags: [],
          zkf_redacteur: selectedDr,
        });

        snackbar.show("Consultation créée avec succès", "success");

        onSave();
        onClose();
      } catch (error) {
        console.error("Error saving consultation:", error);
        snackbar.show(
          "Erreur lors de la création de la consultation",
          "danger",
        );
      } finally {
        setLoading(false);
      }
    } else if (type === "hospitalisation") {
      // first create hospitalisation
      try {
        setLoading(true);
        const hospitalisation = await api.createHospitalisation({
          ...hospitalisationFormValues,
          zkf_redacteur: selectedDr,
          sortie_date:
            hospitalisationFormValues.hospitalisation_ambulatoire ||
            hospitalisationFormValues.sortie_date === ""
              ? null
              : hospitalisationFormValues.sortie_date, // if sortie_date is empty string, set it to null
        });
        // check if we're adding an operation as well, if operationFormValues is not null
        if (operationFormValues !== null) {
          const payload: Partial<Operation> = {
            ...operationFormValues,
            zkf_hospitalisation: hospitalisation.id,
            zkf_redacteur: selectedDr,
            operation_date: date,
            operation_start:
              // must be ISO 8601 format, date today + start time which is in HH:mm format
              dayjs(date).format("YYYY-MM-DD") + "T" + start + ":00",
            operation_end: dayjs(date).format("YYYY-MM-DD") + "T" + end + ":00",
          };
          const ope = await api?.createOperation(payload);
          console.log("Operation saved:", ope);
        }
        onSave();
        onClose();
        snackbar.show("Événement créée avec succès", "success");
      } catch (error) {
        console.error("Error saving operation:", error);
        snackbar.show("Erreur lors de la création de l'opération", "danger");
      } finally {
        setLoading(false);
      }
    }
  };

  const initOperationFormValues = () => {
    setOperationFormValues({
      zkf_patient: currentPatient[0]?.id,
      tags: [],
      operation_date: dayjs(date).format("YYYY-MM-DD"),
      // format should be YYYY-MM-DDThh:mm[:ss[.uuuuuu]][+HH:MM|-HH:MM|Z]
      operation_start: dayjs(start).format(),
      operation_end: dayjs(end).format(),
    });
  };

  const canSave = () => {
    if (type === "consultation") {
      return (
        currentPatient !== null &&
        isMandatoryFieldValid(consultationFormValues.zkf_lieu) &&
        isMandatoryFieldValid(consultationFormValues.zkf_site) &&
        isMandatoryFieldValid(consultationFormValues.motif_consultation)
      );
    } else if (type === "hospitalisation") {
      if (operationFormValues === null) {
        console.log("hospit only");
        console.log(hospitalisationFormValues);
        return (
          isMandatoryFieldValid(hospitalisationFormValues.entree_date) &&
          isMandatoryFieldValid(hospitalisationFormValues.entree_heure) &&
          isMandatoryFieldValid(hospitalisationFormValues.zkf_patient) &&
          isMandatoryFieldValid(hospitalisationFormValues.mode_entree) &&
          isMandatoryFieldValid(
            hospitalisationFormValues.service_hospitalisation,
          ) &&
          isMandatoryFieldValid(
            hospitalisationFormValues.specialite_referente,
          ) &&
          isMandatoryFieldValid(hospitalisationFormValues.medecin_referent)
        );
      } else {
        console.log("hospit + operation");
        return (
          // Required fields for hospitalisation
          isMandatoryFieldValid(hospitalisationFormValues.entree_date) &&
          isMandatoryFieldValid(hospitalisationFormValues.zkf_patient) &&
          isMandatoryFieldValid(hospitalisationFormValues.mode_entree) &&
          isMandatoryFieldValid(
            hospitalisationFormValues.service_hospitalisation,
          ) &&
          isMandatoryFieldValid(
            hospitalisationFormValues.specialite_referente,
          ) &&
          isMandatoryFieldValid(hospitalisationFormValues.medecin_referent) &&
          // Required fields for operation
          isMandatoryFieldValid(operationFormValues.operation_date) &&
          isMandatoryFieldValid(operationFormValues.operation_start) &&
          isMandatoryFieldValid(operationFormValues.operation_end) &&
          isMandatoryFieldValid(operationFormValues.zkf_patient) &&
          isMandatoryFieldValid(operationFormValues.titre_operation) &&
          isMandatoryFieldValid(operationFormValues.operateur_new) &&
          isMandatoryFieldValid(operationFormValues.indication_operatoire)
        );
      }
    }
  };

  return (
    <Stack sx={{ overflow: "scroll", width: "90vw" }}>
      <Typography level="h2">Ajout d'un événement</Typography>
      <ModalClose />
      <Sheet
        color="neutral"
        variant="soft"
        sx={{
          mt: 2,
          borderRadius: "lg",
          border: "1px solid transparent",
          pointerEvents: loading ? "none" : "auto",
          opacity: loading ? 0.7 : 1,
          px: 2,
        }}
      >
        <Stack direction="row" justifyContent="space-between" gap={2}>
          <Stack gap={1}>
            <CurrentPatientManager />
            {!currentPatient && (
              <Typography color="danger" level="body-xs" sx={{ ml: 2, mb: 1 }}>
                Veuillez sélectionner un patient
              </Typography>
            )}
          </Stack>
        </Stack>
      </Sheet>
      <Stack sx={{ mt: 2 }} direction="row" gap={2}>
        <Stack sx={{ width: 300 }} gap={2}>
          <FormControl>
            <FormLabel>Type d'événement</FormLabel>
            <ToggleButtonGroup
              value={type}
              onChange={(event, newValue) => {
                setType(newValue as "consultation" | "hospitalisation");
              }}
              defaultValue="consultation"
              disabled={loading}
            >
              {radioValues.map((radio) => (
                <Button
                  variant="soft"
                  key={radio.value}
                  value={radio.value}
                  startDecorator={radio.icon}
                >
                  {radio.label}
                </Button>
              ))}
            </ToggleButtonGroup>
          </FormControl>
          <FormControl>
            <FormLabel>Docteur</FormLabel>
            <DoctorSelect
              value={selectedDr!}
              onSelect={(doctor: User | User[]) => {
                setSelectedDr((doctor as User).id);
              }}
              disabled={loading}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Date</FormLabel>
            <Input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              disabled={loading}
            />
          </FormControl>
          <Stack direction="row" gap={2}>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel>Heure de début</FormLabel>
              <Input
                type="time"
                value={start}
                onChange={(e) => setStart(e.target.value)}
                disabled={loading}
              />
            </FormControl>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel>Heure de fin</FormLabel>
              <Input
                type="time"
                value={end}
                onChange={(e) => setEnd(e.target.value)}
                disabled={loading}
              />
            </FormControl>
          </Stack>
        </Stack>

        {type === "consultation" ? (
          <Sheet
            sx={{ p: 2, borderRadius: "lg", flex: 1 }}
            color="primary"
            variant="soft"
          >
            <ConsultationForm
              values={consultationFormValues as Consultation}
              setValues={(values) => setConsultationFormValues(values)}
            />
          </Sheet>
        ) : (
          <>
            <Stack gap={1} flex={1}>
              <Sheet
                sx={{ p: 2, borderRadius: "lg", flex: 1 }}
                variant="soft"
                color="warning"
              >
                <Typography
                  level="title-md"
                  startDecorator={<LocalHospital />}
                  sx={{ mb: 1 }}
                >
                  Hospitalisation
                </Typography>
                <HospitalisationForm
                  values={hospitalisationFormValues}
                  setValues={(values) => setHospitalisationFormValues(values)}
                />
              </Sheet>
              {!operationFormValues && (
                <Stack direction="row" gap={2}>
                  <Button
                    onClick={() => initOperationFormValues()}
                    startDecorator={<Add />}
                  >
                    <Masks />
                    &nbsp; Créer une opération
                  </Button>
                </Stack>
              )}
              {operationFormValues !== null && (
                <Sheet
                  sx={{ p: 2, borderRadius: "lg", flex: 1 }}
                  variant="soft"
                  color="success"
                >
                  <Typography
                    level="title-md"
                    startDecorator={<Masks />}
                    sx={{ mb: 1 }}
                  >
                    Opération
                  </Typography>
                  <OperationForm
                    values={operationFormValues}
                    setValues={(values) => setOperationFormValues(values)}
                  />
                </Sheet>
              )}
            </Stack>
          </>
        )}
      </Stack>
      <Stack sx={{ mt: 2 }} direction="row" justifyContent="flex-end" gap={2}>
        <Stack alignItems="flex-end" gap={0.5}>
          {!canSave() && (
            <Typography level="body-xs" color="neutral">
              <sup>*</sup> Champ obligatoire
            </Typography>
          )}
          <Button
            startDecorator={<Save />}
            color="primary"
            variant="outlined"
            onClick={() => checkAndSave()}
            loading={loading}
            disabled={!canSave()}
          >
            Enregistrer
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
