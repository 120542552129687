import { Box } from "@mui/joy";
import React from "react";
import PatientCard from "../../../components/PatientCard";
import { useApi } from "../../../contexts/ApiContext";
import { Operation, Patient } from "../../../models/types";

export default function OperationView({ operation }: { operation: Operation }) {
  const api = useApi();
  const [patient, setPatient] = React.useState<Patient | null>(null);

  React.useEffect(() => {
    api?.getPatient(operation.zkf_patient).then(setPatient);
  }, [api, operation]);

  return <Box>{patient && <PatientCard patient={patient} />}</Box>;
}
