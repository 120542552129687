import { Contact, Patient } from "../../models/types";
import Button from "@mui/joy/Button";
import Add from "@mui/icons-material/Add";
import Autocomplete from "@mui/joy/Autocomplete";
import React, { useCallback, useEffect, useMemo } from "react";
import { useApi } from "../../contexts/ApiContext";
import CorrespondantModalForm from "../Correspondant/CorrespondantModalForm";

interface ContactSelectorProps {
  value: string;
  onSelect: (value: Contact | null, isNew: boolean) => void;
  patient: Patient;
}

export default function ContactSelector({
  value,
  onSelect,
  patient,
}: ContactSelectorProps) {
  const api = useApi();
  const [contacts, setContacts] = React.useState<Contact[]>([]);
  const [newContact, setNewContact] = React.useState<Partial<Contact>>();
  const [auteurInput, setAuteurInput] = React.useState<string>("");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .listContacts()
      .then(setContacts)
      .finally(() => setLoading(false));
  }, [api]);

  const onSave = useCallback(
    (contact: Contact) => {
      onSelect(contact, true);
      setOpen(false);
    },
    [onSelect],
  );

  const createContactModal = useMemo(
    () =>
      open && (
        <CorrespondantModalForm
          open={open}
          onClose={() => setOpen(false)}
          contact={newContact as Contact}
          onSave={onSave}
          patient={patient}
        />
      ),
    [newContact, onSave, open, patient],
  );

  return (
    <>
      <Autocomplete
        sx={{ flex: 1 }}
        loading={loading}
        options={contacts}
        getOptionLabel={(option) =>
          option ? `${option.nom} ${option.prenom}` : ""
        }
        getOptionKey={(option) => option.id}
        onChange={(event, newValue) => onSelect(newValue, false)}
        value={
          contacts.find((c) => c.id === value) ||
          contacts.find((c) => c.correspondant?.id === value) ||
          null
        }
        onInputChange={(e, value) => e && setAuteurInput(value)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        endDecorator={
          <Button
            onClick={() => {
              setNewContact({ nom: auteurInput });
              setOpen(true);
            }}
          >
            <Add />
          </Button>
        }
      />
      {createContactModal}
    </>
  );
}
