import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import React from "react";
import { useApi } from "../../contexts/ApiContext";
import { Protocole } from "../../models/types";

interface ProtocoleSelectProps {
  value: Protocole["id"][];
  onChange: (v: Protocole["id"][]) => void;
}

export default function ProtocoleSelect({
  value,
  onChange,
}: ProtocoleSelectProps) {
  const [protocoles, setProtocoles] = React.useState<Protocole[]>([]);
  const api = useApi();

  React.useEffect(() => {
    if (api) {
      api.getProtocoles().then(setProtocoles);
    }
  }, [api]);

  return (
    <Select value={value} onChange={(e, v) => onChange(v)} multiple>
      {protocoles &&
        protocoles.map((p) => {
          return <Option value={p.id}>{p.libelle}</Option>;
        })}
    </Select>
  );
}
