import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import { useApi } from "../../contexts/ApiContext";
import { FormattedHistoryFields } from "../../models/custom";
import { Patient } from "../../models/types";

export default function HistoryForm({
  p,
  // consultation,
  // hospitalisation,
}: {
  p: Patient;
  // consultation?: Consultation;
  // hospitalisation?: Hospitalisation;
}) {
  // allow the user to update a patient history (antecedents) with a form
  // the form should be populated with the patient's current history if a consultation or hospitalisation is provided
  // if the patient has no history, the form should be empty

  // 1. fetch the fields for the antecedents form
  // 2. fetch the patient's history if consultation or hospitalisation is provided
  // 3. populate the form with the patient's history if available

  const api = useApi();

  const [formatted, setFormatted] =
    React.useState<FormattedHistoryFields | null>(null);

  React.useEffect(() => {
    if (api) {
      Promise.all([api.getAntecedentsFields(p.id)]).then(([fieldsResponse]) => {
        // group by head field
        const heads = new Set<string>();
        Object.keys(fieldsResponse).forEach((key) => {
          heads.add(fieldsResponse[key].head);
        });
        const formatted: FormattedHistoryFields = {};
        // get all fields for each head, then for each head add the fields to the formatted object with the field key as the key
        heads.forEach((head) => {
          const fields = Object.keys(fieldsResponse).filter(
            (key) => fieldsResponse[key].head === head,
          );
          formatted[head] = fields.map((field) => fieldsResponse[field]);
        });
        setFormatted(formatted);
      });
    }
  }, [api, p.id]);

  return (
    <Box>
      <Typography level="h3" sx={{ mb: 4 }}>
        Edition antécédents
      </Typography>
      {formatted && (
        <Stack direction="column" gap={4}>
          {Object.values(formatted).map((fields, index) => (
            // for each head, render the key as title then all the fields as form fields
            <Box key={index}>
              <Typography level="h4" sx={{ mb: 2 }}>
                {fields[0].head}
              </Typography>
              <Stack direction="row" gap={2} flexWrap="wrap">
                {fields.map((field, index) => (
                  <Box key={index} sx={{ width: "250px" }}>
                    {/* <FormComponent
                      input={field}
                      value={values ? values[Object.keys(field)[index]] : null}
                      onUpdate={(newValue) => { 
                        console.log(newValue)
                        setValues({ ...values, [Object.keys(field)[index]]: newValue })
                      }}
                    /> */}
                    {/* {field.conditional_fields && (
                      <Stack direction="column" gap={2}>
                        {JSON.stringify(field)}
                      </Stack>
                    )} */}
                  </Box>
                ))}
              </Stack>
            </Box>
          ))}
        </Stack>
      )}
    </Box>
  );
}
