import { useApi } from "../../../../contexts/ApiContext";
import { useSnackbar } from "../../../../contexts/SnackbarContext";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  MatrielIrradiation,
  PdsUniteEnum,
  Specialite,
} from "../../../../models/types";
import Table from "@mui/joy/Table";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import Checkbox from "@mui/joy/Checkbox";
import ModalComponent from "../../../../components/Modal/Modal";
import Typography from "@mui/joy/Typography";
import Input from "@mui/joy/Input";
import Tooltip from "@mui/joy/Tooltip";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Box from "@mui/joy/Box";
import SpecialiteMultiSelect from "../../../../components/Forms/SpecialiteMultiSelect";

export default function MaterielIrradiationTab() {
  const api = useApi();
  const snackbar = useSnackbar();

  const [materiels, setMateriels] = useState<MatrielIrradiation[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [nom, setNom] = useState<string>("");
  const [marque, setMarque] = useState<string>("");
  const [unite, setUnite] = useState<PdsUniteEnum | "">("");
  const [airUnite, setAirUnite] = useState<string>("");
  const [enUtilisation, setEnUtilisation] = useState<boolean>();
  const [specialites, setSpecialites] = useState<Specialite[]>([]);
  const [selectedSpecialites, setSelectedSpecialites] = useState<string[]>([]);
  const [editMateriel, setEditMateriel] = useState<MatrielIrradiation>();

  useEffect(() => {
    api.getMaterielIrradiation().then((res) => setMateriels(res));
    api.listUserSpecialites().then((res) => setSpecialites(res));
  }, [api]);

  const handleEdit = useCallback((materiel?: MatrielIrradiation) => {
    setNom(materiel?.nom_appareil_radio || "");
    setMarque(materiel?.marque_appareil_radio || "");
    setUnite(materiel?.pds_unite || "");
    setAirUnite(materiel?.air_kerma_unite || "");
    setEnUtilisation(materiel ? materiel.en_utilisation : true);
    setSelectedSpecialites(materiel?.zkf_specialite || []);
    setEditMateriel(materiel);
    setOpenModal(true);
  }, []);

  const handleDelete = useCallback(
    (id: string) => {
      api
        .deleteMaterielIrradiation(id)
        .then(() => setMateriels(materiels.filter((m) => m.id !== id)))
        .catch(() =>
          snackbar.show("Impossible de supprimer le materiel", "danger"),
        );
    },
    [api, materiels, snackbar],
  );

  const handleSave = useCallback(() => {
    const mewMateriel: Partial<MatrielIrradiation> = {
      nom_appareil_radio: nom,
      marque_appareil_radio: marque,
      pds_unite: unite,
      air_kerma_unite: airUnite,
      en_utilisation: enUtilisation,
      zkf_specialite: selectedSpecialites,
    };
    if (editMateriel) {
      api
        .updateMaterielIrradiation({ id: editMateriel.id, ...mewMateriel })
        .then((res) => {
          setMateriels((prevState) =>
            prevState.map((m) => (m.id === editMateriel.id ? res : m)),
          );
          setOpenModal(false);
        })
        .catch(() =>
          snackbar.show("Impossible de modifier le material", "danger"),
        );
    } else {
      api
        .createMaterielIrradiation(mewMateriel)
        .then((res) => {
          setMateriels((prevState) => [...prevState, res]);
          setOpenModal(false);
        })
        .catch(() =>
          snackbar.show("Impossible de créer le materiel", "danger"),
        );
    }
  }, [
    airUnite,
    api,
    editMateriel,
    enUtilisation,
    marque,
    nom,
    selectedSpecialites,
    snackbar,
    unite,
  ]);

  const materielsList = useMemo(
    () =>
      materiels.length === 0 ? (
        <div>Aucun Materiels</div>
      ) : (
        <Table
          sx={{ borderRadius: "sm" }}
          stickyHeader
          hoverRow
          variant="outlined"
        >
          <thead>
            <tr>
              <th>Nom</th>
              <th>Marque</th>
              <th>PDS</th>
              <th>Air Kerma</th>
              <th>En Utilisation</th>
              <th>Specialites</th>
              <th style={{ width: "60px" }}></th>
            </tr>
          </thead>
          <tbody>
            {materiels.map((materiel) => {
              const specialitesValue = specialites
                ?.filter((spe) => materiel.zkf_specialite?.includes(spe.id))
                ?.map((selectSpe) => selectSpe.spe)
                .join(", ");
              return (
                <tr key={materiel.id}>
                  <td>{materiel.nom_appareil_radio}</td>
                  <td>{materiel.marque_appareil_radio}</td>
                  <td>{materiel.pds_unite}</td>
                  <td>{materiel.air_kerma_unite}</td>
                  <td>
                    <Checkbox checked={materiel.en_utilisation} />
                  </td>
                  <td>
                    <Tooltip title={specialitesValue}>
                      <Typography
                        overflow={"hidden"}
                        whiteSpace={"nowrap"}
                        textOverflow={"ellipsis"}
                      >
                        {specialitesValue}
                      </Typography>
                    </Tooltip>
                  </td>
                  <td>
                    <Stack direction="row" justifyContent={"center"} gap={0.5}>
                      <Edit
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleEdit(materiel)}
                      />
                      <Delete
                        sx={{ fill: "red", cursor: "pointer" }}
                        onClick={() => handleDelete(materiel.id)}
                      />
                    </Stack>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ),
    [handleDelete, handleEdit, materiels, specialites],
  );

  const editModal = useMemo(() => {
    const width = "100px";

    return (
      <ModalComponent
        open={openModal}
        title={`${editMateriel ? "Editer" : "Créer"} un materiel`}
        onClose={() => setOpenModal(false)}
        validateLabel={"Enregistrer"}
        onValidate={handleSave}
        canValidate={!!nom && !!marque}
      >
        <Stack spacing={2} flex={1}>
          <Stack spacing={1} direction={"row"} alignItems={"center"}>
            <Typography width={width}>Nom</Typography>
            <Input
              sx={{ flex: 1 }}
              value={nom}
              onChange={(e) => setNom(e.target.value)}
            />
          </Stack>
          <Stack spacing={1} direction={"row"} alignItems={"center"}>
            <Typography width={width}>Marque</Typography>
            <Input
              sx={{ flex: 1 }}
              value={marque}
              onChange={(e) => setMarque(e.target.value)}
            />
          </Stack>
          <Stack spacing={1} direction={"row"} alignItems={"center"}>
            <Typography width={width}>PDS</Typography>
            <Select
              value={unite}
              onChange={(e, newValue) => setUnite(newValue || "")}
              style={{ width: "250px" }}
            >
              <Option value={"Gycm2"}>Gycm2</Option>
              <Option value={"mGycm2"}>mGycm2</Option>
            </Select>
          </Stack>
          <Stack spacing={1} direction={"row"} alignItems={"center"}>
            <Typography width={width}>Air Kerma</Typography>
            <Input
              sx={{ flex: 1 }}
              value={airUnite}
              onChange={(e) => setAirUnite(e.target.value)}
            />
          </Stack>
          <Stack spacing={1} direction={"row"} alignItems={"center"}>
            <Typography width={width}>Spécialités</Typography>
            <SpecialiteMultiSelect
              value={selectedSpecialites}
              onSelect={(spe) =>
                setSelectedSpecialites(spe?.map((spe) => spe.id) || [])
              }
              width={"250px"}
            />
          </Stack>
          <Stack spacing={1} direction={"row"} alignItems={"center"}>
            <Typography width={width}>En utilisation</Typography>
            <Checkbox
              checked={enUtilisation}
              onClick={() => setEnUtilisation(!enUtilisation)}
            />
          </Stack>
        </Stack>
      </ModalComponent>
    );
  }, [
    airUnite,
    editMateriel,
    enUtilisation,
    handleSave,
    marque,
    nom,
    openModal,
    selectedSpecialites,
    unite,
  ]);

  return (
    <Stack
      sx={{
        width: "100%",
        height: "70vh",
        border: "1px solid #e3e3e3",
        borderRadius: "8px",
        p: 2,
      }}
      spacing={2}
    >
      <Stack direction="row" alignItems="center" justifyContent="end">
        <Button onClick={() => handleEdit()}>Créer un materiel</Button>
      </Stack>
      <Divider />
      <Box sx={{ height: "100%", overflow: "auto" }}>{materielsList}</Box>
      {editModal}
    </Stack>
  );
}
