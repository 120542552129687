import React, { useEffect } from "react";
import { useApi } from "../../../../contexts/ApiContext";
import { useUser } from "../../../../contexts/UserContext";
import {
  DocumentLibre,
  DocumentLibreTemplate,
  Operation,
  User,
} from "../../../../models/types";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Input from "@mui/joy/Input";
import SearchIcon from "@mui/icons-material/Search";
import Stack from "@mui/joy/Stack";
import Add from "@mui/icons-material/Add";
import Print from "@mui/icons-material/Print";
import Delete from "@mui/icons-material/Delete";
import Divider from "@mui/joy/Divider";
import Button from "@mui/joy/Button";
import PreviewFile from "../../../../components/File/PreviewFile";
import Checkbox from "@mui/joy/Checkbox";
import { useSnackbar } from "../../../../contexts/SnackbarContext";
import { Deselect, SelectAll, WatchLater } from "@mui/icons-material";
import ModalComponent from "../../../../components/Modal/Modal";
import Autocomplete from "@mui/joy/Autocomplete";
import Editor from "../../../../components/Editor/Editor";
import PrintModal from "../../../../components/PrintManager/PrintModal";

interface DocumentsProps {
  operation: Operation;
}

export default function Documents({ operation }: DocumentsProps) {
  const api = useApi();
  const user = useUser();
  const snackbar = useSnackbar();

  const [templates, setTemplates] = React.useState<DocumentLibreTemplate[]>([]);
  const [displayedTemplates, setDisplayedTemplates] = React.useState<
    DocumentLibreTemplate[]
  >([]);
  const [documents, setDocuments] = React.useState<DocumentLibre[]>([]);
  const [currentDocument, setCurrentDocument] =
    React.useState<DocumentLibre | null>();
  const [currentFile, setCurrentFile] = React.useState<File | null>();
  const [selectedDocuments, setSelectedDocuments] = React.useState<
    DocumentLibre[]
  >([]);
  const [isAllSelected, setIsAllSelected] = React.useState<boolean>(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [redacteurs, setRedacteurs] = React.useState<User[]>([]);
  const [titre, setTitre] = React.useState<string>("");
  const [redacteur, setRedacteur] = React.useState<User | null>();
  const [content, setContent] = React.useState<string>("");
  const [openPrintModal, setOpenPrintModal] = React.useState<boolean>(false);
  const [fileBlobURL, setFileBlobURL] = React.useState<string>("");

  useEffect(() => {
    if (user.user) {
      api.listDocumentLibreTemplates(user.user.id).then((result) => {
        setTemplates(result);
        setDisplayedTemplates(result);
      });
    }
  }, [api, user]);

  useEffect(() => {
    if (operation) {
      api
        .listDocumentsByOperation(operation.id)
        .then((result) => setDocuments(result.filter((doc) => !doc.deleted)));
    }
  }, [api, operation]);

  useEffect(() => {
    if (currentDocument && currentDocument.document_libre_file) {
      fetch(currentDocument.document_libre_file).then(async (response) => {
        if (response.status !== 200) {
          snackbar.show("Impossible d'afficher fichier", "danger");
          return;
        }
        const blob = await response.blob();
        setCurrentFile(new File([blob], "preview", { type: blob.type }));
      });
    } else {
      setCurrentFile(null);
    }
  }, [currentDocument, snackbar]);

  useEffect(() => {
    if (openModal) {
      api
        .getUserIntervenantsEvents()
        .then((res) => setRedacteurs(res?.Redacteur || []));
    } else {
      setRedacteurs([]);
      setTitre("");
      setRedacteur(null);
      setContent("");
    }
  }, [api, openModal]);

  useEffect(() => {
    setIsAllSelected(selectedDocuments.length === documents.length);
  }, [documents.length, selectedDocuments.length]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value;
    if (search.length === 0) {
      setDisplayedTemplates(templates || []);
      return;
    }
    const filtered = templates?.filter((template) => {
      return template.titre_document
        .toLowerCase()
        .includes(search.toLowerCase());
    });
    setDisplayedTemplates(filtered || []);
  };

  const addTemplate = async (template: DocumentLibreTemplate) => {
    try {
      const document = await api.createDocument({
        zkf_operation: operation.id,
        zkf_patient: operation.zkf_patient,
        titre_document: template.titre_document,
        zkf_document_template: template.id,
      });
      setDocuments((prev) => [...prev, document]);
      return document;
    } catch (error) {
      snackbar.show("Erreur lors de l'ajout du template", "danger");
      return null;
    }
  };

  const deleteDocument = (id: string) => {
    api.deleteDocument(id).then(() => {
      setDocuments(documents.filter((doc) => doc.id !== id));
      setSelectedDocuments(selectedDocuments.filter((doc) => doc.id !== id));
      if (currentDocument?.id === id) setCurrentDocument(null);
    });
  };

  const addToPrintManager = (document: DocumentLibre) => {
    api
      .addToPrintManager("document_libre", document.id)
      .then(() =>
        snackbar.show(
          "Document ajouté au gestionnaire d'impression",
          "success",
        ),
      )
      .catch(() =>
        snackbar.show(
          "Erreur lors de l'ajout au gestionnaire d'impression",
          "danger",
        ),
      );
  };

  const iconStyle = {
    color: "initial",
    cursor: "pointer",
  };

  const print = async (docs: DocumentLibre[]) => {
    api
      .printManagerPrintAll(
        docs.map((doc) => ({
          id: doc.id,
          model_app_datas: doc.model_app_datas,
          type_document: "document_libre",
        })),
      )
      .then((blob) => {
        setFileBlobURL(window.URL.createObjectURL(blob));
        setOpenPrintModal(true);
      });
  };

  const PrintLaterIcon = (onClick: () => void, disabled: boolean = false) => (
    <Box
      position="relative"
      display="inline-flex"
      onClick={onClick}
      sx={{ cursor: "pointer" }}
    >
      <Print sx={disabled ? {} : { color: "initial" }} fontSize="large" />
      <Box
        position="absolute"
        bottom={"-9px"}
        right={"-3px"}
        borderRadius="50%"
      >
        <WatchLater
          sx={{
            color: disabled ? "" : "initial",
            width: "11px",
            height: "11px",
          }}
        />
      </Box>
    </Box>
  );

  const preview = () => {
    if (!currentDocument) return null;

    if (currentFile)
      return <PreviewFile file={currentFile} height={"calc(100% - 68px)"} />;
    else if (
      !currentDocument.document_libre_file &&
      currentDocument.corps_document
    )
      return (
        <div
          style={{
            border: "1px solid #ddd",
            padding: "10px",
            maxHeight: "calc(100% - 68px)",
            overflow: "scroll",
          }}
          dangerouslySetInnerHTML={{ __html: currentDocument.corps_document }}
        />
      );
  };

  const handleSaveDocument = async () => {
    try {
      const document = await api.createDocument({
        zkf_operation: operation.id,
        zkf_patient: operation.zkf_patient,
        titre_document: titre,
        corps_document: content,
        redacteur_document: redacteur?.id,
      });
      setDocuments((prev) => [...prev, document]);
      setOpenModal(false);
    } catch (error) {
      snackbar.show("Erreur lors de l'ajout du template", "danger");
      return null;
    }
  };

  const createDocumentModal = () => (
    <ModalComponent
      open={openModal}
      title={"Nouveau document"}
      onClose={() => setOpenModal(false)}
      validateLabel={"Enregistrer"}
      onValidate={handleSaveDocument}
      canValidate={!!titre && !!redacteur && !!content}
      style={{ minWidth: "70%", minHeight: "80%" }}
    >
      <Stack spacing={2} flex={1}>
        <Stack direction={"row"} spacing={3}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography>Titre</Typography>
            <Input value={titre} onChange={(e) => setTitre(e.target.value)} />
          </Stack>
          <Stack direction="row" alignItems={"center"} spacing={1}>
            <Typography>Redacteur</Typography>
            <Autocomplete
              options={redacteurs}
              getOptionLabel={(option) =>
                option ? `${option.last_name} ${option.first_name}` : ""
              }
              getOptionKey={(option) => option.id}
              onChange={(event, newValue) => setRedacteur(newValue)}
              value={redacteur}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Stack>
        </Stack>
        <Divider />
        <Stack flex={1}>
          <Editor data={content} onChange={setContent} />
        </Stack>
      </Stack>
    </ModalComponent>
  );

  return (
    <Stack
      direction="row"
      gap={1}
      sx={{ mt: 2 }}
      alignItems="flex-start"
      spacing={0.5}
    >
      <Box
        sx={{
          width: "250px",
          background: (theme) => theme.palette.background.level1,
          borderTop: "1px solid #e3e3e3",
          borderBottom: "1px solid #e3e3e3",
          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
          ml: -2,
          pl: 2,
          pr: 2,
          py: 2,
        }}
      >
        {/* <Typography level="title-sm">Ajouter un template</Typography> */}
        <Input
          sx={{ mt: 3 }}
          type="search"
          size="sm"
          placeholder="Rechercher un template"
          onChange={handleSearch}
          startDecorator={<SearchIcon fontSize="small" />}
        />
        <Stack
          direction="column"
          gap={1}
          sx={{ mt: 2, maxHeight: "60vh", overflow: "scroll" }}
        >
          {displayedTemplates.map((template, index) => {
            return (
              <Box key={index} sx={{ p: 0.5, borderRadius: "sm" }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  gap={0.5}
                >
                  <Checkbox
                  // checked={selectedDocuments.some(
                  //   (select) => select.id === doc.id,
                  // )}
                  // onChange={(event) => {
                  //   setSelectedDocuments((prevState) =>
                  //     event.target.checked
                  //       ? [...prevState, doc]
                  //       : prevState.filter((select) => select.id !== doc.id),
                  //   );
                  // }}
                  />
                  <Typography level="body-sm" lineHeight={1}>
                    {template.titre_document}
                  </Typography>
                  <Stack direction={"row"} gap={0.5}>
                    <Add sx={iconStyle} onClick={() => addTemplate(template)} />
                    <Print
                      sx={iconStyle}
                      onClick={async () => {
                        const doc = await addTemplate(template);
                        doc && print([doc]);
                      }}
                    />
                    {PrintLaterIcon(async () => {
                      const doc = await addTemplate(template);
                      doc && addToPrintManager(doc);
                    })}
                  </Stack>
                </Stack>
              </Box>
            );
          })}
        </Stack>
      </Box>

      <Stack
        sx={{
          width: "100%",
          height: "70vh",
          border: "1px solid #e3e3e3",
          borderRadius: "8px",
          p: 2,
        }}
        spacing={2}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction={"row"} alignItems="center">
            <Typography mr={1} level="body-sm">
              Actions:
            </Typography>
            <Button
              size={"sm"}
              variant={"plain"}
              color={"primary"}
              onClick={() =>
                setSelectedDocuments(isAllSelected ? [] : documents)
              }
            >
              {isAllSelected ? (
                <Deselect sx={{ color: "initial" }} />
              ) : (
                <SelectAll sx={{ color: "initial" }} />
              )}
            </Button>
            <Button
              size={"sm"}
              variant={"plain"}
              color={"primary"}
              disabled={!selectedDocuments.length}
              onClick={() => print(selectedDocuments)}
            >
              <Print
                sx={selectedDocuments.length ? { color: "initial" } : {}}
              />
            </Button>
            <Button
              size={"sm"}
              variant={"plain"}
              color={"primary"}
              disabled={!selectedDocuments.length}
              onClick={() =>
                selectedDocuments.forEach((doc) => addToPrintManager(doc))
              }
            >
              {PrintLaterIcon(() => null, !selectedDocuments.length)}
            </Button>
          </Stack>
          <Button onClick={() => setOpenModal(true)}>Créer un document</Button>
        </Stack>
        <Divider />
        <Stack sx={{ width: "100%", height: "100%" }} direction="row" gap={2}>
          <Stack
            padding={1}
            spacing={2}
            sx={{ height: "100%", minWidth: "250px", overflow: "scroll" }}
          >
            {documents.map((doc) => (
              <Stack
                key={doc.id}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
                sx={(theme) =>
                  currentDocument?.id === doc.id
                    ? { backgroundColor: theme.palette.background.level2 }
                    : {}
                }
              >
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <Checkbox
                    checked={selectedDocuments.some(
                      (select) => select.id === doc.id,
                    )}
                    onChange={(event) => {
                      setSelectedDocuments((prevState) =>
                        event.target.checked
                          ? [...prevState, doc]
                          : prevState.filter((select) => select.id !== doc.id),
                      );
                    }}
                  />
                  <Typography
                    sx={(theme) => ({
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: theme.palette.background.level2,
                      },
                    })}
                    level="body-sm"
                    lineHeight={1}
                    onClick={() => setCurrentDocument(doc)}
                  >
                    {doc.titre_document}
                  </Typography>
                </Stack>
                <Stack direction={"row"} gap={0.5}>
                  <Print sx={iconStyle} onClick={() => print([doc])} />
                  {PrintLaterIcon(() => addToPrintManager(doc))}
                  <Delete
                    sx={{ fill: "red", cursor: "pointer" }}
                    onClick={() => deleteDocument(doc.id)}
                  />
                </Stack>
              </Stack>
            ))}
          </Stack>
          <div style={{ flex: 1 }}>{preview()}</div>
        </Stack>
      </Stack>
      {createDocumentModal()}
      {
        <PrintModal
          open={openPrintModal}
          onClose={() => setOpenPrintModal(false)}
          fileUrl={fileBlobURL}
        />
      }
    </Stack>
  );
}
