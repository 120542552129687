import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/joy";
import Snackbar from "@mui/joy/Snackbar";
import React from "react";

interface SnackbarContextType {
  show: (
    message: string,
    color: "danger" | "success" | "warning" | "neutral" | "primary",
  ) => void;
}

const SnackbarContext = React.createContext<SnackbarContextType | undefined>(
  undefined,
);

export function useSnackbar() {
  const context = React.useContext(SnackbarContext);
  if (!context) {
    throw new Error(
      "useSnackbarContext must be used within a SnackbarContextProvider",
    );
  }
  return context;
}

interface SnackbarContextProviderProps {
  children: React.ReactNode;
}

export function SnackbarContextProvider({
  children,
}: SnackbarContextProviderProps) {
  const [open, setOpen] = React.useState(false);
  const [color, setColor] = React.useState<
    "danger" | "success" | "warning" | "neutral" | "primary"
  >("neutral");
  const [message, setMessage] = React.useState("");
  const show = (
    message: string,
    color: "danger" | "success" | "warning" | "neutral" | "primary",
  ) => {
    setMessage(message);
    setColor(color);
    setOpen(true);
    // after 5 seconds, close the snackbar only if it's success and still open
    // also cancel previous timeout if it's still running
    setTimeout(() => {
      if (color === "success") {
        setOpen(false);
      }
    }, 5000);
  };

  return (
    <SnackbarContext.Provider value={{ show }}>
      <Snackbar
        open={open}
        variant="outlined"
        color={color}
        onClose={(e, reason) => {
          if (reason === "clickaway") {
            return;
          }
        }}
        endDecorator={
          <IconButton onClick={() => setOpen(false)} size="sm" color={color}>
            <Close />
          </IconButton>
        }
      >
        {message}
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
}
