import Add from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import FormComponent from "../../../../components/Forms/FormComponent";
import { useApi } from "../../../../contexts/ApiContext";
import {
  FormField,
  OperationTemplatesResponse,
  PeriOpData,
} from "../../../../models/custom";
import { Operation } from "../../../../models/types";
import { useSnackbar } from "../../../../contexts/SnackbarContext";

const excluded_fields = [
  "id",
  "patient",
  "created_at",
  "updated_at",
  "deleted_at",
  "zkf_patient",
  "zkf_operation",
];

interface Template {
  label: string;
  value: string;
}

export default function OperationPreOp({
  operation,
}: {
  operation: Operation;
}) {
  const api = useApi();
  const snackbar = useSnackbar();

  const [templates, setTemplates] = React.useState<Template[] | null>(null);
  const [displayedTemplates, setDisplayedTemplates] = React.useState<
    Template[]
  >([]);
  const [templatesFields, setTemplatesFields] = React.useState<
    Map<string, { [key: string]: FormField }> // map of template_name -> fields
  >(new Map());

  // mapping values of the selected templates, keeping in mind that there can be multiple instances of the same template
  // example: VeineSuperficielleLaser has two instances, so we need to store them in an array
  const [data, setData] = React.useState<Map<string, PeriOpData[]>>(new Map());

  const fetchTemplateField = async (
    template: Template | null,
  ): Promise<void> => {
    if (template) {
      // check if the template already exists in data, if we don't have the fields, we need to fetch them
      if (data.has(template.value)) {
        return Promise.resolve();
      }
      // also get the fields for the selected template
      try {
        const templatesFields = await api.getOperationsTemplateFields(
          "chirurgie_vasculaire",
          template.value,
        );
        const fields: { [key: string]: FormField } = {};
        Object.keys(templatesFields).forEach((key) => {
          if (excluded_fields.includes(key)) {
            return;
          }
          if (
            ["pre_op", null].includes(templatesFields[key].timing_operation)
          ) {
            fields[key] = templatesFields[key];
          }
        });

        setTemplatesFields((prev) => {
          const newMap = new Map(prev);
          newMap.set(template.value, fields);
          return newMap;
        });

        return Promise.resolve();
      } catch (err) {
        console.error(err);
        snackbar.show(
          "Erreur lors de la récupération des champs du template",
          "danger",
        );
        return Promise.reject();
      }
    } else {
      snackbar.show("Template non trouvé", "danger");
      return Promise.reject();
    }
  };

  const addTemplate = async (selectedTemplate: Template | null) => {
    // then add the selected template to the data with the fields
    // check if the template already exists in data, if not, add it
    if (selectedTemplate) {
      await fetchTemplateField(selectedTemplate);
      // create the new instance with empty string properties of the fields
      // don't forget the zkf_operation
      const templateFields = templatesFields.get(selectedTemplate.value);
      // console.log(newTemplateFields);
      // bind a value to each field
      const newTemplateFields: PeriOpData = {};
      Object.keys(templateFields || {}).forEach((key) => {
        // depending on type, we need to set a default value
        if (!templateFields) return;
        if (templateFields[key].type === "string") {
          newTemplateFields[key] = "";
        } else if (templateFields[key].type === "integer") {
          newTemplateFields[key] = 0;
        } else if (templateFields[key].type === "choice") {
          newTemplateFields[key] = "";
        } else if (templateFields[key].type === "boolean") {
          newTemplateFields[key] = false;
        } else if (templateFields[key].type === "date") {
          newTemplateFields[key] = new Date().toISOString();
        } else if (templateFields[key].type === "time") {
          // format YYYY-MM-DDTHH:mm:ss.SSSZ => HH:mm:ss
          newTemplateFields[key] = new Date().toISOString().slice(11, 19);
        }
      });
      newTemplateFields["zkf_operation"] = operation?.id as string;
      console.log(newTemplateFields);

      if (!data.has(selectedTemplate.value)) {
        setData((prev) => {
          const newMap = new Map(prev);
          newMap.set(selectedTemplate.value, [newTemplateFields]);
          return newMap;
        });
      } else {
        // add a new instance of the selected template
        setData((prev) => {
          const newMap = new Map(prev);
          const temp = newMap.get(selectedTemplate.value);
          if (temp) {
            temp.push(newTemplateFields);
          }
          newMap.set(selectedTemplate.value, temp!);
          return newMap;
        });
      }
    }
  };

  const update = () => {
    // tricky here, but, if in our templates, we have an ID, we need to update the instance with the ID
    // if we don't have an ID, we need to create a new instance and then update with the returned id, it's two different routes
    Array.from(data).forEach(([templateName, instances]) => {
      instances.forEach(async (instance) => {
        if (instance.id) {
          // update the instance
          try {
            await api.updateOperationTemplate(
              "chirurgie_vasculaire",
              templateName,
              instance.id as string,
              instance,
            );
            snackbar.show("Modifications enregistrées", "success");
          } catch (err) {
            console.error(err);
            snackbar.show("Erreur lors de l'enregistrement", "danger");
          }
        } else {
          // create a new instance
          try {
            const res = await api.createOperationTemplate(
              "chirurgie_vasculaire",
              templateName,
              instance,
            );
            setData((prev) => {
              const newMap = new Map(prev);
              const temp = newMap.get(templateName);
              if (temp) {
                temp[temp.length - 1].id = res.id;
              }
              newMap.set(templateName, temp!);
              return newMap;
            });
            snackbar.show("Modifications enregistrées", "success");
          } catch (err) {
            console.error(err);
            snackbar.show("Erreur lors de l'enregistrement", "danger");
          }
        }
      });
    });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value;
    if (search.length === 0) {
      setDisplayedTemplates(templates || []);
      return;
    }
    const filtered = templates?.filter((template) => {
      return template.label.toLowerCase().includes(search.toLowerCase());
    });
    setDisplayedTemplates(filtered || []);
  };

  React.useEffect(() => {
    async function getPeriOp(): Promise<void> {
      if (operation) {
        api
          ?.getOperationPeriOp(operation.id)
          .then((res) => {
            // remove "Operation" key from object, and check for the other ones which are templates and store them in selectedTemplates
            // for example there can be multiple instances of the same template, so we need to store them in an array
            // and their values in a map
            Object.keys(res).forEach((key) => {
              // define the type as sure that key is not "Operation"
              if (key !== "Operation" && res[key].length > 0) {
                // we could have multiple instances of the same template,
                // so we need to store them in an array
                const temp: PeriOpData[] = [];
                res[key].forEach((instance) => {
                  temp.push(instance as PeriOpData);
                });
                setData((prev) => {
                  const newMap = new Map(prev);
                  newMap.set(key, temp);
                  return newMap;
                });
                // for each template, we need to fetch the fields
                fetchTemplateField({ label: key, value: key });
              }
            });
            return Promise.resolve();
          })
          .catch((err) => {
            console.error(err);
            return Promise.reject();
          });
      } else {
        return Promise.reject();
      }
    }
    getPeriOp().then(() => {
      api?.getOperationTemplates().then((res: OperationTemplatesResponse) => {
        const temp: { label: string; value: string }[] = [];
        Object.keys(res.models).forEach((key) => {
          temp.push({ label: res.models[key], value: key });
        });
        setTemplates(temp);
        setDisplayedTemplates(temp);
      });
    });
  }, [api, operation]);

  return !!operation ? (
    <Stack
      direction="column"
      justifyContent="space-between"
      gap={2}
      sx={{ minHeight: "80vh" }}
    >
      <div>
        <Stack direction="row" justifyContent="space-between" gap={2}>
          <Stack direction="row" gap={2}>
            <Typography level="title-lg">
              Modification des champs pre-opératoires
            </Typography>
          </Stack>
          <Button onClick={() => update()}>Enregistrer</Button>
        </Stack>

        <Stack direction="row" gap={2} sx={{ mt: 2 }} alignItems="flex-start">
          <Box
            sx={{
              width: "250px",
              background: (theme) => theme.palette.background.level1,
              borderTop: "1px solid #e3e3e3",
              borderRight: "1px solid #e3e3e3",
              borderBottom: "1px solid #e3e3e3",
              borderTopRightRadius: "8px",
              borderBottomRightRadius: "8px",
              ml: -2,
              pl: 2,
              pr: 2,
              py: 2,
            }}
          >
            <Typography level="title-sm">Ajouter un template</Typography>
            <Input
              sx={{ mt: 3 }}
              type="search"
              size="sm"
              placeholder="Rechercher un template"
              onChange={handleSearch}
              startDecorator={<SearchIcon fontSize="small" />}
            />
            <Stack
              direction="column"
              gap={1}
              sx={{ mt: 2, maxHeight: "60vh", overflow: "scroll" }}
            >
              {Object.values(displayedTemplates || {}).map(
                (template, index) => {
                  return (
                    <Box
                      key={index}
                      sx={(theme) => ({
                        "&:hover": {
                          backgroundColor: theme.palette.background.level2,
                          cursor: "pointer",
                        },
                        p: 0.5,
                        borderRadius: "sm",
                      })}
                      onClick={() => addTemplate(template)}
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={0.5}
                      >
                        <Typography level="body-sm" lineHeight={1}>
                          {template.label}
                        </Typography>
                        <Add
                          fontSize="small"
                          sx={{
                            color: "inherit",
                          }}
                        />
                      </Stack>
                    </Box>
                  );
                },
              )}
            </Stack>
          </Box>

          <Stack
            sx={{ width: "100%" }}
            direction="row"
            gap={2}
            flexWrap="wrap"
            alignItems="flex-start"
          >
            {Array.from(data).length > 0 ? (
              Array.from(data).map(([templateName, instances]) => {
                return instances.map((instance: PeriOpData, index) => {
                  return (
                    <Sheet
                      key={templateName + index}
                      sx={{
                        p: 2,
                        borderRadius: "sm",
                        minWidth: "calc(50% - 8px)",
                        maxWidth: "calc(50% - 8px)",
                        flex: 1,
                      }}
                      variant="outlined"
                    >
                      <Typography level="title-md">
                        {templates &&
                          templates.find((t) => t.value === templateName)
                            ?.label}
                      </Typography>
                      <Stack sx={{ mt: 2 }} direction="column" gap={2}>
                        {Object.keys(
                          templatesFields.get(templateName) || {},
                        ).map((fieldKey) => {
                          const field =
                            templatesFields.get(templateName)?.[fieldKey];
                          if (!field) return null;
                          return (
                            <FormComponent
                              key={fieldKey}
                              input={field}
                              value={instance[fieldKey]}
                              onUpdate={(value) => {
                                setData((prev) => {
                                  const newMap = new Map(prev);
                                  const temp = newMap.get(templateName);
                                  if (temp) {
                                    temp[index][fieldKey] = value;
                                  }
                                  newMap.set(templateName, temp!);
                                  return newMap;
                                });
                              }}
                            />
                          );
                        })}
                      </Stack>
                    </Sheet>
                  );
                });
              })
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "70vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <div>
                  <Typography level="title-lg">Aucun template</Typography>
                  <Typography level="body-sm">
                    Ajoutez un template pour commencer
                  </Typography>
                </div>
              </Box>
            )}
          </Stack>
        </Stack>
      </div>
      <Stack direction="row" justifyContent="flex-end" gap={2}>
        <Button onClick={() => update()}>Enregistrer</Button>
      </Stack>
    </Stack>
  ) : (
    <p>Loading</p>
  );
}
