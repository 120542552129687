import React from "react";
import PDFPreviewAndPrint from "../PDF/PDFPreview";
import WordPreview from "../Word/WordPreview";

interface PreviewFileProps {
  file: File | null;
  height?: string;
}

const PreviewFile: React.FC<PreviewFileProps> = ({
  file,
  height = "400px",
}) => {
  if (!file) return null;

  if (file.type === "application/pdf") {
    return (
      <PDFPreviewAndPrint
        fileUrl={URL.createObjectURL(file)}
        withPrint={false}
        theme={"light"}
        height={height}
      />
    );
  }

  if (
    file.type === "application/msword" ||
    file.type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ) {
    return <WordPreview file={file} height={height} />;
  }

  if (file.type.startsWith("image/")) {
    return (
      <img
        src={URL.createObjectURL(file)}
        alt="Selected Preview"
        style={{ maxWidth: "100%", maxHeight: height }}
      />
    );
  }

  console.error(`Type ${file.type} is not supported`);
  return null;
};

export default PreviewFile;
