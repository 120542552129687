import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import Sidebar from "../components/Sidebar";
import { useUser } from "../contexts/UserContext";
import { Navigate } from "react-router-dom";
import LoadingPage from "../components/LoadingPage";

interface ConnectedLayoutProps {
  children: React.ReactNode;
}

export default function ConnectedLayout({ children }: ConnectedLayoutProps) {
  const { user, loading } = useUser();

  if (loading) {
    return <LoadingPage />;
  }

  // if (!user) {
  //   return <Navigate to="/login" replace />;
  // }

  return (
    <Stack direction="row">
      <Box
        sx={{
          width: "252px",
          minWidth: "252px",
          maxWidth: "252px",
          flexShrink: 0,
          p: 2,
          backgroundColor: (theme) => theme.palette.background.level1,
          borderRight: "1px solid",
          borderColor: (theme) => theme.palette.divider,
          minHeight: "100vh",
        }}
      >
        <Sidebar />
      </Box>
      <Box sx={{ flex: 1, p: 2, position: "relative" }}>
        <Box sx={{ minHeight: "100vh" }}>{children}</Box>
      </Box>
    </Stack>
  );
}
