import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import DialogTitle from "@mui/joy/DialogTitle";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalOverflow from "@mui/joy/ModalOverflow";
import Stack from "@mui/joy/Stack";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import React from "react";
import { PaginatedResponse } from "../../clients/ApiClient";
import { CreatePatient } from "../../components/Forms/CreatePatient";
import { useApi } from "../../contexts/ApiContext";
import { Patient } from "../../models/types";
import { router } from "../../router";

export default function ListOfPatients() {
  // Context
  const api = useApi();

  // States
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState("");
  const [patients, setPatients] =
    React.useState<PaginatedResponse<Patient> | null>(null);
  const [openCreatePatientModal, setOpenCreatePatientModal] =
    React.useState(false);

  const fetchPatients = React.useCallback(() => {
    if (api) {
      api.listPatients(page).then((res) => {
        setPatients(res);
      });
    }
  }, [api, page, setPatients]);

  React.useEffect(() => {
    fetchPatients();
  }, [fetchPatients]);

  if (!patients) {
    return null;
  }

  return (
    <>
      <Box>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography level="h1" sx={{ mt: 2 }}>
            Patients
          </Typography>
          <Button
            onClick={() => setOpenCreatePatientModal(true)}
            startDecorator={<AddIcon />}
          >
            Ajouter un patient
          </Button>
        </Stack>
        {patients.results.length === 0 ? (
          <Typography level="title-md" sx={{ mt: 2 }}>
            Aucun patient trouvé
          </Typography>
        ) : (
          <>
            <Stack direction="row" gap={2} sx={{ mt: 4 }}>
              <Stack direction="column">
                <Typography level="title-md">Rechercher un patient</Typography>
                <Input
                  placeholder="Antoine DUPONT"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Stack>
            </Stack>

            <Table sx={{ borderRadius: "sm", mt: 2 }} variant="outlined">
              <thead>
                <tr>
                  <th>Nom</th>
                  <th>Prénom</th>
                  <th>Age</th>
                </tr>
              </thead>
              <tbody>
                {patients.results.map((patient) => (
                  <tr
                    key={patient.id}
                    style={{ cursor: "pointer" }}
                    onClick={() => router.navigate("/patient/" + patient.id)}
                  >
                    <td>{patient.nom_naissance}</td>
                    <td>{patient.prenom}</td>
                    <td>{patient.dob}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={3}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        variant="soft"
                        onClick={() => setPage(page - 1)}
                        disabled={page === 0}
                      >
                        Page précédente
                      </Button>
                      <Button variant="soft" sx={{ mx: 2 }} disabled>
                        Page {page}{" "}
                        {patients?.next &&
                          " — " + Math.floor(patients?.count / 10)}
                      </Button>
                      <Button
                        variant="soft"
                        onClick={() => setPage(page + 1)}
                        disabled={!patients?.next}
                      >
                        Page suivante
                      </Button>
                    </Box>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </>
        )}
      </Box>
      <Modal
        open={openCreatePatientModal}
        onClose={() => setOpenCreatePatientModal(false)}
      >
        <ModalOverflow>
          <ModalDialog sx={{ width: "400px" }}>
            <ModalClose />
            <DialogTitle>Ajouter un nouveau patient</DialogTitle>
            <CreatePatient />
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </>
  );
}
