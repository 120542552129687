import Autocomplete from "@mui/joy/Autocomplete";
import React, { useEffect, useState } from "react";
import { useApi } from "../../contexts/ApiContext";
import { Intervenant } from "../../models/types";

interface OperatorSelectorProps {
  onSelect: (value: number[]) => void;
  exludeOperators?: number[];
}

export default function OperatorSelector({
  onSelect,
  exludeOperators,
}: OperatorSelectorProps) {
  const api = useApi();
  const [operators, setOperators] = useState<Intervenant[]>([]);
  const [selectedOperators, setSelectedOperators] =
    React.useState<Intervenant[]>();

  useEffect(() => {
    api
      .getUserIntervenantsEvents()
      .then((res) =>
        setOperators(
          res.Operateur.filter(
            (o) => !exludeOperators?.includes(o.zkf_user as number),
          ),
        ),
      );
  }, [api, exludeOperators]);

  return (
    <Autocomplete
      sx={{ flex: 1 }}
      value={selectedOperators}
      multiple
      disableCloseOnSelect
      onChange={(e, newValue) => {
        onSelect(newValue.map((o) => o.zkf_user as number) || []);
        setSelectedOperators(newValue || []);
      }}
      disabled={operators.length === 0}
      options={operators}
      getOptionLabel={(option) => `${option.nom} ${option.prenom}`}
    />
  );
}
