import Print from "@mui/icons-material/Print";
import React from "react";
import { usePrintManager } from "../../contexts/PrintManagerContext";
import PrintManagerDrawer from "./PrintManagerDrawer";
import Badge from "@mui/joy/Badge";
import Drawer from "@mui/joy/Drawer";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";

export default function PrintManager() {
  const { files } = usePrintManager();

  const [open, setOpen] = React.useState(false);

  return (
    <Badge
      badgeContent={files.length && files.length.toString()}
      color="primary"
    >
      <Drawer
        size="md"
        variant="plain"
        open={open}
        onClose={() => setOpen(false)}
        slotProps={{
          content: {
            sx: {
              bgcolor: "transparent",
              boxShadow: "none",
              width: "100vw",
            },
          },
        }}
      >
        <PrintManagerDrawer />
      </Drawer>
      <Sheet
        variant="outlined"
        sx={{
          p: 1,
          ml: -4,
          pl: 4,
          width: "calc(100% + 32px)",
          borderRadius: "sm",
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
          display: "flex",
          alignItems: "center",
          textWrap: "nowrap",
          transition: "all 0.3s",
          "&:hover": {
            cursor: "pointer",
            ml: -3,
          },
        }}
        onClick={() => setOpen(true)}
      >
        <Typography
          level="title-sm"
          startDecorator={<Print />}
          component={"span"}
        >
          Gestionnaire d'impression
        </Typography>
      </Sheet>
    </Badge>
  );
}
