import Delete from "@mui/icons-material/Delete";
import Alert from "@mui/joy/Alert";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardOverflow from "@mui/joy/CardOverflow";
import Checkbox from "@mui/joy/Checkbox";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Stack from "@mui/joy/Stack";
import Switch from "@mui/joy/Switch";
import Tab from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import Tabs from "@mui/joy/Tabs";
import Typography from "@mui/joy/Typography";
import React from "react";
import { useParams } from "react-router-dom";
import {
  emptyAdresse,
  emptyEmail,
  emptyTelephone,
  panelRender,
} from "../../components/ContactPoint/ContactPoint";
import { useApi } from "../../contexts/ApiContext";
import { Adresse, Email, Patient, Telephone } from "../../models/types";

export default function PatientEditPage() {
  const api = useApi();
  const { id } = useParams();

  const [error, setError] = React.useState<string | null>(null);
  const [patient, setPatient] = React.useState<Patient | null>(null);
  const [coverageOptions, setCoverageOptions] = React.useState<string[]>([]);
  const [emails, setEmails] = React.useState<Email[]>([{ ...emptyEmail }]);
  const [telephones, setTelephones] = React.useState<Telephone[]>([
    { ...emptyTelephone },
  ]);
  const [adresses, setAdresses] = React.useState<Adresse[]>([
    { ...emptyAdresse },
  ]);

  const width = "100px";

  const addEmail = () => setEmails([...emails, { ...emptyEmail }]);
  const addTelephone = () =>
    setTelephones([...telephones, { ...emptyTelephone }]);
  const addAdresse = () => setAdresses([...adresses, { ...emptyAdresse }]);

  const updateEmails = (index: number, field: string, value: any) =>
    setEmails((prevData) =>
      prevData.map((data, i) =>
        index !== i ? data : { ...data, [field]: value },
      ),
    );

  const updateTelephones = (index: number, field: string, value: any) =>
    setTelephones((prevData) =>
      prevData.map((data, i) =>
        index !== i ? data : { ...data, [field]: value },
      ),
    );

  const updateAdresses = async (index: number, field: string, value: any) => {
    const newData = { [field]: value };
    if (field === "code_postal" && value.length === 5) {
      const villes = await api.searchVilleByZipCode(value);
      if (villes.length === 1) {
        newData.ville = villes[0].nom_ville;
      }
    }
    setAdresses((prevData) =>
      prevData.map((data, i) => (index !== i ? data : { ...data, ...newData })),
    );
  };

  const container = (
    title: string,
    canDelete: boolean,
    handleDelete: () => void,
    isFavori: boolean,
    handleFavori: () => void,
    content: React.ReactNode,
  ) => (
    <Box
      key={title}
      padding={2}
      style={{
        position: "relative",
        border: "1px solid #ccc",
        borderRadius: "8px",
      }}
    >
      <Typography
        sx={{
          position: "absolute",
          top: "-10px",
          left: "10px",
          backgroundColor: "white",
          padding: "0 8px",
          fontSize: "12px",
        }}
        endDecorator={
          canDelete && (
            <Delete
              style={{ fill: "red", cursor: "pointer" }}
              onClick={handleDelete}
            />
          )
        }
      >
        {title}
      </Typography>
      <Typography
        sx={{
          position: "absolute",
          top: "-10px",
          right: "10px",
          backgroundColor: "white",
          padding: "0 8px",
          fontSize: "12px",
        }}
        endDecorator={<Checkbox checked={isFavori} onClick={handleFavori} />}
      >
        Favori
      </Typography>
      {content}
    </Box>
  );

  const emailRender = (email: Email, index: number) =>
    container(
      `Email ${index + 1}`,
      emails.length > 1,
      () => setEmails(emails.filter((e, i) => i !== index)),
      email.preferred || false,
      () => updateEmails(index, "preferred", !email.preferred),
      <Stack spacing={2}>
        <Stack direction={"row"} alignItems={"center"}>
          <Typography sx={{ width }}>Categorie</Typography>
          <Input
            value={email.categorie || ""}
            onChange={(e) => updateEmails(index, "categorie", e.target.value)}
            style={{ flex: 1 }}
          />
        </Stack>
        <Stack direction={"row"} alignItems={"center"}>
          <Typography sx={{ width }}>Email</Typography>
          <Input
            value={email.email || ""}
            onChange={(e) => updateEmails(index, "email", e.target.value)}
            style={{ flex: 1 }}
          />
        </Stack>
      </Stack>,
    );

  const telephoneRender = (telephone: Telephone, index: number) =>
    container(
      `Telephone ${index + 1}`,
      telephones.length > 1,
      () => setTelephones(telephones.filter((e, i) => i !== index)),
      telephone.preferred || false,
      () => updateTelephones(index, "preferred", !telephone.preferred),
      <Stack spacing={2}>
        <Stack direction={"row"} alignItems={"center"}>
          <Typography sx={{ width }}>Categorie</Typography>
          <Select
            value={telephone.categorie || ""}
            onChange={(e, newValue) =>
              updateTelephones(index, "categorie", newValue || "")
            }
            style={{ width: "200px", flex: 1 }}
          >
            <Option value={"PORTABLE"}>Portable</Option>
            <Option value={"PROFESSIONNEL"}>Professionnel</Option>
            <Option value={"DOMICILE"}>Domicile</Option>
            <Option value={"FAX"}>Fax</Option>
            <Option value={"AUTRE"}>Autre</Option>
          </Select>
        </Stack>
        <Stack direction={"row"} alignItems={"center"}>
          <Typography sx={{ width }}>Numero</Typography>
          <Input
            value={telephone.numero || ""}
            onChange={(e) => {
              if (/^\d{0,10}$/.test(e.target.value)) {
                updateTelephones(index, "numero", e.target.value);
              }
            }}
            style={{ flex: 1 }}
          />
        </Stack>
      </Stack>,
    );

  const adresseRender = (adresse: Adresse, index: number) =>
    container(
      `Adresse ${index + 1}`,
      adresses.length > 1,
      () => setAdresses(adresses.filter((e, i) => i !== index)),
      adresse.preferred || false,
      () => updateAdresses(index, "preferred", !adresse.preferred),
      <Stack spacing={2}>
        <Stack direction={"row"} spacing={2}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography width={"100px"}>No de rue</Typography>
            <Input
              value={adresse.numero_rue || ""}
              onChange={(e) => {
                if (/^\d{0,5}$/.test(e.target.value)) {
                  updateAdresses(index, "numero_rue", e.target.value);
                }
              }}
              style={{ flex: 1, width: "75px" }}
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={1} flex={1}>
            <Typography>Rue</Typography>
            <Input
              value={adresse.rue || ""}
              onChange={(e) => updateAdresses(index, "rue", e.target.value)}
              style={{ flex: 1 }}
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Typography width={"100px"}>Complement</Typography>
          <Input
            placeholder={"Complement d'adresse"}
            value={adresse.complement_adresse || ""}
            onChange={(e) =>
              updateAdresses(index, "complement_adresse", e.target.value)
            }
            style={{ flex: 1 }}
          />
        </Stack>
        <Stack direction={"row"} spacing={2}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography width={"100px"}>Code Postal</Typography>
            <Input
              value={adresse.code_postal || ""}
              onChange={(e) => {
                if (/^\d{0,5}$/.test(e.target.value)) {
                  updateAdresses(index, "code_postal", e.target.value);
                }
              }}
              style={{ flex: 1, width: "75px" }}
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography>Ville</Typography>
            <Input
              value={adresse.ville || ""}
              onChange={(e) => updateAdresses(index, "ville", e.target.value)}
              style={{ flex: 1 }}
            />
          </Stack>
        </Stack>
      </Stack>,
    );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    patient &&
      setPatient({
        ...patient,
        [e.target.name]: e.target.value,
      });
  };

  const handleChangeSex = (
    event: React.SyntheticEvent | null,
    newValue: "MALE" | "FEMALE" | null,
  ) => {
    patient &&
      newValue &&
      setPatient({
        ...patient,
        sexe: newValue,
      });
  };

  const handleUpdatePatient = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!api || !patient) return;
    try {
      await api.updatePatient(patient);
    } catch (e: any) {
      setError("Une erreur est survenue lors de la mise à jour du patient.");
    }
  };

  React.useEffect(() => {
    const fetchPatient = async () => {
      if (!api || !id) return;
      try {
        const patient = await api.getPatient(id);
        setPatient(patient);
      } catch (e: any) {
        setError("Une erreur est survenue lors de la récupération du patient.");
      }
    };

    const fetchEmail = async () => {
      if (!api || !id) return;
      try {
        const emails = await api.listEmailsByPatient(id);
        if (emails.length === 0) {
          setEmails([{ ...emptyEmail }]);
        } else {
          setEmails(emails);
        }
      } catch (e: any) {
        setError("Une erreur est survenue lors de la récupération des emails.");
      }
    };

    const fetchTelephone = async () => {
      if (!api || !id) return;
      try {
        const phones = await api.listPhonesByPatient(id);
        if (phones.length === 0) {
          setTelephones([{ ...emptyTelephone }]);
        } else {
          setTelephones(phones);
        }
      } catch (e: any) {
        setError(
          "Une erreur est survenue lors de la récupération des téléphones.",
        );
      }
    };

    const fetchAdresse = async () => {
      if (!api || !id) return;
      try {
        const adresses = await api.listAddressesByPatient(id);
        if (adresses.length === 0) {
          setAdresses([{ ...emptyAdresse }]);
        } else {
          setAdresses(adresses);
        }
      } catch (e: any) {
        setError(
          "Une erreur est survenue lors de la récupération des adresses.",
        );
      }
    };

    const fetchCoverageOptions = async () => {
      if (!api) return;
      try {
        const options = await api.getCoverageOptions();
        setCoverageOptions(options.results.map((o) => o.nom));
      } catch (e: any) {
        setError(
          "Une erreur est survenue lors de la récupération des options de couverture.",
        );
      }
    };

    fetchPatient();
    fetchEmail();
    fetchTelephone();
    fetchAdresse();
    fetchCoverageOptions();
  }, [api, id]);

  if (!patient) {
    return null;
  }

  return (
    <Box sx={{ flex: 1, width: "100%" }}>
      <Stack direction="column" spacing={4}>
        <Typography level="h2">Edition des détails administratifs</Typography>
        <Stack
          spacing={4}
          sx={{
            display: "flex",
            maxWidth: "800px",
            mx: "auto",
            px: { xs: 2, md: 6 },
            py: { xs: 2, md: 3 },
          }}
        >
          <form onSubmit={handleUpdatePatient}>
            <Card>
              <Box sx={{ mb: 1 }}>
                <Typography level="title-md">
                  Informations administratives
                </Typography>
              </Box>
              <Divider />
              <Stack
                direction="row"
                spacing={3}
                sx={{ display: { xs: "none", md: "flex" }, my: 1 }}
              >
                <Stack direction="column" gap={2} sx={{ mt: 2 }}>
                  {error && <Alert color="danger">{error}</Alert>}
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <FormControl>
                      <FormLabel>Nom</FormLabel>
                      <Input
                        required
                        name="nom"
                        value={patient.nom}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Nom de naissance</FormLabel>
                      <Input
                        name="nom_naissance"
                        value={patient.nom_naissance}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Prénom.s</FormLabel>
                      <Input
                        required
                        name="prenom"
                        value={patient.prenom}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Stack>
                  <FormControl>
                    <FormLabel>Sexe</FormLabel>
                    <Select
                      name="sexe"
                      value={patient.sexe}
                      onChange={handleChangeSex}
                    >
                      <Option value="MALE">Homme</Option>
                      <Option value="FEMALE">Femme</Option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Date de naissance</FormLabel>
                    <Input
                      required
                      name="dob"
                      value={patient.dob}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl
                    orientation="horizontal"
                    sx={{ width: 300, justifyContent: "space-between" }}
                  >
                    <div>
                      <FormLabel>Le patient est-il décédé ?</FormLabel>
                    </div>
                    <Switch
                      checked={patient.etat === "DCD"}
                      endDecorator={patient.etat === "DCD" ? "Oui" : "Non"}
                      onChange={(event) =>
                        patient.etat === "DCD"
                          ? setPatient({ ...patient, etat: "VIVANT" })
                          : setPatient({ ...patient, etat: "DCD" })
                      }
                    />
                  </FormControl>
                  {patient.etat === "DCD" && (
                    <FormControl>
                      <FormLabel>Cause du décès</FormLabel>
                      <Input
                        name="cause_deces"
                        value={patient.cause_deces || ""}
                        onChange={handleChange}
                      />
                    </FormControl>
                  )}
                  <FormControl>
                    <FormLabel>Numéro de sécurité sociale (INS)</FormLabel>
                    <Input
                      name="numero_securite_sociale"
                      value={patient.numero_securite_sociale || ""}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Couverture maladie</FormLabel>
                    <Select>
                      {coverageOptions.map((option) => (
                        <Option key={option} value={option}>
                          {option}
                        </Option>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Stack>
              <CardOverflow
                sx={{ borderTop: "1px solid", borderColor: "divider" }}
              >
                <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
                  <Button type="submit" size="sm" variant="solid">
                    Sauvegarder
                  </Button>
                </CardActions>
              </CardOverflow>
            </Card>
          </form>
          <Card>
            <Box sx={{ mb: 1 }}>
              <Typography level="title-md">Points de contact</Typography>
            </Box>
            <Divider />

            <Tabs
              defaultValue={0}
              style={{ flex: 1, backgroundColor: "inherit" }}
            >
              <TabList tabFlex={1}>
                <Tab>Emails</Tab>
                <Tab>Téléphones</Tab>
                <Tab>Adresses</Tab>
              </TabList>
              {panelRender(
                0,
                addEmail,
                emails.map((email, index) => emailRender(email, index)),
              )}
              {panelRender(
                1,
                addTelephone,
                telephones.map((tel, index) => telephoneRender(tel, index)),
              )}
              {panelRender(
                2,
                addAdresse,
                adresses.map((adresse, index) => adresseRender(adresse, index)),
              )}
            </Tabs>
            <CardOverflow
              sx={{ borderTop: "1px solid", borderColor: "divider" }}
            >
              <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
                <Button size="sm" variant="solid">
                  Sauvegarder
                </Button>
              </CardActions>
            </CardOverflow>
          </Card>
        </Stack>
      </Stack>
    </Box>
  );
}
