import Draw from "@mui/icons-material/Draw";
import Checkbox from "@mui/joy/Checkbox";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Stack from "@mui/joy/Stack";
import Textarea from "@mui/joy/Textarea";
import Typography from "@mui/joy/Typography";
import React from "react";
import ConsultingSitePlaceSelector from "../../../components/Forms/ConsultingSitePlaceSelector";
import ConsultingSiteSelector from "../../../components/Forms/ConsultingSiteSelector";
import { Consultation, UserSites, UserSitesLieux } from "../../../models/types";

interface ConsultationFormProps {
  values: Partial<Consultation>;
  setValues: (values: Partial<Consultation>) => void;
}

export default function ConsultationForm({
  values,
  setValues,
}: ConsultationFormProps) {
  const [mode, setMode] = React.useState<Consultation["mode_consultation"]>(
    values.mode_consultation || "urgent",
  );
  const [siteId, setSiteId] = React.useState<UserSites["id"] | null>(
    values.zkf_site || null,
  );
  const [placeId, setPlaceId] = React.useState<UserSitesLieux["id"] | null>(
    values.zkf_lieu || null,
  );
  const [description, setDescription] = React.useState<string>(
    values.motif_consultation || "",
  );

  React.useEffect(() => {
    setValues({
      ...values,
      mode_consultation: mode,
      zkf_site: siteId!,
      zkf_lieu: placeId || null,
      motif_consultation: description,
    });
  }, [mode, siteId, placeId, description]);

  return (
    <Stack direction="column" gap={2}>
      <FormControl>
        <Typography
          level="title-sm"
          startDecorator={
            <Checkbox
              checked={mode === "urgent"}
              onChange={(e) => {
                e.target.checked ? setMode("urgent") : setMode("programme");
              }}
            />
          }
        >
          Urgence
        </Typography>
      </FormControl>
      <FormControl>
        <FormLabel>
          Site et lieu de consultation<sup>*</sup>
        </FormLabel>
        <Stack direction="row" gap={1}>
          <ConsultingSiteSelector onSelect={(site_id) => setSiteId(site_id)} />
          <ConsultingSitePlaceSelector
            siteId={siteId}
            onSelect={(place_id) => setPlaceId(place_id)}
            key={siteId}
          />
        </Stack>
      </FormControl>
      <FormControl>
        <FormLabel>
          <Draw /> Motif<sup>*</sup>
        </FormLabel>
        <Textarea
          sx={{ width: "100%" }}
          minRows={4}
          maxRows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </FormControl>
    </Stack>
  );
}
