import { Autocomplete, Textarea } from "@mui/joy";
import Checkbox from "@mui/joy/Checkbox";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { FormField } from "../../models/custom";
import TimeField from "./TimeField";

export default function FormComponent({
  input,
  value,
  onUpdate,
}: {
  input: FormField;
  value: any;
  onUpdate: (newValue: any) => void;
}) {
  if (input.type === "string") {
    return (
      <Stack gap={1}>
        <Typography level="body-md">{input.label}</Typography>
        <Textarea
          sx={{ background: "white" }}
          value={value}
          onChange={(e) => onUpdate(e.target.value)}
          minRows={3}
          maxRows={3}
        />
      </Stack>
    );
  } else if (input.type === "field") {
    return (
      <Stack gap={1}>
        <Typography level="body-md">{input.label}</Typography>
        <Input
          sx={{ background: "white" }}
          value={value}
          onChange={(e) => onUpdate(e.target.value)}
          type="text"
        />
      </Stack>
    );
  } else if (input.type === "integer") {
    return (
      <Stack gap={1}>
        <Typography level="body-md">{input.label}</Typography>
        <Input
          sx={{ background: "white" }}
          type="number"
          value={value}
          onChange={(e) => onUpdate(e.target.value)}
        />
      </Stack>
    );
  } else if (input.type === "choice") {
    return (
      <Stack gap={1}>
        <Typography level="body-md">{input.label}</Typography>
        <Autocomplete
          sx={{ background: "white" }}
          freeSolo
          value={value ? value : ""}
          onChange={(e, newValue) => {
            onUpdate(newValue);
          }}
          inputValue={value ? value : ""}
          onInputChange={(e, value) => onUpdate(value)}
          options={input.choices ? input.choices.map((c) => c.value) : []}
          getOptionLabel={(o) => o}
          getOptionKey={(o) => (o ? o.value : "")}
        />
      </Stack>
    );
  } else if (input.type === "boolean") {
    return (
      <Typography
        sx={{ cursor: "pointer" }}
        level="body-md"
        startDecorator={
          <Checkbox
            checked={value}
            onChange={(e) => onUpdate(e.target.checked)}
          />
        }
        onClick={value ? () => onUpdate(false) : () => onUpdate(true)}
      >
        {input.label}
      </Typography>
    );
  } else if (input.type === "date") {
    return (
      <Stack gap={1}>
        <Typography level="body-md">{input.label}</Typography>
        <DatePicker
          sx={{ background: "white" }}
          value={value ? (value.isValid ? value : dayjs()) : dayjs()}
          onChange={(newValue) => onUpdate(newValue.toISOString())}
        />
      </Stack>
    );
  } else if (input.type === "decimal") {
    return (
      <Stack gap={1}>
        <Typography level="body-md">{input.label}</Typography>
        <Input
          sx={{ background: "white" }}
          type="number"
          value={value}
          onChange={(e) => onUpdate(e.target.value)}
        />
      </Stack>
    );
  } else if (input.type === "time") {
    return (
      <Stack gap={1}>
        <Typography level="body-md">{input.label}</Typography>
        <TimeField
          value={value}
          onChange={(newValue: string) => onUpdate(newValue)}
        />
      </Stack>
    );
  } else if (input.type === "float") {
    return (
      <Stack gap={1}>
        <Typography level="body-md">{input.label}</Typography>
        <Input
          sx={{ background: "white" }}
          type="number"
          value={value}
          onChange={(e) => onUpdate(e.target.value)}
        />
      </Stack>
    );
  } else {
    return <b style={{ color: "red" }}>Unknown field type: {input.type}</b>;
  }
}
